import employeeRepository from '@/api/now/employee';

export async function validationRuleCheckOnBehalfOf(formField) {
  if (!formField.value) {
    formField.email = '';

    return formField;
  }

  try {
    let responseData;
    if (formField.value.includes('@')) {
      responseData = await employeeRepository.getEmployeeByEmail(formField.value);
    } else {
      responseData = await employeeRepository.getEmployeeById(formField.value);
    }

    if (responseData?.data?.nikeId && responseData?.data?.email) {
      formField.value = responseData.data.nikeId;
      formField.email = responseData.data.email;
    } else {
      formField.value = '';
      formField.email = '';
    }
  } catch (err) {
    console.error(err);
    formField.value = '';
    formField.email = '';
  }

  return formField;
}
