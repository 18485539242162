import { defineAsyncComponent } from 'vue';

import { BASE_LOADER_TYPES } from '@/components/ui/BaseLoader';

const BaseLoader = defineAsyncComponent(() => import('@/components/ui/BaseLoader'));

export const importBaseLoaderConfig = {
  components: {
    BaseLoader,
  },

  data() {
    return {
      BASE_LOADER_TYPES,
    };
  },
};
