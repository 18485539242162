const myApprovalsData = {
  getMyApprovalsRequestParams: ({ pageSize, pageNumber }) => {
    return {
      pageSize,
      pageNumber,
      sortBy: null,
      sortOrder: null,
    };
  },
};

const myApprovalsTable = {};

export default {
  ...myApprovalsData,
  ...myApprovalsTable,
};
