import state from './myOrders.state';
import getters from './myOrders.getters';
import actions from './myOrders.actions';
import mutations from './myOrders.mutations';

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
