import { notify } from '@kyvg/vue3-notification';

const defaultConfig = {
  group: 'network',
  position: 'top center',
  classes: 'notification-content',
};

export function showSuccessBanner({
  title, text = '', duration = 5000,
}) {
  notify({
    text,
    title,
    duration,
    type: 'success',
    ...defaultConfig,
  });
}

export function showErrorBanner({
  title, text = '', duration = -1,
}) {
  notify({
    text,
    title,
    duration,
    type: 'error',
    ...defaultConfig,
  });
}
