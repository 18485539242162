import singleOrdersRepository from '@/api/now/orders';

export default {
  async fetchSOCData({ commit }) {
    try {
      const { data } = await singleOrdersRepository.getSOCDataFromServer();

      await commit('SET_SOC_ORDER_DATA', data);
    } catch (e) {
      console.error('SOC DATA FETCHING:', e);
    }
  },

  setSelectedSocOrderType({ state, commit }, orderType) {
    const wasOrderTypeSelected = orderType === state.selectedSocOrderType;

    if (!wasOrderTypeSelected) {
      commit('SET_SELECTED_SOC_ORDER_TYPE', orderType);
    }
  },

  setAllowedOrderTypes({ commit }, allowedOrderTypes) {
    commit('SET_ALLOWED_ORDER_TYPES', allowedOrderTypes);
  },
};
