export default {
  oktaTokenStorage: 'okta-token-storage',
  lastUserAppActivity: 'lastUserAppActivity',
  userAppActiveState: 'userAppActiveState',
  submissionIds: 'submissionIds:',
  showedSubmissionsDetails: 'showedSubmissionsDetails:',
  globalFilter: 'globalFilter',
  language: 'mp-om-locale',
  isLoggedIn: 'isLoggedIn',
};
