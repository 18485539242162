export const validationRuleIsOnlyDigits = (formField) => {
  if (!formField.touched) {
    return formField;
  }

  const pattern = /^[0-9]+$/;

  if (pattern.test(formField.value) || !formField.value) {
    formField.errors = formField.errors.filter((error) => !error.digitsOnly);
  } else {
    formField.errors.push({ digitsOnly: 'Only digits are allowed' });
  }

  return formField;
};
