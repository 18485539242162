const DATE_FORMAT_VARIANTS = [
  {
    name: 'EU',
    inputVariants: ['YYYY/MM/DD', 'DD/MM/YYYY', 'YYYY-MM-DD', 'DD-MM-YYYY'],
    output: 'DD/MM/YYYY',
  },
  {
    name: 'US',
    inputVariants: ['YYYY/DD/MM', 'MM/DD/YYYY', 'YYYY-MM-DD', 'DD-MM-YYYY'],
    output: 'MM/DD/YYYY',
  },
  {
    name: 'CN',
    inputVariants: ['YYYY/DD/MM', 'MM/DD/YYYY', 'YYYY-MM-DD', 'DD-MM-YYYY'],
    output: 'YYYY-MM-DD',
  },
];

export { DATE_FORMAT_VARIANTS };
