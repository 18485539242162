export default {

  setFormData(state, formData) {
    state.formData = { ...formData };
  },

  setBlockNames(state, blockNames) {
    state.blockNames = { ...blockNames };
  },

  setHeaderLevelData(state, items) {
    state.formData.fields = [...items];
  },

  setLineItems(state, items) {
    state.formData.lineItemFields = { ...items };
  },

  setLineItemColumnDefs(state, items) {
    state.formData.lineItemFields.columnDefs = [...items];
  },

  setLineItemDependencies(state, items) {
    state.formData.lineItemFields.dependencies = [...items];
  },

  setLineItemValidationRules(state, items) {
    state.formData.lineItemFields.validationRules = { ...items };
  },

  setRowData(state, items) {
    state.gridApi.setRowData?.(items);
    state.formData.lineItemFields.rowData = [...items];
  },

  setName(state, name) {
    state.name = name;
  },

  setOrderType(state, orderType) {
    state.orderType = orderType;
  },

  setPhase(state, phase) {
    state.phase = phase;
  },

  setDependencyBypass(state, isBypass) {
    state.isDependencyBypass = isBypass;
  },

  setUserContext(state, context) {
    state.userContext = context?.role || '';
  },

  setGridApi(state, gridApi) {
    state.gridApi = gridApi;
  },

  setDraftInfo(state, order) {
    state.draftOrderInfo = { id: order.id, version: order.version };
  },

  setAvailabilityInProgressCount(state, count) {
    state.availabilityInProgressCount = count;
  },

  setIsBEDataPopulated(state, flag) {
    state.isBEDataPopulated = flag;
  },

  pushRowToAvailabilityQueue(state, rowIndex) {
    state.rowsPendingForAvailability.push(rowIndex);
  },

  removeRowFromAvailabilityQueue(state, rowIndex) {
    state.rowsPendingForAvailability = state.rowsPendingForAvailability.filter(
      (index) => index !== rowIndex,
    );
  },

  clearAvailabilityQueue(state) {
    state.rowsPendingForAvailability = [];
  },

  setIsRowDataPaste(state, value) {
    state.isRowDataPaste = value;
  },

  setGeoData(state, geoData) {
    state.geoData = geoData;
  },
};
