import { getConditionResult } from '@/controllers/order/utils/dependency/helpers';

export const dependencyRuleOrOperator = params => {
  const { dependency, form } = params;

  if (!Array.isArray(dependency.conditions)) return false;

  return dependency.conditions.some(condition => {
    // Current implementation of or/and operators can only work with entire columns
    // and not individual line-level fields.
    const isHeaderField = condition.sourceFieldType !== 'LineLevel';
    const updatedField = isHeaderField ? form.fields.find(field => {
      return field.key === condition.sourceFieldKey;
    }) : { rowIndex: 0 };

    const conditionDependency = { ...condition, targetKey: dependency.targetKey };

    return getConditionResult({
      ...params,
      dependency: conditionDependency,
      updatedField,
    });
  });
};
