import mutations from './mutation-type';

const myApprovalsData = {
  [mutations.SET_MY_APPROVALS](state, myApprovals) {
    state.myApprovals = myApprovals;
  },

  [mutations.SET_IS_TABLE_DATA_LOADING](state, isTableDataLoading) {
    state.isTableDataLoading = isTableDataLoading;
  },
};

const myApprovalsTable = {
  [mutations.SET_PAGE_SIZE](state, pageSize) {
    state.pageSize = pageSize;
  },

  [mutations.SET_PAGE_NUMBER](state, pageNumber) {
    state.pageNumber = pageNumber;
  },

  [mutations.SET_TOTAL_AVAILABLE_ORDERS_NUMBER](state, totalAvailableOrdersNumber) {
    state.totalAvailableOrdersNumber = totalAvailableOrdersNumber;
  },

  [mutations.SET_TOTAL_AVAILABLE_PAGES_NUMBER](state, totalAvailablePagesNumber) {
    state.totalAvailablePagesNumber = totalAvailablePagesNumber;
  },
};

export default {
  ...myApprovalsData,
  ...myApprovalsTable,
};
