import mutations from './mutation-type';

const myOrdersData = {
  [mutations.SET_MY_ORDERS](state, myOrders) {
    state.myOrders = myOrders;
  },

  [mutations.SET_MY_ORDERS_ERRORS](state, myOrdersErrors) {
    state.myOrdersErrors = myOrdersErrors;
  },

  [mutations.SET_IS_MY_ORDERS_ERROR_LOADING](state, isMyOrdersLoading) {
    state.isMyOrdersErrorLoading = isMyOrdersLoading;
  },

  [mutations.SET_ORDER_TYPES](state, orderTypes) {
    state.orderTypes = orderTypes;
  },

  [mutations.SET_MY_ORDERS_SUMMARY](state, myOrdersSummary) {
    state.myOrdersSummary = myOrdersSummary;
  },

  [mutations.SET_IS_FETCHING_ORDERS](state, isFetchingOrders) {
    state.isFetchingOrders = isFetchingOrders;
  },

  [mutations.SET_TABLE_DATA_LOADING_ERROR](state, tableDataLoadingError) {
    state.tableDataLoadingError = tableDataLoadingError;
  },

  [mutations.SET_FILTERS_DATA_LOADING_ERROR](state, filtersDataLoadingError) {
    state.filtersDataLoadingError = filtersDataLoadingError;
  },
};

const filtersSelection = {
  [mutations.SET_SELECTED_ORDER_OWNERSHIP](state, selectedOrderOwnership) {
    state.selectedOrderOwnership = selectedOrderOwnership;
  },

  [mutations.SET_SELECTED_ORDER_TYPES](state, selectedOrderTypes) {
    state.selectedOrderTypes = selectedOrderTypes;
  },
};

const myOrdersTable = {
  [mutations.SET_GRID_API](state, gridApi) {
    state.gridApi = gridApi;
  },

  [mutations.SET_DATA_SOURCE](state, datasource) {
    state.datasource = datasource;
  },

  [mutations.SET_COLUMN_DEF](state, columnDef) {
    state.columnDef = columnDef;
  },

  [mutations.SET_TABLE_FILTERS](state, tableFilters) {
    state.tableFilters = tableFilters;
  },

  [mutations.SET_TABLE_PARAMS](state, tableParams) {
    state.tableParams = tableParams;
  },

  [mutations.SET_ORDER_ID](state, orderId) {
    state.orderId = orderId;
  },

  [mutations.SET_PAGE_SIZE](state, pageSize) {
    state.pageSize = pageSize;
  },

  [mutations.SET_PAGE_NUMBER](state, pageNumber) {
    state.pageNumber = pageNumber;
  },

  [mutations.SET_PAGE_TOKEN](state, { pageNumber, token }) {
    state.pageTokens[pageNumber] = token;
  },

  [mutations.RESET_PAGE_TOKEN](state) {
    // first page always has a `null` token
    state.pageTokens = [null];
  },

  [mutations.SET_SORTED_COLUMN_NAME](state, sortedColumnName) {
    state.sortedColumnName = sortedColumnName;
  },

  [mutations.SET_SORTED_COLUMN_ORDER](state, sortedColumnOrder) {
    state.sortedColumnOrder = sortedColumnOrder;
  },

  [mutations.SET_TOTAL_AVAILABLE_ORDERS_NUMBER](state, totalAvailableOrdersNumber) {
    state.totalAvailableOrdersNumber = totalAvailableOrdersNumber;
  },

  [mutations.SET_TOTAL_AVAILABLE_PAGES_NUMBER](state, totalAvailablePagesNumber) {
    state.totalAvailablePagesNumber = totalAvailablePagesNumber;
  },

  [mutations.SET_USER_PREFERENCES](state, userPreferences) {
    state.userPreferences = userPreferences;
  },
};

export default {
  ...myOrdersData,
  ...myOrdersTable,
  ...filtersSelection,
};
