import { isEqual } from 'lodash';
import { fieldNames } from '@/enums/singleOrderCreation/fieldNames';
import { sourcingMethodValues } from '@/enums/validationRules/dropdownValues';
import { mapDropdownItems } from '@/utils/dropdownItemsMappers';
import addressOverrideRepository from '@/api/now/singleOrderCreate/addressOverride';

import store from '@/store';

const mapSalesOrgs = (salesChannelsObj) => salesChannelsObj.map(({ name, sapId }) => ({ text: `${sapId} - ${name}`, value: sapId }));

export async function dependencyRuleSetAsyncItems({
  field, value, dependency, form,
}) {
  if (value) {
    const sourceField = dependency.sourceFieldKey;
    if (sourceField === fieldNames.salesOrganization || sourceField === fieldNames.sourcingMethod) {
      const salesChannels = await getOrFetchSalesChannels(form.fields, value);

      const populatedField = {
        ...field,
        items: salesChannels,
        errors: [],
      };

      if (salesChannels.length === 1) {
        populatedField.value = salesChannels[0].value;
      }

      return populatedField;
    }

    if (sourceField === fieldNames.country) {
      const regions = await getOrFetchRegions(value);
      const isRegionOnList = regions.some(({ value }) => value === field.value);

      return {
        ...field,
        items: regions,
        errors: [],
        value: isRegionOnList ? field.value : null,
      };
    }
  }

  return field;
}

const getOrFetchSalesChannels = async(fields) => {
  const selectedSalesOrganization = fields.find(
    ({ key }) => key === fieldNames.salesOrganization,
  ).value;
  const selectedSourcingMethod = fields.find(({ key }) => key === fieldNames.sourcingMethod)?.value
    || sourcingMethodValues.GENERAL;
  const userGeos = store.state.user.geo;

  if (!selectedSalesOrganization) {
    return [];
  }

  if (selectedSourcingMethod === sourcingMethodValues.AUTO) {
    return [{ text: sourcingMethodValues.AUTO, value: sourcingMethodValues.AUTO }];
  }

  let fetchedGeo = store.state.SOC.geoData;

  if (!fetchedGeo?.names || !isEqual(userGeos, fetchedGeo.names)) {
    fetchedGeo = await store.dispatch('SOC/fetchGeoData', userGeos);
  }

  const salesOrgsIndex = fetchedGeo.salesOrgs.findIndex(
    ({ sapId }) => sapId === Number(selectedSalesOrganization),
  );
  switch (selectedSourcingMethod) {
    case sourcingMethodValues.GENERAL:
      return mapSalesOrgs(fetchedGeo.salesOrgs[salesOrgsIndex].generalSalesChannelsForSoc);

    case sourcingMethodValues.CONTRACT:
      return mapSalesOrgs(fetchedGeo.salesOrgs[salesOrgsIndex].contractSalesChannelsForSoc);
    default:
  }
};

export const getOrFetchRegions = async(value) => {
  try {
    const regions = await addressOverrideRepository.fetchCountries(value);

    return mapDropdownItems(regions);
  } catch (e) {
    console.error(e);

    return [];
  }
};
