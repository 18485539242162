import { dependencyRulesActionSetDisabled } from '@/controllers/order/dependencies/header/actions/setDisabled';
import { dependencyRulesActionSetCleared } from '@/controllers/order/dependencies/header/actions/setCleared';
import { dependencyRulesActionSetRequired } from '@/controllers/order/dependencies/header/actions/setRequired';
import { dependencyRulesActionSetParentValue } from '@/controllers/order/dependencies/header/actions/setParentValue';
import { dependencyRulesActionSetItems } from '@/controllers/order/dependencies/header/actions/setItems';
import { dependencyRulesActionClearTouched } from '@/controllers/order/dependencies/header/actions/clearTouched';
import { dependencyRulesActionSetMinDate } from '@/controllers/order/dependencies/header/actions/setMinDate';
import { dependencyRulesActionSetDateValue } from '@/controllers/order/dependencies/header/actions/setDateValue';
import { dependencyRulesActionSetHidden } from '@/controllers/order/dependencies/header/actions/setHidden';
import { dependencyRulesActionSetNewManagers } from '@/controllers/order/dependencies/header/actions/setNewManagers';
import { dependencyRulesActionSetValue } from '@/controllers/order/dependencies/header/actions/setValue';
import { dependencyRulesActionClearRequired } from '@/controllers/order/dependencies/header/actions/clearRequired';
import { dependencyRuleSetTooltipMessage } from '@/controllers/order/dependencies/header/actions/setTooltipMessage';
import { dependencyRulesActionSetFirstItemAsValue } from '@/controllers/order/dependencies/header/actions/setFirstItemAsValue.js';
import { dependencyRuleSetAsyncItems } from '@/controllers/order/dependencies/header/actions/setAsyncItems';
import { dependencyRulesActionSetVisible } from '@/controllers/order/dependencies/header/actions/setVisible';

export const DependencyRulesActionsMap = {
  setDisabled: dependencyRulesActionSetDisabled,
  setCleared: dependencyRulesActionSetCleared,
  setRequired: dependencyRulesActionSetRequired,
  setParentValue: dependencyRulesActionSetParentValue,
  setNewManagers: dependencyRulesActionSetNewManagers,
  clearTouched: dependencyRulesActionClearTouched,
  setMinDate: dependencyRulesActionSetMinDate,
  setDateValue: dependencyRulesActionSetDateValue,
  setHidden: dependencyRulesActionSetHidden,
  setItems: dependencyRulesActionSetItems,
  setValue: dependencyRulesActionSetValue,
  clearRequired: dependencyRulesActionClearRequired,
  setTooltipMessage: dependencyRuleSetTooltipMessage,
  setFirstItemAsValue: dependencyRulesActionSetFirstItemAsValue,
  setAsyncItems: dependencyRuleSetAsyncItems,
  setVisible: dependencyRulesActionSetVisible,
};
