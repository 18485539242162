import repository from '@/api/repositories/serverRepository';
import responseStructureTypes from '@/enums/http/responseStructureTypes';
import { TEMPLATE } from '@/api/endpoints';

export default {
  getTemplates(data) {
    return repository.post(TEMPLATE.DOWNLOAD_TEMPLATE, data, {
      responseType: 'blob',
      responseStructureSuccess: responseStructureTypes.internalNonStandard,
    });
  },

  getFieldMapping() {
    repository.__addFilter(new RegExp(TEMPLATE.FIELD_MAPPING));

    return repository.get(TEMPLATE.FIELD_MAPPING);
  },
};
