import filterManagementRepo from '@/api/now/filterManagement';
import { getFiltersToSave, mergeSavedFilters } from './helpers';

export default {
  fetchSavedFilters: async({ state, commit, dispatch }) => {
    commit('SET_IS_LOADING', true);

    try {
      const { data } = await filterManagementRepo.getSavedFilters();

      commit('SET_SAVED_FILTERS', data.filters);

      if (data.defaultFilterId && !state.selectedFiltersName) {
        const defaultFiltersName = data.filters.find(filter => {
          return filter.filterId === data.defaultFilterId;
        })?.filterName;

        commit('SET_DEFAULT_FILTERS_NAME', defaultFiltersName);

        dispatch('selectSavedFilters', defaultFiltersName);
      }

      commit('SET_IS_FORM_EDITED', false);
    } catch (error) {
      console.log(error);

      commit('SET_SAVED_FILTERS', []);
    } finally {
      commit('SET_IS_LOADING', false);
    }
  },

  selectSavedFilters: ({ state, commit, dispatch }, filterName) => {
    commit('SET_SELECTED_FILTERS_NAME', filterName);
    commit('SET_IS_FORM_EDITED', false);

    if (filterName) {
      const filtersToApply = state.savedFilters.find(filter => filter.filterName === filterName).configs;

      const updatedFilterForm = mergeSavedFilters(state.filterForm, filtersToApply);

      commit('SET_FILTER_FORM', updatedFilterForm);

      return;
    }

    dispatch('clearFilters');
  },

  saveFilters: async({
    state, getters, commit, dispatch,
  }) => {
    commit('SET_IS_LOADING', true);

    try {
      const { selectedFiltersConfig } = getters;
      const updatedFilters = getFiltersToSave(state.filterForm);

      await filterManagementRepo.updateFilters(selectedFiltersConfig.filterId,
        { configs: updatedFilters });

      await dispatch('fetchSavedFilters');
    } catch (error) {
      console.log(error);
    } finally {
      commit('SET_IS_LOADING', false);
    }
  },

  createNewFilters: async({
    state, commit, dispatch,
  }, filterName) => {
    commit('SET_IS_LOADING', false);

    try {
      const filtersToSave = getFiltersToSave(state.filterForm);

      const payload = {
        filterName,
        configs: filtersToSave,
      };

      await filterManagementRepo.saveFilters(payload);

      await dispatch('fetchSavedFilters');
      dispatch('selectSavedFilters', filterName);
    } catch (error) {
      console.log(error);
    } finally {
      commit('SET_IS_LOADING', false);
    }
  },

  renameFilters: async({
    state, getters, commit, dispatch,
  }, newName) => {
    commit('SET_IS_LOADING', true);

    try {
      const { filterId } = getters.selectedFiltersConfig;
      const selectedConfig = { ...getters.selectedFiltersConfig };

      delete selectedConfig.filterId;

      await filterManagementRepo.updateFilters(filterId,
        { ...selectedConfig, filterName: newName });

      await dispatch('fetchSavedFilters');

      if (selectedConfig.filterName === state.defaultFiltersName) {
        commit('SET_DEFAULT_FILTERS_NAME', newName);
      }

      dispatch('selectSavedFilters', newName);
    } catch (error) {
      console.log(error);
    } finally {
      commit('SET_IS_LOADING', false);
    }
  },

  deleteFilters: async({
    getters, commit, dispatch,
  }) => {
    commit('SET_IS_LOADING', true);

    try {
      const { selectedFiltersConfig } = getters;

      await filterManagementRepo.deleteFilters(selectedFiltersConfig.filterId);

      await dispatch('fetchSavedFilters');
      dispatch('selectSavedFilters', '');
    } catch (error) {
      console.log(error);
    } finally {
      commit('SET_IS_LOADING', false);
    }
  },

  makeDefaultFilters: async({
    state, getters, commit, dispatch,
  }) => {
    commit('SET_IS_LOADING', true);

    try {
      const { selectedFiltersConfig } = getters;
      const isDefault = state.defaultFiltersName === state.selectedFiltersName;

      await filterManagementRepo.setDefaultFilters(selectedFiltersConfig.filterId, { defaultFilter: !isDefault });

      commit('SET_DEFAULT_FILTERS_NAME', isDefault ? '' : state.selectedFiltersName);

      await dispatch('fetchSavedFilters');
    } catch (error) {
      console.log(error);
    } finally {
      commit('SET_IS_LOADING', false);
    }
  },
};
