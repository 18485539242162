export default {
  filterForm: [],
  appliedFilterFields: [],
  componentKey: 0,
  fieldsWithErrors: new Set(),
  searchQuery: '',

  isLoading: false,

  selectedFiltersName: '',
  defaultFiltersName: '',
  savedFilters: null,

  isFormEdited: false,
};
