import ExternalServicesURLEnum from '@/enums/externalServices';

import { OrderTypeSapIdsEnum } from '@/enums/order/orderType';

import localStorageKey from '@/enums/browserStorage/localStorageKeyEnum';

import { getStoreData } from './storeData';

/**
 * @param {string} caseGroupLabel - label gotten automatically from click on chart
 * Function transform chart label to the format of BE data in store
 * then - finds exact caseGroup and redirect to CM with chosen Id
 */
export function redirectToDetails() {
  return redirectBy({ baseUrl: ExternalServicesURLEnum.CM_L2 });
}

export function redirectToCmOrderException(poNumber) {
  return redirectBy({
    dynamicParams: { po: [poNumber] },
    addFilterPanelData: true,
    emptyDefaultFilterValues: true,
  });
}

export function redirectByCaseGroupId(caseGroupLabel) {
  const { availableExceptionGroups } = getStoreData();

  const caseGroupName = caseGroupLabel.join().replaceAll(',', ' ');

  const { caseGroupId } = availableExceptionGroups.find(({ name }) => name.trim() === caseGroupName) || {};

  return redirectBy({
    dynamicParams: { caseGroupId: [caseGroupId.toString()] },
    baseUrl: ExternalServicesURLEnum.CM_L2,
  });
}

export function redirectByGroupBy(groupBy) {
  redirectBy({ dynamicParams: { groupBy } });
}

function redirectBy({
  dynamicParams = {},
  baseUrl = ExternalServicesURLEnum.CM_L1,
  addFilterPanelData = true,
  emptyDefaultFilterValues = false,
}) {
  const queryParams = {
    filters: {
      ...(addFilterPanelData ? getFilterPanelData({ emptyDefaultFilterValues }).filters : []),
      ...dynamicParams,
    },
  };

  const result = queryParams.filters.orderType.map((element) => {
    if (element === OrderTypeSapIdsEnum.NOW_SAMPLE_STD) {
      return OrderTypeSapIdsEnum.ZSTA;
    }
    if (element === OrderTypeSapIdsEnum.NOW_SAMPLE_FREE) {
      return OrderTypeSapIdsEnum.ZFRE;
    }

    return element;
  });

  queryParams.filters.orderType = result;

  window.location.href = createRedirectionURL(queryParams, baseUrl);
}

/**
 * For redirection from NOW MyOrders to CM Overview only mandatory query param is PoNumber but CM expects optional params to be sent as well with empty values
 */
export function getFilterPanelData({ emptyDefaultFilterValues = false } = {}) {
  const {
    soldTo = [],
    shipTo = [],
    dateTo = '',
    dateFrom = '',
    orderTypes = [],
    exceptionGroups = [],
  } = (!emptyDefaultFilterValues && JSON.parse(localStorage.getItem(localStorageKey.globalFilter))) || {};

  const { transformedDateFrom, transformedDateTo } = transormDates({ dateFrom, dateTo });

  return {
    filters: {
      businessType: getStoreData().idsOfSelctedBusinessTypes,
      subChannel: [],
      partner: [],
      soldTo,
      shipTo,
      orderType: orderTypes,
      caseGroupId: exceptionGroups,
      dateFrom: transformedDateFrom,
      dateTo: transformedDateTo,
      caseTypeId: [],
    },
  };
}

function transormDates({ dateFrom, dateTo }) {
  let transformedDateFrom = '';
  let transformedDateTo = '';

  if (dateFrom.length) {
    transformedDateFrom = new Date(dateFrom).toISOString();
  }

  if (dateTo.length) {
    transformedDateTo = new Date(dateTo).toISOString().replace('00:00:00.000Z', '23:59:59.000Z');
  }

  return {
    transformedDateFrom,
    transformedDateTo,
  };
}

function createRedirectionURL(queryParams, baseUrl) {
  return `${baseUrl}?params=${encodeParameters(queryParams)}`;
}

function encodeParameters(queryParams) {
  return encodeURIComponent(JSON.stringify(queryParams));
}
