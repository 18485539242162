<template>
  <p :class="[baseClassName, statusColorClass]">
    {{ params.data.status }}
  </p>
</template>

<script setup>
import { computed } from 'vue';

import { ExceptionStatus } from '@/pages/PurchaseOrder/constants';

const props = defineProps({
  params: {
    type: Object,
    required: true,
  },
});

const baseClassName = 'order-status-renderer';

const statusColorClass = computed(() => {
  const isException = props.params.data.status === ExceptionStatus.EXCEPTION;

  return isException ? `${baseClassName}__exception` : '';
});
</script>

<style lang="scss">
@import 'podium-tokens';

.order-status-renderer__exception {
  color: $podium-color-red-600;
}
</style>
