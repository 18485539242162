import myApprovalsRepository from '@/api/now/approvals';
import mutations from './mutation-type';

const myApprovalsData = {
  setIsTableDataLoading({ commit }, isTableDataLoading) {
    commit(mutations.SET_IS_TABLE_DATA_LOADING, isTableDataLoading);
  },

  async fetchMyApprovals({
    commit, dispatch, getters,
  }) {
    try {
      await dispatch('setIsTableDataLoading', true);

      const { data } = await myApprovalsRepository.fetchMyApprovals(
        getters.getMyApprovalsRequestParams,
      );

      commit(mutations.SET_MY_APPROVALS, data?.orders);
      commit(mutations.SET_TOTAL_AVAILABLE_ORDERS_NUMBER, data?.totalOrders);
      commit(mutations.SET_TOTAL_AVAILABLE_PAGES_NUMBER, data?.totalPages);
      commit(mutations.SET_PAGE_NUMBER, data?.pageNumber);
    } catch (error) {
      commit(mutations.SET_MY_APPROVALS, []);
      commit(mutations.SET_TOTAL_AVAILABLE_ORDERS_NUMBER, 0);
      commit(mutations.SET_TOTAL_AVAILABLE_PAGES_NUMBER, 0);
    } finally {
      await dispatch('setIsTableDataLoading', false);
    }
  },
};

const myApprovalsTable = {
  setPageSize({ commit }, pageSize) {
    commit(mutations.SET_PAGE_SIZE, pageSize);
  },

  setPageNumber({ dispatch, commit }, pageNumber) {
    commit(mutations.SET_PAGE_NUMBER, pageNumber);
    dispatch('fetchMyApprovals');
  },

  resetPageNumber({ commit }) {
    commit(mutations.SET_PAGE_NUMBER, 0);
  },
};

export default {
  ...myApprovalsData,
  ...myApprovalsTable,
};
