export default {
  getOrderTypes: ({ socOrderData: { orderTypes = [] } = {} }) => orderTypes,

  getAllowedOrderTypesIds: ({ allowedOrderTypes }) => {
    return allowedOrderTypes.map(({ sapId }) => sapId);
  },

  isOrderTypeAllowed:
    (_, { getAllowedOrderTypesIds }) => (sapId) => {
      return getAllowedOrderTypesIds.includes(sapId);
    },
};
