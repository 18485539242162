import development from '@/config/development.json';
import production from '@/config/production.json';

export async function wait(delay = 0) {
  return new Promise(resolve => {
    setTimeout(resolve, delay);
  });
}

export function getGlobalConfig() {
  const env = process.env.NODE_ENV;
  const isProduction = env && ['production', 'production-echo'].includes(env);

  return isProduction ? production : development;
}
