export const debugValues = {
  salesOrganization: {
    // defaultValue: '9800',
  },

  crd: {
    // defaultValue: new Date(new Date().setDate(new Date().getDate())).toISOString().slice(0, 10),
  },

  cancelDate: {
    // defaultValue: new Date(new Date().setDate(new Date().getDate() + 31)).toISOString().slice(0, 10),
    // minDate: new Date(new Date().setDate(new Date().getDate() + 6)).toISOString().slice(0, 10),
  },
};
