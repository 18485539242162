import repository from '@/api/repositories/serverRepository';
import { COMP } from '@/api/endpoints';

export default {
  fetchMyApprovals(request) {
    return repository.post(COMP.MY_APPROVALS, request);
  },

  updateApprovalStatus(request) {
    return repository.post(COMP.APPROVAL, request);
  },
};
