import { FIELD } from '@/controllers/order/enums/config';
import {
  dateFields,
  logicalTypes,
  secondLevelDependencyFields,
} from '@/controllers/order/enums/field';

export const completeHeaderFields = (headerFields) => {
  return headerFields.map(
    field => ({
      ...FIELD.DEFAULT_PROPERTIES,
      ...field,
    }),
  );
};

export const isDateField = (fieldName) => dateFields.has(fieldName);
export const isLogicalField = (type) => logicalTypes.has(type);
export const isSecondLevelDependencyField = (fieldKey) => secondLevelDependencyFields.has(fieldKey);
