const unSortIcon = (fillColor = '#111111') => `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" class="v-icon notranslate v-icon--is-component theme--light" style="font-size: 16px; height: 16px; width: 20px;"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.75842 3L7.51754 5.75912L6.82789 6.44878L5.24718 4.86807L5.25107 13.4034H4.27575L4.27186 4.86619L2.68975 6.44873L2 5.75917L4.75842 3ZM11.7532 3V11.5137L13.3103 9.95619L14 10.6458L11.2416 13.4049L8.48246 10.6458L9.17211 9.95615L10.7562 11.5402L10.7779 3H11.7532Z" fill="${fillColor}"></path></svg>`;

const sortDesIcon = (fillColor = '#111111') => `<svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" class="v-icon notranslate v-icon--is-component theme--light" style="font-size: 20px; height: 16px; width: 16px;"><path fill-rule="evenodd" clip-rule="evenodd" d="M2 10.3197L5.18021 13.4999L8.36042 10.3197L7.56493 9.52418L5.71267 11.3764L5.71267 3L4.58768 3L4.58768 11.3164L2.79549 9.52418L2 10.3197ZM11.62 5.76047L12.1197 4.10923H12.1304L12.6139 5.76047H11.62ZM11.7006 3L10.25 7.49995H11.0989L11.3997 6.49786H12.8342L13.1243 7.49995H14L12.5656 3H11.7006ZM10.25 13.4999V12.7184L12.6358 9.83185H10.4369V8.99993H13.9377V9.78144L11.5519 12.668H14V13.4999H10.25Z" fill="${fillColor}"></path></svg>`;

const sortAscIcon = (fillColor = '#111111') => `<svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" class="v-icon notranslate v-icon--is-component theme--light" style="font-size: 20px; height: 16px; width: 16px;"><path fill-rule="evenodd" clip-rule="evenodd" d="M8.3605 6.18025L5.18025 3L2 6.18025L2.7955 6.97574L4.64771 5.12353L4.64771 13.5H5.77271L5.77271 5.18345L7.565 6.97574L8.3605 6.18025ZM11.62 5.7605L12.1196 4.10924H12.1304L12.6139 5.7605H11.62ZM11.7006 3L10.25 7.5H11.0989L11.3997 6.4979H12.8342L13.1243 7.5H14L12.5655 3H11.7006ZM10.25 13.5V12.7185L12.6358 9.83193H10.4369V9H13.9377V9.78151L11.5519 12.6681H14V13.5H10.25Z" fill="${fillColor}"></path></svg>`;

const contractedIcon = (fillColor = '#111111') => `<svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fill-rule="evenodd" clip-rule="evenodd" d="M5 6.3999L9 1.5999H1L5 6.3999Z" fill="${fillColor}"/></svg>`;

const expandedIcon = (fillColor = '#111111') => `<svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M5 1.6001L1 6.4001L9 6.4001L5 1.6001Z" fill="${fillColor}"/></svg>`;

const menuVertical = (fillColor = '#111111') => `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M14.25 19.5C14.25 20.742 13.242 21.75 12 21.75C10.758 21.75 9.75 20.742 9.75 19.5C9.75 18.258 10.758 17.25 12 17.25C13.242 17.25 14.25 18.258 14.25 19.5Z" stroke="${fillColor}" stroke-width="1.5"/><path fill-rule="evenodd" clip-rule="evenodd" d="M14.25 12C14.25 13.242 13.242 14.25 12 14.25C10.758 14.25 9.75 13.242 9.75 12C9.75 10.758 10.758 9.75 12 9.75C13.242 9.75 14.25 10.758 14.25 12Z" stroke="${fillColor}" stroke-width="1.5"/><path fill-rule="evenodd" clip-rule="evenodd" d="M14.25 4.5C14.25 5.742 13.242 6.75 12 6.75C10.758 6.75 9.75 5.742 9.75 4.5C9.75 3.258 10.758 2.25 12 2.25C13.242 2.25 14.25 3.258 14.25 4.5Z" stroke="${fillColor}" stroke-width="1.5"/></svg>`;

const filterIcon = (fillColor = '#111111') => `<svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" class="v-icon notranslate v-icon--is-component theme--light" style="font-size: 20px; height: 16px; width: 16px;"><path fill-rule="evenodd" clip-rule="evenodd" d="M1 3H14.5V5.25H1V3ZM3.25 6.9375H12.25V9.1875H3.25V6.9375ZM10 10.875H5.5V13.125H10V10.875Z" fill="${fillColor}"></path></svg>`;

export {
  unSortIcon,
  sortDesIcon,
  sortAscIcon,
  expandedIcon,
  contractedIcon,
  menuVertical,
  filterIcon,
};
