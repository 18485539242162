<template>
  <div class="cell-loader">
    <Spinner :size="SpinnerSize.SMALL" />
    <span class="cell-loader__label">{{ loadingLabel }}</span>
  </div>
</template>

<script setup lang="ts">
import {
  Spinner, SpinnerSize,
} from '@nike/now.podium-vue';
import { translateModule } from '@/utils/string/translate';

const loadingLabel = translateModule('base')('loading');
</script>

<style lang="scss">
.cell-loader {
  display: flex;
  margin-left: 10px;

  &__label {
    @extend .now-body-3;

    margin-left: 10px;
  }
}
</style>
