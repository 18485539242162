export default {
  SET_EXCEPTIONS(state, exceptions) {
    state.exceptions = [...exceptions];
  },

  SET_IS_EXCEPTION_FETCHING(state, isExceptionsFetching) {
    state.isExceptionsFetching = isExceptionsFetching;
  },

  SET_SUMMARY(state, summary) {
    state.summary = summary;
  },
};
