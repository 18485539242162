import i18n from '@/plugins/i18n';
import httpErrorMessages from '@/enums/http/errorMessages';
import { getEndpointMappedErrorMessages } from '@/api/interceptors/errorEndpointsMessagesMapper';
import { showErrorBanner } from '@/utils/notifications';

import monitoring from '@/utils/monitoring';
import { handleMaintenanceRedirect } from '@/utils/router/redirection';

/**
 * Generates one red banner per failed request (endpoint)
 * One failed request can have N error messages inside one red banner
 *
 * ? Should this functionality be be moved into src/api/repositories/serverRepository.js
 */
export default (error) => {
  monitoring.reportError(error, {});
  monitoring.sendCustomEvent('ERR_BACKEND_CALL', {
    backendStatus: error.response?.status,
    backendErrorCode: error.response?.data?.errors?.[0].errorCode,
    backendErrorMessage: error.response?.data?.errors?.[0].message,
    backendTrace: error.response?.headers?.['server-timing'],
    backendPath: error.request?.responseURL,
  });

  // 555 - planned outage, 556 - service down
  if (error.response?.status === 555 || error.response?.status === 556) {
    return handleMaintenanceRedirect(error.response?.status);
  }

  const errorMessageString = generateErrorMessage(error).join(' ');
  const titleDelimiter = errorMessageString.indexOf('.');

  showErrorBanner({
    title:
      titleDelimiter > 0 ? errorMessageString.slice(0, titleDelimiter + 1) : errorMessageString,
    text: titleDelimiter > 0 ? errorMessageString.slice(titleDelimiter + 1) : '',
  });

  return Promise.reject(error);
};

/**
 * @param {promise} Axios response
 * @return {string[]} Array of error messages
 */
function generateErrorMessage({ response = null, mockedData = null }) {
  if (!response) {
    // Will catch any network error (ECONNABORTED, ETIMEDOUT, ERR_CONNECTION_REFUSED, ERR_CONNECTION_RESET...)
    return getMessageForAbortedRequests();
  }

  // TODO: Get rid of mockedData if we do not use it
  const httpStatusCode = response.status || mockedData.status;

  return getMappedErrorMessage(httpStatusCode, response) || getHttpErrorMessage(httpStatusCode);
}

/**
 * @param {number} httpStatusCode
 * @param {promise} Axios response
 * @return {VueI18n.TranslateResult[]|null} Array of translated messages or null
 */
function getMappedErrorMessage(
  httpStatusCode,
  { data: { errors = [] }, config: { url: requestedEndpoint } },
) {
  const mappedErrors = getEndpointMappedErrorMessages(requestedEndpoint);
  const beErrorCodes = getBackendErrorCodes(errors);

  const mappedErrorMessage = [];
  const nonMappedBeErrorCodes = [];

  if (mappedErrors) {
    beErrorCodes.forEach((beErrorCode) => {
      if (mappedErrors?.[beErrorCode]) {
        mappedErrorMessage.push(
          i18n.global.t(`notifications.errorMessages.http.${mappedErrors[beErrorCode]}`),
        );
      } else if (mappedErrors?.[httpStatusCode]?.[beErrorCode]) {
        mappedErrorMessage.push(
          i18n.global.t(
            `notifications.errorMessages.http.${mappedErrors[httpStatusCode][beErrorCode]}`,
          ),
        );
      } else {
        nonMappedBeErrorCodes.push(beErrorCode);
      }
    });
  }

  logBeFeMappingInconsistency(
    mappedErrors ? nonMappedBeErrorCodes : beErrorCodes,
    requestedEndpoint,
  );

  return mappedErrorMessage.length ? mappedErrorMessage : null;
}

function getHttpErrorMessage(httpStatusCode = null) {
  return [
    i18n.global.t(
      `notifications.errorMessages.http.${
        httpErrorMessages?.[httpStatusCode] || httpErrorMessages.unknown
      }`,
    ),
  ];
}

function getBackendErrorCodes(errors = []) {
  return errors.map((error) => error.errorCode);
}

function getMessageForAbortedRequests() {
  return [i18n.global.t('notifications.errorMessages.http.systemErrorOccurred')];
}

/**
 * Inconsistency may emerge over time of development
 *
 * TODO: This logger should be off on prod env
 * Go LIVE: process.env.VUE_APP_ENV !== prod
 *
 * @param {string[]} nonMappedErrorCodes
 * @param {string} endpoint
 */
function logBeFeMappingInconsistency(nonMappedErrorCodes, endpoint) {
  if (['prod', 'prod-echo'].includes(process.env.VUE_APP_ENV)) return;

  if (nonMappedErrorCodes.length) {
    console.error(
      `Update the Error Mapper: inconsistency found for Endpoint: ${endpoint} errorCode(s): ${nonMappedErrorCodes}`,
    );
  }
}
