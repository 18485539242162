import i18n from '@/plugins/i18n';

import { fieldNames } from '@/enums/singleOrderCreation/fieldNames';
import { LineLevelDependencyConditionEnum } from '@/controllers/order/dependencies/line';
import {
  HeaderLevelDependencyActionsEnum,
  HeaderLevelDependencyConditionEnum,
} from '@/enums/SOC/dependencyEnums';
import { FormTemplateValidationRulesMap } from '@/enums/SOC/formTemplate.consts';
import { LineLevelValidationRulesEnum } from '@/controllers/order/validations/line';
import { setInitialMinDate } from '@/utils/singleOrderCreate';

import { fieldsMaxTextLength } from '@/controllers/order/enums/field';

import { translateModule } from '@/utils/string/translate';
import { FIELDS_ENUM } from '@/enums/language/fieldsEnum';

const translateFields = translateModule('fields');
const translateSOC = translateModule('pages.SOC');

export default {
  fields: [
    {
      type: 'SelectSingle',
      key: fieldNames.salesOrganization,
      dependencyRules: [
        {
          sourceFieldKey: fieldNames.materialNumber,
          sourceFieldType: 'LineLevel',
          conditionType: LineLevelDependencyConditionEnum.columnHasValue,
          action: HeaderLevelDependencyActionsEnum.setDisabled,
        },
        {
          sourceFieldKey: fieldNames.materialNumber,
          sourceFieldType: 'LineLevel',
          conditionType: LineLevelDependencyConditionEnum.columnHasValue,
          action: HeaderLevelDependencyActionsEnum.setTooltipMessage,
          tooltipMessage: i18n.global.t('pages.SOC.form.disabledDropdownTooltip'),
        },
      ],
      validationRules: [FormTemplateValidationRulesMap.REQUIRED],
      items: [],
      class: 'sales-organization-wrapper',
      testId: 'soc-form-sales-org-field',
      width: '375',
      label: translateFields(FIELDS_ENUM.salesOrg),
      value: '',
      tooltipMessage: '',
      disabled: false,
      hidden: false,
      required: true,
    },
    {
      type: 'SelectSingle',
      key: fieldNames.salesChannel,
      dependencyRules: [
        {
          conditionType: HeaderLevelDependencyConditionEnum.or,
          conditions: [
            {
              sourceFieldKey: fieldNames.salesOrganization,
              conditionType: HeaderLevelDependencyConditionEnum.empty,
            },
            {
              sourceFieldKey: fieldNames.materialNumber,
              sourceFieldType: 'LineLevel',
              conditionType: LineLevelDependencyConditionEnum.columnHasValue,
            },
          ],
          action: HeaderLevelDependencyActionsEnum.setDisabled,
        },
        {
          sourceFieldKey: fieldNames.materialNumber,
          sourceFieldType: 'LineLevel',
          conditionType: LineLevelDependencyConditionEnum.columnHasValue,
          action: HeaderLevelDependencyActionsEnum.setTooltipMessage,
          tooltipMessage: i18n.global.t('pages.SOC.form.disabledDropdownTooltip'),
        },
        {
          sourceFieldKey: fieldNames.salesOrganization,
          conditionType: HeaderLevelDependencyConditionEnum.changedValue,
          action: HeaderLevelDependencyActionsEnum.setAsyncItems,
        },
        {
          sourceFieldKey: fieldNames.salesChannel,
          conditionType: HeaderLevelDependencyConditionEnum.hasOneSelectItem,
          action: HeaderLevelDependencyActionsEnum.setFirstItemAsValue,
        },
        {
          sourceFieldKey: fieldNames.salesOrganization,
          conditionType: HeaderLevelDependencyConditionEnum.empty,
          action: HeaderLevelDependencyActionsEnum.setCleared,
        },
      ],
      items: [],
      class: 'sales-channel-wrapper',
      testId: 'soc-form-sales-channel-field',
      width: '375',
      label: translateFields(FIELDS_ENUM.channel),
      value: null,
      tooltipMessage: '',
      isValueText: true,
      disabled: true,
      hidden: false,
      required: true,
      async: true,
    },
    {
      type: 'SoldTo',
      key: fieldNames.soldToNumber,
      validationRules: [FormTemplateValidationRulesMap.REQUIRED],
      dependencyRules: [
        {
          conditionType: HeaderLevelDependencyConditionEnum.or,
          conditions: [
            {
              sourceFieldKey: fieldNames.salesOrganization,
              sourceFieldType: 'HeaderLevel',
              conditionType: HeaderLevelDependencyConditionEnum.empty,
            },
            {
              sourceFieldKey: fieldNames.materialNumber,
              sourceFieldType: 'LineLevel',
              conditionType: LineLevelDependencyConditionEnum.columnHasValue,
            },
          ],
          action: HeaderLevelDependencyActionsEnum.setDisabled,
        },
        {
          sourceFieldKey: fieldNames.salesOrganization,
          conditionType: HeaderLevelDependencyConditionEnum.filled,
          action: HeaderLevelDependencyActionsEnum.setParentValue,
        },
        {
          sourceFieldKey: fieldNames.salesOrganization,
          conditionType: HeaderLevelDependencyConditionEnum.changedValue,
          action: HeaderLevelDependencyActionsEnum.setParentValue,
        },
        {
          sourceFieldKey: fieldNames.salesOrganization,
          conditionType: HeaderLevelDependencyConditionEnum.empty,
          action: HeaderLevelDependencyActionsEnum.setCleared,
        },
        {
          sourceFieldKey: fieldNames.materialNumber,
          sourceFieldType: 'LineLevel',
          conditionType: LineLevelDependencyConditionEnum.columnHasValue,
          action: HeaderLevelDependencyActionsEnum.setTooltipMessage,
          tooltipMessage: i18n.global.t('pages.SOC.form.disabledDropdownTooltip'),
        },
      ],
      class: 'sold-to-wrapper',
      width: '375',
      label: translateFields(FIELDS_ENUM.soldTo),
      name: 'Sold To',
      value: null,
      tooltipMessage: '',
      parentValue: '',
      disabled: true,
      hidden: false,
      required: true,
    },
    {
      type: 'ShipTo',
      key: fieldNames.shipToNumber,
      validationRules: [FormTemplateValidationRulesMap.REQUIRED],
      dependencyRules: [
        {
          conditionType: HeaderLevelDependencyConditionEnum.or,
          conditions: [
            {
              sourceFieldKey: fieldNames.salesOrganization,
              sourceFieldType: 'HeaderLevel',
              conditionType: HeaderLevelDependencyConditionEnum.empty,
            },
            {
              sourceFieldKey: fieldNames.soldToNumber,
              sourceFieldType: 'HeaderLevel',
              conditionType: HeaderLevelDependencyConditionEnum.empty,
            },
            {
              sourceFieldKey: fieldNames.materialNumber,
              sourceFieldType: 'LineLevel',
              conditionType: LineLevelDependencyConditionEnum.columnHasValue,
            },
          ],
          action: HeaderLevelDependencyActionsEnum.setDisabled,
        },
        {
          conditionType: HeaderLevelDependencyConditionEnum.or,
          conditions: [
            {
              sourceFieldKey: fieldNames.salesOrganization,
              conditionType: HeaderLevelDependencyConditionEnum.empty,
            },
            {
              sourceFieldKey: fieldNames.soldToNumber,
              conditionType: HeaderLevelDependencyConditionEnum.empty,
            },
          ],
          action: HeaderLevelDependencyActionsEnum.setCleared,
        },
        {
          sourceFieldKey: fieldNames.soldToNumber,
          conditionType: HeaderLevelDependencyConditionEnum.changedValue,
          action: HeaderLevelDependencyActionsEnum.setItems,
        },
        {
          sourceFieldKey: fieldNames.materialNumber,
          sourceFieldType: 'LineLevel',
          conditionType: LineLevelDependencyConditionEnum.columnHasValue,
          action: HeaderLevelDependencyActionsEnum.setTooltipMessage,
          tooltipMessage: i18n.global.t('pages.SOC.form.disabledDropdownTooltip'),
        },
      ],
      class: 'ship-to-wrapper',
      width: '375',
      label: translateFields(FIELDS_ENUM.shipTo),
      name: 'Ship To',
      value: '',
      tooltipMessage: '',
      parentValue: {},
      touched: false,
      disabled: true,
      hidden: false,
      required: true,
    },
    {
      type: 'Input',
      key: fieldNames.customerPONumber,
      validationRules: [
        FormTemplateValidationRulesMap.REQUIRED,
        FormTemplateValidationRulesMap.MAXLENGTH,
        FormTemplateValidationRulesMap.CAPITALIZE,
      ],
      maxLength: 35,
      dependencyRules: [
        {
          sourceFieldKey: fieldNames.salesOrganization,
          conditionType: HeaderLevelDependencyConditionEnum.empty,
          action: HeaderLevelDependencyActionsEnum.setDisabled,
        },
      ],
      class: 'customer-po-number-wrapper',
      testId: 'soc-form-customer-po-number-field',
      width: '375',
      label: translateFields(FIELDS_ENUM.purchaseOrderNumber),
      value: null,
      disabled: false,
      hidden: false,
      required: true,
    },
    {
      type: 'Datepicker',
      key: fieldNames.crd,
      validationRules: [FormTemplateValidationRulesMap.REQUIRED],
      dependencyRules: [
        {
          sourceFieldKey: fieldNames.salesOrganization,
          conditionType: HeaderLevelDependencyConditionEnum.empty,
          action: HeaderLevelDependencyActionsEnum.setDisabled,
        },
      ],
      class: 'crd-wrapper',
      testId: 'soc-form-crd-field',
      width: '375',
      minDate: setInitialMinDate({
        refDate: new Date(),
      }),
      label: translateFields(FIELDS_ENUM.crd),
      value: null,
      disabled: false,
      hidden: false,
      required: true,
    },
    {
      type: 'Datepicker',
      key: fieldNames.cancelDate,
      validationRules: [FormTemplateValidationRulesMap.REQUIRED],
      dependencyRules: [
        {
          conditionType: HeaderLevelDependencyConditionEnum.or,
          conditions: [
            {
              sourceFieldKey: fieldNames.salesOrganization,
              conditionType: HeaderLevelDependencyConditionEnum.empty,
            },
            {
              sourceFieldKey: fieldNames.crd,
              conditionType: HeaderLevelDependencyConditionEnum.empty,
            },
          ],
          action: HeaderLevelDependencyActionsEnum.setDisabled,
        },
        {
          sourceFieldKey: fieldNames.crd,
          conditionType: HeaderLevelDependencyConditionEnum.changedValue,
          action: HeaderLevelDependencyActionsEnum.setMinDate,
          actionArgs: {
            add: 6,
            unit: 'calendarDay',
          },
        },
        {
          sourceFieldKey: fieldNames.crd,
          conditionType: HeaderLevelDependencyConditionEnum.changedValue,
          action: HeaderLevelDependencyActionsEnum.setDateValue,
          actionArgs: {
            add: 30,
            unit: 'calendarDay',
          },
        },
      ],
      class: 'cancel-date-wrapper',
      testId: 'soc-form-cancel-date-field',
      width: '375',
      label: translateFields(FIELDS_ENUM.cancelDate),
      minDate: null,
      value: null,
      disabled: false,
      hidden: false,
      required: true,
    },
    {
      type: 'Datepicker',
      key: fieldNames.validFromDate,
      validationRules: [FormTemplateValidationRulesMap.REQUIRED],
      dependencyRules: [
        {
          sourceFieldKey: fieldNames.salesOrganization,
          conditionType: HeaderLevelDependencyConditionEnum.empty,
          action: HeaderLevelDependencyActionsEnum.setDisabled,
        },
      ],
      class: 'valid-from-date-wrapper',
      testId: 'soc-form-valid-from-date-field',
      width: '375',
      label: translateFields(FIELDS_ENUM.validFromDate),
      value: null,
      minDate: setInitialMinDate({
        refDate: new Date(),
      }),
      disabled: false,
      hidden: false,
      required: true,
    },
    {
      type: 'Datepicker',
      key: fieldNames.validToDate,
      validationRules: [FormTemplateValidationRulesMap.REQUIRED],
      dependencyRules: [
        {
          conditionType: HeaderLevelDependencyConditionEnum.or,
          conditions: [
            {
              sourceFieldKey: fieldNames.salesOrganization,
              conditionType: HeaderLevelDependencyConditionEnum.empty,
            },
            {
              sourceFieldKey: fieldNames.validFromDate,
              conditionType: HeaderLevelDependencyConditionEnum.empty,
            },
          ],
          action: HeaderLevelDependencyActionsEnum.setDisabled,
        },
        {
          sourceFieldKey: fieldNames.validFromDate,
          conditionType: HeaderLevelDependencyConditionEnum.changedValue,
          action: HeaderLevelDependencyActionsEnum.setMinDate,
          actionArgs: {
            add: 1,
            unit: 'calendarDay',
          },
        },
        {
          sourceFieldKey: fieldNames.validFromDate,
          conditionType: HeaderLevelDependencyConditionEnum.changedValue,
          action: HeaderLevelDependencyActionsEnum.setDateValue,
          actionArgs: {
            add: 30,
            unit: 'calendarDay',
          },
        },
      ],
      class: 'valid-to-wrapper',
      testId: 'soc-form-valid-to-date-field',
      width: '375',
      label: translateFields(FIELDS_ENUM.validToDate),
      minDate: null,
      value: null,
      disabled: false,
      hidden: false,
      required: true,
    },
    {
      type: 'Input',
      key: fieldNames.nameOfOrderer,
      validationRules: [FormTemplateValidationRulesMap.MAXLENGTH],
      maxLength: 35,
      dependencyRules: [
        {
          sourceFieldKey: fieldNames.salesOrganization,
          conditionType: HeaderLevelDependencyConditionEnum.empty,
          action: 'setDisabled',
        },
      ],
      class: 'name-of-orderer-wrapper',
      testId: 'soc-form-name-of-orderer-field',
      width: '375',
      label: translateFields(FIELDS_ENUM.nameOfOrderer),
      value: null,
      disabled: false,
      hidden: false,
      required: false,
    },
    {
      type: 'Input',
      key: fieldNames.markFor,
      validationRules: [
        FormTemplateValidationRulesMap.MAXLENGTH,
        FormTemplateValidationRulesMap.ONLY_DIGITS,
      ],
      maxLength: 10,
      dependencyRules: [
        {
          sourceFieldKey: fieldNames.salesOrganization,
          conditionType: HeaderLevelDependencyConditionEnum.empty,
          action: 'setDisabled',
        },
      ],
      class: 'mark-for-wrapper',
      testId: 'soc-form-mark-for-field',
      width: '375',
      label: translateFields(FIELDS_ENUM.markFor),
      value: null,
      disabled: false,
      hidden: false,
      required: false,
    },
    {
      type: 'SelectSingle',
      key: fieldNames.departmentCode,
      validationRules: [
        FormTemplateValidationRulesMap.MAXLENGTH,
        FormTemplateValidationRulesMap.ONLY_DIGITS,
      ],
      maxLength: 10,
      dependencyRules: [
        {
          sourceFieldKey: fieldNames.soldToNumber,
          conditionType: HeaderLevelDependencyConditionEnum.changedValue,
          action: HeaderLevelDependencyActionsEnum.setItems,
        },
        {
          conditionType: HeaderLevelDependencyConditionEnum.or,
          conditions: [
            {
              sourceFieldKey: fieldNames.soldToNumber,
              conditionType: HeaderLevelDependencyConditionEnum.empty,
            },
            {
              sourceFieldKey: fieldNames.salesOrganization,
              conditionType: HeaderLevelDependencyConditionEnum.empty,
            },
          ],
          action: HeaderLevelDependencyActionsEnum.setCleared,
        },
        {
          conditionType: HeaderLevelDependencyConditionEnum.or,
          conditions: [
            {
              sourceFieldKey: fieldNames.soldToNumber,
              conditionType: HeaderLevelDependencyConditionEnum.empty,
            },
            {
              sourceFieldKey: fieldNames.salesOrganization,
              conditionType: HeaderLevelDependencyConditionEnum.empty,
            },
          ],
          action: HeaderLevelDependencyActionsEnum.setDisabled,
        },
      ],
      class: 'department-code-wrapper',
      testId: 'soc-form-department-code-field',
      width: '375',
      label: translateFields(FIELDS_ENUM.departmentCode),
      value: null,
      disabled: false,
      hidden: false,
      required: false,
    },
    {
      type: 'Checkbox',
      key: fieldNames.reasonCodeAuthorized,
      validationRules: [FormTemplateValidationRulesMap.REQUIRED],
      dependencyRules: [
        {
          sourceFieldKey: fieldNames.salesOrganization,
          conditionType: HeaderLevelDependencyConditionEnum.empty,
          action: 'setDisabled',
        },
      ],
      class: 'confirmation-checkbox-wrapper header-level__item_fullwidth',
      testId: 'soc-form-reason-code-checkbox-field',
      width: '100%',
      label: translateSOC('form.reasonCodeAuthorized'),
      value: false,
      disabled: true,
      hidden: false,
      required: false,
    },
    {
      type: 'SelectSingle',
      key: fieldNames.reasonCode,
      validationRules: [FormTemplateValidationRulesMap.REQUIRED],
      dependencyRules: [
        {
          conditionType: HeaderLevelDependencyConditionEnum.or,
          conditions: [
            {
              sourceFieldKey: fieldNames.salesOrganization,
              conditionType: HeaderLevelDependencyConditionEnum.empty,
            },
            {
              sourceFieldKey: fieldNames.reasonCodeAuthorized,
              conditionType: HeaderLevelDependencyConditionEnum.empty,
            },
          ],
          action: 'setDisabled',
        },
        {
          sourceFieldKey: fieldNames.reasonCodeAuthorized,
          conditionType: HeaderLevelDependencyConditionEnum.filled,
          action: 'setRequired',
        },
        {
          sourceFieldKey: fieldNames.reasonCodeAuthorized,
          conditionType: HeaderLevelDependencyConditionEnum.empty,
          action: 'setCleared',
        },
      ],
      items: [],
      class: 'reason-code-wrapper',
      testId: 'soc-form-reason-code-field',
      width: '375',
      label: translateFields(FIELDS_ENUM.reasonCode),
      value: null,
      disabled: false,
      hidden: false,
      required: true,
    },
    {
      type: 'Textarea',
      key: fieldNames.nikeInternalComments,
      validationRules: [
        FormTemplateValidationRulesMap.REQUIRED,
        FormTemplateValidationRulesMap.MAXLENGTH,
      ],
      dependencyRules: [
        {
          sourceFieldKey: fieldNames.salesOrganization,
          conditionType: HeaderLevelDependencyConditionEnum.empty,
          action: 'setDisabled',
        },
        {
          sourceFieldKey: fieldNames.reasonCode,
          conditionType: HeaderLevelDependencyConditionEnum.filled,
          action: 'setRequired',
        },
      ],
      class: 'nike-internal-comments-wrapper header-level__item_fullwidth',
      testId: 'soc-form-nike-internal-comments-field',
      width: '782',
      label: translateFields(FIELDS_ENUM.nikeInternalComments),
      maxLength: fieldsMaxTextLength.nikeInternalCommentsLength,
      value: null,
      disabled: false,
      hidden: false,
      required: false,
    },
  ],
  lineItemFields: {
    columnDefs: [
      {
        name: 'Line',
        field: fieldNames.line,
        required: false,
      },
      {
        name: translateFields(FIELDS_ENUM.materialNumber),
        field: fieldNames.materialNumber,
        required: true,
      },
      {
        name: translateFields(FIELDS_ENUM.orderQuantity),
        field: fieldNames.qty,
        required: true,
      },
      {
        name: translateFields(FIELDS_ENUM.crdLineLevel),
        field: fieldNames.crd,
        required: true,
      },
      {
        name: translateFields(FIELDS_ENUM.cancelDateLineLevel),
        field: fieldNames.cancelDate,
        required: true,
      },
      {
        name: translateFields(FIELDS_ENUM.usageCode),
        field: fieldNames.usageCode,
        required: false,
      },
      {
        name: translateFields(FIELDS_ENUM.plantCode),
        field: fieldNames.plantCode,
        required: false,
      },
      {
        name: translateFields(FIELDS_ENUM.requirementSegment),
        field: fieldNames.requirementSegment,
        required: false,
      },
      {
        field: 'appSizes',
        name: '',
        required: false,
      },
      {
        field: 'availabilityData',
        name: '',
        required: false,
      },
    ],
    validationRules: {
      [fieldNames.crd]: {
        [LineLevelValidationRulesEnum.required]: {
          required: 'columnDefined',
        },
      },
      [fieldNames.cancelDate]: {
        [LineLevelValidationRulesEnum.required]: {
          required: 'columnDefined',
        },
      },
      [fieldNames.materialNumber]: {
        [LineLevelValidationRulesEnum.required]: {
          required: 'columnDefined',
        },
        [LineLevelValidationRulesEnum.regExpValidation]: {
          pattern: '^([a-zA-Z0-9]{6})-([a-zA-Z0-9]{3})$',
          errorMessage: 'Expected Format: BQ3757-101',
        },
        [LineLevelValidationRulesEnum.lengthValidation]: {
          maxLength: 10,
        },
      },
      [fieldNames.qty]: {
        [LineLevelValidationRulesEnum.regExpValidation]: {
          pattern: '^[0-9]*$',
          errorMessage: 'Only digits are allowed',
        },
      },
    },
    dependencies: [],
    rowData: [],
  },
  name: 'Contract',
  orderType: {
    name: 'Contract',
    id: 1,
    sapId: 'ZCON',
  },
};
