import { cloneDeep } from 'lodash';

import maintenanceRepository from '@/api/now/maintenance';

import * as mutations from './constants/mutation-types';
import * as actionsEnum from './constants/actions';
import { outageEntity } from './maintenance.state';

import { showSuccessBanner } from '@/utils/notifications';
import i18n from '@/plugins/i18n';

import { getDateFromUTC, getUTCDate } from '@/utils/timezones';

const {
  FETCH_OUTAGE_STATUS,
  FETCH_SERVICE_DOWN_STATUS,
  ENABLE_PLANNED_OUTAGE,
  SET_PLANNED_OUTAGE,
  SET_SERVICE_DOWN,
  DISABLE_PLANNED_OUTAGE,
} = actionsEnum;

export default {
  async [FETCH_OUTAGE_STATUS]({ dispatch }) {
    try {
      const { data } = await maintenanceRepository.getOutageStatus();

      let newData;

      if (data?.enabled) {
        newData = {
          ...data,
          startDate: getDateFromUTC(data?.startDate, data?.zone),
          expireDate: getDateFromUTC(data?.expireDate, data?.zone),
        };
      } else {
        newData = {
          ...cloneDeep(outageEntity),
          ...data,
        };
      }

      dispatch(SET_PLANNED_OUTAGE, newData);
    } catch (error) {
      console.error('OUTAGE FETCHING: ', error);
    }
  },

  async [ENABLE_PLANNED_OUTAGE]({ dispatch, commit }, payload) {
    try {
      commit(mutations.SET_MAINTENANCE_LOADING, true);
      const {
        startDate, expireDate, zone,
      } = payload;
      const data = {
        startDate: getUTCDate({
          ...startDate,
          timezone: zone,
        }),
        expireDate: getUTCDate({
          ...expireDate,
          timezone: zone,
        }),
        zone,
      };

      await maintenanceRepository.enableOutage(data);
      dispatch(SET_PLANNED_OUTAGE, payload);

      showSuccessBanner({
        title: i18n.global.t('notifications.sucessMessages.plannedOutageWasSet'),
      });
    } catch (error) {
      console.error(error);
    } finally {
      commit(mutations.SET_MAINTENANCE_LOADING, false);
    }
  },

  [SET_PLANNED_OUTAGE]({ commit }, payload) {
    commit(mutations.SET_PLANNED_OUTAGE, payload);
  },

  async [DISABLE_PLANNED_OUTAGE]({ commit }) {
    try {
      commit(mutations.SET_MAINTENANCE_LOADING, true);
      await maintenanceRepository.disableOutage();

      commit(mutations.SET_PLANNED_OUTAGE, cloneDeep(outageEntity));

      showSuccessBanner({
        title: i18n.global.t('notifications.sucessMessages.plannedOutageWasDisabled'),
      });
    } catch (error) {
      console.error(error);
    } finally {
      commit(mutations.SET_MAINTENANCE_LOADING, false);
    }
  },

  async [FETCH_SERVICE_DOWN_STATUS]({ commit }) {
    try {
      const { data } = await maintenanceRepository.getServiceDownStatus();

      commit(mutations.SET_SERVICE_DOWN, data?.enabled);
    } catch (error) {
      console.error('SERVICE DOWN STATUS: ', error);
    }
  },

  async [SET_SERVICE_DOWN]({ commit }, isServiceDown) {
    try {
      commit(mutations.SET_MAINTENANCE_LOADING, true);

      if (isServiceDown) {
        await maintenanceRepository.enableServiceDown();
      } else {
        await maintenanceRepository.disableServiceDown();
      }

      commit(mutations.SET_SERVICE_DOWN, isServiceDown);

      const bannerWord = isServiceDown ? 'Enabled' : 'Disabled';
      showSuccessBanner({
        title: i18n.global.t(`notifications.sucessMessages.serviceDownWas${bannerWord}`),
      });
    } catch (error) {
      console.error(error);
    } finally {
      commit(mutations.SET_MAINTENANCE_LOADING, false);
    }
  },
};
