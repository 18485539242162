import repository from '@/api/repositories/serverRepository';
import { FILE } from '@/api/endpoints';

export default {
  // TODO move it to MOC
  sendFiles(formData) {
    return repository.post(FILE.UPLOAD_FILES, formData);
  },

  // TODO move it to MOC
  fetchFileProcessStatus(fileNames = []) {
    if (fileNames.length) {
      return repository.post(FILE.FILE_PROCESS_STATUS, fileNames);
    }

    return null;
  },

  deleteFile(file = '') {
    if (file) {
      return repository.delete(`${FILE.DELETE_FILE}/${file}`);
    }

    return null;
  },

  getNotSucceededFiles() {
    return repository.get(FILE.NOT_SUCCEEDED_FILES);
  },
};
