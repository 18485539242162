import state from './myApprovals.state';
import getters from './myApprovals.getters';
import actions from './myApprovals.actions';
import mutations from './myApprovals.mutations';

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
