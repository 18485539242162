export function dependencyRulesActionSetValue({
  field, value, dependency,
}) {
  if (!value) return field;

  if (field?.items?.length) {
    if (field.items.find(({ itemValue }) => itemValue === dependency.value)) {
      return { ...field, value: dependency.value };
    }

    return field;
  }

  return { ...field, value: dependency.value };
}
