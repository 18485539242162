export const validationRuleIsonlyDigitsLettersDashSpace = (formField) => {
  if (!formField.touched) {
    return formField;
  }

  const pattern = /^[0-9A-Za-z- ]+$/;

  if (pattern.test(formField.value) || !formField.value) {
    formField.errors = formField.errors.filter((error) => !error.digitsLettersSpacesOnly);
  } else {
    formField.errors.push({ digitsLettersSpacesOnly: 'Only digits, letters, spaces and "-" are allowed' });
  }

  return formField;
};
