import { validationRuleIsRequired } from '@/controllers/order/validations/header/required.js';
import { validationRuleIsMaxLengthExceeded } from '@/controllers/order/validations/header/maxLength.js';
import { validationRuleIsOnlyDigits } from '@/controllers/order/validations/header/onlyDigits.js';
import { validationRuleIsOnlyDigitsLetters } from '@/controllers/order/validations/header/onlyDigitsLetters';
import { validationRuleIsOnlyDigitsLettersSpaces } from '@/controllers/order/validations/header/onlyDigitsLettersSpaces';
import { validationRuleIsonlyDigitsLettersDashSpace } from '@/controllers/order/validations/header/onlyDigitsLettersDashSpace';
import { validationRuleIsOnlyLettersSpaces } from '@/controllers/order/validations/header/onlyLettersSpaces';
import { validationRuleCheckOnBehalfOf } from '@/controllers/order/validations/header/checkOnBehalfOf';
import { validationRuleCapitalize } from '@/controllers/order/validations/header/capitalize';
import { validationRuleCheckAutocomplete } from '@/controllers/order/validations/header/checkAutocomplete.js';

export const ValidationRules = {
  required: validationRuleIsRequired,
  maxLength: validationRuleIsMaxLengthExceeded,
  onlyDigits: validationRuleIsOnlyDigits,
  onlyDigitsLetters: validationRuleIsOnlyDigitsLetters,
  onlyDigitsLettersSpaces: validationRuleIsOnlyDigitsLettersSpaces,
  onlyDigitsLettersDashSpace: validationRuleIsonlyDigitsLettersDashSpace,
  onlyLettersSpaces: validationRuleIsOnlyLettersSpaces,
  checkOnBehalfOf: validationRuleCheckOnBehalfOf,
  capitalize: validationRuleCapitalize,
  checkAutocomplete: validationRuleCheckAutocomplete,
};
