export default {
  user: {},
  isMpoUser: null,
  userHasPermissions: undefined,
  isAdmin: false,
  geo: [],
  hasAccessToken: false,
  casePermissions: {},
  casePermissionsObjects: {},
};
