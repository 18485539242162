// This component will be removed once v-select will be substituted with Podium component
<template>
  <svg
    width="81"
    height="81"
    viewBox="0 0 81 81"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M75.7828 45.0907C76.8324 37.5462 75.4617 29.8632 71.8678 23.1472C68.2739 16.4312 62.6422 11.0283 55.7828 7.71608C48.9235 4.40383 41.1903 3.35294 33.6958 4.7146C26.2013 6.07626 19.332 9.78029 14.0762 15.2937C8.82043 20.8071 5.44914 27.8457 4.44743 35.3968C3.44571 42.9478 4.86521 50.6219 8.50168 57.315C12.1382 64.0081 17.8041 69.375 24.6843 72.6436C31.5626 75.9113 39.2998 76.9134 46.7835 75.506C46.7857 75.5056 46.7878 75.5052 46.79 75.5047L47.5308 79.4399C47.5285 79.4404 47.5263 79.4408 47.524 79.4412C46.209 79.6886 44.887 79.8689 43.5628 79.983C36.5125 80.5902 29.401 79.3176 22.966 76.2605C15.3204 72.6283 9.02416 66.6643 4.98315 59.2267C0.942139 51.789 -0.635272 43.2612 0.477881 34.8702C1.59103 26.4791 5.33736 18.6575 11.1778 12.5308C17.0183 6.404 24.6518 2.28792 32.98 0.774779C41.3082 -0.738363 49.9017 0.429439 57.5241 4.11016C65.1465 7.79087 71.4047 13.7947 75.3984 21.2579C78.7597 27.5393 80.371 34.5818 80.1015 41.6531C80.0508 42.9835 79.9336 44.315 79.7489 45.6425L75.7828 45.0907Z"
    />
  </svg>
</template>
