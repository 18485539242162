import CryptoJS from 'crypto-js';

export function createFormData(uploadedFiles = [], orderTypes = []) {
  if (!uploadedFiles.length || !orderTypes.length) {
    return new FormData();
  }

  return appendDataToForm(createRequestData(uploadedFiles, orderTypes));
}

export function createHashOfFile(file) {
  return CryptoJS.MD5(CryptoJS.enc.Latin1.parse(file)).toString(CryptoJS.enc.Base64);
}

function createRequestData(uploadedFiles = [], orderTypes = []) {
  const hashesOfFiles = [];
  const orderTypeIds = [];
  const files = [];

  uploadedFiles.forEach(({
    data, target, selectedOrderType,
  }) => {
    const { id } = orderTypes.find(({ name }) => name === selectedOrderType);

    hashesOfFiles.push(createHashOfFile(target));
    orderTypeIds.push(id);
    files.push(data);
  });

  return {
    hashesOfFiles: hashesOfFiles.join(','),
    orderTypeIds: orderTypeIds.join(','),
    files,
  };
}

function appendDataToForm({
  hashesOfFiles = [], orderTypeIds = [], files = [],
}) {
  const formData = new FormData();

  formData.append('hashes', hashesOfFiles);
  formData.append('orderTypes', orderTypeIds);

  files.forEach((file) => formData.append('files', file));

  return formData;
}
