<template>
  <div
    :class="`${baseClassName}__container`"
  >
    <!-- CONTENT -->
    <BaseStatusIcon
      :class="`${baseClassName}__message-status`"
      :isWarningBanner="isErrorType"
    />

    <div :class="`${baseClassName}__content`">
      <div :class="`${baseClassName}__title`">
        {{ title }}
      </div>

      <div :class="`${baseClassName}__text`">
        {{ text }}
        <div v-if="isDataToRenderAvailable">
          <p v-for="(dataText, index) in mergedData.dataToRender" :key="index">
            {{ dataText }}
          </p>
        </div>
      </div>
    </div>

    <!-- CLOSE ACTION -->
    <Icon
      role="button"
      name="CloseMediumDefaultOnLight"
      :class="`${baseClassName}__close-button`"
      @click="close"
    />
  </div>
</template>

<script setup>
import {
  Icon,
} from 'podium';

import { computed } from 'vue';

import BaseStatusIcon from '@/components/ui/BaseStatusIcon/BaseStatusIcon.vue';

const props = defineProps({
  title: {
    type: String,
    default: '',
  },

  text: {
    type: String,
    default: '',
  },

  type: {
    type: String,
    default: '',
  },

  data: {
    type: Object,
    default: () => ({
      dataToRender: [],
    }),
  },

  close: {
    type: Function,
    default: () => ({}),
  },
});

const baseClassName = 'notification';

const mergedData = computed(() => ({
  dataToRender: [],
  ...props.data,
}));

const isErrorType = computed(() => props.type === 'error');
const isDataToRenderAvailable = computed(() => !!props.mergedData?.dataToRender.length);

</script>

<style lang="scss" scoped>
@import '@/styles/colors.scss';
@import "podium-tokens";

.notification {

  &__container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 10px;
    padding: 8px 20px;
    border-radius: 50px;
    min-height: $podium-space-64;
    color: $primary-black;
    background-color: $primary-white;
  }

  &__message-status {
    flex-shrink: 0;
  }

  &__content {
    display: flex;
    flex-direction: column;
    margin: 0 10px;
    font-family: Helvetica Neue;
    font-weight: 500;
    font-size: $podium-space-16;
    line-height: $podium-space-24;
    max-width: 40vw;
    word-wrap: break-word;

  }
  &__close-button {
      flex-shrink: 0;
      margin-left: auto;
    }
}

</style>
