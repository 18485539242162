import localStorageKey from '@/enums/browserStorage/localStorageKeyEnum';

function getOktaTokens() {
  return { ...JSON.parse(localStorage.getItem(localStorageKey.oktaTokenStorage)) };
}

export function getAccessToken() {
  return getOktaTokens()?.accessToken?.accessToken || '';
}

export function getAccessTokenObject() {
  return getOktaTokens()?.accessToken;
}
