export default {
  dashboardLayoutSnapshot: [],

  myOrdersQuantity: 0,

  summary: {
    totalExceptions: 0,
    totalPOExceptions: 0,
    totalUnitsImpacted: 0,
    myApprovalsQuantity: 0,
    massOrdersInProgress: 0,
  },

  isOrdersInfoLoading: false,
};
