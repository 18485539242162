import { isEqual, findIndex } from 'lodash';

import filterPanel from '@/api/now/caseManagement/filterPanel';

export default {
  setSoldTo({ commit }, data) {
    commit('SET_SOLD_TO', data);
  },

  setShipTo({ dispatch, state }, shipTo) {
    if (!isEqual(shipTo, state.shipTo)) {
      dispatch('exceptionGroupsProxy', ['SET_SHIP_TO', shipTo]);
    }
  },

  setPreAppliedSoldTo({ commit }, soldTo) {
    commit('SET_PRE_APPLIED_SOLD_TO', soldTo);
  },

  setPreAppliedShipTo({ commit }, shipTo) {
    commit('SET_PRE_APPLIED_SHIP_TO', shipTo);
  },

  setOrderTypes({ dispatch, state }, orderTypes) {
    if (!isEqual(orderTypes, state.orderTypes)) {
      dispatch('exceptionGroupsProxy', ['SET_ORDER_TYPES', orderTypes]);
    }
  },

  async getExceptionGroups({
    commit,
    getters: { getIdsOfSelctedBusinessTypes = [] },
    state: { soldTo = [], shipTo = [] },
  }) {
    const { data: exceptionGroups } = await filterPanel.fetchExceptionGroups({
      businessType: getIdsOfSelctedBusinessTypes,
      soldTo,
      shipTo,
      orderType: [],
    });

    commit('SET_AVAILABLE_EXCEPTION_GROUPS', exceptionGroups);

    return exceptionGroups;
  },

  setExceptionGroups({ commit, state }, exceptionGroups) {
    const exceptionGroupIds = exceptionGroups.map(({ caseGroupId }) => caseGroupId);

    if (!isEqual(exceptionGroupIds, state.exceptionGroups)) {
      commit('SET_EXCEPTION_GROUPS', exceptionGroups);
    }
  },

  clearAllFilters({ commit }) {
    commit('CLEAR_ALL_FILTERS');
  },

  applyAllFilters({ commit }) {
    commit('APPLY_ALL_FILTERS');
  },

  exceptionGroupsProxy({
    commit, dispatch, state,
  }, mutation) {
    commit(mutation[0], mutation[1]);

    dispatch('getExceptionGroups').then((availableExceptionGroups) => {
      const result = availableExceptionGroups.filter((exceptionGroup) => {
        return (
          findIndex(state.exceptionGroups, (item) => exceptionGroup.caseGroupId === item) !== -1
        );
      });

      dispatch('setExceptionGroups', result);
    });
  },

  setDates({ commit, state }, selectedDates) {
    const [dateFrom = '', dateTo = ''] = selectedDates;

    if (state.dateFrom !== dateFrom) {
      commit('SET_DATE_FROM', dateFrom);
    }

    if (state.dateTo !== dateTo) {
      commit('SET_DATE_TO', dateTo);
    }
  },
};
