import { dependencyRulesActionSetDisabled } from '@/controllers/order/dependencies/line/actions/setDisabled';
import { dependencyRuleActionSetValue } from '@/controllers/order/dependencies/line/actions/setValue';
import { dependencyRuleActionSetRequired } from '@/controllers/order/dependencies/line/actions//setColumnRequired';
import { dependencyRulesActionSetCleared } from '@/controllers/order/dependencies/line/actions/setCleared';
import { dependencyRulesActionSetMinDate } from '@/controllers/order/dependencies/line/actions/setMinDate';
import { dependencyRuleActionSetColumnValue } from '@/controllers/order/dependencies/line/actions/setColumnValue';

export const LineLevelDependencyActionEnum = {
  setDisabled: 'setDisabled',
  setValue: 'setValue',
  setCleared: 'setCleared',
  setRequired: 'setRequired',
  setAvailability: 'setAvailability',
  setCellProperty: 'setCellProperty',
  setMinDate: 'setMinDate',
  setColumnValue: 'setColumnValue',
};

export const LineLevelDependencyRulesActionsMap = {
  [LineLevelDependencyActionEnum.setDisabled]: dependencyRulesActionSetDisabled,
  [LineLevelDependencyActionEnum.setValue]: dependencyRuleActionSetValue,
  [LineLevelDependencyActionEnum.setRequired]: dependencyRuleActionSetRequired,
  [LineLevelDependencyActionEnum.setCleared]: dependencyRulesActionSetCleared,
  [LineLevelDependencyActionEnum.setMinDate]: dependencyRulesActionSetMinDate,
  [LineLevelDependencyActionEnum.setColumnValue]: dependencyRuleActionSetColumnValue,
};
