import { createApp } from 'vue';
import Notifications from '@kyvg/vue3-notification';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { vMaska } from 'maska';

// MIXINS
import colorMixin from './mixins/colorMixin';
import { importBaseInputConfig } from '@/mixins/components/ui/BaseInput';
import { importBaseRadioConfig } from '@/mixins/components/ui/BaseRadio';
import { importBaseLoaderConfig } from '@/mixins/components/ui/BaseLoader';
import { importBaseCheckboxConfig } from '@/mixins/components/ui/BaseCheckbox';
import { importBaseTextAreaConfig } from '@/mixins/components/ui/BaseTextArea';
import { importBaseBreadcrumbConfig } from '@/mixins/components/ui/BaseBreadcrumb';
import { importBaseDatepickerConfig } from '@/mixins/components/ui/BaseDatepicker';
import { importBaseSelectMultiConfig } from '@/mixins/components/ui/BaseSelectMulti';
import { importBaseSelectSingleConfig } from '@/mixins/components/ui/BaseSelectSingle';
import { importBaseExpansionPanelConfig } from '@/mixins/components/ui/BaseExpansionPanel';
import { importBaseRadioExpansionPanelConfig } from '@/mixins/components/ui/BaseRadioExpansionPanel';

// Directives
import outsideClick from '@/directives/outsideClick';

import App from './App.vue';
import { registerRouter } from './router';
import { registerStore } from './store';
import { registerVuetify } from './plugins/vuetify';
import i18nPlugin from './plugins/i18n';
import { registerOkta } from './plugins/okta';
import './styles/main.scss';

import { registerApp } from './appInstance';

import monitoring from '@/utils/monitoring';
import { getGlobalConfig } from './utils/global';

// The license key is private and should be moved to env variables
LicenseManager.setLicenseKey('CompanyName=Insight Enterprises, Inc._on_behalf_of_Nike, Inc.,LicensedGroup=Nike Order Workspace,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-029364,SupportServicesEnd=31_August_2023_[v2]_MTY5MzQzNjQwMDAwMA==fd456cbca7158257525170edc431c65e');

const app = createApp(App);
registerApp(app);

const globalConfig = getGlobalConfig();

app.config.globalProperties.$config = Object.freeze(globalConfig);

app.config.errorHandler = (err, vm, info) => {
  console.error(err);

  monitoring.vueErrorHandler(err, vm, info);
};
app.config.warnHandler = err => {
  console.warn(err);
};

app.use(i18nPlugin);
app.use(Notifications);

registerStore(app);
registerOkta(app);
registerRouter(app);
registerVuetify(app);

app.directive('outside-click', outsideClick);
app.directive('maska', vMaska);

app.mixin(colorMixin);
app.mixin(importBaseInputConfig);
app.mixin(importBaseRadioConfig);
app.mixin(importBaseLoaderConfig);
app.mixin(importBaseCheckboxConfig);
app.mixin(importBaseTextAreaConfig);
app.mixin(importBaseBreadcrumbConfig);
app.mixin(importBaseDatepickerConfig);
app.mixin(importBaseSelectMultiConfig);
app.mixin(importBaseSelectSingleConfig);
app.mixin(importBaseExpansionPanelConfig);
app.mixin(importBaseRadioExpansionPanelConfig);

app.mount('#app');

export { app };
