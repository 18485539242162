import { dependencyRuleOrOperator } from './conditions/orOperator';
import { dependencyRuleAndOperator } from './conditions/andOperator';
import { dependencyRuleCertainValue } from './conditions/certainValue';
import { dependencyRuleIsParentEmpty } from './conditions/isParentEmpty';
import { dependencyRuleMatchingValue } from './conditions/matchingValue';
import { dependencyRuleSetOfConditions } from './conditions/setOfConditions';
import { dependencyRuleIsParentFilled } from './conditions/isParentFilled';
import { dependencyRuleChangeNodeValue } from './conditions/changeNodeValue';
import { dependencyRuleIsParentVisible } from './conditions/isParentVisible';
import { dependencyRuleNotCertainValue } from './conditions/notCertainValue';
import { dependencyRuleIsSportsMarketing } from './conditions/isSportsMarketing';
import { dependencyRuleHasOneSelectItem } from './conditions/hasOneSelectItem.js';
import { dependencyRuleIsNotSportsMarketing } from './conditions/isNotSportsMarketing';

export const DependencyRules = {
  or: dependencyRuleOrOperator,
  and: dependencyRuleAndOperator,
  empty: dependencyRuleIsParentEmpty,
  filled: dependencyRuleIsParentFilled,
  visible: dependencyRuleIsParentVisible,
  certainValue: dependencyRuleCertainValue,
  matchingValue: dependencyRuleMatchingValue,
  changedValue: dependencyRuleChangeNodeValue,
  setOfConditions: dependencyRuleSetOfConditions,
  notCertainValue: dependencyRuleNotCertainValue,
  hasOneSelectItem: dependencyRuleHasOneSelectItem,
  isSportsMarketing: dependencyRuleIsSportsMarketing,
  isNotSportsMarketing: dependencyRuleIsNotSportsMarketing,
};
