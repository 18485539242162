import { statusKeyToTableCellText } from "@/store/modules/myOrders/constants/table.js";
import { FIELD_KEYS } from "@/pages/Home/GlobalFilters/config.ts";

import { filterTypes } from "@/utils/globalFilters/index.js";

const getOptions = (list) =>
  list.map(({ code, name, description }) => ({
    value: code,
    text: `${code} - ${name || description}`,
  }));

const genderAndLeagueOptionGetter = (options) =>
  options.map(({ code, description }) => ({
    value: description,
    text: `${code} - ${description}`,
  }));

const marketplaceUnitGetter = (options) =>
  options.map(({ description }) => ({ value: description, text: description }));

const getStatusOptions = (statusList) =>
  statusList.map((status) => {
    const mappedStatus = statusKeyToTableCellText[status] || status;

    return { value: status, text: mappedStatus };
  });

const mapItems = (items, key) => {
  if (typeof items[0] === "string") {
    return getStatusOptions(items);
  }

  if ([FIELD_KEYS.LINE_GENDER, FIELD_KEYS.LINE_LEAGUE_INDICATOR].includes(key)) {
    return genderAndLeagueOptionGetter(items);
  }

  if (FIELD_KEYS.MARKETPLACE_UNIT === key) {
    return marketplaceUnitGetter(items);
  }

  return getOptions(items);
};

export const updateFieldsItems = ({ filterForm, geos }) => {
  filterForm.set("geos", geos);
};

export const validateField = (field) => {
  const { pattern, errorMessage } = field.validation;

  const regex = new RegExp(pattern);
  const valuesToCheck = field.value.split(",").map((value) => value.trim());

  const hasErrors = valuesToCheck.some((value) => !regex.test(value));

  return hasErrors ? errorMessage : "";
};

export const getFiltersToSave = (filterForm) => {
  const filtersToSave = [];

  filterForm.forEach(({ key, exclude, value }) => {
    const fieldConfig = {};

    if (value?.length) {
      if (typeof value === "string") {
        fieldConfig.value = value;
        fieldConfig.operator = filterTypes.EQUALS;
      } else {
        fieldConfig.values = value;
        fieldConfig.operator = filterTypes.IN;
      }

      filtersToSave.push({
        ...fieldConfig,
        key,
        exclude,
      });
    }
  });

  return filtersToSave;
};

export const mergeSavedFilters = (filterForm, savedFilters) => {
  filterForm.forEach((value, key) => {
    const clearField = { ...value, exclude: false, value: undefined };

    filterForm.set(key, clearField);
  });

  savedFilters.forEach(({ key, exclude, value, values }) => {
    const savedFieldValue = value || values;

    if (savedFieldValue?.length) {
      filterForm.set(key, { ...filterForm.get(key), exclude, value: savedFieldValue });
    }
  });

  return filterForm;
};
