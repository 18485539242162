import { splitSearchQuery } from '@/utils/globalFilters';

export default {
  isNavbarSearchActive: (state) => state.searchQuery?.length > 2,

  isSavedFiltersDataLoading: (state) => state.isLoading && !state.savedFilters,

  selectedFiltersConfig: (state) => state.savedFilters
    .find(filter => filter.filterName === state.selectedFiltersName),

  separatedSearchQueries: state => splitSearchQuery(state.searchQuery),
};
