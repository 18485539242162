import state from './orderErrors.state';
import actions from './orderErrors.actions';
import mutations from './orderErrors.mutations';
import getters from './orderErrors.getters';

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
