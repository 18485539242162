// AUTO = "System Defined"
// GENERAL = General Availability
// CONTRACT = "Contract Reference"
export const sourcingMethodValues = {
  AUTO: 'AUTO',
  GENERAL: 'GENERAL',
  CONTRACT: 'CONTRACT',
};

export const disabledReasonCodes = ['R40', 'R60', 'R71'];

export const dropdownValues = {
  sourcingMethod: [
    {
      text: 'System Defined',
      value: sourcingMethodValues.AUTO,
    },
    {
      text: 'General Availability',
      value: sourcingMethodValues.GENERAL,
    },
    {
      text: 'Contract Reference',
      value: sourcingMethodValues.CONTRACT,
    },
  ],

  sourcingMethodForSamples: [
    {
      text: 'General Availability',
      value: sourcingMethodValues.GENERAL,
    },
    {
      text: 'System Defined',
      value: sourcingMethodValues.AUTO,
    },
  ],

  deliveryBlock: [
    {
      text: '01 - Cancel Expected',
      value: '01',
    },
    {
      text: '02 - Exception Hold-Alloc',
      value: '02',
    },
    {
      text: '03 - Exception Hold-NoAlo',
      value: '03',
    },
    {
      text: '04 - Waiting on PO',
      value: '04',
    },
    {
      text: '05 - Waiting on Product',
      value: '05',
    },
    {
      text: '06 - Contract Not Rfrncd',
      value: '06',
    },
    {
      text: 'DS - WMS Status:Desched',
      value: 'DS',
    },
    {
      text: 'PH - WMS Status:Pack&Hold',
      value: 'PH',
    },
    {
      text: 'PK - WMS Status:Picked',
      value: 'PK',
    },
    {
      text: 'RE - WMS Status:Acknowled',
      value: 'RE',
    },
    {
      text: 'SC - WMS Status:Scheduled',
      value: 'SC',
    },
    {
      text: 'ZG - WMS GI:Infull',
      value: 'ZG',
    },
    {
      text: 'ZP - WMS GI:Partial',
      value: 'ZP',
    },
    {
      text: 'ZS - WMS GI:Del Split',
      value: 'ZS',
    },
    {
      text: 'SD - WMS:Stop, Bad Data',
      value: 'SD',
    },
    {
      text: 'SI - WMS:Stop, Inv Issue',
      value: 'SI',
    },
    {
      text: 'SO - WMS:Stop, Operationa',
      value: 'SO',
    },
    {
      text: 'SS - WMS:Stop, No Reason',
      value: 'SS',
    },
  ],

  reasonCode: [
    {
      text: 'R00 - Negotiated Returns',
      value: 'R00',
    },
    {
      text: 'R10 - Product Integrity',
      value: 'R10',
    },
    {
      text: 'R19 - Marketplace Integrity(formerly recall)',
      value: 'R19',
    },
    {
      text: 'R20 - Transportation Integrity',
      value: 'R20',
    },
    {
      text: 'R30 - Order Execution Integrity',
      value: 'R30',
    },
    {
      text: 'R40 - DC Execution Integrity',
      value: 'R40',
    },
    {
      text: 'R50 - Refused Order',
      value: 'R50',
    },
    {
      text: 'R60 - Unauthorized Return',
      value: 'R60',
    },
    {
      text: 'R70 - Counterfeit Product',
      value: 'R70',
    },
    {
      text: 'R71 - Consumer Worn Defective',
      value: 'R71',
    },
    {
      text: 'R73 - COMP Return',
      value: 'R73',
    },
    {
      text: 'R74 - Free Astray',
      value: 'R74',
    },
    {
      text: 'R75 - Digital to Wholesale Transfer',
      value: 'R75',
    },
    {
      text: 'R76 - Crested Product',
      value: 'R76',
    },
    {
      text: 'R77 - Obsolete Product',
      value: 'R77',
    },
  ],

  shippingCondition: [
    {
      text: 'Customer default',
      value: 'Customer default',
    },
    {
      text: 'Z0 - DC Proc 0, Transit 0',
      value: 'Z0',
    },
    {
      text: 'Z1 - DC Proc 0, Transit 1',
      value: 'Z1',
    },
    {
      text: 'Z2 - DC Proc 0, Transit 2',
      value: 'Z2',
    },
    {
      text: 'Z3 - DC Proc 0, Transit 3',
      value: 'Z3',
    },
    {
      text: 'ZS - DC Proc 0, Transit standard',
      value: 'ZS',
    },
  ],

  eventTypes: [
    {
      text: 'Private',
      value: 'PRIVATE',
    },
    {
      text: 'Open to Public',
      value: 'PUBLIC',
    },
  ],

  approved: [
    {
      text: '(Y) Yes',
      value: 'Y',
    },
    {
      text: '(N) No',
      value: 'N',
    },
  ],

  usageCode: [
    {
      text: 'Load in',
      value: 'LI',
    },
    {
      text: 'Fill in',
      value: 'FI',
    },
  ],

  vatRate: [
    {
      text: 'C6',
      value: 'C6',
    },
    {
      text: 'C7',
      value: 'C7',
    },
  ],
};

export const RADIO_YES_VALUE = 'y';
export const RADIO_NO_VALUE = 'n';

export const radioOptions = [
  {
    text: 'Yes',
    value: RADIO_YES_VALUE,
  },
  {
    text: 'No',
    value: RADIO_NO_VALUE,
  },
];
