import { createI18n } from 'vue-i18n';

import en from '@/enums/language/locales/en.json';

const i18nPlugin = createI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: { en },
});

export default i18nPlugin;
