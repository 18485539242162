export default [
  {
    value: 'MY_ORDERS',
    label: 'My Orders',
  },
  {
    value: 'ALL_OFOA_ORDERS',
    label: 'All Orders',
  },
];
