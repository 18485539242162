<script>
import pagesURL from '@/router/routes.js';

import { getAccessTokenObject } from '@/api/tokens/auth.token';

import monitoring from '@/utils/monitoring';

export default {
  beforeMount() {
    const queryParams = this.$route.query;

    this.handleAlreadyLoggedinUsers();

    if (!queryParams.error && queryParams.code && queryParams.state) {
      this.handleSuccessfulAuthentication();
    } else if (queryParams.error && queryParams.state) {
      this.handleFailedAuthentication();
    } else {
      this.handleDirectUrlAccess();
    }
  },

  methods: {
    async handleSuccessfulAuthentication() {
      try {
        await this.$store.dispatch('user/login');
        monitoring.onAuthenticated();
        monitoring.sendCustomEvent('LOGIN_OK');
      } catch (error) {
        // Workaround solution for INTERNAL Okta errors when user is using multiple tabs
        // This could be fixed with authState provided within @okta/okta-vue version 3 or newer
        setTimeout(() => {
          this.$auth.handleLoginRedirect();
        }, 300);
      }
    },

    handleFailedAuthentication() {
      const queryParams = this.$route.query;
      const expectedADGroupErrorMessage = 'User is not assigned to the client application.';

      const { name } = this.$router.resolve({ path: pagesURL.NOT_ALLOWED });

      const redirectConfig = {
        name,
        params: null,
      };

      monitoring.sendCustomEvent('LOGIN_FAILED', {
        queryParamsError: queryParams.error,
        queryParamsErrorDescription: queryParams.error_description,
      });

      if (
        queryParams.error === 'access_denied'
        && queryParams.error_description === expectedADGroupErrorMessage
      ) {
        redirectConfig.params = {
          info: 'notAssignedToAdGroupInfo',
          contact: 'pleaseContactManager',
        };
      }

      this.$router.replace(redirectConfig);
    },

    handleDirectUrlAccess() {
      this.$router.replace({
        path: pagesURL.ROOT,
      });
    },

    handleAlreadyLoggedinUsers() {
      const oktaAccessToken = getAccessTokenObject();

      if (oktaAccessToken) {
        window.location.replace(window.location.origin);
      }
    },
  },

  render() {
    return null;
  },
};
</script>
