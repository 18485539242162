const accessModesShortcuts = {
  READ_WRITE: 'R-W',
  READ: 'R',
};

const accessModes = {
  READ_WRITE: 'Read-Write',
  READ: 'Read',
};

export function initPendo(user) {
  const isLocalHost = window.location.origin.includes('localhost');

  if (user?.nikeId && !isLocalHost) {
  // eslint-disable-next-line
    pendo.initialize({
      visitor: {
        id: `${process.env.VUE_APP_ENV}-${user.nikeId}`,
        email: user.email,
        full_name: user.displayName,
        environment: `${process.env.VUE_APP_ENV}`,
        role: formatRoles(user.roles),
        geo: user.roles.map(({ geo }) => geo).join(', '),
        accessMode: user.roles.map(({ accessMode }) => accessModes[accessMode]).join(', '),
      },
      account: {
        id: 'Nike',
        name: 'Nike',
      },
    });
  }
}

function getGeoShortName(geo) {
  return geo.match(/\b(\w)/g).join('');
}

function formatRoles(roles) {
  return roles.map(({ geo, name, accessMode }) => {
    return `${name}/${getGeoShortName(geo)}/${accessModesShortcuts[accessMode]}`;
  }).join(', ');
}
