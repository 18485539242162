export default {
  SET_FILTER_FORM(state, form) {
    state.filterForm = form;
  },

  SET_FILTER_FIELD(state, field) {
    state.filterForm.set(field.key, field);
  },

  SET_APPLY_FILTER_FIELDS(state, fields) {
    state.appliedFilterFields = fields;
  },

  ADD_FIELD_WITH_ERROR(state, label) {
    state.fieldsWithErrors.add(label);
  },

  REMOVE_FIELD_WITH_ERROR(state, label) {
    state.fieldsWithErrors.delete(label);
  },

  RESET_FIELD_ERRORS(state) {
    state.fieldsWithErrors = new Set();
  },

  SET_SEARCH_QUERY(state, searchQuery) {
    state.searchQuery = searchQuery;
  },

  SET_SAVED_FILTERS(state, savedFilters) {
    state.savedFilters = savedFilters;
  },

  SET_SELECTED_FILTERS_NAME(state, selectedFiltersName) {
    state.selectedFiltersName = selectedFiltersName;
  },

  SET_DEFAULT_FILTERS_NAME(state, selectedFiltersName) {
    state.defaultFiltersName = selectedFiltersName;
  },

  SET_IS_LOADING(state, isLoading) {
    state.isLoading = isLoading;
  },

  SET_IS_FORM_EDITED(state, isFormEdited) {
    state.isFormEdited = isFormEdited;
  },
};
