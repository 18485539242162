export const disableHeaderFields = (form) => (
  form.fields.map(field => ({ ...field, disabled: true }))
);

export const disableLineFields = (form) => {
  const lineLevel = { ...form.lineItemFields };

  lineLevel.rowData = form.lineItemFields.rowData.map(row => {
    Object.keys(row).forEach(field => {
      if (!['id', 'line'].includes(field)) {
        row[field].disabled = true;
      }
    });

    return row;
  });

  return lineLevel;
};
