import repository from '@/api/repositories/serverRepository';
import { getBaseUrl } from '@/utils/api';

const baseUrl = `${getBaseUrl()}/v3/userOrderFilters`;

export default {
  getSavedFilters: () => repository.get(baseUrl),

  saveFilters: (payload = {}) => repository.post(baseUrl, payload),

  setDefaultFilters: (filterId, payload = {}) => repository.put(`${baseUrl}/${filterId}/default`, payload),

  updateFilters: (filterId, payload = {}) => repository.put(`${baseUrl}/${filterId}`, payload),

  deleteFilters: (filterId) => repository.delete(`${baseUrl}/${filterId}`),
};
