import { lengthRuleValidation } from '@/controllers/order/validations/line/lengthValidation';
import { regExpRuleValidation } from '@/controllers/order/validations/line/regExpRuleValidation';
import { requiredRuleValidation } from '@/controllers/order/validations/line/requiredRuleValidation';
import { asyncRuleValidation } from '@/controllers/order/validations/line/asyncRuleValidation';

export const LineLevelValidationRulesEnum = {
  required: 'required',
  lengthValidation: 'lengthValidation',
  regExpValidation: 'regExpValidation',
  asyncValidation: 'asyncValidation',
};

export const LineLevelValidationRules = {
  [LineLevelValidationRulesEnum.required]: requiredRuleValidation,
  [LineLevelValidationRulesEnum.lengthValidation]: lengthRuleValidation,
  [LineLevelValidationRulesEnum.regExpValidation]: regExpRuleValidation,
  [LineLevelValidationRulesEnum.asyncValidation]: asyncRuleValidation,
};
