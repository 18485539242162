import { FILTER_KEYS } from '@/enums/availability/filterKeys';

import { getPaginationEntries } from '@/utils/pagination';

import * as getterTypes from './constants/getters';

export default {
  [getterTypes.GTINS]: ({ filters }) => filters[FILTER_KEYS.GTINS],

  [getterTypes.AVAILABILITY_TYPE]: ({ filters }) => filters[FILTER_KEYS.SOURCING_METHOD],

  [getterTypes.AVAILABILITY_DETAILS]: ({ availabilityDetails }) => availabilityDetails || [],

  [getterTypes.HAS_ACTIVE_FILTERS]: ({ filters }) => Object.values(filters).some(Boolean),

  [getterTypes.CURRENT_PAGE_AVAILABILITY_DETAILS]: ({
    availabilityDetails, pageNumber, pageSize,
  }) => {
    return getPaginationEntries(availabilityDetails, pageNumber, pageSize);
  },
};
