import { statusKeyToTableCellText } from './constants/table';
import { transfromIsoDatesToTableFormat } from '@/utils/dates';

import { parseFilters } from './helpers';
import { parseGlobalFilters, getSearchFilters } from '@/utils/globalFilters';

const myOrdersData = {

  getOrderTypesNames({ orderTypes }) {
    return orderTypes.reduce((acc, { id, name }) => {
      acc[id] = name;

      return acc;
    }, {});
  },

  hasOrders({ myOrders }) {
    return myOrders.length > 0;
  },
};

const filtersSelection = {

  getRequestFilterParams: ({
    tableFilters,
    pageSize,
    pageNumber,
    sortedColumnName,
    sortedColumnOrder,
    selectedOrderTypes,
    selectedOrderOwnership,
    pageTokens,
  }, getters, rootState, rootGetters) => {
    const paginationToken = pageTokens.length >= pageNumber ? pageTokens[pageNumber] : pageTokens[pageTokens.length - 1];
    const { searchQuery, appliedFilterFields: globalFilters } = rootState.globalFilters;

    const filters = searchQuery
      ? getSearchFilters(rootGetters['globalFilters/separatedSearchQueries'])
      : [...parseGlobalFilters(globalFilters), ...parseFilters(tableFilters)];

    return {
      viewMode: selectedOrderOwnership,
      orderTypeIds: selectedOrderTypes,
      pageSize,
      pageNumber,
      sortBy: sortedColumnName,
      sortOrder: sortedColumnOrder,
      filters,
      paginationToken,
    };
  },
};

const myOrdersTable = {
  getFormatedTableData: ({ myOrders }) => {
    return myOrders.map((order) => {
      return {
        ...order,
        ...transfromIsoDatesToTableFormat(order),
        status: statusKeyToTableCellText[order.status],
      };
    });
  },
};

export default {
  ...myOrdersData,
  ...myOrdersTable,
  ...filtersSelection,
};
