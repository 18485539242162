import repository from '@/api/repositories/serverRepository';
import {
  ORDER, LO_FILTERING, TEMPLATE,
} from '@/api/endpoints';

export default {
  fetchOrdersFromServer: () => repository.get(ORDER.ORDERS),

  saveAndSubmitSocOrder: (order = {}) => repository.post(ORDER.SAVE_AND_SUBMIT, order),

  resubmitOrder: (orderId = {}) => repository.post(ORDER.RESUBMIT, orderId),

  saveDraftSocOrder: (order = {}) => repository.post(ORDER.SAVE, order),

  getOrderById: (orderId = '') => orderId && repository.get(`${ORDER.ORDERS}/${orderId}`),

  submitOrders: (orderIDs = []) => orderIDs.length && repository.post(ORDER.SUBMIT_ALL, orderIDs),

  saveOrders: (ordersToSave = []) => ordersToSave.length && repository.post(ORDER.SAVE_ALL, ordersToSave),

  deleteOrders: (orderIDs = []) => orderIDs.length && repository.post(ORDER.DELETE, orderIDs),

  // ! Deprecated - API endpoint will be removed eventually
  getOrdersByFileNames: (fileNames = []) => fileNames.length && repository.post(ORDER.GET_BY_FILE_NAMES, fileNames),

  getSOCDataFromServer() {
    repository.__addFilter(new RegExp(TEMPLATE.VALID_SINGLE_ORDER_TRAITS)); // adds caching for http calls when we expect non-dynamic data

    return repository.get(TEMPLATE.VALID_SINGLE_ORDER_TRAITS);
  },

  fetchUserActivityOverview: () => repository.get(LO_FILTERING.USER_ACTIVITY_OVERVIEW),

  fetchUserActivityOverviewByFilters: filters => {
    return repository.post(LO_FILTERING.USER_ACTIVITY_OVERVIEW, filters);
  },
};
