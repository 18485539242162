export default {
  getIsSubmitButtonDisabled: (
    { orderErrors },
    _,
    {
      orders: {
        selectedOrderForTable: { errors },
      },
    },
  ) => !!errors || !!orderErrors.length,
};
