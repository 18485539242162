import { asc, des } from '@/enums/sortTypes';
import exceptionsChart from '@/api/now/exceptions';

export default {
  async fetchExceptions({ commit, dispatch }) {
    try {
      dispatch('setIsExceptionsFetching', true);

      const {
        data: { caseTypes, summary },
      } = await exceptionsChart.getExceptions();

      commit(
        'SET_EXCEPTIONS',
        caseTypes.map(({
          name, totalUnits, totalExceptions,
        }) => {
          return {
            label: name,
            count: totalUnits,
            totalExceptions,
          };
        }),
      );
      commit('SET_SUMMARY', summary);

      dispatch('setIsExceptionsFetching', false);

      return true;
    } catch (error) {
      console.log('CAN NOT FETCH EXCEPTIONS ', error);

      commit('SET_SUMMARY', {
        totalPOs: 0, totalCases: 0, totalUnitsOnAllOrders: 0,
      });
      dispatch('setIsExceptionsFetching', false);
    }

    return false;
  },

  sortCaseTypes({ state: { exceptions }, commit }, order) {
    const exceptionASC = exceptions.sort((a, b) => a.count - b.count);

    switch (order) {
      case asc: {
        commit('SET_EXCEPTIONS', exceptionASC);
        break;
      }
      case des: {
        commit('SET_EXCEPTIONS', [...exceptionASC].reverse());
        break;
      }
      default:
    }
  },

  setIsExceptionsFetching({ commit }, isExceptionsFetching) {
    commit('SET_IS_EXCEPTION_FETCHING', isExceptionsFetching);
  },
};
