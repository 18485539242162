import employeeActions from './employee/employee.actions';
import approvalsActions from './approvals/approvals.actions';
import orderInfoActions from './ordersInfo/ordersInfo.actions';
import SOCActions from './orderCreation/SOC.actions';

import employeeGetters from './employee/employee.getters';
import approvalsGetters from './approvals/approvals.getters';
import orderInfoGetters from './ordersInfo/ordersInfo.getters';
import SOCGetters from './orderCreation/SOC.getters';

import employeeMutations from './employee/employee.mutations';
import approvalsMutations from './approvals/approvals.mutations';
import orderInfoMutations from './ordersInfo/ordersInfo.mutations';
import SOCMutations from './orderCreation/SOC.mutations';

import approvalsState from './approvals/approvals.state';
import employeeState from './employee/employee.state';
import orderInfoState from './ordersInfo/ordersInfo.state';
import SOCState from './orderCreation/SOC.state';

const state = {
  ...approvalsState,
  ...employeeState,
  ...orderInfoState,
  ...SOCState,
};

const getters = {
  ...employeeGetters,
  ...approvalsGetters,
  ...orderInfoGetters,
  ...SOCGetters,
};

const actions = {
  ...employeeActions,
  ...approvalsActions,
  ...orderInfoActions,
  ...SOCActions,
};

const mutations = {
  ...employeeMutations,
  ...approvalsMutations,
  ...orderInfoMutations,
  ...SOCMutations,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
