// TODO: To detected if value of the cell has changed:
// 1. Compare vuex state parent (Master) field value with controller.formTemplate.lineItemFields child (Slave) field value
// 2. In order for that to work vuex SOC state should be udpated on the ready phase of the controller flow
// 3. Dependencies and Validations actions should operate on controller.formTemplate.lineItemFields instead on formData global state

/**
 * TODO: Full descriptive name: atLeastOneCellInColumnChangedValue or just ColumnChangedValue?
 * Returns value of the cell in the column that has changed value
 * If no cell in the column chagned value returns UNDEFINED
 */
export const dependencyRuleColumnChangedValue = ({
  dependency,
  updatedField,
}) => {
  // * Temporary solution that works - will trigger change if the partnet (Master) field is updatedField
  // ! Will not work in case of chained dependencies: e.g. Value of Field3 that depends on Field2 that depends on Field1 - not urgent since we do not have such chained dependency yet
  if (dependency?.sourceFieldKey === updatedField?.name) {
    return updatedField.cellData.value;
  }
};
