export const mapDropdownItems = regions => regions?.map(({ code, name }) => {
  return ({ value: code, text: `${code} - ${name}` });
}) || [];

export const mapCountries = (countries) => {
  return countries.map(({
    code, name, regions,
  }) => {
    const regionItems = mapDropdownItems(regions);

    return {
      value: `${code}`, text: `${code} - ${name}`, regions: regionItems,
    };
  });
};

export const mapSalesOrgs = (salesOrgs) => salesOrgs.map(({
  sapId, name, geo,
}) => ({
  value: `${sapId}`,
  text: `${name} - ${sapId}`,
  geo: geo.id,
}));
