import filterPanel from '@/api/now/caseManagement/filterPanel';

export default {
  async getCustomerDetails(
    {
      commit, getters: { getIdsOfSelctedBusinessTypes = [] }, state,
    },
    query = '',
  ) {
    const filterParams = {
      query,
      businessTypes: getIdsOfSelctedBusinessTypes,
    };

    if (state.paginationToken) {
      filterParams.paginationToken = state.paginationToken;
    }

    if (state.paginationToken !== undefined) {
      const { data: customerDetails } = await filterPanel.fetchCustomerDetails(
        filterParams,
      );

      if (state.paginationToken) {
        commit('EXTEND_CUSTOMER_DETAILS', customerDetails.results);
      } else {
        commit('SET_CUSTOMER_DETAILS', customerDetails.results);
      }

      commit('SET_CUSTOMER_DETAILS_PAGINATION', customerDetails.nextPaginationToken);

      return customerDetails.results;
    }
  },

  resetCustomerDetailsPagination({ commit }) {
    commit('SET_CUSTOMER_DETAILS_PAGINATION', '');
  },
};
