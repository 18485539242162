export const APPROVAL_STATUSES = {
  OPEN: 'OPEN',
  PENDING: 'PENDING',
  REJECTED: 'REJECTED',
  APPROVED: 'APPROVED',
  BYPASS: 'BYPASS',
  REWORK: 'REWORK',
};

export const APPROVAL_STATUSES_VALUES = [
  { text: 'Approve', value: APPROVAL_STATUSES.APPROVED },
  { text: 'Reject', value: APPROVAL_STATUSES.REJECTED },
  { text: 'Rework', value: APPROVAL_STATUSES.REWORK },
];

export const USER_CONTEXT_ROLE_VALUES = {
  REQUESTER: 'REQUESTER',
  CONTRACT_OWNER: 'CONTRACT_OWNER',
  MANAGER: 'MANAGER',
  NONE: 'NONE',
};
