import store from '@/store';

export function getStoreData() {
  const { state, getters } = store;

  return {
    selectedBusinessTypes: state.caseManagement.selectedBusinessTypes,
    idsOfSelctedBusinessTypes: getters['caseManagement/getIdsOfSelctedBusinessTypes'],
    availableExceptionGroups: state.caseManagement.availableExceptionGroups,
  };
}
