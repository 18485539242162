import i18n from '@/plugins/i18n';

import { isDateField } from '@/controllers/order/utils/field';
import { convertDateFormat, isPastDate } from '@/utils/dates';
import { addDaysToDate } from '@/utils/singleOrderCreate';

import { fieldNames } from '@/enums/singleOrderCreation/fieldNames';
import dateFormats from '@/enums/dates/dateFormats';

const excludedFields = ['eqpSizes', 'customSizes', 'fwSizes', 'line', 'id', 'allSizes'];
const excludedFieldsDraft = ['eqpSizes', 'customSizes', 'fwSizes', 'line', 'id', 'approverStatus', 'errors', 'allSizes'];

const draftOrderMappers = (row, rowFromBE, field) => {
  switch (true) {
    case field === fieldNames.totalQuantity:
      row.qty.value = 0;
      break;
    case field === fieldNames.vasCodes:
      row[field].value = rowFromBE[field].map(({ value }) => value);
      break;
    case isDateField(field):
      if (rowFromBE[field]?.value) {
        row[field].value = convertDateFormat(rowFromBE[field].value);
        row[field].errors = isPastDate(rowFromBE[field].value) ? [{ error: i18n.global.t('widgets.errorMessages.incorrectDate') }] : [];
        if (field === fieldNames.cancelDate && rowFromBE[fieldNames.crd]?.value) {
          row[field].minDate = addDaysToDate(rowFromBE.crd?.value, 6);
        }
      }
      break;
    case ((!excludedFieldsDraft.includes(field)) && !!rowFromBE[field]):
      if (rowFromBE[field]?.value) {
        row[field].value = rowFromBE[field].value;
      } else if (typeof rowFromBE[field] !== 'object') {
        row[field].value = rowFromBE[field];
      }
      break;
    default: break;
  }

  return row;
};

const submittedOrderMappers = (row, rowFromBE, field) => {
  switch (true) {
    case field === fieldNames.totalQuantity:
      row.qty.value = rowFromBE[field];
      break;
    case field === fieldNames.vasCodes:
      row[field].value = rowFromBE[field].map(({ value }) => value);
      break;
    case isDateField(field):
      row[field].value = convertDateFormat(rowFromBE[field].value, dateFormats.CHINA);
      row[field].errors = isPastDate(rowFromBE[field].value) ? [{ error: i18n.global.t('widgets.errorMessages.incorrectDate') }] : [];
      break;
    case field === fieldNames.approverStatus:
      if (row && rowFromBE[field]) {
        row[field].value = rowFromBE[field];
      }
      break;
    case field === fieldNames.requirementSegment:
      row[field].value = rowFromBE[field].value;
      row[field].items = [{ text: rowFromBE[field].value, value: rowFromBE[field].value }];
      break;
    case field === 'id':
      row.approverStatus.FetchedRowId = rowFromBE[field];
      break;
    case ((!excludedFields.includes(field)) && !!rowFromBE[field]):
      if (rowFromBE[field]?.value) {
        row[field].value = rowFromBE[field].value;
      } else {
        row[field].value = rowFromBE[field];
      }
      break;
    default: break;
  }

  return row;
};

export const mapLineFields = (form, fieldsFromBackEnd, draft) => {
  const { lineItemFields, lineItemFields: { rowData } } = form || { lineItemFields: { rowData: [] } };
  const { lineItems: rowsFromBE } = fieldsFromBackEnd || { lineItems: [] };

  if (!rowData.length) return lineItemFields;

  const mappers = draft ? draftOrderMappers : submittedOrderMappers;
  const copiedRows = [...rowData];

  rowsFromBE.forEach((_row, rowIndex) => {
    Object.keys(_row).forEach((rowField) => {
      mappers(copiedRows[rowIndex], _row, rowField);
    });
  });

  return {
    ...lineItemFields,
    rowData: copiedRows,
  };
};
