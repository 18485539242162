import axios from 'axios';
import wrapper from 'axios-cache-plugin';

import httpErrorResponseInterceptor from '@/api/interceptors/http/response/errors.interceptor';
import responseStructureTypes from '@/enums/http/responseStructureTypes';
import { getAccessToken } from '@/api/tokens/auth.token';
import localStorageKey from '@/enums/browserStorage/localStorageKeyEnum';
import { getBaseUrl } from '@/utils/api';
import monitoring from '@/utils/monitoring';
import appWrapper from '@/appInstance';

const baseURL = getBaseUrl();

const httpClient = axios.create({
  baseURL,
  responseStructureSuccess: responseStructureTypes.internalStandard,
  responseStructureError: responseStructureTypes.internalStandard,
});

const requestInterceptor = (config) => {
  localStorage.setItem(localStorageKey.lastUserAppActivity, new Date().getTime());
  const accessToken = getAccessToken();

  if (!accessToken) {
    monitoring.sendCustomEvent('EMPTY_ACCESS_TOKEN');
  }

  config.headers.Authorization = `Bearer ${accessToken}`;

  return config;
};

httpClient.interceptors.request.use(requestInterceptor, (error) => Promise.reject(error));

/**
 * Handler for different response structures
 *
 * Explicitly define response JSON structure type in Axios fetch requests
 * Default for success and error responses is internalStandard
 *
 * external is preset for future use when NOW UI starts to fetch data from external APIs
 */
httpClient.interceptors.response.use(
  (response) => {
    switch (response.config.responseStructureSuccess) {
      case responseStructureTypes.internalNonStandard:
        return response;
      case responseStructureTypes.external:
        return response;
      default:
        return { ...response, data: response.data.data || [], warnings: response.data.warnings };
    }
  },
  async(error) => {
    const { $auth } = appWrapper.app.config.globalProperties;

    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest.isTokenRefreshed) {
      try {
        await $auth.tokenManager.renew('accessToken');

        originalRequest.isTokenRefreshed = true;
        monitoring.sendCustomEvent('OK_REFRESH_TOKEN');
        monitoring.onAuthenticated();

        return httpClient(originalRequest);
      } catch (error) {
        monitoring.sendCustomEvent('ERROR_REFRESH_TOKEN');

        return Promise.reject(error);
      }
    }

    switch (error.config.responseStructureError) {
      case responseStructureTypes.external:
        return Promise.reject(error);
      default:
        return httpErrorResponseInterceptor(error);
    }
  },
);

export default wrapper(httpClient, {
  maxCacheSize: 15,
  ttl: 60000,
  excludeHeaders: true,
});
