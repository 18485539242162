import { defineAsyncComponent } from 'vue';

import { BASE_INPUT_SIZES } from '@/components/ui/BaseInput';

const BaseInput = defineAsyncComponent(() => import('@/components/ui/BaseInput'));

export const importBaseInputConfig = {
  components: {
    BaseInput,
  },

  data() {
    return { BASE_INPUT_SIZES };
  },
};
