import i18n from '@/plugins/i18n';

import { FormTemplateValidationRulesMap } from '@/enums/SOC/formTemplate.consts';
import { fieldNames } from '@/enums/singleOrderCreation/fieldNames';
import { LineLevelDependencyConditionEnum } from '@/controllers/order/dependencies/line';
import {
  HeaderLevelDependencyActionsEnum,
  HeaderLevelDependencyConditionEnum,
} from '@/enums/SOC/dependencyEnums';
import {
  LineLevelDependencyActionEnum,
} from '@/controllers/order/dependencies/line/actions/actionsMap';
import { LineLevelValidationRulesEnum } from '@/controllers/order/validations/line';
import { setInitialMinDate } from '@/utils/singleOrderCreate';

import { translateModule } from '@/utils/string/translate';
import { FIELDS_ENUM } from '@/enums/language/fieldsEnum';

const translateFields = translateModule('fields');

export default {
  fields: [
    {
      type: 'SelectSingle',
      key: fieldNames.salesOrganization,
      dependencyRules: [
        {
          sourceFieldKey: fieldNames.materialNumber,
          sourceFieldType: 'LineLevel',
          conditionType: LineLevelDependencyConditionEnum.columnHasValue,
          action: HeaderLevelDependencyActionsEnum.setDisabled,
        },
        {
          sourceFieldKey: fieldNames.materialNumber,
          sourceFieldType: 'LineLevel',
          conditionType: LineLevelDependencyConditionEnum.columnHasValue,
          action: HeaderLevelDependencyActionsEnum.setTooltipMessage,
          tooltipMessage: i18n.global.t('pages.SOC.form.disabledDropdownTooltip'),
        },
      ],
      validationRules: [FormTemplateValidationRulesMap.REQUIRED],
      items: [],
      class: 'sales-organization-wrapper header-level__item_fullwidth header-level__item_title',
      testId: 'soc-form-sales-org-field',
      width: '375',
      label: translateFields(FIELDS_ENUM.salesOrg),
      value: '',
      tooltipMessage: '',
      disabled: false,
      hidden: false,
      required: true,
    },
    {
      type: 'SoldTo',
      key: fieldNames.soldToNumber,
      validationRules: [FormTemplateValidationRulesMap.REQUIRED],
      dependencyRules: [
        {
          conditionType: HeaderLevelDependencyConditionEnum.or,
          conditions: [
            {
              sourceFieldKey: fieldNames.salesOrganization,
              sourceFieldType: 'HeaderLevel',
              conditionType: HeaderLevelDependencyConditionEnum.empty,
            },
            {
              sourceFieldKey: fieldNames.materialNumber,
              sourceFieldType: 'LineLevel',
              conditionType: LineLevelDependencyConditionEnum.columnHasValue,
            },
          ],
          action: HeaderLevelDependencyActionsEnum.setDisabled,
        },
        {
          sourceFieldKey: fieldNames.salesOrganization,
          conditionType: HeaderLevelDependencyConditionEnum.filled,
          action: HeaderLevelDependencyActionsEnum.setParentValue,
        },
        {
          sourceFieldKey: fieldNames.salesOrganization,
          conditionType: HeaderLevelDependencyConditionEnum.changedValue,
          action: HeaderLevelDependencyActionsEnum.setParentValue,
        },
        {
          sourceFieldKey: fieldNames.salesOrganization,
          conditionType: HeaderLevelDependencyConditionEnum.empty,
          action: HeaderLevelDependencyActionsEnum.setCleared,
        },
        {
          sourceFieldKey: fieldNames.materialNumber,
          sourceFieldType: 'LineLevel',
          conditionType: LineLevelDependencyConditionEnum.columnHasValue,
          action: HeaderLevelDependencyActionsEnum.setTooltipMessage,
          tooltipMessage: i18n.global.t('pages.SOC.form.disabledDropdownTooltip'),
        },
      ],
      class: 'sold-to-wrapper',
      width: '375',
      label: translateFields(FIELDS_ENUM.soldTo),
      name: 'Sold To',
      value: null,
      tooltipMessage: '',
      parentValue: '',
      disabled: true,
      hidden: false,
      required: true,
    },
    {
      type: 'ShipTo',
      key: fieldNames.shipToNumber,
      validationRules: [FormTemplateValidationRulesMap.REQUIRED],
      dependencyRules: [
        {
          conditionType: HeaderLevelDependencyConditionEnum.or,
          conditions: [
            {
              sourceFieldKey: fieldNames.salesOrganization,
              sourceFieldType: 'HeaderLevel',
              conditionType: HeaderLevelDependencyConditionEnum.empty,
            },
            {
              sourceFieldKey: fieldNames.soldToNumber,
              sourceFieldType: 'HeaderLevel',
              conditionType: HeaderLevelDependencyConditionEnum.empty,
            },
            {
              sourceFieldKey: fieldNames.materialNumber,
              sourceFieldType: 'LineLevel',
              conditionType: LineLevelDependencyConditionEnum.columnHasValue,
            },
          ],
          action: HeaderLevelDependencyActionsEnum.setDisabled,
        },
        {
          conditionType: HeaderLevelDependencyConditionEnum.or,
          conditions: [
            {
              sourceFieldKey: fieldNames.salesOrganization,
              conditionType: HeaderLevelDependencyConditionEnum.empty,
            },
            {
              sourceFieldKey: fieldNames.soldToNumber,
              conditionType: HeaderLevelDependencyConditionEnum.empty,
            },
          ],
          action: HeaderLevelDependencyActionsEnum.setCleared,
        },
        {
          sourceFieldKey: fieldNames.materialNumber,
          sourceFieldType: 'LineLevel',
          conditionType: LineLevelDependencyConditionEnum.columnHasValue,
          action: HeaderLevelDependencyActionsEnum.setTooltipMessage,
          tooltipMessage: i18n.global.t('pages.SOC.form.disabledDropdownTooltip'),
        },
        {
          sourceFieldKey: fieldNames.soldToNumber,
          conditionType: HeaderLevelDependencyConditionEnum.changedValue,
          action: HeaderLevelDependencyActionsEnum.setItems,
        },
      ],
      class: 'ship-to-wrapper',
      width: '375',
      label: translateFields(FIELDS_ENUM.shipTo),
      name: 'Ship To',
      value: '',
      tooltipMessage: '',
      parentValue: {},
      touched: false,
      disabled: true,
      hidden: false,
      required: true,
    },
    {
      type: 'Input',
      key: fieldNames.customerPONumber,
      validationRules: [
        FormTemplateValidationRulesMap.REQUIRED,
        FormTemplateValidationRulesMap.MAXLENGTH,
        FormTemplateValidationRulesMap.CAPITALIZE,
      ],
      maxLength: 35,
      dependencyRules: [
        {
          sourceFieldKey: fieldNames.salesOrganization,
          conditionType: HeaderLevelDependencyConditionEnum.empty,
          action: HeaderLevelDependencyActionsEnum.setDisabled,
        },
      ],
      class: 'customer-po-number-wrapper',
      testId: 'soc-form-customer-po-number-field',
      width: '375',
      label: translateFields(FIELDS_ENUM.purchaseOrderNumber),
      value: null,
      disabled: false,
      hidden: false,
      required: true,
    },
    {
      type: 'Datepicker',
      key: fieldNames.crd,
      validationRules: [FormTemplateValidationRulesMap.REQUIRED],
      dependencyRules: [
        {
          sourceFieldKey: fieldNames.salesOrganization,
          conditionType: HeaderLevelDependencyConditionEnum.empty,
          action: HeaderLevelDependencyActionsEnum.setDisabled,
        },
      ],
      class: 'crd-wrapper',
      testId: 'soc-form-crd-field',
      width: '375',
      minDate: setInitialMinDate({
        refDate: new Date(),
      }),
      label: translateFields(FIELDS_ENUM.crd),
      value: null,
      disabled: false,
      hidden: false,
      required: true,
    },
    {
      type: 'Datepicker',
      key: fieldNames.cancelDate,
      validationRules: [FormTemplateValidationRulesMap.REQUIRED],
      dependencyRules: [
        {
          conditionType: HeaderLevelDependencyConditionEnum.or,
          conditions: [
            {
              sourceFieldKey: fieldNames.salesOrganization,
              conditionType: HeaderLevelDependencyConditionEnum.empty,
            },
            {
              sourceFieldKey: fieldNames.crd,
              conditionType: HeaderLevelDependencyConditionEnum.empty,
            },
          ],
          action: HeaderLevelDependencyActionsEnum.setDisabled,
        },
        {
          sourceFieldKey: fieldNames.crd,
          conditionType: HeaderLevelDependencyConditionEnum.changedValue,
          action: HeaderLevelDependencyActionsEnum.setMinDate,
          actionArgs: {
            add: 30,
            unit: 'calendarDay',
          },
        },
        {
          sourceFieldKey: fieldNames.crd,
          conditionType: HeaderLevelDependencyConditionEnum.changedValue,
          action: HeaderLevelDependencyActionsEnum.setDateValue,
          actionArgs: {
            add: 30,
            unit: 'calendarDay',
          },
        },
      ],
      class: 'cancel-date-wrapper',
      testId: 'soc-form-cancel-date-field',
      width: '375',
      label: translateFields(FIELDS_ENUM.cancelDate),
      minDate: null,
      value: null,
      disabled: false,
      hidden: false,
    },
    {
      type: 'SelectSingle',
      key: fieldNames.reasonCode,
      validationRules: [FormTemplateValidationRulesMap.REQUIRED],
      dependencyRules: [
        {
          sourceFieldKey: fieldNames.salesOrganization,
          conditionType: HeaderLevelDependencyConditionEnum.empty,
          action: 'setDisabled',
        },
      ],
      items: [],
      class: 'reason-code-wrapper',
      testId: 'soc-form-reason-code-field',
      width: '375',
      label: translateFields(FIELDS_ENUM.reasonCode),
      value: null,
      disabled: false,
      hidden: false,
      required: true,
    },
    {
      type: 'Input',
      key: fieldNames.nameOfOrderer,
      validationRules: [FormTemplateValidationRulesMap.MAXLENGTH],
      maxLength: 35,
      dependencyRules: [
        {
          sourceFieldKey: fieldNames.salesOrganization,
          conditionType: HeaderLevelDependencyConditionEnum.empty,
          action: 'setDisabled',
        },
      ],
      class: 'name-of-orderer-wrapper',
      testId: 'soc-form-name-of-orderer-field',
      width: '375',
      label: translateFields(FIELDS_ENUM.nameOfOrderer),
      value: null,
      disabled: false,
      hidden: false,
      required: false,
    },
    {
      type: 'Input',
      key: fieldNames.returnAuthorizationsNumber,
      validationRules: [
        FormTemplateValidationRulesMap.MAXLENGTH,
        FormTemplateValidationRulesMap.ONLY_DIGITS_LETTERS,
      ],
      maxLength: 35,
      dependencyRules: [
        {
          sourceFieldKey: fieldNames.salesOrganization,
          conditionType: HeaderLevelDependencyConditionEnum.empty,
          action: 'setDisabled',
        },
        {
          sourceFieldKey: fieldNames.reasonCode,
          conditionType: HeaderLevelDependencyConditionEnum.certainValue,
          value: 'R00',
          action: 'setRequired',
        },
      ],
      class: 'return-authorizations-number-wrapper',
      testId: 'soc-form-return-authorizations-number-field',
      width: '375',
      label: 'Return Authorizations Number',
      value: null,
      disabled: false,
      hidden: false,
      required: false,
    },
    {
      type: 'Input',
      key: fieldNames.numberOfCartons,
      validationRules: [
        FormTemplateValidationRulesMap.MAXLENGTH,
        FormTemplateValidationRulesMap.ONLY_DIGITS,
      ],
      maxLength: 4,
      dependencyRules: [
        {
          sourceFieldKey: fieldNames.salesOrganization,
          conditionType: HeaderLevelDependencyConditionEnum.empty,
          action: 'setDisabled',
        },
      ],
      class: 'number-of-cartons-wrapper',
      testId: 'soc-form-number-of-cartons-field',
      width: '375',
      label: 'Number of Cartons',
      value: null,
      disabled: false,
      hidden: false,
      required: false,
    },
    {
      type: 'Input',
      key: fieldNames.internalOrderReferenceNumber,
      validationRules: [
        FormTemplateValidationRulesMap.MAXLENGTH,
        FormTemplateValidationRulesMap.ONLY_DIGITS_LETTERS,
      ],
      maxLength: 20,
      dependencyRules: [
        {
          sourceFieldKey: fieldNames.salesOrganization,
          conditionType: HeaderLevelDependencyConditionEnum.empty,
          action: 'setDisabled',
        },
        {
          sourceFieldKey: fieldNames.soldToNumber,
          conditionType: HeaderLevelDependencyConditionEnum.isNotSportsMarketing,
          action: 'setRequired',
        },
      ],
      class: 'internal-order-reference-number-wrapper',
      testId: 'soc-form-internal-order-reference-number-field',
      width: '375',
      label: translateFields(FIELDS_ENUM.internalOrderNumber),
      value: null,
      disabled: false,
      hidden: false,
      required: false,
    },
    {
      type: 'SelectSingle',
      key: fieldNames.departmentCode,
      validationRules: [
        FormTemplateValidationRulesMap.MAXLENGTH,
        FormTemplateValidationRulesMap.ONLY_DIGITS,
      ],
      maxLength: 10,
      dependencyRules: [
        {
          sourceFieldKey: fieldNames.soldToNumber,
          conditionType: HeaderLevelDependencyConditionEnum.changedValue,
          action: HeaderLevelDependencyActionsEnum.setItems,
        },
        {
          conditionType: HeaderLevelDependencyConditionEnum.or,
          conditions: [
            {
              sourceFieldKey: fieldNames.soldToNumber,
              conditionType: HeaderLevelDependencyConditionEnum.empty,
            },
            {
              sourceFieldKey: fieldNames.salesOrganization,
              conditionType: HeaderLevelDependencyConditionEnum.empty,
            },
          ],
          action: HeaderLevelDependencyActionsEnum.setCleared,
        },
        {
          conditionType: HeaderLevelDependencyConditionEnum.or,
          conditions: [
            {
              sourceFieldKey: fieldNames.soldToNumber,
              conditionType: HeaderLevelDependencyConditionEnum.empty,
            },
            {
              sourceFieldKey: fieldNames.salesOrganization,
              conditionType: HeaderLevelDependencyConditionEnum.empty,
            },
          ],
          action: HeaderLevelDependencyActionsEnum.setDisabled,
        },
      ],
      class: 'department-code-wrapper',
      testId: 'soc-form-department-code-field',
      width: '375',
      label: translateFields(FIELDS_ENUM.departmentCode),
      value: null,
      disabled: false,
      hidden: false,
      required: false,
    },
  ],
  lineItemFields: {
    columnDefs: [
      {
        name: 'Line',
        field: 'line',
        required: false,
      },
      {
        name: translateFields(FIELDS_ENUM.materialNumber),
        field: 'materialNumber',
        required: true,
      },
      {
        name: translateFields(FIELDS_ENUM.orderQuantity),
        field: 'qty',
        required: true,
      },
      {
        name: translateFields(FIELDS_ENUM.crdLineLevel),
        field: 'crd',
        required: true,
      },
      {
        name: translateFields(FIELDS_ENUM.cancelDateLineLevel),
        field: 'cancelDate',
        required: false,
      },
      {
        name: translateFields(FIELDS_ENUM.plantCode),
        field: 'plantCode',
        required: false,
      },
      {
        name: translateFields(FIELDS_ENUM.unitAllotment),
        field: 'unitAllotment',
        required: false,
      },
      {
        name: 'Invoice Number',
        field: 'invoiceNumber',
        required: false,
      },
      {
        name: 'VAT Rate',
        field: 'vatRate',
        required: false,
      },
      {
        field: 'appSizes',
        name: '',
        required: false,
      },
      {
        field: 'availabilityData',
        name: '',
        required: false,
      },
    ],
    validationRules: {
      [fieldNames.crd]: {
        [LineLevelValidationRulesEnum.required]: {
          required: 'columnDefined',
        },
      },
      [fieldNames.cancelDate]: {
        [LineLevelValidationRulesEnum.required]: {
          required: 'columnDefined',
        },
      },
      [fieldNames.materialNumber]: {
        [LineLevelValidationRulesEnum.required]: {
          required: 'columnDefined',
        },
        [LineLevelValidationRulesEnum.regExpValidation]: {
          pattern: '^([a-zA-Z0-9]{6})-([a-zA-Z0-9]{3})$',
          errorMessage: 'Expected Format: BQ3757-101',
        },
        [LineLevelValidationRulesEnum.lengthValidation]: {
          maxLength: 10,
        },
      },
      [fieldNames.qty]: {
        [LineLevelValidationRulesEnum.regExpValidation]: {
          pattern: '^[0-9]*$',
          errorMessage: 'Only digits are allowed',
        },
      },
      unitAllotment: {
        [LineLevelValidationRulesEnum.lengthValidation]: {
          maxLength: 70,
        },
      },
      invoiceNumber: {
        [LineLevelValidationRulesEnum.regExpValidation]: {
          pattern: '^[0-9]*$',
          errorMessage: 'Only digits are allowed',
        },
        [LineLevelValidationRulesEnum.lengthValidation]: {
          maxLength: 10,
        },
      },
    },
    dependencies: [
      {
        fieldName: fieldNames.plantCode,
        sourceFieldKey: fieldNames.plantCode,
        sourceFieldType: 'LineLevel',
        conditionType: LineLevelDependencyConditionEnum.columnChangedValue,
        action: LineLevelDependencyActionEnum.setColumnValue,
      },
      {
        fieldName: fieldNames.cancelDate,
        sourceFieldKey: fieldNames.crd,
        sourceFieldType: 'LineLevel',
        conditionType: LineLevelDependencyConditionEnum.cellChangedValue,
        action: LineLevelDependencyActionEnum.setMinDate,
        actionArgs: {
          add: 30,
          unit: 'calendarDay',
        },
      },
    ],
    rowData: [],
  },
  name: 'Free-of-Charge Returns Sales Order',
  orderType: {
    name: 'Free-of-Charge Returns Sales Order',
    id: 7,
    sapId: 'ZRFR',
  },
};
