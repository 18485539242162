export const MASS_ORDER_CREATION = 'massOrderCreation';

export const MASS_ORDER_CREATION_L1 = `${MASS_ORDER_CREATION}/L1`;
export const MASS_ORDER_CREATION_L2 = `${MASS_ORDER_CREATION}/L2`;
export const MASS_ORDER_CREATION_FILE_UPLOAD = `${MASS_ORDER_CREATION}/fileUpload`;
export const MASS_ORDER_CREATION_DUPLICATE_LINES = `${MASS_ORDER_CREATION}/duplicateLines`;

export const MODAL = 'modal';
export const MAINTENANCE = 'maintenance';
export const SOC = 'SOC';

export const RESORCES = 'resources';
export const AVAILABILITY = 'availability';

export const USER = 'user';

export const ORDERS = 'orders';
