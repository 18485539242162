import { dependencyRuleIsParentEmpty } from './conditions/isParentEmpty';
import { dependencyRuleIsParentFilled } from './conditions/isParentFilled';
import { dependencyRuleColumnHasValue } from './conditions/isColumnHasValue';
import { dependencyRuleColumnChangedValue } from './conditions/columnChangedValue.js';
import { dependencyRuleCellChangedValue } from './conditions/cellChangedValue.js';

export const LineLevelDependencyConditionEnum = {
  empty: 'empty', // CellEmpty
  filled: 'filled', // CellFilled or CellPopulated
  cellChangedValue: 'cellChangedValue',
  columnHasValue: 'columnHasValue',
  columnChangedValue: 'columnChangedValue',
};

export const LineLevelDependencyRules = {
  [LineLevelDependencyConditionEnum.empty]: dependencyRuleIsParentEmpty,
  [LineLevelDependencyConditionEnum.filled]: dependencyRuleIsParentFilled,
  [LineLevelDependencyConditionEnum.cellChangedValue]: dependencyRuleCellChangedValue,
  [LineLevelDependencyConditionEnum.columnHasValue]: dependencyRuleColumnHasValue,
  [LineLevelDependencyConditionEnum.columnChangedValue]: dependencyRuleColumnChangedValue,
};
