import { createStore } from "vuex";

import modules from "./modules";

const store = createStore({
  modules: {
    ...modules,
  },
});

export function registerStore(app) {
  app.use(store);
}

export default store;
