import state from './agGrid.state';
import mutations from './agGrid.mutations';
import getters from './agGrid.getters';
import actions from './agGrid.actions';

export default {
  namespaced: true,
  getters,
  mutations,
  actions,
  state,
};
