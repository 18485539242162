const fs = require('fs');
const path = require('path');

module.exports = (base = '.', scanSubDirectories = false, regularExpression = /\.js$/) => {
  const files = {};

  function readDirectory(directory) {
    fs.readdirSync(directory).forEach((file) => {
      const fullPath = path && path.resolve(directory, file);

      if (fs.statSync(fullPath).isDirectory()) {
        if (scanSubDirectories) readDirectory(fullPath);

        return;
      }

      if (!regularExpression.test(fullPath)) return;

      files[fullPath] = true;
    });
  }

  readDirectory(path.resolve(__dirname, base));

  function Module(file) {
    /**
     * TODO:
     * Webpack doesn't allow imports without predefined paths. The following
     * template string is a hack allowing this kind of behavior. We should
     * think about a better solution in the future.
     */
    return require(`${file}`);
  }

  Module.keys = () => Object.keys(files);

  return Module;
};
