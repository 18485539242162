export default Object.freeze({
  // * Foundation colors
  'primary-white': '#FFFFFF',
  'primary-black': '#111111',

  'grey-1': '#757575',
  'grey-2': '#CCCCCC',
  'grey-3': '#E5E5E5',
  'grey-4': '#F5F5f5',
  'grey-5': '#999999',

  'blue-1': '#1151FF',
  'blue-2': '#1190FF',
  'red-1': '#D30005',

  'active-link': '#428EFF',

  // * Brand colors
  'vivid-blue': '#2F9CC2',
  'brand-orange': '#FA5400',
  'brand-volt': '#CEFF00',

  // * Brand extension
  annotation: '#FF008A',
  'brand-purple': '#6D1E77',
  'dark-sea': '#0F5357',

  // * Statues
  'success-green': '#3E9E37',
  'warning-orange': '#F5A623',
  'error-red': '#D43F21',
  'now-error-red': '#DD654D',

  critical: '#fef6e9',
  primary: '#000000',
  white: '#ffffff',
  actionBlueWithOpacity: '#ebf2ff',
  warn: '#ffca00',
  successBG: '#e7f4e5',
  listItem: '#fff8f1',
  listItemActive: '#fff2e6',
  error: '#e03a3b',

  // Podium design system colors
  podiumGrey1: '#707072',
  podiumGrey3: '#EFEFEF',
});
