export const FormTemplateValidationRulesMap = {
  REQUIRED: {
    conditions: 'required',
    message: 'this field is required',
  },
  MAXLENGTH: {
    conditions: 'maxLength',
    message: 'max length exceeded',
  },
  ONLY_DIGITS: {
    conditions: 'onlyDigits',
    message: 'Only digits are allowed',
  },
  ONLY_DIGITS_LETTERS: {
    conditions: 'onlyDigitsLetters',
    message: 'Only digits and letters are allowed',
  },
  ONLY_DIGITS_LETTERS_SPACES: {
    conditions: 'onlyDigitsLettersSpaces',
    message: 'Only digits, letters and spaces are allowed',
  },
  ONLY_DIGITS_LETTERS_DASH_SPACES: {
    conditions: 'onlyDigitsLettersDashSpace',
    message: 'Only digits, letters, spaces and "-" are allowed',
  },
  ONLY_LETTERS_SPACES: {
    conditions: 'onlyLettersSpaces',
    message: 'Only letters and spaces are allowed',
  },
  CHECK_ON_BEHALF_OF: {
    conditions: 'checkOnBehalfOf',
    message: '',
  },
  CAPITALIZE: {
    conditions: 'capitalize',
  },
  CHECK_AUTOCOMPLETE: {
    conditions: 'checkAutocomplete',
    message: 'Invalid selection',
  },
};
