import state from './modal.state';
import actions from './modal.actions';
import mutations from './modal.mutations';
import getters from './modal.getters';

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
