<template>
  <v-icon class="loader" :size="determineSize">
    $nowLoader
  </v-icon>
</template>

<script>
import { BASE_LOADER_TYPES, BASE_LOADER_SIZES } from './config';

export default {
  props: {
    type: {
      type: String,
      default: BASE_LOADER_TYPES.S,
    },
  },

  computed: {
    determineSize() {
      switch (this.type) {
        case BASE_LOADER_TYPES.L:
          return BASE_LOADER_SIZES.L;
        case BASE_LOADER_TYPES.M:
          return BASE_LOADER_SIZES.M;
        case BASE_LOADER_TYPES.XS:
          return BASE_LOADER_SIZES.XS;
        case BASE_LOADER_TYPES.S:
        default:
          return BASE_LOADER_SIZES.S;
      }
    },
  },

  created() {
    this.validateSize();
  },

  methods: {
    validateSize() {
      if (!Object.values(BASE_LOADER_TYPES).includes(this.type)) {
        console.error(
          `Invalid size prop, allowed sizes for the loader are: ${Object.values(
            BASE_LOADER_TYPES,
          ).toString()}`,
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/colors.scss';

.loader {
  fill: $active-link;
  animation: rotate 1.4s linear infinite;
}

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}
</style>
