/**
 * Sets disabled status of LL field (AgGrid cell)
 *
 * There are 2 use cases:
 * 1. actionArgs if provided is used as explicit disabled setter. Condition result is used as conditional statement (flow control) whether this rule will execute or not
 * 2. actionArgs if NOT provided this rule will always execute and condition result will be used as disabled status setter value
 *
 * NOTE: Disabled LL field/cell has priority over required status of the column/cell.
 * Form with empty LL field/cell that is disabled can be Submitted even though Column to which field belongs to is required and marked with asterisk
 */
export function dependencyRulesActionSetDisabled({
  result,
  fieldName,
  lineLevelData,
  dependencyRule = {},
}) {
  let newDisabledStatus;
  if (!dependencyRule?.actionArgs) {
    newDisabledStatus = result;
  } else if (result && dependencyRule?.actionArgs) {
    newDisabledStatus = dependencyRule.actionArgs?.disabled;
  }
  if (
    typeof newDisabledStatus !== 'boolean'
    || lineLevelData[fieldName].disabled === newDisabledStatus
  ) {
    return lineLevelData;
  }

  return {
    ...lineLevelData,
    [fieldName]: { ...lineLevelData[fieldName], disabled: newDisabledStatus },
  };
}
