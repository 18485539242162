import store from '@/store';
import { fieldNames } from '@/enums/singleOrderCreation/fieldNames';

// TODO: Should not access vuex store. Instead look for a way to get items from autocomplete to be attached to the formField.items
export const validationRuleCheckAutocomplete = (formField, validationRule) => {
  if (!formField.touched) {
    return formField;
  }

  let autocompleteOptions = [];
  switch (formField.key) {
    case fieldNames.nameOfOrderer:
      autocompleteOptions = store.state.SOC.foundEmployees;
      break;
    default:
  }

  formField.errors = formField.errors.filter((error) => !error.checkAutocomplete);

  if (
    formField.value
    && !autocompleteOptions.some((option) => option?.toLowerCase() === formField?.value?.toLowerCase())
  ) {
    formField.errors.push({ checkAutocomplete: validationRule.message });
  }

  return formField;
};
