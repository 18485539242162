export function dependencyRuleActionSetColumnValue({
  fieldName,
  result,
  lineLevelData,
  form,
}) {
  if (result === undefined || result === null) {
    return lineLevelData;
  }

  const lineIndex = lineLevelData.line - 1;

  form.lineItemFields.rowData.forEach(row => {
    row[fieldName].value = result;
  });

  return form.lineItemFields.rowData[lineIndex];
}
