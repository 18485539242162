import templatesRepository from '@/api/now/templates';

export default {
  async fetchTemplate({
    commit,
    rootState: {
      fileValidationRules: {
        validationRules: { orderTypes },
      },
      templates: { selectedOrderType, selectedProductEnginesSapIds },
    },
    rootGetters,
  }) {
    const productEngineMap = rootGetters['templates/getProductEnginesMap'];

    const { id: orderTypeId } = orderTypes.find(({ name }) => name === selectedOrderType);

    const productEnginesIds = selectedProductEnginesSapIds.reduce((acc, item) => {
      if (productEngineMap[item]) {
        acc.push(productEngineMap[item]);
      }

      return acc;
    }, []);

    try {
      const { data, headers } = await templatesRepository.getTemplates({
        orderType: orderTypeId,
        productEngines: productEnginesIds,
      });

      commit('SET_TEMPLATE', {
        fileName: headers['content-disposition'].split('=')[1],
        value: data,
      });
    } catch (error) {
      console.log('TEMPLATE FETCHING: ', error);
    }
  },

  setSelectedProductEnginesSapIds({ commit }, selectedProductEnginesSapIds) {
    commit('SET_SELECTED_PRODUCT_ENGINES_SAP_IDS', [...selectedProductEnginesSapIds]);
  },

  setOrderType({ commit }, selectedOrderType) {
    commit('SET_ORDER_TYPE', selectedOrderType);
  },

  resetTemplate({ commit }) {
    commit('SET_TEMPLATE', {});
  },

  async fetchFieldMapping({ commit }) {
    try {
      const { data } = await templatesRepository.getFieldMapping();

      commit('SET_FIELD_MAPPING', data);

      return data;
    } catch (e) {
      console.log('FIELD MAPPING: ', e);
    }
  },
};
