export const orderTypeMap = {
  ZCON: 1,
  ZFRE: 3,
  ZSTA: 5,
  ZRST: 6,
  ZRFR: 7,
  SUSR: 8,
  ZDIG: 13,
  ZFIN: 14,
  ZSYS: 15,
  STO: 16,
  RSTO: 17,
  NOW_SAMPLE_STD: 18,
  NOW_SAMPLE_FREE: 19,
};
