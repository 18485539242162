export const dependencyRuleCellChangedValue = ({
  dependency = {},
  updatedField = {},
  rowIndex,
}) => {
  if (
    dependency.sourceFieldKey
    && updatedField.name
    && dependency.sourceFieldKey === updatedField.name
    && updatedField.rowIndex === rowIndex
  ) {
    return updatedField.cellData.value;
  }
};
