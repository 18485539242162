/**
 * ! This store module is not used anywhere, but might come in handy when language switching is about to be introduced
 */
import languageOptions from '@/enums/language/languageOptions';
import localStorageKey from '@/enums/browserStorage/localStorageKeyEnum';

const state = {
  currentLanguage: 'en',
};

const actions = {
  getLanguage({
    commit, state, rootState,
  }) {
    const locale = localStorage.getItem(localStorageKey.language);
    const userLocale = rootState.users.currentUser.user_locale;

    if (locale && (state.currentLanguage !== locale || state.currentLanguage !== userLocale)) {
      if (Object.keys(languageOptions).find((lang) => lang === locale)) {
        commit('setLanguage', locale);
      } else {
        commit('setLanguage', rootState.users.currentUser.user_locale);
      }
    }
  },
};

const mutations = {
  setLanguage(state, value) {
    localStorage.setItem(localStorageKey.language, value);
    state.currentLanguage = value;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
