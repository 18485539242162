export const validationRuleIsOnlyDigitsLettersSpaces = (formField) => {
  if (!formField.touched) {
    return formField;
  }

  const pattern = /^[0-9A-Za-z\u3000\u3400-\u4DBF\u4E00-\u9FFF\\ ]+$/;

  if (pattern.test(formField.value) || !formField.value) {
    formField.errors = formField.errors.filter((error) => !error.digitsLettersSpacesOnly);
  } else {
    formField.errors.push({
      digitsLettersSpacesOnly: 'Only digits, letters and spaces are allowed',
    });
  }

  return formField;
};
