import { TEMPLATE } from '@/api/endpoints';
import repository from '@/api/repositories/serverRepository';

export function getValidationRules() {
  const END_POINT = TEMPLATE.VALID_MASS_ORDER_TRAITS;

  repository.__addFilter(new RegExp(END_POINT));

  return repository.get(END_POINT);
}
