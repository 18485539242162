<template>
  <div v-outside-click="handleClickOutside" class="datepicker">
    <BaseInput
      :key="inputComponentKey"
      ref="inputDate"
      :injectedValue="inputValue"
      :size="inputSize"
      :label="label"
      :upperLabel="upperLabel"
      :placeholder="inputPlaceholder"
      :isDisabled="isDisabled"
      :errorMessage="errorLabelMessage"
      :isRequired="isRequired"
      :inputMask="baseInputMask"
      :isReadonly="isRangeDatepicker"
      :iconRight="inputIconRight"
      isBorder
      @focus="handleFocus"
      @click="handleFocus"
      @change="handleChange"
      @keydown.tab="handleChange"
      @input="handleInput"
    >
      <div
        v-if="isClearable"
        :class="{'datepicker__icons': true}"
      >
        <Icon
          v-if="injectedValue || injectedDateRange.length"
          size="28"
          name="CloseLargeDefaultOnLight"
          class="datepicker__icon datepicker__icon--close"
          @click.stop="handleClear"
        />
        <Icon
          size="28"
          class="datepicker__icon"
          :name="iconRight"
        />
      </div>
    </BaseInput>
    <transition name="slide-from-left">
      <base-calendar
        v-if="isDatepickerVisible"
        ref="calendarPopup"
        :injectedSelectedDate="selectedDate"
        :injectedSelectedRange="selectedRange"
        :minDate="minimalDate"
        :maxDate="maximalDate"
        :isRangeDatepicker="isRangeDatepicker"
        :isDisplayedAboveInput="isCalendarDisplayedAboveInput"
        :isPrevAndNextMonthVisible="isPrevAndNextMonthVisible"
        :isHoverHiglightDisabled="isHoverHiglightDisabled"
        @daySelected="handleSetDate"
        @rangeSelected="handleRangeSelected"
        @changesAccepted="handleAcceptChanges"
        @cancel="handleCancel"
      />
    </transition>
  </div>
</template>

<script>
import moment from 'moment-business-days';
import { Icon } from 'podium';

import BaseInput from '@/components/ui/BaseInput/BaseInput.vue';

import dateFormats from '@/enums/dates/dateFormats';
import timeFormats from '@/enums/dates/timeFormats';
import { BASE_INPUT_SIZES } from '@/components/ui/BaseInput/config';
import { DATE_FORMAT_VARIANTS } from './config';

import BaseCalendar from '@/components/ui/BaseCalendar/BaseCalendar.vue';

function dateValidatorFunction(date) {
  return (
    !date.length
    || moment(date, dateFormats.ISO).isValid()
    || moment(date, timeFormats.hr24).isValid()
  );
}

export default {
  components: {
    Icon,
    BaseInput,
    BaseCalendar,
  },

  props: {
    isRequired: Boolean,
    isDisabled: Boolean,
    isReadOnly: Boolean,
    isPrevAndNextMonthVisible: Boolean,
    isRangeDatepicker: Boolean,
    isHoverHiglightDisabled: Boolean,
    isClearable: Boolean,

    errorMessage: {
      type: String,
      default: '',
    },

    minDate: {
      type: String,
      default: '1900-01-01',
      validator: dateValidatorFunction,
    },

    maxDate: {
      type: String,
      default: '2099-12-31',
      validator: dateValidatorFunction,
    },

    label: {
      type: String,
      default: '',
    },

    upperLabel: {
      type: String,
      default: '',
    },

    placeholder: {
      type: String,
      default: '',
    },

    iconRight: {
      type: String,
      default: 'CalendarLargeDefaultOnLight',
    },

    inputSize: {
      type: String,
      default: BASE_INPUT_SIZES.M,
      validator(size) {
        return Object.values(BASE_INPUT_SIZES).includes(size);
      },
    },

    inputDateFormatCode: {
      type: String,
      default: 'EU',
      validator: (code) => DATE_FORMAT_VARIANTS.map(({ name }) => name).includes(code),
    },

    injectedValue: {
      type: String,
      default: '',
      validator: dateValidatorFunction,
    },

    injectedDateRange: {
      type: Array,
      default: () => [],
      validator: (datesArray) => {
        return datesArray.every((date) => dateValidatorFunction(date));
      },
    },
  },

  emits: ['change'],

  data() {
    return {
      inputValue: '',
      selectedDate: null,
      isDatepickerVisible: false,
      errorLabelMessage: '',
      isCalendarDisplayedAboveInput: false,
      inputComponentKey: 0,
      selectedRange: [],
      hasFocus: false,
    };
  },

  computed: {
    inputPlaceholder() {
      return !this.isDisabled ? this.placeholder || this.inputDateFormat : null;
    },

    inputDateFormat() {
      const inputDateFormat = DATE_FORMAT_VARIANTS.find(
        ({ name }) => name === this.inputDateFormatCode,
      ).output;

      return inputDateFormat;
    },

    inputDateFormatVariants() {
      const inputDateFormatVariants = DATE_FORMAT_VARIANTS.find(
        ({ name }) => name === this.inputDateFormatCode,
      ).inputVariants;

      return inputDateFormatVariants;
    },

    baseInputMask() {
      if (!this.isRangeDatepicker) {
        return this.inputDateFormat.replace(/D|M|Y/g, '#');
      }

      return '';
    },

    minimalDate() {
      return this.minDate || '1900-01-01';
    },

    maximalDate() {
      return this.maxDate || '2099-12-31';
    },

    inputIconRight() {
      if (this.isClearable) {
        return undefined;
      }

      return this.iconRight;
    },
  },

  watch: {
    selectedDate(val) {
      const isFilled = val !== null;

      this.inputValue = isFilled ? this.selectedDate.format(this.inputDateFormat) : '';
    },

    injectedValue(val) {
      if (val === '') {
        this.handleSetDate(null);

        return;
      }

      if (moment(this.injectedValue).isSame(this.selectedDate)) return;

      if (moment(this.injectedValue).isValid()) {
        this.handleSetDate(moment(this.injectedValue));
      }
    },

    injectedDateRange: {
      handler(dateRange) {
        this.handleRangeSelected(dateRange);
      },
      deep: true,
    },

    errorMessage: {
      handler() {
        this.errorLabelMessage = this.errorMessage;
      },
      immediate: true,
    },

    inputValue() {
      if (this.inputValue.length !== 10) {
        return;
      }

      const parsedInput = moment(this.inputValue, this.inputDateFormatVariants);

      if (parsedInput.isValid()) {
        this.inputValue = parsedInput.format(this.inputDateFormat);
      }
    },
  },

  mounted() {
    if (this.injectedValue) {
      this.handleSetDate(moment(this.injectedValue));
    }

    if (this.injectedDateRange) {
      this.handleRangeSelected(this.injectedDateRange);
    }
  },

  methods: {
    handleFocus() {
      this.hasFocus = true;
      this.handleOpenCalendar();
    },

    handleClickOutside() {
      if (this.isDatepickerVisible && !this.isRangeDatepicker) {
        this.validateManualInput(this.inputValue);
        this.closeDatepicker();

        return;
      }

      if (this.isRangeDatepicker) {
        this.handleCancel();
      }
    },

    validateManualInput(date) {
      const momentDate = moment(date, this.inputDateFormatVariants);

      const minDate = moment(this.minimalDate);
      const maxDate = moment(this.maximalDate);

      if (!momentDate.isValid() || momentDate.isBefore(minDate) || momentDate.isAfter(maxDate)) {
        if (this.injectedValue) {
          this.inputValue = moment(this.injectedValue).format(this.inputDateFormat);
        } else {
          this.inputValue = '';
        }

        return;
      }

      this.selectedDate = momentDate;
    },

    handleSetDate(date) {
      this.selectedDate = date;
      if (this.isDatepickerVisible) {
        this.closeDatepicker();
      }
    },

    closeDatepicker() {
      this.hasFocus = false;
      this.isDatepickerVisible = false;
      let payload = null;

      if (this.selectedDate) {
        payload = this.selectedDate.format('YYYY-MM-DD');
      }

      if (payload && payload !== this.injectedValue) {
        this.errorLabelMessage = '';
        this.$emit('change', payload);
      }
    },

    handleOpenCalendar() {
      if (this.isDatepickerVisible || !this.hasFocus) {
        return;
      }

      this.isCalendarDisplayedAboveInput = false;

      const inputOffset = this.$refs.inputDate.$el.getBoundingClientRect().bottom;

      if (window.innerHeight - inputOffset < 280) {
        this.isCalendarDisplayedAboveInput = true;
      }

      this.isDatepickerVisible = true;
    },

    handleInput(value) {
      this.inputValue = value;
      this.handleOpenCalendar();
    },

    handleChange() {
      if (!this.isRangeDatepicker) {
        this.validateManualInput(this.inputValue);
        this.closeDatepicker();
      }

      this.isDatepickerVisible = false;
    },

    handleRangeSelected(range) {
      this.selectedRange = range;
      this.inputValue = range.map((date) => moment(date).format(this.inputDateFormat)).join(' - ');
    },

    handleAcceptChanges() {
      this.$emit('change', this.selectedRange);
      this.isDatepickerVisible = false;
      this.hasFocus = false;
    },

    handleCancel() {
      this.handleRangeSelected(this.injectedDateRange);
      this.isDatepickerVisible = false;
      this.hasFocus = false;
    },

    handleClear() {
      this.$emit('change', []);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/colors.scss';

.datepicker {
  position: relative;

  &__icons {
    display: flex;
    align-items: center;
  }

  &__icon {
    cursor: pointer;
    stroke: $grey-1;

    &--close {
      stroke: $grey-1;
      margin-right: 2px;
    }
  }
}

:deep(.base-input__value) {
  cursor: pointer;
}

:deep(.base-input) {
  cursor: pointer;
}

:deep(.base-input__icons) {
  position: absolute;
  right: 1%;
  height: 70%;
}

:deep(.base-input__icon-right) {
  color: $grey-2;
}
</style>
