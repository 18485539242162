import state from './resources.state';
import actions from './resources.actions';
import mutations from './resources.mutations';
import getters from './resources.getters';

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
