/**
 * Used for Axios to handle different responses JSON structures
 *
 * NOTE: Our UI currently fetches data only from NOW BE API and use internalStandard
 * which includes key wrappers: data and errors
 *
 * Only /downloadTemplate API does return internalNonStandard (No standard JSON data)
 * but in case of error it does return standard JSON strcuture
 *
 * external is preset for future use when NOW UI starts to fetch data from external APIs
 */
export default {
  internalStandard: 'internalStandard',
  internalNonStandard: 'internalNonStandard',
  external: 'external',
};
