import actions from "./auditEvent.actions.js";
import state from "./auditEvent.state.js";
import mutations from "./auditEvent.mutations.js";
import getters from "./auditEvent.getters.js";
import filterManagementActions from "./filterManagement.actions.js";

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions: { ...actions, ...filterManagementActions },
};
