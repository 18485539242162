<template>
  <div :class="{baseClassName}">
    <v-menu bottom left>
      <!-- eslint-disable-next-line vue/no-template-shadow -->
      <template #activator="{ props }">
        <Icon
          v-bind="props"
          size="18"
          name="EllipsisVerticalLargeDefaultOnLight"
          :class="`${baseClassName}__icon`"
        />
      </template>

      <div v-if="shouldRenderList(params.data, actions)" :class="`${baseClassName}__list-container`">
        <template v-for="{ name, conditions, handler } in actions">
          <div
            v-if="shouldRenderAction(params.data, conditions)"
            :key="name"
            link
            :class="`${baseClassName}__list-item`"
            @click="handler(params)"
          >
            {{ name }}
          </div>
        </template>
      </div>

      <div v-else :class="`${baseClassName}__list-container`">
        <div :class="`${baseClassName}__list-item`">
          {{ $t('base.noActionsAvailable') }}
        </div>
      </div>
    </v-menu>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { Icon } from 'podium';

const baseClassName = 'ag-grid-actions-renderer';

const props = defineProps({
  params: {
    type: Object,
    required: true,
  },
});

const actions = computed(() => props.params.actions);

function shouldRenderList(orderData, actions) {
  return actions.some(action => shouldRenderAction(orderData, action.conditions));
}

function shouldRenderAction(orderData, conditions) {
  if (!conditions?.status) {
    return true;
  }

  const orderStatus = (orderData?.status)?.replaceAll(' ', '_').toUpperCase();

  return conditions.status.includes(orderStatus);
}
</script>

<style lang="scss">
@import "podium-tokens";

.ag-grid-actions-renderer {
  &__list-container {
    background: $podium-color-white;
    border-radius: 2px !important;
    border: 1px solid $podium-color-grey-100;
    box-shadow: 0 9px 16px rgba(0, 0, 0, 0.2);
  }

  &__list-item {
    font: $podium-text-body-3;
    padding: $podium-space-12 $podium-space-16;
    cursor: pointer;
  }

  .v-list {
    padding: 0;
  }

  :deep(.ag-grid-actions-renderer__icon) {
    fill: $podium-color-black;
  }
}
</style>
