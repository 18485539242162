import employeeRepository from '@/api/now/employee';
import regex from '@/enums/RegExp';

export default {
  async findEmployee({ commit }, query) {
    const isValidQuery = query.match(new RegExp(regex.EmailValidQuery));

    if (isValidQuery) {
      commit('SET_IS_FOUND_EMPLOYEES_LOADING', true);

      try {
        const { data } = await employeeRepository.getEmployeeByQuery(query);
        commit(
          'SET_FOUND_EMPLOYEES',
          data.map(({ email }) => email),
        );
      } catch (err) {
        console.error(err);
      } finally {
        commit('SET_IS_FOUND_EMPLOYEES_LOADING', false);
      }
    } else {
      commit('SET_FOUND_EMPLOYEES', []);
    }
  },

  async fetchCurrentUserEmail({ commit }) {
    const { data } = await employeeRepository.getCurrentUser();
    commit('SET_EMPLOYEE_EMAIL', data?.email);

    return data?.email;
  },
};
