import { LineLevelValidationRulesEnum } from '@/controllers/order/validations/line/index';

export const regExpRuleValidation = ({
  formFieldData,
  pattern,
  errorMessage = 'Format is incorrect',
}) => {
  formFieldData.errors = formFieldData.errors.filter(
    (error) => !error[LineLevelValidationRulesEnum.regExpValidation],
  );

  pattern = new RegExp(pattern);

  if (!pattern.test(formFieldData.value) && formFieldData.value) {
    formFieldData.errors.push({ [LineLevelValidationRulesEnum.regExpValidation]: errorMessage });
  }

  return formFieldData;
};
