const BASE_INPUT_SIZES = {
  XS: 'xs',
  S: 's',
  M: 'm',
  L: 'l',
  XL: 'xl',
  UNI: 'universal',
};

const BASE_CLASS_NAME = 'base-input';

export { BASE_CLASS_NAME, BASE_INPUT_SIZES };
