import repository from '@/api/repositories/serverRepository';
import { EMPLOYEE } from '@/api/endpoints';

export default {
  getEmployeeByQuery: (query = '') => repository.get(`${EMPLOYEE.LOOKUP}?query=${query}`),

  getEmployeeById: (id) => repository.get(`${EMPLOYEE.FIND_BY_ID}?id=${id}`),

  getEmployeeByEmail: (email = '') => repository.get(`${EMPLOYEE.FIND_BY_EMAIL}?email=${email}`),

  getManagers(onBehalfOf = '') {
    let END_POINT = EMPLOYEE.MANAGERS;

    if (onBehalfOf) {
      END_POINT += `?onBehalfOf=${onBehalfOf}`;
    }

    return repository.get(END_POINT);
  },

  getCurrentUser() {
    return repository.get(EMPLOYEE.FIND_CURRENT_USER);
  },
};
