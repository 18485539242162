import state from './uploadedFiles.state';
import actions from './uploadedFiles.actions';
import mutations from './uploadedFiles.mutations';
import getters from './uploadedFiles.getters';

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
