import repository from '@/api/repositories/serverRepository';
import {
  ORDER,
  ORDER_REPO,
  GET_ORDER_TYPE_MAPPING,
} from '@/api/endpoints';

export default {
  fetchMyOrdersSummary(params) {
    return repository.post(ORDER_REPO.ORDERS_SUMMARY, params);
  },

  fetchMyOrders(params) {
    return repository.post(ORDER_REPO.ORDERS, params);
  },

  fetchOrderErrorDetails(params) {
    return repository.post(ORDER_REPO.FAILED_ORDER_DETAILS, params);
  },

  fetchOrderTypes() {
    return repository.get(GET_ORDER_TYPE_MAPPING);
  },

  fetchUserPreferences() {
    return repository.get(ORDER.USER_PREFERENCES);
  },

  saveUserPreferences(preferences) {
    return repository.post(ORDER.USER_PREFERENCES, preferences);
  },
};
