export const validationRuleIsMaxLengthExceeded = (formField) => {
  if (!formField.touched) {
    return formField;
  }

  if (formField.value.length <= formField.maxLength || !formField.value) {
    formField.errors = formField.errors.filter((error) => !error.maxLength);
  } else if (!formField.errors.some((error) => error.maxLength)) {
    formField.errors.push({ maxLength: `Max length (${formField.maxLength}) exceeded` });
  }

  return formField;
};
