import { formatDate, isDateFormatValid, isShortDateFormatValid } from "@/utils/dates";

import dateFormats from "@/enums/dates/dateFormats";

/**
 * Formats all date fields in ag-grid line object
 *
 * @param gridLine object containing ag-grid line
 * @param dateKeys array of key names that correspond to date fields
 * @returns modified ag-grid line with all date fields being formatted
 */
export const transformGridLineDates = (gridLine, dateKeys) => {
  return Object.entries(gridLine).reduce((acc, [cellKey, cellValue]) => {
    if (!dateKeys.includes(cellKey)) return acc;

    if (isDateFormatValid(cellValue, dateFormats.ISO) || isShortDateFormatValid(cellValue)) {
      acc[cellKey] = formatDate(cellValue);
    }

    return acc;
  }, gridLine);
};

/**
 * Replaces ag-grid cell content with a placeholder if it's empty
 *
 * @param columnName - the name of the ag-grid column
 * @param emptyCellPlaceholder - ag-grid cell placeholder text
 * @returns - updated getter for ag-grid cell content
 */
export const getCellValue = (columnName, emptyCellPlaceholder = "−") => {
  return (params) => params.data[columnName] ?? emptyCellPlaceholder;
};
