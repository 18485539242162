import { defineAsyncComponent } from 'vue';

const BaseRadioExpansionPanel = defineAsyncComponent(() => import('@/components/ui/BaseRadioExpansionPanel'),
);

export const importBaseRadioExpansionPanelConfig = {
  components: {
    BaseRadioExpansionPanel,
  },
};
