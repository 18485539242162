import { addressOverrideValidationRules } from './addressOverride';
import {
  radioOptions,
  dropdownValues,
  RADIO_NO_VALUE,
  RADIO_YES_VALUE,
} from './dropdownValues';

import { debugValues } from './debugValues';

export default {
  formatRules: {
    ...addressOverrideValidationRules,

    salesOrganization: {
      data: dropdownValues.salesOrganization,
      isSelectFormat: true,
      ...debugValues.salesOrganization,
    },

    sourcingMethod: {
      data: dropdownValues.sourcingMethod,
      isSelectFormat: true,
    },

    soldToNumber: {
      pattern: '^[0-9]{1,10}$',
      errorMessage: 'Only digits are allowed',
      maxCharacter: 10,
    },

    shipToNumber: {
      pattern: '^[0-9]{1,10}$',
      maxCharacter: 10,
      errorMessage: 'Only digits are allowed',
      hint: {
        level: 'header',
        message: 'Ship to will be the location/address that the return will be picked up from',
      },
    },

    customerPONumber: {
      maxCharacter: 35,
    },

    nameOfOrderer: {
      pattern: '^(?=.{1,35}$).*',
      maxCharacter: 35,
      errorMessage: 'Invalid value',
    },

    markFor: {
      pattern: '^[0-9]{1,10}$',
      maxCharacter: 10,
      errorMessage: 'Only digits are allowed',
    },

    internalOrderReferenceNumber: {
      pattern: '^[0-9A-Za-z]{1,20}$',
      maxCharacter: 20,
      errorMessage: 'Only digits and letters are allowed',
    },

    deliveryBlock: {
      data: dropdownValues.deliveryBlock,
      isSelectFormat: true,
    },

    returnAuthorizationsNumber: {
      pattern: '^[0-9A-Za-z\\\\!/@#\\$%\\^\\&*\\)\\(|+=._-]{1,35}$',
      errorMessage: 'Only digits, letters and special characters are allowed',
      maxCharacter: 35,
    },

    numberOfCartons: {
      pattern: '^[0-9]{1,4}$',
      maxCharacter: 4,
      errorMessage: 'Only digits are allowed',
    },

    door: {
      pattern: '^[0-9]{1,10}$',
      maxCharacter: 10,
      errorMessage: 'Only digits are allowed',
    },

    nikeInternalComments: {
      isTextArea: true,
      maxCharacter: 500,
    },

    plantCode: {
      pattern: '^[0-9]*$',
      maxCharacter: 4,
      errorMessage: 'Only digits are allowed',
    },

    unitAllotment: {
      pattern: '^[0-9]*$',
      maxCharacter: 7,
      errorMessage: 'Only digits are allowed',
    },

    vasField3: {
      maxCharacter: 20,
      hint: {
        level: 'line',
        message: 'Can’t contain following characters: = ,',
      },
    },

    vasField2: {
      pattern: '^[0-9A-Za-z]{1,20}$',
      maxCharacter: 20,
      errorMessage: 'Only digits and letters are allowed',
      hint: {
        level: 'line',
        message: 'Can’t contain following characters: = ,',
      },
    },

    vasField1: {
      pattern: '^[0-9A-Za-z]{1,20}$',
      maxCharacter: 20,
      errorMessage: 'Only digits and letters are allowed',
      hint: {
        level: 'line',
        message: 'Can’t contain following characters: = ,',
      },
    },

    vasLongText: {
      maxCharacter: 132,
      hint: {
        level: 'line',
        message: 'Can’t contain following characters: = ,',
      },
    },

    invoiceNumber: {
      pattern: '^[0-9]*$',
      maxCharacter: 10,
      errorMessage: 'Only digits are allowed',
    },

    materialNumber: {
      pattern: '^([a-zA-Z0-9]{6})-([a-zA-Z0-9]{3})$',
      errorMessage: 'Required format is BQ3757-101',
      maxCharacter: 10,
    },

    contractNumber: {
      pattern: '^[0-9]{1,10}$',
      maxCharacter: 10,
      errorMessage: 'Only digits are allowed',
    },

    departmentCode: {
      pattern: '^[0-9]{1,10}$',
      maxCharacter: 10,
      errorMessage: 'Only digits are allowed',
    },

    crd: {
      pattern: 'DD/MM/YYYY',
      hint: {
        level: 'header',
        message: 'For Returns, CRD is the date that the return will be picked up',
      },
      isDateFormat: true,
      ...debugValues.crd,
    },

    cancelDate: {
      pattern: 'DD/MM/YYYY',
      isDateFormat: true,
      ...debugValues.cancelDate,
    },

    validFromDate: {
      isDateFormat: true,
      pattern: 'DD/MM/YYYY',
    },

    validToDate: {
      isDateFormat: true,
      pattern: 'DD/MM/YYYY',
    },

    totalQty: {},

    reasonCode: {
      data: dropdownValues.reasonCode,
      isSelectFormat: true,
    },

    orderReasonCode: {
      data: dropdownValues.orderReasonCode,
      isSelectFormat: true,
    },

    reasonCodeAuthorized: {
      isCheckbox: true,
    },

    shippingCondition: {
      data: dropdownValues.shippingCondition,
      isSelectFormat: true,

      defaultValue: 'Customer default',
    },

    addressOverride: {
      data: radioOptions,
      isSelectFormat: true,
      hint: {
        level: 'header',
        message:
          'This means that the delivery should happen to a different address. For Return orders this field means "Pick up from a different address',
      },
      defaultValue: RADIO_NO_VALUE,
    },

    approved: {
      data: dropdownValues.approved,
      isSelectFormat: true,

      defaultValue: 'Y',
    },

    usageCode: {
      data: dropdownValues.usageCode,
      isSelectFormat: true,
    },

    vatRate: {
      data: dropdownValues.vatRate,
      isSelectFormat: true,
    },

    bpDate: {
      pattern: 'DD/MM/YYYY',
      isDateFormat: true,
    },

    bpEventType: {
      isRadio: true,
      description: 'IS THIS EVENT PRIVATE OR OPEN TO PUBLIC?',
      data: dropdownValues.eventTypes,
      isSelectFormat: true,
      defaultValue: dropdownValues.eventTypes[0].value,
    },

    bpPreApproved: {
      isRadio: true,
      description: 'HAVE YOU GOTTEN PRE-APPROVAL ON DISCLOSURE TOOL?',
      data: radioOptions,
      isSelectFormat: true,
      defaultValue: RADIO_YES_VALUE,
    },

    bpCharityNote: {
      isTextArea: true,
      description: 'Charity/Gift Note',
      maxCharacter: 512,
    },

    bpCharityTaxId: {
      maxCharacter: 128,
    },

    bpAttendeesList: {
      isTextArea: true,
      description: 'Please list attendees',
      maxCharacter: 512,
    },

    bpRecipientName: {
      maxCharacter: 64,
    },

    bpGlobalSourcing: {
      isRadio: true,
      description: 'ARE YOU FROM GLOBAL SOURCING AND MANUFACTURING?',
      data: radioOptions,
      isSelectFormat: true,
      defaultValue: RADIO_NO_VALUE,
    },

    bpIntendedPurpose: {
      isTextArea: true,
      description: 'What is the intended purpose for the comp product?',
      maxCharacter: 512,
    },

    bpCharityDueDiligence: {
      isRadio: true,
      description: 'HAS DUE DILIGENCE FOR THE CHARITY ORGANIZATION BEEN COMPLETED?',
      data: radioOptions,
      isSelectFormat: true,
      defaultValue: RADIO_YES_VALUE,
    },

    bpPreApprovalRefNumber: {
      maxCharacter: 128,
    },

    bpCharitableOrganization: {
      maxCharacter: 128,
    },

    managersName: {
      data: [],
      isSelectFormat: true,
    },

    onBehalfOf: {
      maxCharacter: 35,
    },

    governmentOfficial: {
      isRadio: true,
      defaultValue: RADIO_NO_VALUE,
      data: radioOptions,
    },

    goPreApproved: {
      isRadio: true,
      defaultValue: RADIO_NO_VALUE,
      data: radioOptions,
      description: 'HAVE YOU GOTTEN PRE-APPROVAL ON DISCLOSURE TOOL?',
    },

    goPreApprovalRefNumber: {
      maxCharacter: 128,
    },
  },

  goldenRules: {
    validFromDate: {
      rules: [
        {
          relatedField: 'now',
          daysToAdd: 0,
          operation: 'more or equal',
        },
      ],
    },

    cancelDate: {
      rules: [
        {
          relatedField: 'crd',
          daysToAdd: 0,
          operation: 'more',
        },
        {
          relatedField: 'now',
          daysToAdd: 5,
          operation: 'more or equal',
        },
      ],
    },

    crd: {
      rules: [
        {
          relatedField: 'now',
          daysToAdd: 0,
          operation: 'more or equal',
        },
        {
          relatedField: 'cancelDate',
          daysToAdd: 0,
          operation: 'less',
        },
      ],
    },

    validToDate: {
      rules: [
        {
          relatedField: 'validFromDate',
          daysToAdd: 0,
          operation: 'more',
        },
      ],
    },
  },
};
