export default {
  getProductEnginesMap: (_, _2, rootState) => {
    const { productEngines = [] } = rootState.fileValidationRules.validationRules;

    return productEngines.reduce((acc, { sapId, id }) => {
      acc[sapId] = id;

      return acc;
    }, {});
  },
};
