import jwtDecode from 'jwt-decode';
import noop from 'lodash/noop';
import { getAccessToken } from '@/api/tokens/auth.token';

const { newrelic } = window;

const isLocalEnv = Boolean(process.env.VUE_APP_IS_LOCAL);
const isNewRelicEnabled = Boolean(newrelic) && !isLocalEnv;

const newRelicMonitoring = {
  // Set user-related attributes for all the following calls
  initUserAttributes: (username, iat, exp) => {
    const encodedUsername = Buffer.from(username).toString('hex');
    newrelic.setCustomAttribute('now_sessionid', encodedUsername);
    if (iat) {
      newrelic.setCustomAttribute('now_session_iat', iat);
    }
    if (exp) {
      newrelic.setCustomAttribute('now_session_exp', exp);
    }
  },

  /**
   * Report a `JS Error` to New Relic
   * Custom attributes can be added to give an additional context
   */
  reportError: (err, attributes) => {
    newrelic.noticeError(err, attributes);
  },

  // Send a custom event to New Relic
  sendCustomEvent: (eventName, attributes) => {
    newrelic.addPageAction(eventName, attributes);
  },

  // Create an adapter for Vue error handler
  vueErrorHandler: (err, instance, info) => {
    const attributes = {
      vueDebugInfo: info,
      vueComponentTag: instance?.$vnode?.componentOptions?.tag,
      vueComponentId: instance?.$vnode?.elm?.id,
      vueComponentClass: instance?.$vnode?.elm?.className,
    };

    newrelic.noticeError(err, attributes);
  },
};

const defaultMonitoring = {
  initUserAttributes: noop,
  reportError: noop,
  sendCustomEvent: noop,
  vueErrorHandler: noop,
};

const monitoring = isNewRelicEnabled ? newRelicMonitoring : defaultMonitoring;

const onAuthenticated = () => {
  try {
    const token = getAccessToken();
    const decoded = jwtDecode(token);

    if (decoded) {
      const username = decoded.sub;
      const tokenIssuedAt = decoded.iat;
      const tokenExpiresAt = decoded.exp;
      monitoring.initUserAttributes(username, tokenIssuedAt, tokenExpiresAt);
    }
  } catch (e) {
    console.error(e);
  }
};

export default {
  ...monitoring,
  onAuthenticated,
};
