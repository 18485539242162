import { addressOverrideFieldNames } from '@/enums/singleOrderCreation/addressOverride';

const {
  city,
  name1,
  name2,
  region,
  street1,
  street2,
  country,
  postalCode,
  forwardingAgent,
  transportationZone,
} = addressOverrideFieldNames;

export const addressOverrideValidationRules = {
  [forwardingAgent]: {
    maxCharacter: 10,
    // defaultValue: '123ASD',
    pattern: '^[0-9A-Za-z]{1,10}$',
    errorMessage: 'Only digits and letters are allowed',
  },

  [transportationZone]: {
    maxCharacter: 10,
    // defaultValue: '123ASD',
    pattern: '^[0-9A-Za-z]{1,10}$',
    errorMessage: 'Only digits and letters are allowed',
  },

  [name1]: {
    maxCharacter: 40,
    // defaultValue: 'Name',
    pattern: '^[0-9A-Za-z\u3000\u3400-\u4DBF\u4E00-\u9FFF\\ ]*$',
    errorMessage: 'Only digits, letters and spaces are allowed',
  },

  [name2]: {
    maxCharacter: 40,
    // defaultValue: 'Name - C/O',
    pattern: '^[0-9A-Za-z\u3000\u3400-\u4DBF\u4E00-\u9FFF\\ ]*$',
    errorMessage: 'Only digits, letters and spaces are allowed',
  },

  [street1]: {
    maxCharacter: 60,
    pattern: '^(?=.{1,60}$).*',
    // defaultValue: 'Street One Name',
  },

  [street2]: {
    maxCharacter: 40,
    pattern: '^(?=.{1,40}$).*',
    // defaultValue: 'Street Two Name',
  },

  [postalCode]: {
    maxCharacter: 10,
    // defaultValue: '30-002',
    pattern: '^[0-9A-Za-z-]{1,10}$',
    errorMessage: 'Only digits, letters, spaces and "-" are allowed',
  },

  [city]: {
    maxCharacter: 40,
    // defaultValue: 'City Name',
    pattern: '^[A-Za-z\u3000\u3400-\u4DBF\u4E00-\u9FFF\\s]{1,40}$',
    errorMessage: 'Only letters and spaces are allowed',
  },

  [country]: {
    maxCharacter: 2,
    // defaultValue: 'ALB',
    pattern: '^[A-Za-z]{1,2}$',
    errorMessage: 'Only letters are allowed',
    data: [],
  },

  [region]: {
    maxCharacter: 3,
    // defaultValue: 'NTR',
    pattern: '^[0-9A-Za-z]{1,3}$',
    errorMessage: 'Only digits and letters are allowed',
    data: [],
  },

  district: {
    maxCharacter: 40,
    // defaultValue: 'District Name',
    pattern: '^[0-9A-Za-z\u3000\u3400-\u4DBF\u4E00-\u9FFF\\s]{1,40}$',
    errorMessage: 'Only digits, letters and spaces are allowed',
  },

  telephone: {
    maxCharacter: 30,
    // defaultValue: '555-555-555',
    pattern: '^(?=.{1,30}$).*',
  },
};
