import { get } from 'lodash';

import { getValidationRules } from '@/api/now/validationRules/index.js';

import mutations from './mutation-type';

export default {
  async fetchValidationRules({ commit, dispatch }) {
    try {
      const { data } = await getValidationRules();

      commit(mutations.SET_VALIDATION_RULES, data);

      dispatch('setOrderTypes');
      dispatch('setProductEngines');

      return data;
    } catch (e) {
      console.error('VALIDATION RULES: ', e);
    }
  },

  setOrderTypes({ state, commit }) {
    const orderTypes = get(state, 'validationRules.orderTypes', []);

    commit(
      mutations.SET_ORDER_TYPES,
      orderTypes.map(({ name }) => name),
    );
  },

  setProductEngines({ state, commit }) {
    const productEngines = get(state, 'validationRules.productEngines', []);
    commit(
      mutations.SET_PRODUCT_ENGINES,
      productEngines.map(({
        id, name, sapId,
      }) => ({
        id, name, sapId,
      })),
    );
  },
};
