import router from '@/router';
import pagesURL from '@/router/routes.js';

export async function confirmRouteChangeAction({ next, confirmationElement }) {
  const isAccepted = await confirmationElement.show();
  if (isAccepted) {
    next();
  } else {
    try {
      next(false);
    } catch (e) {
      console.error('Navigation prevented');
    }
  }
}

export function handleMaintenanceRedirect(statusCode) {
  const { path } = router.currentRoute;

  if (statusCode === 555 && path !== pagesURL?.PLANNED_OUTAGE) {
    router.push(pagesURL?.PLANNED_OUTAGE);
  } else if (statusCode === 556 && path !== pagesURL?.SERVICE_DOWN) {
    router.push(pagesURL?.SERVICE_DOWN);
  }
}
