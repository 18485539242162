import {
  mapCountries, mapDropdownItems, mapSalesOrgs,
} from '@/utils/dropdownItemsMappers';

import { OrderTypeSapIdsEnum } from '@/enums/order/orderType';
import { fieldNames } from '@/enums/singleOrderCreation/fieldNames';

// TODO: Samples: Fix after adding required order type
const getAvailableSalesOrgs = (userPermissions, orderType) => (
  userPermissions.orderTypesToSalesOrgs.filter(item => orderType.name === item.orderType.name,
  )
);

const getUserGeosIds = (userPermissions) => userPermissions.geos.map(({ id }) => id);

const getFieldByKey = (form, key) => form.fields.find(item => item.key === key);

const getSelectedGeo = (salesOrgField, availableSalesOrgs) => {
  if (!salesOrgField.value) {
    return null;
  }

  return availableSalesOrgs.salesOrganizations.find(
    ({ sapId }) => sapId === salesOrgField.value,
  ).geo.id;
};

const getCountriesByGeo = (geo, selectedGeo) => (
  geo.countries.find(({ name }) => name === selectedGeo).countries
);

const getReasonCodesByGeo = (geo, selectedGeo, isOrderReturnType) => (
  mapDropdownItems(geo.reasonCodes.find(({ name }) => name === selectedGeo).reasonCodes[isOrderReturnType])
);

const setSalesOrganizationFieldOptions = (salesOrganizationField, availableSalesOrgs, userGeos) => {
  salesOrganizationField.items = mapSalesOrgs(availableSalesOrgs.salesOrganizations)
    .filter(({ geo }) => userGeos.includes(geo));
};

const setCountryFieldOptions = (countryField, geo, selectedGeo, orderType) => {
  if (selectedGeo && (orderType.sapId === OrderTypeSapIdsEnum.ZFRE || orderType.sapId === OrderTypeSapIdsEnum.ZSTA)) {
    countryField.items = mapCountries(getCountriesByGeo(geo, selectedGeo));
  }
};

const setReasonFieldOptions = (reasonCodeField, geo, selectedGeo, orderType) => {
  if (selectedGeo) {
    const isOrderReturnType = orderType.isReturnOrderType ? 'returns' : 'standard';
    reasonCodeField.items = getReasonCodesByGeo(geo, selectedGeo, isOrderReturnType);
  }
};

export const updateOptions = (form, orderType, userPermissions, geo) => {
  const userGeos = getUserGeosIds(userPermissions);
  const [availableSalesOrgs] = getAvailableSalesOrgs(userPermissions, orderType);

  const salesOrgField = getFieldByKey(form, fieldNames.salesOrganization);
  const countryField = getFieldByKey(form, fieldNames.country);
  const reasonCodeField = getFieldByKey(form, fieldNames.reasonCode);

  const selectedGeo = getSelectedGeo(salesOrgField, availableSalesOrgs);

  setSalesOrganizationFieldOptions(salesOrgField, availableSalesOrgs, userGeos);
  setCountryFieldOptions(countryField, geo, selectedGeo, orderType);
  setReasonFieldOptions(reasonCodeField, geo, selectedGeo, orderType);

  return form;
};
