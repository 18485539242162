import { fieldNames } from '@/enums/singleOrderCreation/fieldNames';

const {
  vasField1, vasField2, vasField3, vasLongText, vasChargeCode,
} = fieldNames;

export default {
  S01: {
    text: 'S01 - Expedited Delivery',
    value: 'S01',
    applicableVasFields: [vasField1, vasField2, vasField3],
    disabled: true,
  },
  L03: {
    text: 'L03 - Label by Order Purpose',
    value: 'L03',
    applicableVasFields: [vasField3],
  },
  L02: {
    text: 'L02 - Label by Mark For',
    value: 'L02',
    applicableVasFields: [vasLongText],
  },
  S03: {
    text: 'S03 - Deliver on Date',
    value: 'S03',
    applicableVasFields: [vasField1, vasField2, vasField3],
  },
  S04: {
    text: 'S04 - Deliver by Date',
    value: 'S04',
    applicableVasFields: [vasField1, vasField2, vasField3],
  },
  P08: {
    text: 'P08 - Remove Polybag',
    value: 'P08',
    applicableVasFields: [vasField3, vasChargeCode],
    requiredVasFields: [vasField3, vasChargeCode],
  },
  C01: {
    text: 'C01 - Pack by SKU, Style, Style/Color',
    value: 'C01',
    applicableVasFields: [vasField3],
    requiredVasFields: [vasField3],
  },
  C06: {
    text: 'C06 - Sports Marketing SAVR',
    value: 'C06',
    applicableVasFields: [],
  },
  C07: {
    text: 'C07 - Sports Marketing Consolidated',
    value: 'C07',
    applicableVasFields: [],
  },
  A02: {
    text: 'A02 - Cresting (CRS)',
    value: 'A02',
    applicableVasFields: [vasField1, vasField2, vasField3, vasChargeCode],
    requiredVasFields: [vasChargeCode],
  },
  A03: {
    text: 'A03 - Screen Print',
    value: 'A03',
    applicableVasFields: [vasField1, vasField2, vasField3, vasChargeCode],
    requiredVasFields: [vasChargeCode],
  },
  A04: {
    text: 'A04 - Heat Transfer',
    value: 'A04',
    applicableVasFields: [vasField1, vasField2, vasField3, vasChargeCode],
    requiredVasFields: [vasChargeCode],
  },
  A05: {
    text: 'A05 - Logo',
    value: 'A05',
    applicableVasFields: [vasField1, vasField2, vasField3, vasChargeCode],
    requiredVasFields: [vasChargeCode],
  },
  A06: {
    text: 'A06 - Embroidery',
    value: 'A06',
    applicableVasFields: [vasField1, vasField2, vasField3, vasChargeCode],
    requiredVasFields: [vasChargeCode],
  },
};
