import * as mutations from './mutation-types';

export default {
  [mutations.SET_USER](state, data) {
    state.user = data;
  },

  [mutations.SET_IS_MPO_USER](state, isMpoUser) {
    state.isMpoUser = isMpoUser;
  },

  [mutations.UPDATE_USER](state, data) {
    state.user = {
      ...state.user,
      [data.attribute]: data.item,
    };
  },

  [mutations.SET_USER_HAS_PERMISSIONS](state, flag) {
    state.userHasPermissions = flag;
  },

  [mutations.SET_USER_ADMIN_RIGHTS](state, flag) {
    state.isAdmin = flag;
  },

  [mutations.SET_USER_GEO](state, geo) {
    state.geo = geo;
  },

  [mutations.SET_HAS_ACCESS_TOKEN](state, hasToken) {
    state.hasAccessToken = hasToken;
  },

  [mutations.SET_CASE_PERMISSIONS](state, casePermissions) {
    state.casePermissions = casePermissions;
  },

  [mutations.SET_CASE_PERMISSIONS_OBJECTS](state, casePermissionsObjects) {
    state.casePermissionsObjects = casePermissionsObjects;
  },
};
