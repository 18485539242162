export default {
  SET_TEMPLATE(state, template) {
    state.template = template;
  },

  SET_SELECTED_PRODUCT_ENGINES_SAP_IDS(state, selectedProductEnginesSapIds) {
    state.selectedProductEnginesSapIds = selectedProductEnginesSapIds;
  },

  SET_ORDER_TYPE(state, orderType) {
    state.selectedOrderType = orderType;
  },

  SET_FIELD_MAPPING(state, mapping) {
    // TODO: Check this. Seems like an incorrect functionality since always getting Result: {undefined: undefined}
    // Also we might want to move this logic into Actions (modules/templates/templates.actions.js::fetchFieldMapping)
    const result = {};
    mapping.forEach((mappingItem) => {
      result[mappingItem.fieldItem] = mapping.columnName;
    });
    state.fieldMapping = result;
  },
};
