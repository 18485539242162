import * as mutations from './mutation-types';

export default {
  [mutations.SET_IS_APPROVAL_FLOW](state, payload) {
    state.isApprovalFlow = payload;
  },

  [mutations.SET_APPROVAL_STATUS_VALUE](state, payload) {
    state.approvalStatusValue = payload;
  },

  [mutations.SET_APPROVAL_COMMENT_VALUE](state, payload) {
    state.approvalCommentValue = payload;
  },

  [mutations.SET_TOTAL_APPROVAL_COUNT](state, payload) {
    state.totalApprovalCount = payload;
  },

  [mutations.SET_IS_APPROVALS_LOADING](state, payload) {
    state.isApprovalsLoading = payload;
  },

  [mutations.SET_ORDER_APPROVAL_SUBMIT_LOADING](state, isOrderApprovalSubmitLoading) {
    state.isOrderApprovalSubmitLoading = isOrderApprovalSubmitLoading;
  },

  [mutations.SET_ORDER_APPROVAL_DRAFT_SAVE_LOADING](state, isOrderApprovalDraftSaveLoading) {
    state.isOrderApprovalDraftSaveLoading = isOrderApprovalDraftSaveLoading;
  },

  [mutations.SET_APPROVAL_ROW_DATA](state, approvalRowData) {
    state.approvalRowData = approvalRowData;
  },

  [mutations.SET_APPROVAL_ORDER_ID](state, approvalOrderId) {
    state.approvalOrderId = approvalOrderId;
  },

  [mutations.SET_LINES_APPROVAL_STATUS](state, approvalRowData) {
    state.approvalRowData = approvalRowData;
  },

  [mutations.SET_USER_CONTEXT_ROLE](state, userContextRole) {
    state.userContextRole = userContextRole;
  },

  [mutations.SET_APPROVAL_SUBMIT_ERROR](state, approvalSubmitError) {
    state.approvalSubmitError = approvalSubmitError;
  },

  [mutations.SET_SELECTED_ORDER](state, order) {
    state.selectedOrder = order;
  },
};
