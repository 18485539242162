<template>
  <Overlay :isVisible="isVisible">
    <div class="user-confirmation">
      <div class="user-confirmation__header">
        <Typography variant="title-4" class="user-confirmation__title">
          {{ title }}
        </Typography>
        <IconButton
          class="user-confirmation__close-btn"
          :size="Size.SMALL"
          variant="ghost"
          iconName="CloseLargeDefaultOnLight"
          @click="handleSelection(closeButtonValue)"
        />
      </div>

      <slot name="description" />

      <div class="user-confirmation__footer">
        <Button
          class="user-confirmation__footer--btn"
          :size="Size.SMALL"
          :variant="ButtonVariant.SECONDARY"
          @click="handleSelection('No')"
        >
          {{ $t(noConfirmationText) }}
        </Button>

        <Button
          class="user-confirmation__footer--btn"
          :size="Size.SMALL"
          :variant="ButtonVariant.PRIMARY"
          @click="handleSelection('Yes')"
        >
          {{ $t(yesConfirmationText) }}
        </Button>
      </div>
    </div>
  </Overlay>
</template>

<script>
import { mapGetters } from "vuex";
import { Size, Button, ButtonVariant, Typography, IconButton, Overlay } from "podium";

export default {
  components: {
    Button,
    Overlay,
    Typography,
    IconButton,
  },

  props: {
    title: {
      type: String,
      default: "Warning",
    },

    yesConfirmationText: {
      type: String,
      default: "base.yes",
    },

    noConfirmationText: {
      type: String,
      default: "base.no",
    },
    closeButtonValue: {
      type: String,
      default: "Yes",
    },
  },

  emits: ["orderSuccessfullySent"],

  data() {
    return {
      Size,
      ButtonVariant,
      resolve: null,
      isVisible: false,
    };
  },

  computed: {
    ...mapGetters("SOC", {
      headerLevel: "headerLevel",
    }),

    isSaveAsDraftVisisble() {
      return this.headerLevel?.length;
    },
  },

  methods: {
    show() {
      this.isVisible = true;

      return new Promise((resolve) => {
        this.resolve = resolve;
      });
    },

    handleSelection(selectedValue) {
      this.isVisible = false;

      if (selectedValue === "Yes") {
        this.resolve(true);
      } else {
        this.resolve(false);
      }
    },

    hide(resolveValue = null) {
      if (this.isVisible) {
        this.isVisible = false;
        this.resolve(resolveValue);
      }
    },

    orderSuccessfullySent() {
      this.$emit("orderSuccessfullySent", true);
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/colors.scss";
@import "@/styles/components/Modal.scss";

.user-confirmation {
  max-width: 600px;
  padding: 24px;
  border-radius: 5px;
  box-shadow: $modal-box-shadow;
  background-color: $primary-white;

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
  }

  &__title {
    margin: 8px 16px 0 0;
  }

  &__close-button {
    justify-self: end;
  }

  :deep(.confirmation-message) {
    margin-bottom: 4px;
  }

  :deep(.action-message) {
    margin-bottom: 32px;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px solid $podium-grey-2;
  }
}
</style>
