<template>
  <div :class="baseClassName">
    <router-link :to="route" :class="`${baseClassName}__order-id`">
      {{ orderId }}
    </router-link>
  </div>
</template>

<script setup>
import { computed } from "vue";
import pagesURL from "@/router/routes";

const props = defineProps({
  params: {
    type: Object,
    required: true,
  },
});

const baseClassName = "audit-event-order-renderer";

const orderId = computed(() => props.params.data.entryObjectId);

const route = computed(() => {
  return `${pagesURL.AUDIT_EVENT.BASE}/${pagesURL.AUDIT_EVENT.EVENTS}/${orderId.value}`;
});
</script>

<style lang="scss">
@import "podium-tokens";

.audit-event-order-renderer {
  &__order-id {
    color: $podium-color-blue-600;
  }

  &__danger {
    background-color: $podium-color-red-600;
  }
}
</style>
