<template>
  <div
    v-if="isNonProd || isQaDelta"
    :class="[
      'env-banner',
      { 'env-banner--dev': isDevEnv }
    ]"
  >
    {{ envBannerText }}
  </div>
</template>

<script>
import { capitalizeEveryWord } from '@/utils/filters';
import DevEnvMixin from '@/mixins/DevEnvMixin';

export default {
  mixins: [DevEnvMixin],
  computed: {
    envBannerText() {
      const envLabel = `${capitalizeEveryWord(this.$envName)} environment. `;
      const warningLabel = this.isDevEnv ? 'Unstable!' : 'Can be partially unstable.';

      // this is a temporary solution to show the warning label in QA Delta
      if (this.isQaDelta) {
        return 'This environment is mapped to MANA version of OFOA on QA-Oscar.';
      }

      return `${envLabel}${warningLabel}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/colors.scss';

.env-banner {
  @extend .now-body-3;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  color: $primary-black;
  background-color: $warning-orange;
  letter-spacing: .034em;

  &--dev {
    background-color: $error-red;
  }
}
</style>
