export const orderStatuses = {
  DRAFT: 'DRAFT',
  REWORK: 'REWORK',
  STASHED: 'STASHED',
  DELETED: 'DELETED',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  RECEIVED: 'RECEIVED',
  ARCHIVED: 'ARCHIVED',
  EXCEPTION: 'EXCEPTION',
  SUBMITTED: 'SUBMITTED',
  SENT: 'SENT',
  SUBMITTING: 'SUBMITTING',
  SENT_FAILED: 'SENT_FAILED',
  NOT_ACCEPTED: 'NOT_ACCEPTED',
  ACKNOWLEDGED: 'ACKNOWLEDGED',
  FAILED_IN_S4: 'FAILED_IN_S/4',
  FAILED_IN_SCP: 'FAILED_IN_SCP',
  READY_TO_SUBMIT: 'READY_TO_SUBMIT',
  CONTAINS_ERRORS: 'CONTAINS_ERRORS',
  PENDING_APPROVAL: 'PENDING_APPROVAL',
  STO_IDOC_CREATED: 'STO_IDOC_CREATED',
};

export const editableOrderStatuses = [
  orderStatuses.DRAFT,
  orderStatuses.REWORK,
  orderStatuses.CONTAINS_ERRORS,
];
