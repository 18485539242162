import store from '@/store';

import { MAINTENANCE } from '@/store/moduleNames';
import {
  FETCH_OUTAGE_STATUS,
  FETCH_SERVICE_DOWN_STATUS,
} from '@/store/modules/maintenance/constants/actions';

import pagesURL from '@/router/routes.js';
import localStorageKey from '@/enums/browserStorage/localStorageKeyEnum';

export default async function userPermissionGuard(
  { meta: {
    requiresAuth, requiresUnauth, isMaintenance,
  } },
  _from,
  next,
) {
  if (requiresAuth) {
    try {
      await store.dispatch('user/checkUserPermissions', false, {
        root: true,
      });

      await store.dispatch(`${MAINTENANCE}/${FETCH_OUTAGE_STATUS}`, false, {
        root: true,
      });

      await store.dispatch(`${MAINTENANCE}/${FETCH_SERVICE_DOWN_STATUS}`, false, {
        root: true,
      });
    } catch (error) {
      if (error.response?.status === 401) {
        return next({ path: pagesURL.NOT_ALLOWED });
      }
    }
  } else if (
    requiresUnauth
    && JSON.parse(localStorage.getItem(localStorageKey.userAppActiveState))
  ) {
    next({ path: pagesURL.ROOT });
  }

  if (store.state.maintenance.isServiceDown && !isMaintenance) {
    return next({ path: pagesURL.SERVICE_DOWN });
  }

  return next();
}
