export const validationRuleIsOnlyDigitsLetters = (formField) => {
  if (!formField.touched) {
    return formField;
  }

  const pattern = /^[0-9A-Za-z]+$/;

  if (pattern.test(formField.value) || !formField.value) {
    formField.errors = formField.errors.filter((error) => !error.digitsLettersOnly);
  } else {
    formField.errors.push({ digitsLettersOnly: 'Only digits and letters are allowed' });
  }

  return formField;
};
