// TODO after dropdown refactoring, add to modals.

const refs = new Map();

function outClickHandler(el, handler, event) {
  const isOutClick = event.target !== el && !el.contains(event.target);
  const isFunction = typeof handler === 'function';
  const objHandler = {
    handler: isFunction ? handler : handler.handler,
    middleware: handler.middleware || ((isOutClick) => isOutClick),
  };

  return objHandler.middleware(isOutClick) ? objHandler.handler(el, event) : null;
}

const OutClick = {
  beforeMount(el, binding) {
    refs.set(el, outClickHandler.bind(null, el, binding.value));

    window.addEventListener('mouseup', refs.get(el));
  },
  unmounted(el) {
    window.removeEventListener('mouseup', refs.get(el));

    refs.delete(el);
  },
};

export default OutClick;
