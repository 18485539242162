export default {
  SET_SOC_ORDER_DATA(state, payload) {
    state.socOrderData = payload;
  },

  SET_SELECTED_SOC_ORDER_TYPE(state, payload) {
    state.selectedSocOrderType = payload;
  },

  SET_ALLOWED_ORDER_TYPES(state, allowedOrderTypes) {
    state.allowedOrderTypes = allowedOrderTypes;
  },
};
