export default {
  SET_CUSTOMER_DETAILS(state, customerDetails) {
    state.customerDetails = customerDetails;
  },

  SET_CUSTOMER_DETAILS_PAGINATION(state, paginationToken) {
    if (state.paginationToken !== paginationToken) {
      state.paginationToken = paginationToken;
    }
  },

  EXTEND_CUSTOMER_DETAILS(state, customerDetails) {
    state.customerDetails = [...state.customerDetails, ...customerDetails];
  },
};
