import i18n from '@/plugins/i18n';

import mutations from './mutationTypes';
import resourcesRepository from '@/api/now/resources';
import { showErrorBanner } from '@/utils/notifications';

const resources = {
  async fetchResources({ commit }, withLoading = true) {
    if (withLoading) {
      commit(mutations.SET_IS_RESOURCES_LOADING, true);
    }

    try {
      const { data } = await resourcesRepository.fetchResources();

      commit(mutations.SET_RESOURCES, data);
    } catch (error) {
      showErrorBanner({
        title: 'Unable to fetch resources, please try again later',
        width: 300,
      });
    } finally {
      if (withLoading) {
        commit(mutations.SET_IS_RESOURCES_LOADING, false);
      }
    }
  },

  async updateFavoriteStatus({ dispatch }, { resourceId, favoriteStatus }) {
    try {
      await resourcesRepository.updateFavoriteStatus({
        resourceId,
        isFavorite: favoriteStatus,
      });

      setTimeout(() => {
        dispatch('fetchResources', false);
      }, 1000);
    } catch (error) {
      const errorMessage = favoriteStatus
        ? i18n.global.t('pages.resources.favoriteAddError')
        : i18n.global.t('pages.resources.favoriteRemoveError');

      showErrorBanner({
        title: errorMessage,
        width: 300,
      });

      dispatch('fetchResources');
    }
  },
};

export default {
  ...resources,
};
