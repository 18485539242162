import actions from './globalFilters.actions';
import state from './globalFilters.state';
import mutations from './globalFilters.mutations.js';
import getters from './globalFilters.getters.js';
import filterManagementActions from './filterManagement.actions';

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions: { ...actions, ...filterManagementActions },
};
