import { splitSearchQuery } from "@/utils/auditEvent/index.js";
import { transfromIsoDatesToTableFormat } from "@/utils/dates";
import { parseGlobalFilters, getSearchFilters } from "@/utils/globalFilters";

export default {
  isNavbarSearchActive: (state) => state.searchQuery?.length > 2,

  isSavedFiltersDataLoading: (state) => state.isLoading && !state.savedFilters,

  selectedFiltersConfig: (state) =>
    state.savedFilters.find((filter) => filter.filterName === state.selectedFiltersName),

  separatedSearchQueries: (state) => splitSearchQuery(state.searchQuery),
  getRequestFilterParams: (
    {
      tableFilters,
      pageSize,
      pageNumber,
      sortedColumnName,
      sortedColumnOrder,
      selectedOrderTypes,
      selectedOrderOwnership,
      pageTokens,
    },
    getters,
    rootState,
    rootGetters
  ) => {
    const paginationToken =
      pageTokens.length >= pageNumber ? pageTokens[pageNumber] : pageTokens[pageTokens.length - 1];
    const { searchQuery, appliedFilterFields: globalFilters } = rootState.auditEvent;

    const filters = searchQuery
      ? getSearchFilters(rootGetters["auditEvent/separatedSearchQueries"])
      : [...parseGlobalFilters(globalFilters)];

    return {
      viewMode: selectedOrderOwnership,
      orderTypeIds: selectedOrderTypes,
      pageSize,
      pageNumber,
      sortBy: sortedColumnName,
      sortOrder: sortedColumnOrder,
      filters,
      paginationToken,
    };
  },

  getFormatedOrdersData: ({ orders }) => {
    return [
      {
        ...orders,
        ...transfromIsoDatesToTableFormat(orders),
      },
    ];
  },

  getFormatedOrderEventsData: ({ orderEvents }) => {
    return orderEvents.map((order) => {
      return {
        ...order,
        ...transfromIsoDatesToTableFormat(order),
      };
    });
  },
};
