import { fieldNames } from '@/enums/singleOrderCreation/fieldNames';

export const dateFields = new Set([
  fieldNames.crd,
  fieldNames.cancelDate,
  fieldNames.validToDate,
  fieldNames.validFromDate,
  fieldNames.businessPurposeDate,
]);

export const logicalTypes = new Set(['Checkbox', 'PurchasingPolicy']);

export const secondLevelDependencyFields = new Set([
  fieldNames.paDisclosureTool,
  fieldNames.paRefNumber,
]);

export const preApprovalConditionFields = [
  fieldNames.paDepartment,
  fieldNames.paGovernmentOfficial,
  fieldNames.paExternalGift,
  fieldNames.paInternalGift,
];

export const fieldsMaxTextLength = {
  nikeInternalCommentsLength: 500,
};
