import { LineLevelValidationRulesEnum } from '@/controllers/order/validations/line/index';

export const lengthRuleValidation = ({
  formFieldData, minLength, maxLength, errorMessage,
}) => {
  formFieldData.errors = formFieldData.errors.filter(
    (error) => !error[LineLevelValidationRulesEnum.lengthValidation],
  );

  if (maxLength && minLength) {
    if (formFieldData.value.length > maxLength || formFieldData.value.length < minLength) {
      formFieldData.errors.push({
        [LineLevelValidationRulesEnum.lengthValidation]:
          errorMessage || `Length must be between ${minLength} and ${maxLength} characters`,
      });
    }
  } else if (minLength && formFieldData.value.length < minLength) {
    formFieldData.errors.push({
      [LineLevelValidationRulesEnum.lengthValidation]:
        errorMessage || `Min length (${minLength}) exceeded`,
    });
  } else if (maxLength && formFieldData.value.length > maxLength) {
    formFieldData.errors.push({
      [LineLevelValidationRulesEnum.lengthValidation]:
        errorMessage || `Max length (${maxLength}) exceeded`,
    });
  }

  return formFieldData;
};
