export default {
  Date: 'DD/MM/YYYY',
  ShortDate: 'D/M/YYYY',
  AlphaNumeric: '^[0-9A-Za-z]',
  Alphabetic: '^[A-Za-z]',
  Alphabetic_Space: '^[A-Za-z\u3000\u3400-\u4DBF\u4E00-\u9FFF\\s]',
  AlphaNumeric_Space: '^[0-9A-Za-z\u3000\u3400-\u4DBF\u4E00-\u9FFF\\s]',
  AlphaNumeric_Comma: '^[0-9A-Za-z,]',
  ValidEmail: '[a-z0-9]+@[a-z]+.[a-z]{2,3}',
  EmailValidQuery: '^[a-zA-Z0-9@.]{1,35}$',
  AlphaNumeric_Dash_Space: '^[0-9A-Za-z- ]',
  Numeric_NoLimit: '^[0-9]*$',
  AlphaNumericSpecialChars_20: '^(?=.{1,20}$).*',
  AlphaNumericSpecialChars_30: '^(?=.{1,30}$).*',
  AlphaNumericSpecialChars_35: '^(?=.{1,35}$).*',
  AlphaNumericSpecialChars_40: '^(?=.{1,40}$).*',
  AlphaNumericSpecialChars_60: '^(?=.{1,60}$).*',
  AlphaNumericSpecialChars_70: '^(?=.{1,70}$).*',
  AlphaNumericUpperCaseUnderscore: '^[0-9A-Z_]',
  ValidVasChargeCode: '[A-Za-z]\\d\\d=[A-Za-z]\\d\\d',
  Any: '^.',
  Numeric: '^[0-9]',
  YN_1: '^[YNyn]{1}$',
  Y_1: '^[Yy]{1}$',
  Length_2: '{1,2}$',
  Length_2_Strict: '{2}$',
  Length_3: '{1,3}$',
  Length_3_Strict: '{3}$',
  Length_4: '{1,4}$',
  Length_5: '{1,5}$',
  Length_5_Strict: '{5}$',
  Length_6: '{1,6}$',
  Length_7: '{1,7}$',
  Length_10: '{1,10}$',
  Length_12: '{1,12}$',
  Length_19: '{1,19}$',
  Length_20: '{1,20}$',
  Length_35: '{1,35}$',
  Length_40: '{1,40}$',
  Length_60: '{1,60}$',
  Length_70: '{1,70}$',
  Length_132: '{1,132}$',
  Length_500: '{1,500}$',
};
