import { PRODUCT } from '@/api/endpoints';
import repository from '@/api/repositories/serverRepository';

export function fetchAvailabilityDetails(params) {
  return repository.post(PRODUCT.AVAILABILITY_DETAILS, params);
}

export default {
  fetchAvailabilityDetails,
};
