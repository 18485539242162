export default {
  setOrderErrors({
    state, commit, dispatch,
  }, errors) {
    commit('SET_ORDER_ERRORS', errors);

    if (!state.selectedError.id && errors.length) {
      dispatch('setSelectedError', errors[0]);
    }
  },

  setSelectedError({ commit }, error) {
    commit('SET_SELECTED_ERROR', error);
  },

  moveToSelectedError({ state }) {
    const agGridApi = state.agGrid.api;
    const {
      selectedError: { name, rowIndex },
    } = state;
    const colKey = agGridApi.columnController.columnApi
      .getAllColumns()
      .find(({ colDef }) => colDef.headerName.toLowerCase() === name.toLowerCase())?.colId;

    agGridApi.stopEditing();
    agGridApi.ensureIndexVisible(rowIndex);
    agGridApi.ensureColumnVisible(colKey);
    agGridApi.setFocusedCell(rowIndex, colKey);
  },
};
