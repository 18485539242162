import { markRaw } from "vue";
import { ToggleButtonGroup, Select, TextField } from "@nike/now.podium-vue";

import BaseDatepicker from "@/components/ui/BaseDatepicker";

import { translateModule } from "@/utils/string/translate";

import { FIELDS_ENUM } from "@/enums/language/fieldsEnum";

import { FormField, MaterialClassificationResults, FormInputResults } from "./types";

const translateFields = translateModule("fields");

export const FIELD_KEYS = {
  START_DATE: "startTime",
  END_DATE: "endTime",
  ORDER_TYPE: "orderType",
  PO_TYPE: "poType",
  ORDER_ID: "orderIds",
  PO_NUMBER: "poNumbers",
  SALES_ORG: "salesOrg",
} as const;

export const FILTERS_CATEGORIES = [
  { name: "Order ID", id: "orderId" },
  { name: "PO Number", id: "poNumber" },
];

export const VAS_VALUES = {
  WITH_WAS: "With Vas",
  NO_VAS: "No Vas",
};

export const LINE_DC_BYPASS_ELIGIBILITY_VALUES = {
  ELIGIBLE: "Eligible",
  NOT_ELIGIBLE: "Not Eligible",
};

const optionsGetterForMaterialClassification = (results: MaterialClassificationResults) => {
  return results.map(({ code, description }) => ({
    value: code,
    text: `${code} - ${description}`,
  }));
};

const optionsGetterForFormInputs = (results: FormInputResults) => {
  return results.map(({ id, alias }) => ({ value: id, text: alias }));
};

const headerFields: Array<FormField> = [
  {
    key: FIELD_KEYS.ORDER_ID,
    component: markRaw(TextField),
    label: translateFields(FIELDS_ENUM.orderUuid),
    type: "orderId",
  },
  {
    key: FIELD_KEYS.PO_NUMBER,
    component: markRaw(TextField),
    label: translateFields(FIELDS_ENUM.poNumber),
    type: "poNumber",
  },
  {
    key: FIELD_KEYS.START_DATE,
    component: markRaw(BaseDatepicker),
    label: translateFields(FIELDS_ENUM.startDate),
    isRangeDatepicker: false,
    type: "poNumber",
  },
  {
    key: FIELD_KEYS.END_DATE,
    component: markRaw(BaseDatepicker),
    label: translateFields(FIELDS_ENUM.endDate),
    isRangeDatepicker: false,
    type: "poNumber",
  },
  // {
  //   key: FIELD_KEYS.DEPARTMENT_CODE,
  //   component: markRaw(Select),
  //   isSearchable: true,
  //   multiple: true,
  //   label: translateFields(FIELDS_ENUM.departmentCode),
  //   emptyListText: translateGlobalFilters("startTypingToSeeOptions"),
  //   onInputAction: "globalFilters/fetchOptions",
  //   optionsEndpoint: "getDepartmentCodesForGlobalFilters",
  //   optionsGetter: optionsGetterForFormInputs,
  // },
  // {
  //   key: FIELD_KEYS.MARK_FOR,
  //   component: markRaw(TextField),
  //   iconRight: "SearchLargeDefaultOnLight",
  //   label: translateFields(FIELDS_ENUM.markFor),
  //   validation: {
  //     pattern: "^[0-9]{1,10}$",
  //     errorMessage: "Expected format: 1-10 digits",
  //   },
  // },
  // {
  //   key: FIELD_KEYS.MARKETPLACE_UNIT,
  //   component: markRaw(Select),
  //   componentType: "select",
  //   label: translateFields(FIELDS_ENUM.marketplaceUnit),
  //   multiple: true,
  // },
  // {
  //   key: FIELD_KEYS.MODIFIED_BY,
  //   component: markRaw(Select),
  //   label: translateFields(FIELDS_ENUM.modifiedBy),
  //   multiple: true,
  //   onInputAction: "globalFilters/fetchUser",
  //   emptyListText: translateGlobalFilters("startTypingToSeeOptions"),
  //   isSearchable: true,
  // },
  // {
  //   key: FIELD_KEYS.STATUS,
  //   component: markRaw(Select),
  //   componentType: "select",
  //   displayLabelsAsValues: true,
  //   label: translateFields(FIELDS_ENUM.orderStatus),
  //   multiple: true,
  // },
  // {
  //   key: FIELD_KEYS.REASON_CODE,
  //   component: markRaw(Select),
  //   componentType: "select",
  //   label: translateFields(FIELDS_ENUM.reasonCode),
  //   multiple: true,
  // },
  // {
  //   key: FIELD_KEYS.SALES_ORG,
  //   component: markRaw(Select),
  //   componentType: "select",
  //   label: translateFields(FIELDS_ENUM.salesOrg),
  //   multiple: true,
  // },
  // {
  //   key: FIELD_KEYS.SHIP_TO,
  //   component: markRaw(Select),
  //   onInputAction: "globalFilters/fetchOptions",
  //   optionsEndpoint: "getShipTosForGlobalFilters",
  //   emptyListText: translateGlobalFilters("startTypingToSeeOptions"),
  //   isSearchable: true,
  //   multiple: true,
  //   label: translateFields(FIELDS_ENUM.shipTo),
  //   optionsGetter: optionsGetterForFormInputs,
  // },
  // {
  //   key: FIELD_KEYS.SOLD_TO,
  //   component: markRaw(Select),
  //   onInputAction: "globalFilters/fetchOptions",
  //   optionsEndpoint: "getSoldTosForGlobalFilters",
  //   emptyListText: translateGlobalFilters("startTypingToSeeOptions"),
  //   isSearchable: true,
  //   multiple: true,
  //   label: translateFields(FIELDS_ENUM.soldTo),
  //   optionsGetter: optionsGetterForFormInputs,
  // },
  // {
  //   key: FIELD_KEYS.SOURCE_SYSTEM,
  //   component: markRaw(Select),
  //   componentType: "select",
  //   label: translateFields(FIELDS_ENUM.sourceSystem),
  //   multiple: true,
  // },
  // {
  //   key: FIELD_KEYS.SUB_CHANNEL,
  //   component: markRaw(Select),
  //   componentType: "select",
  //   label: translateFields(FIELDS_ENUM.subChannel),
  //   multiple: true,
  // },
];

// const lineFields: Array<FormField> = [
//   {
//     key: FIELD_KEYS.LINE_CANCEL_DATE,
//     component: markRaw(BaseDatepicker),
//     label: translateFields(FIELDS_ENUM.cancelDateLineLevel),
//     isRangeDatepicker: true,
//   },
//   {
//     key: FIELD_KEYS.LINE_CATEGORY,
//     component: markRaw(Select),
//     label: translateFields(FIELDS_ENUM.category),
//     multiple: true,
//     isSearchable: true,
//     onInputAction: 'globalFilters/fetchOptions',
//     optionsEndpoint: 'fetchMaterialCategory',
//     emptyListText: translateGlobalFilters('startTypingToSeeOptions'),
//     optionsGetter: optionsGetterForMaterialClassification,
//   },
//   {
//     key: FIELD_KEYS.LINE_CRD,
//     component: markRaw(BaseDatepicker),
//     label: translateFields(FIELDS_ENUM.crdLineLevel),
//     isRangeDatepicker: true,
//   },
//   {
//     key: FIELD_KEYS.LINE_DC_BYPASS_ELIGIBILITY,
//     component: markRaw(ToggleButtonGroup),
//     label: translateFields(FIELDS_ENUM.dcBypassEligibility),
//     items: [
//       {
//         text: translateGlobalFilters('all'),
//         value: undefined,
//       },
//       {
//         text: translateGlobalFilters('eligible'),
//         value: LINE_DC_BYPASS_ELIGIBILITY_VALUES.ELIGIBLE,
//       },
//       {
//         text: translateGlobalFilters('notEligible'),
//         value: LINE_DC_BYPASS_ELIGIBILITY_VALUES.NOT_ELIGIBLE,
//       },
//     ],
//   },
//   {
//     key: FIELD_KEYS.LINE_DIVISION,
//     component: markRaw(Select),
//     componentType: 'select',
//     label: translateFields(FIELDS_ENUM.division),
//     multiple: true,
//   },
//   {
//     key: FIELD_KEYS.LINE_GENDER,
//     component: markRaw(Select),
//     label: translateFields(FIELDS_ENUM.gender),
//     isSearchable: true,
//     multiple: true,
//   },
//   {
//     key: FIELD_KEYS.LINE_ITEM_CATEGORY,
//     component: markRaw(Select),
//     label: translateFields(FIELDS_ENUM.itemCategory),
//     isSearchable: true,
//     multiple: true,
//   },
//   {
//     key: FIELD_KEYS.LINE_KBI_INDICATOR,
//     component: markRaw(Select),
//     label: translateFields(FIELDS_ENUM.launchCode),
//     isSearchable: true,
//     multiple: true,
//   },
//   {
//     key: FIELD_KEYS.LINE_LEAGUE_INDICATOR,
//     component: markRaw(Select),
//     label: translateFields(FIELDS_ENUM.leagueIndicator),
//     isSearchable: true,
//     multiple: true,
//   },
//   {
//     key: FIELD_KEYS.LINE_MATERIAL_NUMBER,
//     component: markRaw(TextField),
//     iconRight: 'SearchLargeDefaultOnLight',
//     label: translateFields(FIELDS_ENUM.materialNumber),
//     validation: {
//       pattern: '^([a-zA-Z0-9]{6})-([a-zA-Z0-9]{3})$',
//       errorMessage: 'Expected Format: BQ3757-101, SX7676-100',
//     },
//   },
//   {
//     key: FIELD_KEYS.LINE_PLANT_CODE,
//     component: markRaw(TextField),
//     iconRight: 'SearchLargeDefaultOnLight',
//     label: translateFields(FIELDS_ENUM.plantCode),
//   },
//   {
//     key: FIELD_KEYS.LINE_REQUIREMENT_SEGMENT,
//     component: markRaw(Select),
//     componentType: 'select',
//     multiple: true,
//     label: translateFields(FIELDS_ENUM.requirementSegment),
//   },
//   {
//     key: FIELD_KEYS.LINE_SUB_CATEGORY,
//     component: markRaw(Select),
//     label: translateFields(FIELDS_ENUM.subCategory),
//     multiple: true,
//     isSearchable: true,
//     onInputAction: 'globalFilters/fetchOptions',
//     optionsEndpoint: 'fetchMaterialSubCategory',
//     emptyListText: translateGlobalFilters('startTypingToSeeOptions'),
//     optionsGetter: optionsGetterForMaterialClassification,
//   },
//   {
//     key: FIELD_KEYS.LINE_VAS,
//     component: markRaw(ToggleButtonGroup),
//     label: translateGlobalFilters('vas'),
//     items: [
//       {
//         text: translateGlobalFilters('all'),
//         value: undefined,
//       },
//       {
//         text: translateGlobalFilters('withVas'),
//         value: VAS_VALUES.WITH_WAS,
//       },
//       {
//         text: translateGlobalFilters('noVas'),
//         value: VAS_VALUES.NO_VAS,
//       },
//     ],
//   },
// ];

// const adjustedLineFields = lineFields.map(field => ({ ...field, isLineItem: true }));

export const FIELDS = [...headerFields];
