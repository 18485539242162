import localStorageKey from '@/enums/browserStorage/localStorageKeyEnum';

const {
  soldTo = [],
  shipTo = [],
  orderTypes = [],
  exceptionGroups = [],
  dateFrom = '',
  dateTo = '',
} = JSON.parse(localStorage.getItem(localStorageKey.globalFilter)) || {};

export function getDefaultState() {
  const defaultFilters = {
    soldTo,
    shipTo,
    orderTypes,
    exceptionGroups,
    dateFrom,
    dateTo,
  };

  return {
    availableExceptionGroups: [],

    preAppliedSoldTo: [],
    preAppliedShipTo: [],

    ...defaultFilters,

    appliedFilters: {
      ...defaultFilters,
    },
  };
}

export default getDefaultState();
