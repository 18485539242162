import test from "@/config/test.json";
import local from "@/config/local.json";
import delta from "@/config/qa-delta.json";
import oscar from "@/config/qa-oscar.json";
import sierra from "@/config/qa-sierra.json";
import production from "@/config/production.json";
import development from "@/config/development.json";
import productionEcho from "@/config/production-echo.json";

export const getBaseUrl = () => {
  let apiEnv = "";

  switch (process.env.VUE_APP_ENV) {
    case "prod":
      apiEnv = production.baseUrl;
      break;

    case "prod-echo":
      apiEnv = productionEcho.baseUrl;
      break;

    case "dev":
      apiEnv = development.baseUrl;
      break;

    case "test":
      apiEnv = test.baseUrl;
      break;

    case "qa-delta":
      apiEnv = delta.baseUrl;
      break;

    case "qa-sierra":
      apiEnv = sierra.baseUrl;
      break;

    case "qa-oscar":
      apiEnv = oscar.baseUrl;
      break;

    case "local":
      apiEnv = local.baseUrl;
      break;
    default:
  }

  return `${apiEnv}/order_mgmt/olympus_app/`;
};

export const getProductionSupportBaseUrl = () => {
  let apiEnv = "";

  switch (process.env.VUE_APP_ENV) {
    case "prod":
      apiEnv = production.productionSupportUrl;
      break;

    case "prod-echo":
      apiEnv = productionEcho.productionSupportUrl;
      break;

    case "dev":
      apiEnv = development.productionSupportUrl;
      break;

    case "test":
      apiEnv = test.productionSupportUrl;
      break;

    case "qa-delta":
      apiEnv = delta.productionSupportUrl;
      break;

    case "qa-sierra":
      apiEnv = sierra.productionSupportUrl;
      break;

    case "qa-oscar":
      apiEnv = oscar.productionSupportUrl;
      break;

    case "local":
      apiEnv = local.productionSupportUrl;
      break;
    default:
  }

  return `${apiEnv}/`;
};

export const getAuditEventBaseUrl = () => {
  let apiEnv = "";

  switch (process.env.VUE_APP_ENV) {
    case "prod":
      apiEnv = production.auditEventUrl;
      break;

    case "prod-echo":
      apiEnv = productionEcho.auditEventUrl;
      break;

    case "dev":
      apiEnv = development.auditEventUrl;
      break;

    case "test":
      apiEnv = test.auditEventUrl;
      break;

    case "qa-delta":
      apiEnv = delta.auditEventUrl;
      break;

    case "qa-sierra":
      apiEnv = sierra.auditEventUrl;
      break;

    case "qa-oscar":
      apiEnv = oscar.auditEventUrl;
      break;

    case "local":
      apiEnv = local.auditEventUrl;
      break;
    default:
  }

  return `${apiEnv}/`;
};
