import {
  DependencyRulesActionsMap,
} from '@/controllers/order/dependencies/header/actions/actionsMap';
import {
  LineLevelDependencyRulesActionsMap,
} from '@/controllers/order/dependencies/line/actions/actionsMap';

import {
  getFieldConfig,
  memoHeaderFields,
  getConditionResult,
  getFieldsWithDependencies,
} from './helpers';

const applyHeaderDependency = async(payload) => {
  const {
    config, initialForm, form, dependency, updatedField, columnRequiredStatusUpdates,
  } = payload;

  let resolvedForm = form;
  const index = form.fields.findIndex(field => field.key === dependency.targetKey);

  const hasChanged = memoHeaderFields(resolvedForm.fields[index]);

  const conditionResult = getConditionResult({
    dependency,
    initialForm,
    updatedField,
    form: resolvedForm,
    field: resolvedForm.fields[index],
  });

  resolvedForm.fields[index] = await DependencyRulesActionsMap[dependency?.action]?.({
    field: resolvedForm.fields[index],
    value: conditionResult,
    dependency,
    updatedField,
    initialForm,
    form: resolvedForm,
  }) ?? resolvedForm.fields[index];

  if (hasChanged(resolvedForm.fields[index])) {
    resolvedForm = await applyDependencies({
      config,
      initialForm,
      form: resolvedForm,
      updatedField: form.fields[index],
      columnRequiredStatusUpdates,
    });
  }

  return resolvedForm;
};

const applyLineDependency = async(payload) => {
  const {
    config, initialForm, form, dependency, updatedField, columnRequiredStatusUpdates,
  } = payload;
  const resolvedRows = form.lineItemFields.rowData;

  return await resolvedRows.reduce(async(formPromise, row, rowIndex) => {
    let resolvedForm = await formPromise;
    const previousValue = row[dependency.targetKey]?.value;

    const rows = resolvedForm.lineItemFields.rowData;
    const conditionResult = getConditionResult({
      dependency, updatedField, initialForm, form: resolvedForm,
    });

    rows[rowIndex] = await LineLevelDependencyRulesActionsMap[dependency?.action]?.({
      result: conditionResult,
      fieldName: dependency.targetKey,
      lineLevelData: row,
      headerLevelFormData: form.fields,
      dependency,
      orderType: form.orderType,
      columnRequiredStatusUpdates,
      form: resolvedForm,
    }) ?? row;

    const newValue = rows[rowIndex][dependency.targetKey]?.value;

    if (previousValue !== newValue) {
      resolvedForm = await applyDependencies({
        config,
        initialForm,
        form: resolvedForm,
        updatedField: {
          name: dependency.targetKey,
          rowIndex,
          cellData: rows[rowIndex][dependency.targetKey],
        },
        columnRequiredStatusUpdates,
      });
    }

    return resolvedForm;
  }, Promise.resolve(form));
};

const DependencyHandler = {
  HeaderLevel: applyHeaderDependency,
  LineLevel: applyLineDependency,
};

const applyFieldDependencies = async(payload) => {
  const {
    config, form, updatedField,
  } = payload;
  const fieldConfig = getFieldConfig(config, updatedField);
  const hasDependencies = fieldConfig?.dependencies?.length;

  if (!hasDependencies) return form;

  return await fieldConfig.dependencies.reduce(async(formPromise, dependency) => {
    const resolvedForm = await formPromise;

    return await DependencyHandler[dependency.targetType]?.({
      ...payload, dependency, form: resolvedForm,
    });
  }, Promise.resolve(form));
};

export const applyDependencies = async({
  form,
  config,
  operation,
  initialForm,
  updatedField,
  columnRequiredStatusUpdates,
}) => {
  const fields = getFieldsWithDependencies({
    form,
    config,
    updatedField,
    operation,
  });

  const updatedForm = await fields.reduce(async(formPromise, field) => {
    const resolvedForm = await formPromise;

    return await applyFieldDependencies({
      config,
      initialForm,
      form: resolvedForm,
      updatedField: field,
      columnRequiredStatusUpdates,
    });
  }, Promise.resolve(form));

  return updatedForm;
};
