import moment from 'moment';
import { addDaysToDate, addBusinessDaysToDate } from '@/utils/singleOrderCreate';
import dateFormats from '@/enums/dates/dateFormats';

export function dependencyRulesActionSetDateValue({
  field, value, dependency, updatedField,
}) {
  if (dependency.sourceFieldKey !== updatedField?.key) return field;

  let date;
  if (!value) return field;
  if (field.value && field.key === updatedField.key) {
    return field;
  }

  if (dependency.actionArgs?.add) {
    const momentFromValue = moment(value).startOf('day');

    if (dependency.actionArgs.unit === 'calendarDay') {
      date = addDaysToDate(momentFromValue, dependency.actionArgs.add);
    } else if (dependency.actionArgs.unit === 'businessDay') {
      date = addBusinessDaysToDate(momentFromValue, dependency.actionArgs.add).format(dateFormats.ISO);
    } else {
      date = value;
    }
  }

  return {
    ...field, value: date, errors: undefined,
  };
}
