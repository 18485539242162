const { VUE_APP_ENV } = process.env;

export default {
  created() {
    this.$envName = VUE_APP_ENV;
  },

  computed: {
    isNonProd() {
      return !['prod', 'prod-echo', 'qa-delta', 'qa-sierra', 'qa-oscar'].includes(this.$envName);
    },

    isQaDelta() {
      return this.$envName === 'qa-delta';
    },

    isDevEnv() {
      return this.$envName === 'dev';
    },
  },
};
