import PORenderer from "@/components/ui/AgGridTable/NewCellRenderers/PORenderer.vue";
// import ActionsRenderer from "@/components/ui/AgGridTable/NewCellRenderers/ActionsRenderer.vue";
import NoRowCellRenderer from "@/components/ui/AgGridTable/CellRenderers/NoRowsCellRenderer.vue";
import OrderStatusRenderer from "@/components/ui/AgGridTable/CellRenderers/OrderStatusRenderer.vue";
import LoadingCellRenderer from "@/components/ui/AgGridTable/CellRenderers/LoadingCellRenderer.vue";
import AuditEventOrderRenderer from "@/components/ui/AgGridTable/CellRenderers/AuditEventOrderRenderer.vue";

import {
  unSortIcon,
  filterIcon,
  sortDesIcon,
  sortAscIcon,
} from "@/components/ui/AgGridTable/icons";

import colors from "@/enums/colors";

import { translateModule } from "@/utils/string/translate";

import { getCellValue } from "@/utils/agGrid";

import { FIELDS_ENUM } from "@/enums/language/fieldsEnum";

const translateFields = translateModule("fields");

const translateAuditEvent = translateModule("pages.auditEvent");

export const AUDIT_EVENT_PAGES = [
  { name: translateAuditEvent("orders"), id: "orders" },
  { name: translateAuditEvent("orderEvents"), id: "orderEvents" },
];

export const COLUMN_KEYS = {
  ORDER_UUID: "entryObjectId",
  PARENT_ORDER_ID: "parentOrderId",
  BASE_ORDER_ID: "baseOrderId",
  ORDER_RECEIVED_DATE: "orderReceivedDate",
  EVENT_CREATED_DATE: "eventCreatedDate",
  CUSTOMER_PURCHASE_ORDER_TYPE: "customerPurchaseOrderType",
  SALES_ORDER_TYPE: "salesOrderType",
  CUSTOMER_PURCHASE_ORDER_NUMBER: "customerPurchaseOrderNumber",
  SOLD_TO_PARTY: "customerSoldToParty",
  EVENT_NAME: "eventName",
  SAP_ORDER_NUMBER: "sapOrderNumber",
  GEO_LOCATION: "geo",
  EVENT_RECEIVED_DATE: "eventCreatedDate",
  EXCEPTION_FLAG: "isExceptionEvent",
  HEADER_OR_LINE: "exceptionLevel",
  SIZE_DETAIL: "impactedSize",
  FIELD_NAME: "primaryEditableKey",
  OLD_VALUE: "oldValue",
  AUGMENTED_VALUE: "newValue",
};

const rawColumnDefs = [
  {
    field: COLUMN_KEYS.ORDER_UUID,
    headerName: translateFields(FIELDS_ENUM.orderUuid),
    lockPosition: "left",
    filter: false,
    resizable: true,
    cellRenderer: AuditEventOrderRenderer,
  },
  // {
  //   field: COLUMN_KEYS.ORDER_UUID,
  //   headerName: translateFields(FIELDS_ENUM.orderUuid),
  //   maxWidth: 240,
  //   resizable: true,
  //   cellRenderer: PORenderer,
  //   cellClass: "home-orders-table__cell",
  // },
  {
    field: COLUMN_KEYS.PARENT_ORDER_ID,
    headerName: translateFields(FIELDS_ENUM.parentOrderId),
  },
  {
    field: COLUMN_KEYS.BASE_ORDER_ID,
    headerName: translateFields(FIELDS_ENUM.baseOrderId),
    // cellRenderer: OrderStatusRenderer,
  },
  {
    field: COLUMN_KEYS.ORDER_RECEIVED_DATE,
    headerName: translateFields(FIELDS_ENUM.receivedDate),
    cellClass: "home-orders-table__cell home-orders-table__cell--right-aligned",
  },
  {
    field: COLUMN_KEYS.EVENT_CREATED_DATE,
    headerName: translateFields(FIELDS_ENUM.latestStatusDate),
    cellClass: "home-orders-table__cell home-orders-table__cell--right-aligned",
  },
  {
    field: COLUMN_KEYS.CUSTOMER_PURCHASE_ORDER_TYPE,
    headerName: translateFields(FIELDS_ENUM.poType),
    width: 100,
  },
  {
    field: COLUMN_KEYS.SALES_ORDER_TYPE,
    headerName: translateFields(FIELDS_ENUM.orderType),
    // cellClass: "home-orders-table__cell home-orders-table__cell--right-aligned",
    // type: "rightAligned",
  },
  {
    field: COLUMN_KEYS.CUSTOMER_PURCHASE_ORDER_NUMBER,
    headerName: translateFields(FIELDS_ENUM.customerPo),
  },
  {
    field: COLUMN_KEYS.SOLD_TO_PARTY,
    headerName: translateFields(FIELDS_ENUM.soldTo),
  },
  {
    field: COLUMN_KEYS.EVENT_NAME,
    headerName: translateFields(FIELDS_ENUM.status),
  },
  {
    field: COLUMN_KEYS.SAP_ORDER_NUMBER,
    headerName: translateFields(FIELDS_ENUM.sapOrderNumber),
    // type: "rightAligned",
  },
  {
    field: COLUMN_KEYS.GEO_LOCATION,
    headerName: translateFields(FIELDS_ENUM.geoLocation),
    // type: "rightAligned",
  },
  // {
  //   width: 50,
  //   maxWidth: 50,
  //   pinned: "right",
  //   suppressMenu: true,
  //   sortable: false,
  //   resizable: false,
  //   cellRendererSelector: () => ({
  //     component: ActionsRenderer,
  //     params: {
  //       actions: [
  //         {
  //           name: translateActions("edit"),
  //           conditions: {
  //             status: [orderStatuses.DRAFT],
  //           },
  //           handler: editHandler,
  //         },
  //         {
  //           name: translateActions("deleteOrder"),
  //           conditions: {
  //             status: [orderStatuses.DRAFT],
  //           },
  //           handler: deleteHandler,
  //         },
  //         {
  //           name: translateActions("errorDetail"),
  //           conditions: {
  //             status: [orderStatuses.FAILED_IN_S4],
  //           },
  //           handler: errorDetailHandler,
  //         },
  //         {
  //           name: translateActions("edit"),
  //           conditions: {
  //             status: [orderStatuses.REWORK],
  //           },
  //           handler: editHandler,
  //         },
  //         {
  //           name: translateActions("deleteOrder"),
  //           conditions: {
  //             status: [orderStatuses.REWORK],
  //           },
  //           handler: deleteHandler,
  //         },
  //         {
  //           name: translateActions("edit"),
  //           conditions: {
  //             status: [orderStatuses.CONTAINS_ERRORS],
  //           },
  //           handler: editHandler,
  //         },
  //         {
  //           name: translateActions("deleteOrder"),
  //           conditions: {
  //             status: [orderStatuses.CONTAINS_ERRORS],
  //           },
  //           handler: deleteHandler,
  //         },
  //         {
  //           name: translateActions("resolve"),
  //           conditions: {
  //             status: [orderStatuses.EXCEPTION],
  //           },
  //           handler: resolveActionHandler,
  //         },
  //         {
  //           name: translateActions("resubmit"),
  //           conditions: {
  //             status: [orderStatuses.SENT_FAILED],
  //           },
  //           handler: resubmitActionHandler,
  //         },
  //         {
  //           name: translateActions("deleteOrder"),
  //           conditions: {
  //             status: [orderStatuses.SENT_FAILED],
  //           },
  //           handler: deleteHandler,
  //         },
  //       ],
  //     },
  //   }),
  // },
];

export const columnDefs = rawColumnDefs.map((def) => ({
  valueGetter: !def.cellRenderer && getCellValue(def.field),
  ...def,
}));

export const renderOptions = {
  rowModelType: "serverSide",
  maxConcurrentDatasourceRequests: 1,
  loadingCellRenderer: LoadingCellRenderer,
  noRowsOverlayComponent: NoRowCellRenderer,
};

export const gridOptions = {
  animateRows: true,
  suppressMenuHide: true,
  suppressCellFocus: true,
  enableCellTextSelection: true,
  rowClass: "home-orders-table__row",
  rowHeight: 54,
  defaultColDef: {
    sortable: false,
    unSortIcon: false,
    cellClass: "home-orders-table__cell",
    suppressMovable: true,
    resizable: true,
  },
  icons: {
    menu: filterIcon(colors.podiumGrey1),
    sortUnSort: unSortIcon(),
    sortAscending: sortAscIcon(),
    sortDescending: sortDesIcon(),
  },
};
