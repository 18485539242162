import i18n from '@/plugins/i18n';

import { FormTemplateValidationRulesMap } from '@/enums/SOC/formTemplate.consts';
import OldSocValidationRulesEnum from '@/enums/validationRules/SOC.js';
import { LineLevelDependencyConditionEnum } from '@/controllers/order/dependencies/line';
import {
  HeaderLevelDependencyActionsEnum,
  HeaderLevelDependencyConditionEnum,
} from '@/enums/SOC/dependencyEnums';
import { LineLevelDependencyActionEnum } from '@/controllers/order/dependencies/line/actions/actionsMap';
import { LineLevelValidationRulesEnum } from '@/controllers/order/validations/line';
import { fieldNames } from '@/enums/singleOrderCreation/fieldNames';
import {
  OTHER_VALUE,
  BUSINESS_PURPOSE_VALUES,
  EVENT_VALUE,
  SEEDING_VALUE,
  INTENDED_PURPOSE_VISIBILITY_VALUES,
  NAME_OF_RECIPIENT_VISIBILITY_VALUES,
} from '@/enums/SOC/businessPurpose';

import {
  sourcingMethodValues,
  dropdownValues,
  RADIO_NO_VALUE,
  RADIO_YES_VALUE,
  radioOptions,
} from '@/enums/validationRules/dropdownValues';

import { setInitialMinDate } from '@/utils/singleOrderCreate';

import { fieldsMaxTextLength, preApprovalConditionFields } from '@/controllers/order/enums/field';

import { translateModule } from '@/utils/string/translate';
import { FIELDS_ENUM } from '@/enums/language/fieldsEnum';

const translateFields = translateModule('fields');
const translateSOC = translateModule('pages.SOC');

const shippingConditionDefaultValue = OldSocValidationRulesEnum.formatRules.shippingCondition.defaultValue;

function getSetOfConditions(payload = {}) {
  return preApprovalConditionFields.map(field => ({
    sourceFieldKey: field,
    conditionType: HeaderLevelDependencyConditionEnum.setOfConditions,
    ...payload,
  }));
}

export default {
  fields: [
    {
      type: 'SelectSingle',
      key: fieldNames.salesOrganization,
      dependencyRules: [
        {
          sourceFieldKey: fieldNames.materialNumber,
          sourceFieldType: 'LineLevel',
          conditionType: LineLevelDependencyConditionEnum.columnHasValue,
          action: HeaderLevelDependencyActionsEnum.setDisabled,
        },
        {
          sourceFieldKey: fieldNames.materialNumber,
          sourceFieldType: 'LineLevel',
          conditionType: LineLevelDependencyConditionEnum.columnHasValue,
          action: HeaderLevelDependencyActionsEnum.setTooltipMessage,
          tooltipMessage: i18n.global.t('pages.SOC.form.disabledDropdownTooltip'),
        },
      ],
      validationRules: [FormTemplateValidationRulesMap.REQUIRED],
      items: [],
      class: 'sales-organization-wrapper header-level__item_fullwidth header-level__item_title',
      testId: 'soc-form-sales-org-field',
      width: '375',
      label: translateFields(FIELDS_ENUM.salesOrg),
      value: '',
      tooltipMessage: '',
      disabled: false,
      hidden: false,
      required: true,
      block: 1,
    },
    {
      type: 'SelectSingle',
      key: fieldNames.sourcingMethod,
      dependencyRules: [
        {
          conditionType: HeaderLevelDependencyConditionEnum.or,
          conditions: [
            {
              sourceFieldKey: fieldNames.salesOrganization,
              conditionType: HeaderLevelDependencyConditionEnum.empty,
            },
            {
              sourceFieldKey: fieldNames.materialNumber,
              sourceFieldType: 'LineLevel',
              conditionType: LineLevelDependencyConditionEnum.columnHasValue,
            },
          ],
          action: HeaderLevelDependencyActionsEnum.setDisabled,
        },
        {
          sourceFieldKey: fieldNames.materialNumber,
          sourceFieldType: 'LineLevel',
          conditionType: LineLevelDependencyConditionEnum.columnHasValue,
          action: HeaderLevelDependencyActionsEnum.setTooltipMessage,
          tooltipMessage: i18n.global.t('pages.SOC.form.disabledDropdownTooltip'),
        },
        {
          sourceFieldKey: fieldNames.salesOrganization,
          conditionType: HeaderLevelDependencyConditionEnum.empty,
          action: HeaderLevelDependencyActionsEnum.setCleared,
        },
      ],
      isValueText: true,
      items: dropdownValues.sourcingMethod,
      class: 'sourcing-method-channel-wrapper',
      testId: 'soc-form-sourcing-method-channel-field',
      width: '375',
      label: translateFields(FIELDS_ENUM.sourcingMethod),
      value: null,
      tooltipMessage: '',
      disabled: false,
      hidden: false,
      required: true,
      block: 1,
    },
    {
      type: 'SelectSingle',
      key: fieldNames.salesChannel,
      dependencyRules: [
        {
          conditionType: HeaderLevelDependencyConditionEnum.or,
          conditions: [
            {
              sourceFieldKey: fieldNames.salesOrganization,
              conditionType: HeaderLevelDependencyConditionEnum.empty,
            },
            {
              sourceFieldKey: fieldNames.materialNumber,
              sourceFieldType: 'LineLevel',
              conditionType: LineLevelDependencyConditionEnum.columnHasValue,
            },
            {
              sourceFieldKey: fieldNames.sourcingMethod,
              conditionType: HeaderLevelDependencyConditionEnum.certainValue,
              value: sourcingMethodValues.AUTO,
            },
          ],
          action: HeaderLevelDependencyActionsEnum.setDisabled,
        },
        {
          sourceFieldKey: fieldNames.materialNumber,
          sourceFieldType: 'LineLevel',
          conditionType: LineLevelDependencyConditionEnum.columnHasValue,
          action: HeaderLevelDependencyActionsEnum.setTooltipMessage,
          tooltipMessage: i18n.global.t('pages.SOC.form.disabledDropdownTooltip'),
        },
        {
          sourceFieldKey: fieldNames.sourcingMethod,
          conditionType: HeaderLevelDependencyConditionEnum.changedValue,
          action: LineLevelDependencyActionEnum.setCleared,
        },
        {
          sourceFieldKey: fieldNames.salesOrganization,
          conditionType: HeaderLevelDependencyConditionEnum.changedValue,
          action: HeaderLevelDependencyActionsEnum.setCleared,
        },
        {
          sourceFieldKey: fieldNames.salesOrganization,
          conditionType: HeaderLevelDependencyConditionEnum.empty,
          action: HeaderLevelDependencyActionsEnum.setCleared,
        },
        {
          sourceFieldKey: fieldNames.salesOrganization,
          conditionType: HeaderLevelDependencyConditionEnum.filled,
          action: HeaderLevelDependencyActionsEnum.setAsyncItems,
        },
        {
          sourceFieldKey: fieldNames.sourcingMethod,
          conditionType: HeaderLevelDependencyConditionEnum.filled,
          action: HeaderLevelDependencyActionsEnum.setAsyncItems,
        },
      ],
      items: [],
      class: 'sales-channel-wrapper',
      testId: 'soc-form-sales-channel-field',
      width: '375',
      label: translateFields(FIELDS_ENUM.channel),
      isValueText: true,
      value: null,
      tooltipMessage: '',
      disabled: true,
      hidden: false,
      required: true,
      block: 1,
      async: true,
    },
    {
      type: 'SoldTo',
      key: fieldNames.soldToNumber,
      validationRules: [FormTemplateValidationRulesMap.REQUIRED],
      dependencyRules: [
        {
          conditionType: HeaderLevelDependencyConditionEnum.or,
          conditions: [
            {
              sourceFieldKey: fieldNames.salesOrganization,
              sourceFieldType: 'HeaderLevel',
              conditionType: HeaderLevelDependencyConditionEnum.empty,
            },
            {
              sourceFieldKey: fieldNames.materialNumber,
              sourceFieldType: 'LineLevel',
              conditionType: LineLevelDependencyConditionEnum.columnHasValue,
            },
          ],
          action: HeaderLevelDependencyActionsEnum.setDisabled,
        },
        {
          sourceFieldKey: fieldNames.materialNumber,
          sourceFieldType: 'LineLevel',
          conditionType: LineLevelDependencyConditionEnum.columnHasValue,
          action: HeaderLevelDependencyActionsEnum.setTooltipMessage,
          tooltipMessage: i18n.global.t('pages.SOC.form.disabledDropdownTooltip'),
        },
        {
          sourceFieldKey: fieldNames.salesOrganization,
          conditionType: HeaderLevelDependencyConditionEnum.changedValue,
          action: HeaderLevelDependencyActionsEnum.setParentValue,
        },
        {
          sourceFieldKey: fieldNames.salesOrganization,
          conditionType: HeaderLevelDependencyConditionEnum.filled,
          action: HeaderLevelDependencyActionsEnum.setParentValue,
        },
        {
          sourceFieldKey: fieldNames.salesOrganization,
          conditionType: HeaderLevelDependencyConditionEnum.empty,
          action: HeaderLevelDependencyActionsEnum.setCleared,
        },
      ],
      class: 'sold-to-wrapper',
      width: '375',
      label: translateFields(FIELDS_ENUM.soldTo),
      name: 'Sold To',
      value: null,
      tooltipMessage: '',
      parentValue: '',
      disabled: true,
      hidden: false,
      required: true,
      block: 1,
    },
    {
      type: 'ShipTo',
      key: fieldNames.shipToNumber,
      validationRules: [FormTemplateValidationRulesMap.REQUIRED],
      dependencyRules: [
        {
          conditionType: HeaderLevelDependencyConditionEnum.or,
          conditions: [
            {
              sourceFieldKey: fieldNames.salesOrganization,
              sourceFieldType: 'HeaderLevel',
              conditionType: HeaderLevelDependencyConditionEnum.empty,
            },
            {
              sourceFieldKey: fieldNames.soldToNumber,
              sourceFieldType: 'HeaderLevel',
              conditionType: HeaderLevelDependencyConditionEnum.empty,
            },
            {
              sourceFieldKey: fieldNames.materialNumber,
              sourceFieldType: 'LineLevel',
              conditionType: LineLevelDependencyConditionEnum.columnHasValue,
            },
          ],
          action: HeaderLevelDependencyActionsEnum.setDisabled,
        },
        {
          conditionType: HeaderLevelDependencyConditionEnum.or,
          conditions: [
            {
              sourceFieldKey: fieldNames.salesOrganization,
              conditionType: HeaderLevelDependencyConditionEnum.empty,
            },
            {
              sourceFieldKey: fieldNames.soldToNumber,
              conditionType: HeaderLevelDependencyConditionEnum.empty,
            },
          ],
          action: HeaderLevelDependencyActionsEnum.setCleared,
        },
        {
          sourceFieldKey: fieldNames.materialNumber,
          sourceFieldType: 'LineLevel',
          conditionType: LineLevelDependencyConditionEnum.columnHasValue,
          action: HeaderLevelDependencyActionsEnum.setTooltipMessage,
          tooltipMessage: i18n.global.t('pages.SOC.form.disabledDropdownTooltip'),
        },
        {
          sourceFieldKey: fieldNames.soldToNumber,
          conditionType: HeaderLevelDependencyConditionEnum.changedValue,
          action: HeaderLevelDependencyActionsEnum.setItems,
        },
      ],
      class: 'ship-to-wrapper',
      width: '375',
      label: translateFields(FIELDS_ENUM.shipTo),
      name: 'Ship To',
      value: '',
      tooltipMessage: '',
      parentValue: {},
      touched: false,
      disabled: true,
      hidden: false,
      required: true,
      block: 1,
    },
    {
      type: 'Input',
      key: fieldNames.customerPONumber,
      validationRules: [
        FormTemplateValidationRulesMap.REQUIRED,
        FormTemplateValidationRulesMap.MAXLENGTH,
        FormTemplateValidationRulesMap.CAPITALIZE,
      ],
      maxLength: 35,
      dependencyRules: [
        {
          sourceFieldKey: fieldNames.salesOrganization,
          conditionType: HeaderLevelDependencyConditionEnum.empty,
          action: HeaderLevelDependencyActionsEnum.setDisabled,
        },
      ],
      class: 'customer-po-number-wrapper',
      testId: 'soc-form-customer-po-number-field',
      width: '375',
      label: translateFields(FIELDS_ENUM.purchaseOrderNumber),
      value: null,
      disabled: false,
      hidden: false,
      required: true,
      block: 1,
    },
    {
      type: 'Datepicker',
      key: fieldNames.crd,
      validationRules: [FormTemplateValidationRulesMap.REQUIRED],
      dependencyRules: [
        {
          sourceFieldKey: fieldNames.salesOrganization,
          conditionType: HeaderLevelDependencyConditionEnum.empty,
          action: HeaderLevelDependencyActionsEnum.setDisabled,
        },
      ],
      class: 'crd-wrapper',
      testId: 'soc-form-crd-field',
      width: '375',
      minDate: setInitialMinDate({
        refDate: new Date(),
      }),
      label: translateFields(FIELDS_ENUM.crd),
      value: null,
      disabled: false,
      hidden: false,
      required: true,
      block: 1,
    },
    {
      type: 'Datepicker',
      key: fieldNames.cancelDate,
      validationRules: [FormTemplateValidationRulesMap.REQUIRED],
      dependencyRules: [
        {
          conditionType: HeaderLevelDependencyConditionEnum.or,
          conditions: [
            {
              sourceFieldKey: fieldNames.salesOrganization,
              conditionType: HeaderLevelDependencyConditionEnum.empty,
            },
            {
              sourceFieldKey: fieldNames.crd,
              conditionType: HeaderLevelDependencyConditionEnum.empty,
            },
          ],
          action: HeaderLevelDependencyActionsEnum.setDisabled,
        },
        {
          sourceFieldKey: fieldNames.crd,
          conditionType: HeaderLevelDependencyConditionEnum.changedValue,
          action: HeaderLevelDependencyActionsEnum.setMinDate,
          actionArgs: {
            add: 6,
            unit: 'calendarDay',
          },
        },
        {
          sourceFieldKey: fieldNames.crd,
          conditionType: HeaderLevelDependencyConditionEnum.changedValue,
          action: HeaderLevelDependencyActionsEnum.setDateValue,
          actionArgs: {
            add: 30,
            unit: 'calendarDay',
          },
        },
      ],
      class: 'cancel-date-wrapper',
      testId: 'soc-form-cancel-date-field',
      width: '375',
      label: translateFields(FIELDS_ENUM.cancelDate),
      minDate: null,
      value: null,
      disabled: false,
      hidden: false,
      required: true,
      block: 1,
    },
    {
      type: 'SelectSingle',
      key: fieldNames.shippingCondition,
      dependencyRules: [
        {
          sourceFieldKey: fieldNames.salesOrganization,
          conditionType: HeaderLevelDependencyConditionEnum.empty,
          action: 'setDisabled',
        },
      ],
      items: [
        ...dropdownValues.shippingCondition,
      ],
      class: 'shipping-condition-method-channel-wrapper',
      testId: 'soc-form-shipping-condition',
      width: '375',
      label: translateFields(FIELDS_ENUM.shippingCondition),
      value: shippingConditionDefaultValue,
      disabled: false,
      hidden: false,
      required: false,
      block: 1,
    },
    {
      type: 'NameOfOrderer',
      key: fieldNames.nameOfOrderer,
      validationRules: [
        FormTemplateValidationRulesMap.MAXLENGTH,
        FormTemplateValidationRulesMap.CHECK_AUTOCOMPLETE,
      ],
      maxLength: 35,
      dependencyRules: [
        {
          sourceFieldKey: fieldNames.salesOrganization,
          conditionType: HeaderLevelDependencyConditionEnum.empty,
          action: 'setDisabled',
        },
      ],
      class: 'name-of-orderer-wrapper',
      testId: 'soc-form-name-of-orderer-field',
      width: '375',
      label: translateFields(FIELDS_ENUM.nameOfOrderer),
      value: null,
      fetchInitialValue: {
        vuexAction: 'SOC/fetchCurrentUserEmail',
        vuexActionArgs: null,
      },
      items: [],
      disabled: false,
      hidden: false,
      required: false,
      block: 1,
    },
    {
      type: 'Input',
      key: fieldNames.markFor,
      validationRules: [
        FormTemplateValidationRulesMap.MAXLENGTH,
        FormTemplateValidationRulesMap.ONLY_DIGITS,
      ],
      maxLength: 10,
      dependencyRules: [
        {
          sourceFieldKey: fieldNames.salesOrganization,
          conditionType: HeaderLevelDependencyConditionEnum.empty,
          action: 'setDisabled',
        },
      ],
      class: 'mark-for-wrapper',
      testId: 'soc-form-mark-for-field',
      width: '375',
      label: translateFields(FIELDS_ENUM.markFor),
      value: null,
      disabled: false,
      hidden: false,
      required: false,
      block: 1,
    },
    {
      type: 'Input',
      key: fieldNames.internalOrderReferenceNumber,
      validationRules: [
        FormTemplateValidationRulesMap.MAXLENGTH,
        FormTemplateValidationRulesMap.ONLY_DIGITS_LETTERS,
      ],
      maxLength: 20,
      dependencyRules: [
        {
          sourceFieldKey: fieldNames.salesOrganization,
          conditionType: HeaderLevelDependencyConditionEnum.empty,
          action: 'setDisabled',
        },
        {
          sourceFieldKey: fieldNames.soldToNumber,
          conditionType: HeaderLevelDependencyConditionEnum.isNotSportsMarketing,
          action: 'setRequired',
        },
      ],
      class: 'internal-order-reference-number-wrapper',
      testId: 'soc-form-internal-order-reference-number-field',
      width: '375',
      label: translateFields(FIELDS_ENUM.internalOrderNumber),
      value: null,
      disabled: false,
      hidden: false,
      required: false,
      block: 1,
    },
    {
      type: 'SelectSingle',
      key: fieldNames.deliveryBlock,
      dependencyRules: [
        {
          sourceFieldKey: fieldNames.salesOrganization,
          conditionType: HeaderLevelDependencyConditionEnum.empty,
          action: 'setDisabled',
        },
      ],
      items: [...dropdownValues.deliveryBlock],
      class: 'delivery-block-channel-wrapper',
      testId: 'soc-form-delivery-block-channel-field',
      width: '375',
      label: translateFields(FIELDS_ENUM.deliveryBlock),
      value: null,
      disabled: false,
      hidden: false,
      required: false,
      block: 1,
    },
    {
      type: 'SelectSingle',
      key: fieldNames.managersName,
      dependencyRules: [
        {
          conditionType: HeaderLevelDependencyConditionEnum.or,
          conditions: [
            {
              sourceFieldKey: fieldNames.salesOrganization,
              conditionType: HeaderLevelDependencyConditionEnum.empty,
            },
            {
              sourceFieldKey: fieldNames.soldToNumber,
              conditionType: HeaderLevelDependencyConditionEnum.isSportsMarketing,
            },
          ],
          action: 'setDisabled',
        },
        {
          sourceFieldKey: fieldNames.salesOrganization,
          conditionType: HeaderLevelDependencyConditionEnum.filled,
          sourceFieldType: 'HeaderLevel',
          action: 'setNewManagers',
        },
        {
          sourceFieldKey: fieldNames.onBehalfOf,
          conditionType: HeaderLevelDependencyConditionEnum.changedValue,
          sourceFieldType: 'HeaderLevel',
          action: 'setNewManagers',
        },
        {
          sourceFieldKey: fieldNames.soldToNumber,
          conditionType: HeaderLevelDependencyConditionEnum.isSportsMarketing,
          sourceFieldType: 'HeaderLevel',
          action: HeaderLevelDependencyActionsEnum.setTooltipMessage,
          tooltipMessage: i18n.global.t('pages.SOC.form.managersBypassTooltip'),
        },
      ],
      validationRules: [FormTemplateValidationRulesMap.REQUIRED],
      items: [
        {
          text: '',
          value: '',
        },
      ],
      class: 'managers-name-wrapper',
      testId: 'soc-form-managers-name-field',
      width: '375',
      label: translateFields(FIELDS_ENUM.managersName),
      value: '',
      tooltipMessage: '',
      // TODO: move async attr to dependencies array
      async: true,
      disabled: true,
      hidden: false,
      required: true,
      block: 1,
    },
    {
      type: 'Input',
      key: fieldNames.onBehalfOf,
      validationRules: [
        FormTemplateValidationRulesMap.MAXLENGTH,
        FormTemplateValidationRulesMap.CHECK_ON_BEHALF_OF,
      ],
      maxLength: 255,
      dependencyRules: [
        {
          sourceFieldKey: fieldNames.salesOrganization,
          conditionType: HeaderLevelDependencyConditionEnum.empty,
          action: 'setDisabled',
        },
      ],
      class: 'on-behalf-of-wrapper',
      testId: 'soc-form-on-behalf-of-field',
      width: '375',
      label: translateFields(FIELDS_ENUM.onBehalfOf),
      value: '',
      email: '',
      async: true,
      disabled: true,
      hidden: false,
      required: false,
      block: 1,
    },
    {
      type: 'SelectSingle',
      key: fieldNames.departmentCode,
      validationRules: [
        FormTemplateValidationRulesMap.MAXLENGTH,
        FormTemplateValidationRulesMap.ONLY_DIGITS,
      ],
      maxLength: 10,
      dependencyRules: [
        {
          sourceFieldKey: fieldNames.soldToNumber,
          conditionType: HeaderLevelDependencyConditionEnum.changedValue,
          action: HeaderLevelDependencyActionsEnum.setItems,
        },
        {
          conditionType: HeaderLevelDependencyConditionEnum.or,
          conditions: [
            {
              sourceFieldKey: fieldNames.soldToNumber,
              conditionType: HeaderLevelDependencyConditionEnum.empty,
            },
            {
              sourceFieldKey: fieldNames.salesOrganization,
              conditionType: HeaderLevelDependencyConditionEnum.empty,
            },
          ],
          action: HeaderLevelDependencyActionsEnum.setCleared,
        },
        {
          conditionType: HeaderLevelDependencyConditionEnum.or,
          conditions: [
            {
              sourceFieldKey: fieldNames.soldToNumber,
              conditionType: HeaderLevelDependencyConditionEnum.empty,
            },
            {
              sourceFieldKey: fieldNames.salesOrganization,
              conditionType: HeaderLevelDependencyConditionEnum.empty,
            },
          ],
          action: HeaderLevelDependencyActionsEnum.setDisabled,
        },
      ],
      class: 'department-code-wrapper',
      testId: 'soc-form-department-code-field',
      width: '375',
      label: translateFields(FIELDS_ENUM.departmentCode),
      value: null,
      disabled: false,
      hidden: false,
      required: false,
      block: 1,
    },
    {
      type: 'Checkbox',
      key: fieldNames.reasonCodeAuthorized,
      validationRules: [FormTemplateValidationRulesMap.REQUIRED],
      dependencyRules: [
        {
          sourceFieldKey: fieldNames.salesOrganization,
          conditionType: HeaderLevelDependencyConditionEnum.empty,
          action: 'setDisabled',
        },
      ],
      class: 'confirmation-checkbox-wrapper header-level__item_fullwidth',
      testId: 'soc-form-reason-code-checkbox-field',
      width: '100%',
      label: translateSOC('form.reasonCodeAuthorized'),
      value: false,
      disabled: true,
      hidden: false,
      required: false,
      block: 1,
    },
    {
      type: 'SelectSingle',
      key: fieldNames.reasonCode,
      validationRules: [FormTemplateValidationRulesMap.REQUIRED],
      dependencyRules: [
        {
          conditionType: HeaderLevelDependencyConditionEnum.or,
          conditions: [
            {
              sourceFieldKey: fieldNames.salesOrganization,
              conditionType: HeaderLevelDependencyConditionEnum.empty,
            },
            {
              sourceFieldKey: fieldNames.reasonCodeAuthorized,
              conditionType: HeaderLevelDependencyConditionEnum.empty,
            },
          ],
          action: 'setDisabled',
        },
        {
          sourceFieldKey: fieldNames.reasonCodeAuthorized,
          conditionType: HeaderLevelDependencyConditionEnum.filled,
          action: 'setRequired',
        },
        {
          sourceFieldKey: fieldNames.reasonCodeAuthorized,
          conditionType: HeaderLevelDependencyConditionEnum.empty,
          action: 'setCleared',
        },
      ],
      items: [],
      class: 'reason-code-wrapper',
      testId: 'soc-form-reason-code-field',
      width: '375',
      label: translateFields(FIELDS_ENUM.reasonCode),
      value: null,
      disabled: false,
      hidden: false,
      required: true,
      block: 1,
    },
    {
      type: 'Textarea',
      key: fieldNames.nikeInternalComments,
      validationRules: [
        FormTemplateValidationRulesMap.REQUIRED,
        FormTemplateValidationRulesMap.MAXLENGTH,
      ],
      dependencyRules: [
        {
          sourceFieldKey: fieldNames.salesOrganization,
          conditionType: HeaderLevelDependencyConditionEnum.empty,
          action: 'setDisabled',
        },
        {
          conditionType: HeaderLevelDependencyConditionEnum.or,
          conditions: [
            {
              sourceFieldKey: fieldNames.reasonCode,
              conditionType: HeaderLevelDependencyConditionEnum.empty,
            },
            {
              sourceFieldKey: fieldNames.reasonCodeAuthorized,
              conditionType: HeaderLevelDependencyConditionEnum.empty,
            },
          ],
          action: 'clearRequired',
        },
      ],
      class: 'nike-internal-comments-wrapper header-level__item_fullwidth',
      testId: 'soc-form-nike-internal-comments-field',
      width: '782',
      label: translateFields(FIELDS_ENUM.nikeInternalComments),
      maxLength: fieldsMaxTextLength.nikeInternalCommentsLength,
      value: null,
      disabled: false,
      hidden: false,
      required: false,
      block: 1,
    },
    {
      type: 'BaseRadioGroup',
      key: fieldNames.addressOverride,
      validationRules: [],
      dependencyRules: [
        {
          sourceFieldKey: fieldNames.salesOrganization,
          conditionType: HeaderLevelDependencyConditionEnum.empty,
          action: 'setDisabled',
        },
      ],
      class: 'address-override-wrapper header-level__item_fullwidth',
      label: translateFields(FIELDS_ENUM.addressOverride),
      tooltip: 'For returns the address override is where the return needs to be picked up from if not the defaulted ship to address',
      value: RADIO_NO_VALUE,
      radioItems: radioOptions,
      disabled: false,
      hidden: false,
      required: false,
      block: 1,
    },
    {
      type: 'Input',
      key: fieldNames.forwardingAgent,
      validationRules: [
        FormTemplateValidationRulesMap.MAXLENGTH,
        FormTemplateValidationRulesMap.ONLY_DIGITS_LETTERS,
      ],
      maxLength: 10,
      dependencyRules: [
        {
          sourceFieldKey: fieldNames.addressOverride,
          conditionType: HeaderLevelDependencyConditionEnum.matchingValue,
          conditionArgs: {
            values: [RADIO_NO_VALUE],
          },
          action: 'setHidden',
        },
      ],
      class: 'forwarding-agent-wrapper',
      width: '375',
      label: translateFields(FIELDS_ENUM.forwardingAgent),
      value: null,
      disabled: false,
      hidden: false,
      required: false,
      block: 1,
    },
    {
      type: 'Input',
      key: fieldNames.transportationZone,
      validationRules: [
        FormTemplateValidationRulesMap.MAXLENGTH,
        FormTemplateValidationRulesMap.ONLY_DIGITS_LETTERS,
      ],
      maxLength: 10,
      dependencyRules: [
        {
          sourceFieldKey: fieldNames.addressOverride,
          conditionType: HeaderLevelDependencyConditionEnum.matchingValue,
          conditionArgs: {
            values: [RADIO_NO_VALUE],
          },
          action: 'setHidden',
        },
      ],
      class: 'transportation-zone-wrapper',
      width: '375',
      label: translateFields(FIELDS_ENUM.transportationZone),
      value: null,
      disabled: false,
      hidden: false,
      required: false,
      block: 1,
    },
    {
      type: 'Input',
      key: fieldNames.name1,
      validationRules: [
        FormTemplateValidationRulesMap.MAXLENGTH,
        FormTemplateValidationRulesMap.ONLY_DIGITS_LETTERS_SPACES,
      ],
      maxLength: 40,
      dependencyRules: [
        {
          sourceFieldKey: fieldNames.addressOverride,
          conditionType: HeaderLevelDependencyConditionEnum.matchingValue,
          conditionArgs: {
            values: [RADIO_NO_VALUE],
          },
          action: 'setHidden',
        },
        {
          sourceFieldKey: fieldNames.addressOverride,
          conditionType: HeaderLevelDependencyConditionEnum.matchingValue,
          conditionArgs: {
            values: [RADIO_YES_VALUE],
          },
          action: 'setRequired',
        },
      ],
      class: 'name-wrapper',
      width: '375',
      label: translateFields(FIELDS_ENUM.name),
      value: null,
      disabled: false,
      hidden: false,
      required: false,
      block: 1,
    },
    {
      type: 'Input',
      key: fieldNames.name2,
      validationRules: [
        FormTemplateValidationRulesMap.MAXLENGTH,
        FormTemplateValidationRulesMap.ONLY_DIGITS_LETTERS_SPACES,
      ],
      maxLength: 40,
      dependencyRules: [
        {
          sourceFieldKey: fieldNames.addressOverride,
          conditionType: HeaderLevelDependencyConditionEnum.matchingValue,
          conditionArgs: {
            values: [RADIO_NO_VALUE],
          },
          action: 'setHidden',
        },
      ],
      class: 'name-co-wrapper',
      width: '375',
      label: translateFields(FIELDS_ENUM.nameCO),
      value: null,
      disabled: false,
      hidden: false,
      required: false,
      block: 1,
    },
    {
      type: 'Input',
      key: fieldNames.street1,
      validationRules: [FormTemplateValidationRulesMap.MAXLENGTH],
      maxLength: 60,
      dependencyRules: [
        {
          sourceFieldKey: fieldNames.addressOverride,
          conditionType: HeaderLevelDependencyConditionEnum.matchingValue,
          conditionArgs: {
            values: [RADIO_NO_VALUE],
          },
          action: 'setHidden',
        },
        {
          sourceFieldKey: fieldNames.addressOverride,
          conditionType: HeaderLevelDependencyConditionEnum.matchingValue,
          conditionArgs: {
            values: [RADIO_YES_VALUE],
          },
          action: 'setRequired',
        },
      ],
      class: 'street-number-wrapper',
      width: '375',
      label: translateFields(FIELDS_ENUM.streetNumberName),
      value: null,
      disabled: false,
      hidden: false,
      required: false,
      block: 1,
    },
    {
      type: 'Input',
      key: fieldNames.street2,
      validationRules: [FormTemplateValidationRulesMap.MAXLENGTH],
      maxLength: 40,
      dependencyRules: [
        {
          sourceFieldKey: fieldNames.addressOverride,
          conditionType: HeaderLevelDependencyConditionEnum.matchingValue,
          conditionArgs: {
            values: [RADIO_NO_VALUE],
          },
          action: 'setHidden',
        },
      ],
      class: 'shop-wrapper',
      width: '375',
      label: translateFields(FIELDS_ENUM.shopFloorSuiteEtc),
      value: null,
      disabled: false,
      hidden: false,
      required: false,
      block: 1,
    },
    {
      type: 'Input',
      key: fieldNames.postalCode,
      validationRules: [
        FormTemplateValidationRulesMap.MAXLENGTH,
        FormTemplateValidationRulesMap.ONLY_DIGITS_LETTERS_DASH_SPACES,
      ],
      maxLength: 10,
      dependencyRules: [
        {
          sourceFieldKey: fieldNames.addressOverride,
          conditionType: HeaderLevelDependencyConditionEnum.matchingValue,
          conditionArgs: {
            values: [RADIO_NO_VALUE],
          },
          action: 'setHidden',
        },
        {
          sourceFieldKey: fieldNames.addressOverride,
          conditionType: HeaderLevelDependencyConditionEnum.matchingValue,
          conditionArgs: {
            values: [RADIO_YES_VALUE],
          },
          action: 'setRequired',
        },
      ],
      class: 'postal-code-wrapper',
      width: '375',
      label: translateFields(FIELDS_ENUM.postalCode),
      value: null,
      disabled: false,
      hidden: false,
      required: false,
      block: 1,
    },
    {
      type: 'Input',
      key: fieldNames.city,
      validationRules: [
        FormTemplateValidationRulesMap.MAXLENGTH,
        FormTemplateValidationRulesMap.ONLY_LETTERS_SPACES,
      ],
      maxLength: 40,
      dependencyRules: [
        {
          sourceFieldKey: fieldNames.addressOverride,
          conditionType: HeaderLevelDependencyConditionEnum.matchingValue,
          conditionArgs: {
            values: [RADIO_NO_VALUE],
          },
          action: 'setHidden',
        },
        {
          sourceFieldKey: fieldNames.addressOverride,
          conditionType: HeaderLevelDependencyConditionEnum.matchingValue,
          conditionArgs: {
            values: [RADIO_YES_VALUE],
          },
          action: 'setRequired',
        },
      ],
      class: 'city-wrapper',
      width: '375',
      label: translateFields(FIELDS_ENUM.city),
      value: null,
      disabled: false,
      hidden: false,
      required: false,
      block: 1,
    },
    {
      type: 'SelectSingle',
      key: fieldNames.country,
      dependencyRules: [
        {
          sourceFieldKey: fieldNames.addressOverride,
          conditionType: HeaderLevelDependencyConditionEnum.matchingValue,
          conditionArgs: {
            values: [RADIO_NO_VALUE],
          },
          action: 'setHidden',
        },
        {
          sourceFieldKey: fieldNames.addressOverride,
          conditionType: HeaderLevelDependencyConditionEnum.matchingValue,
          conditionArgs: {
            values: [RADIO_YES_VALUE],
          },
          action: 'setRequired',
        },
      ],
      items: [],
      class: 'country-wrapper',
      width: '375',
      label: translateFields(FIELDS_ENUM.country),
      isValueText: true,
      value: null,
      disabled: false,
      hidden: false,
      required: true,
      block: 1,
    },
    {
      type: 'SelectSingle',
      key: fieldNames.region,
      validationRules: [],
      maxLength: 3,
      dependencyRules: [
        {
          sourceFieldKey: fieldNames.addressOverride,
          conditionType: HeaderLevelDependencyConditionEnum.matchingValue,
          conditionArgs: {
            values: [RADIO_NO_VALUE],
          },
          action: HeaderLevelDependencyActionsEnum.setHidden,
        },
        {
          sourceFieldKey: fieldNames.addressOverride,
          conditionType: HeaderLevelDependencyConditionEnum.matchingValue,
          conditionArgs: {
            values: [RADIO_YES_VALUE],
          },
          action: HeaderLevelDependencyActionsEnum.setRequired,
        },
        {
          sourceFieldKey: fieldNames.country,
          conditionType: HeaderLevelDependencyConditionEnum.filled,
          action: HeaderLevelDependencyActionsEnum.setAsyncItems,
        },
        {
          sourceFieldKey: fieldNames.country,
          conditionType: HeaderLevelDependencyConditionEnum.empty,
          action: HeaderLevelDependencyActionsEnum.setDisabled,
        },
        {
          conditionType: HeaderLevelDependencyConditionEnum.or,
          conditions: [
            {
              sourceFieldKey: fieldNames.country,
              conditionType: HeaderLevelDependencyConditionEnum.empty,
            },
            {
              sourceFieldKey: fieldNames.country,
              conditionType: HeaderLevelDependencyConditionEnum.changedValue,
            },
          ],
          action: HeaderLevelDependencyActionsEnum.setCleared,
        },
      ],
      class: 'region-wrapper',
      width: '375',
      label: translateFields(FIELDS_ENUM.region),
      isValueText: true,
      value: null,
      items: [],
      async: true,
      disabled: false,
      hidden: true,
      required: false,
      block: 1,
    },
    {
      type: 'Input',
      key: fieldNames.district,
      validationRules: [
        FormTemplateValidationRulesMap.MAXLENGTH,
        FormTemplateValidationRulesMap.ONLY_DIGITS_LETTERS_SPACES,
      ],
      maxLength: 40,
      dependencyRules: [
        {
          sourceFieldKey: fieldNames.addressOverride,
          conditionType: HeaderLevelDependencyConditionEnum.matchingValue,
          conditionArgs: {
            values: [RADIO_NO_VALUE],
          },
          action: 'setHidden',
        },
      ],
      class: 'district-wrapper',
      width: '375',
      label: translateFields(FIELDS_ENUM.district),
      value: null,
      disabled: false,
      hidden: false,
      required: false,
      block: 1,
    },
    {
      type: 'Input',
      key: fieldNames.telephone,
      validationRules: [FormTemplateValidationRulesMap.MAXLENGTH],
      maxLength: 30,
      dependencyRules: [
        {
          sourceFieldKey: fieldNames.addressOverride,
          conditionType: HeaderLevelDependencyConditionEnum.matchingValue,
          conditionArgs: {
            values: [RADIO_NO_VALUE],
          },
          action: 'setHidden',
        },
      ],
      class: 'telephone-wrapper',
      width: '375',
      label: translateFields(FIELDS_ENUM.telephone),
      value: null,
      disabled: false,
      hidden: false,
      required: false,
      block: 1,
    },
    {
      type: 'BaseRadioGroup',
      key: fieldNames.paDepartment,
      validationRules: [FormTemplateValidationRulesMap.REQUIRED],
      dependencyRules: [
        {
          sourceFieldKey: fieldNames.salesOrganization,
          conditionType: HeaderLevelDependencyConditionEnum.empty,
          action: HeaderLevelDependencyActionsEnum.setDisabled,
        },
      ],
      class: 'pa-department-wrapper header-level__item_fullwidth',
      testId: 'soc-form-pa-department-field',
      width: '100%',
      label: translateSOC('form.paDepartment'),
      value: RADIO_NO_VALUE,
      radioItems: radioOptions,
      default: RADIO_NO_VALUE,
      required: false,
      disabled: false,
      hidden: false,
      block: 2,
    },
    {
      type: 'BaseRadioGroup',
      key: fieldNames.paGovernmentOfficial,
      validationRules: [FormTemplateValidationRulesMap.REQUIRED],
      dependencyRules: [
        {
          sourceFieldKey: fieldNames.salesOrganization,
          conditionType: HeaderLevelDependencyConditionEnum.empty,
          action: HeaderLevelDependencyActionsEnum.setDisabled,
        },
      ],
      class: 'pa-government-official-wrapper header-level__item_fullwidth',
      testId: 'soc-form-pa-government-official-field',
      width: '100%',
      value: RADIO_YES_VALUE,
      label: translateSOC('form.paGoverment'),
      radioItems: radioOptions,
      default: RADIO_YES_VALUE,
      tooltip: 'Government Official: means any appointed, elected, or career employee of a government agency, department, embassy, or other governmental entity, as well as politicians, political candidates, party officials, members of a ruling or royal family, and employees of state-owned or controlled organizations, and employees of public international organizations. Family members, close friends, agents, business partners, and charities of government officials are also considered Government Officials.',
      required: false,
      disabled: true,
      hidden: false,
      block: 2,
    },
    {
      type: 'BaseRadioGroup',
      key: fieldNames.paExternalGift,
      validationRules: [],
      dependencyRules: [
        {
          sourceFieldKey: fieldNames.salesOrganization,
          conditionType: HeaderLevelDependencyConditionEnum.empty,
          action: HeaderLevelDependencyActionsEnum.setDisabled,
        },
      ],
      class: 'pa-external-gift-wrapper header-level__item_fullwidth',
      testId: 'soc-form-pa-external-gift-field',
      width: '100%',
      label: translateSOC('form.paExternalGift'),
      tooltip: null,
      default: RADIO_YES_VALUE,
      value: RADIO_YES_VALUE,
      radioItems: radioOptions,
      disabled: true,
      hidden: false,
      required: false,
      block: 2,
    },
    {
      type: 'BaseRadioGroup',
      key: fieldNames.paInternalGift,
      validationRules: [],
      dependencyRules: [
        {
          sourceFieldKey: fieldNames.salesOrganization,
          conditionType: HeaderLevelDependencyConditionEnum.empty,
          action: HeaderLevelDependencyActionsEnum.setDisabled,
        },
      ],
      class: 'pa-internal-gift-wrapper header-level__item_fullwidth',
      testId: 'soc-form-pa-internal-gift-field',
      width: '100%',
      label: translateSOC('form.paInternalGift'),
      tooltip: null,
      default: RADIO_YES_VALUE,
      value: RADIO_YES_VALUE,
      radioItems: radioOptions,
      disabled: true,
      hidden: false,
      required: false,
      block: 2,
    },
    {
      type: 'BaseRadioGroup',
      key: fieldNames.paDisclosureTool,
      validationRules: [],
      dependencyRules: [
        {
          sourceFieldKey: fieldNames.salesOrganization,
          conditionType: HeaderLevelDependencyConditionEnum.empty,
          action: HeaderLevelDependencyActionsEnum.setDisabled,
        },
        ...getSetOfConditions({
          action: HeaderLevelDependencyActionsEnum.setVisible,
        }),
      ],
      class: 'pa-disclosure-tool-wrapper header-level__item_fullwidth',
      testId: 'soc-form-pa-disclosure-tool-field',
      width: '100%',
      label: translateSOC('form.paDisclosureTool'),
      tooltip: null,
      default: RADIO_YES_VALUE,
      value: RADIO_YES_VALUE,
      radioItems: radioOptions,
      disabled: true,
      hidden: true,
      required: false,
      block: 2,
    },
    {
      type: 'Textblock',
      key: fieldNames.paNotAllowed,
      validationRules: [],
      dependencyRules: [
        {
          sourceFieldKey: fieldNames.salesOrganization,
          conditionType: HeaderLevelDependencyConditionEnum.empty,
          action: HeaderLevelDependencyActionsEnum.setDisabled,
        },
        ...getSetOfConditions({
          action: HeaderLevelDependencyActionsEnum.setVisible,
        }),
      ],
      class: 'pa-not-allowed-wrapper header-level__item_fullwidth now-label-1',
      testId: 'soc-form-pa-not-allowed-field',
      width: '100%',
      label: translateSOC('form.paNotAllowed'),
      tooltip: null,
      default: RADIO_YES_VALUE,
      value: RADIO_YES_VALUE,
      radioItems: radioOptions,
      disabled: true,
      hidden: true,
      required: false,
      block: 2,
    },
    {
      type: 'Input',
      key: fieldNames.paRefNumber,
      validationRules: [
        FormTemplateValidationRulesMap.MAXLENGTH,
        FormTemplateValidationRulesMap.ONLY_DIGITS_LETTERS,
      ],
      maxLength: 128,
      dependencyRules: [
        {
          sourceFieldKey: fieldNames.salesOrganization,
          conditionType: HeaderLevelDependencyConditionEnum.empty,
          action: HeaderLevelDependencyActionsEnum.setDisabled,
        },
        {
          conditionType: HeaderLevelDependencyConditionEnum.or,
          conditions: [
            ...getSetOfConditions(),
            {
              sourceFieldKey: fieldNames.paContactEco,
              conditionType: HeaderLevelDependencyConditionEnum.visible,
            },
          ],
          action: HeaderLevelDependencyActionsEnum.setHidden,
        },
      ],
      class: 'pa-reference-number-wrapper',
      testId: 'soc-form-pa-reference-number-field',
      width: '375',
      label: translateFields(FIELDS_ENUM.preApprovalReferenceNumber),
      value: null,
      disabled: true,
      hidden: true,
      forcedUpdate: true,
      required: true,
      block: 2,
    },
    {
      type: 'Textblock',
      key: fieldNames.paContactEco,
      validationRules: [],
      dependencyRules: [
        {
          conditionType: HeaderLevelDependencyConditionEnum.and,
          conditions: [
            ...getSetOfConditions({
              key: fieldNames.paDisclosureTool,
            }),
            {
              sourceFieldKey: fieldNames.paDisclosureTool,
              conditionType: HeaderLevelDependencyConditionEnum.certainValue,
              value: RADIO_NO_VALUE,
            },
          ],
          action: HeaderLevelDependencyActionsEnum.setVisible,
        },
      ],
      class: 'pa-not-contact-eco-wrapper header-level__item_fullwidth now-label-1',
      testId: 'soc-form-pa-contact-eco-field',
      width: '100%',
      label: 'Please Contact eco@nike.com to get pre-approval via <a class="active-link--text" target="_blank" href="https://niketech.service-now.com/nikehr?id=nikehr_kb_article_view&sysparm_article=KB0031894">Disclosure Tool</a>',
      tooltip: null,
      disabled: false,
      hidden: true,
      forcedUpdate: true,
      required: true,
      block: 2,
    },
    {
      type: 'BaseRadioGroup',
      key: fieldNames.paDonationToCharitableOrganization,
      validationRules: [],
      dependencyRules: [
        {
          sourceFieldKey: fieldNames.salesOrganization,
          conditionType: HeaderLevelDependencyConditionEnum.empty,
          action: HeaderLevelDependencyActionsEnum.setDisabled,
        },
      ],
      class: 'pa-charitable-organization-wrapper header-level__item_fullwidth now-label-1',
      testId: 'soc-form-pa-charitable-organization-field',
      width: '100%',
      label: translateSOC('form.paCharitableDonation'),
      tooltip: null,
      default: RADIO_NO_VALUE,
      value: RADIO_NO_VALUE,
      radioItems: radioOptions,
      disabled: true,
      hidden: false,
      required: false,
      block: 2,
    },
    {
      type: 'BaseRadioGroup',
      key: fieldNames.paSciDisclosureTool,
      validationRules: [],
      dependencyRules: [
        {
          sourceFieldKey: fieldNames.salesOrganization,
          conditionType: HeaderLevelDependencyConditionEnum.empty,
          action: HeaderLevelDependencyActionsEnum.setDisabled,
        },
        {
          sourceFieldKey: fieldNames.paDonationToCharitableOrganization,
          conditionType: HeaderLevelDependencyConditionEnum.certainValue,
          value: RADIO_NO_VALUE,
          action: HeaderLevelDependencyActionsEnum.setHidden,
        },
        {
          sourceFieldKey: fieldNames.paDonationToCharitableOrganization,
          conditionType: HeaderLevelDependencyConditionEnum.changedValue,
          value: RADIO_YES_VALUE,
          action: HeaderLevelDependencyActionsEnum.setValue,
        },
      ],
      class: 'pa-sci-disclosure-tool-wrapper header-level__item_fullwidth',
      testId: 'soc-form-pa-sci-disclosure-tool-field',
      width: '100%',
      label: translateSOC('form.paSciDisclosure'),
      tooltip: null,
      default: RADIO_NO_VALUE,
      value: RADIO_NO_VALUE,
      radioItems: radioOptions,
      disabled: true,
      hidden: false,
      required: false,
      block: 2,
    },
    {
      type: 'Input',
      key: fieldNames.paSciRefNumber,
      validationRules: [
        FormTemplateValidationRulesMap.MAXLENGTH,
        FormTemplateValidationRulesMap.ONLY_DIGITS_LETTERS,
      ],
      maxLength: 128,
      dependencyRules: [
        {
          sourceFieldKey: fieldNames.salesOrganization,
          conditionType: HeaderLevelDependencyConditionEnum.empty,
          action: HeaderLevelDependencyActionsEnum.setDisabled,
        },
        {
          conditionType: HeaderLevelDependencyConditionEnum.and,
          conditions: [
            {
              sourceFieldKey: fieldNames.paDonationToCharitableOrganization,
              conditionType: HeaderLevelDependencyConditionEnum.certainValue,
              value: RADIO_YES_VALUE,
            },
            {
              sourceFieldKey: fieldNames.paSciDisclosureTool,
              conditionType: HeaderLevelDependencyConditionEnum.certainValue,
              value: RADIO_YES_VALUE,
            },
          ],
          action: HeaderLevelDependencyActionsEnum.setVisible,
        },

      ],
      class: 'pa-sci-reference-number-wrapper',
      testId: 'soc-form-pa-sci-reference-number-field',
      width: '375',
      label: translateFields(FIELDS_ENUM.preApprovalSCIReferenceNumber),
      value: null,
      disabled: true,
      hidden: true,
      forcedUpdate: true,
      required: true,
      block: 2,
    },
    {
      type: 'Textblock',
      key: fieldNames.paContactSCI,
      validationRules: [],
      dependencyRules: [

        {

          conditionType: HeaderLevelDependencyConditionEnum.and,
          conditions: [
            {
              sourceFieldKey: fieldNames.paSciDisclosureTool,
              conditionType: HeaderLevelDependencyConditionEnum.certainValue,
              value: RADIO_NO_VALUE,
            },
            {
              sourceFieldKey: fieldNames.paSciDisclosureTool,
              conditionType: HeaderLevelDependencyConditionEnum.visible,
            },

          ],
          action: HeaderLevelDependencyActionsEnum.setVisible,
        },
      ],
      class: 'pa-contact-sci-wrapper header-level__item_fullwidth now-label-1',
      testId: 'soc-form-pa-contact-sci-field',
      width: '100%',
      label: 'Please contact SCI.Donations@Nike.com to get pre-approval via <a class="active-link--text" target="_blank" href="https://niketech.service-now.com/nikehr?id=nikehr_kb_article_view&sysparm_article=KB0031559">SCI Disclosure Tool</a>.',
      tooltip: null,
      disabled: false,
      hidden: true,
      forcedUpdate: true,
      required: true,
      block: 2,
    },
    {
      type: 'SelectSingle',
      key: fieldNames.businessPurpose,
      validationRules: [FormTemplateValidationRulesMap.REQUIRED],
      dependencyRules: [
        {
          sourceFieldKey: fieldNames.salesOrganization,
          conditionType: HeaderLevelDependencyConditionEnum.empty,
          action: HeaderLevelDependencyActionsEnum.setDisabled,
        },
      ],
      items: [...BUSINESS_PURPOSE_VALUES],
      class: 'business-purpose-wrapper header-level__item_fullwidth header-level__item_title',
      testId: 'soc-business-purpose-select-field',
      width: '375',
      label: translateFields(FIELDS_ENUM.businessPurpose),
      isValueText: true,
      value: null,
      disabled: false,
      hidden: false,
      required: true,
      block: 3,
    },
    {
      type: 'BaseRadioGroup',
      key: fieldNames.eventPurpose,
      validationRules: [],
      dependencyRules: [
        {
          sourceFieldKey: fieldNames.businessPurpose,
          conditionType: HeaderLevelDependencyConditionEnum.filled,
          action: HeaderLevelDependencyActionsEnum.setRequired,
        },
        {
          sourceFieldKey: fieldNames.businessPurpose,
          conditionType: HeaderLevelDependencyConditionEnum.matchingValue,
          action: HeaderLevelDependencyActionsEnum.setHidden,
        },
        {
          sourceFieldKey: fieldNames.businessPurpose,
          conditionType: HeaderLevelDependencyConditionEnum.matchingValue,
          action: HeaderLevelDependencyActionsEnum.setCleared,
        },
      ],
      class: 'event-purpose-wrapper header-level__item_fullwidth',
      width: '100%',
      label: translateSOC('form.eventPurpose'),
      tooltip: null,
      radioItems: [
        {
          text: 'Private',
          value: 'PRIVATE',
        },
        {
          text: 'Open to Public',
          value: 'PUBLIC',
        },
      ],
      parentVisibilityValues: [EVENT_VALUE],
      default: 'PRIVATE',
      value: 'PRIVATE',
      disabled: false,
      hidden: false,
      required: false,
      block: 3,
    },
    {
      type: 'Textarea',
      key: fieldNames.attendeesList,
      validationRules: [
        FormTemplateValidationRulesMap.REQUIRED,
        FormTemplateValidationRulesMap.MAXLENGTH,
      ],
      dependencyRules: [
        {
          conditionType: HeaderLevelDependencyConditionEnum.or,
          conditions: [
            {
              sourceFieldKey: fieldNames.eventPurpose,
              conditionType: HeaderLevelDependencyConditionEnum.matchingValue,
              conditionArgs: {
                values: ['PUBLIC'],
              },
            },
            {
              sourceFieldKey: fieldNames.businessPurpose,
              conditionType: HeaderLevelDependencyConditionEnum.matchingValue,
            },
          ],
          action: HeaderLevelDependencyActionsEnum.setHidden,
        },
        {
          conditionType: HeaderLevelDependencyConditionEnum.or,
          conditions: [
            {
              sourceFieldKey: fieldNames.eventPurpose,
              conditionType: HeaderLevelDependencyConditionEnum.matchingValue,
              conditionArgs: {
                values: ['PUBLIC'],
              },
            },
            {
              sourceFieldKey: fieldNames.businessPurpose,
              conditionType: HeaderLevelDependencyConditionEnum.matchingValue,
            },
          ],
          action: HeaderLevelDependencyActionsEnum.setCleared,
        },
        {
          conditionType: HeaderLevelDependencyConditionEnum.and,
          conditions: [
            {
              sourceFieldKey: fieldNames.businessPurpose,
              conditionType: HeaderLevelDependencyConditionEnum.filled,
            },
            {
              sourceFieldKey: fieldNames.businessPurpose,
              conditionType: HeaderLevelDependencyConditionEnum.certainValue,
              value: EVENT_VALUE,
            },
          ],
          action: HeaderLevelDependencyActionsEnum.setRequired,
        },
      ],
      class: 'attendees-list-wrapper header-level__item_fullwidth',
      width: '100%',
      label: translateSOC('form.attendeesList'),
      value: null,
      parentVisibilityValues: [EVENT_VALUE],
      maxLength: 1024,
      disabled: false,
      hidden: false,
      required: false,
      block: 3,
    },
    {
      type: 'Textblock',
      key: fieldNames.seedingInfo,
      validationRules: [],
      dependencyRules: [
        {
          conditionType: HeaderLevelDependencyConditionEnum.or,
          conditions: [
            {
              sourceFieldKey: fieldNames.businessPurpose,
              conditionType: HeaderLevelDependencyConditionEnum.matchingValue,
            },
          ],
          action: HeaderLevelDependencyActionsEnum.setHidden,
        },
        {
          conditionType: HeaderLevelDependencyConditionEnum.or,
          conditions: [
            {
              sourceFieldKey: fieldNames.businessPurpose,
              conditionType: HeaderLevelDependencyConditionEnum.matchingValue,
            },
          ],
          action: HeaderLevelDependencyActionsEnum.setCleared,
        },
      ],
      class: 'seeding-disclaimer-wrapper',
      width: '375',
      label: 'Seeding product is any product distributed free of charge to an external or internal party. This includes, but is not limited to, product for sports marketing assets, product delivered to entertainers, collaborators, media, or other influencers who represent or provide value to the brand and product for employees who regularly interact with the external part. For more details, <a class="active-link--text" target="_blank" href="https://confluence.nike.com/pages/viewpage.action?spaceKey=SAGO&title=Seeding">please click here</a>.',
      value: null,
      parentVisibilityValues: [SEEDING_VALUE],
      disabled: false,
      hidden: true,
      required: false,
      block: 3,
    },
    {
      type: 'BaseRadioGroup',
      key: fieldNames.globalSourcing,
      validationRules: [],
      dependencyRules: [
        {
          sourceFieldKey: fieldNames.businessPurpose,
          conditionType: HeaderLevelDependencyConditionEnum.matchingValue,
          action: HeaderLevelDependencyActionsEnum.setHidden,
        },
        {
          sourceFieldKey: fieldNames.businessPurpose,
          conditionType: HeaderLevelDependencyConditionEnum.matchingValue,
          action: HeaderLevelDependencyActionsEnum.setCleared,
        },
      ],
      class: 'global-sourcing-wrapper header-level__item_fullwidth',
      width: '100%',
      label: translateSOC('form.globalSourcing'),
      tooltip: null,
      default: RADIO_NO_VALUE,
      value: RADIO_NO_VALUE,
      radioItems: radioOptions,
      disabled: false,
      hidden: false,
      required: false,
      block: 3,
    },
    {
      type: 'Textarea',
      key: fieldNames.intendedPurpose,
      validationRules: [
        FormTemplateValidationRulesMap.REQUIRED,
        FormTemplateValidationRulesMap.MAXLENGTH,
      ],
      dependencyRules: [
        {
          conditionType: HeaderLevelDependencyConditionEnum.or,
          conditions: [
            {
              sourceFieldKey: fieldNames.businessPurpose,
              conditionType: HeaderLevelDependencyConditionEnum.matchingValue,
            },
          ],
          action: HeaderLevelDependencyActionsEnum.setHidden,
        },
        {
          conditionType: HeaderLevelDependencyConditionEnum.or,
          conditions: [
            {
              sourceFieldKey: fieldNames.businessPurpose,
              conditionType: HeaderLevelDependencyConditionEnum.matchingValue,
            },
          ],
          action: HeaderLevelDependencyActionsEnum.setCleared,
        },
        {
          conditionType: HeaderLevelDependencyConditionEnum.and,
          conditions: [
            {
              sourceFieldKey: fieldNames.businessPurpose,
              conditionType: HeaderLevelDependencyConditionEnum.filled,
            },
            {
              sourceFieldKey: fieldNames.businessPurpose,
              conditionType: HeaderLevelDependencyConditionEnum.matchingValue,
            },
          ],
          action: HeaderLevelDependencyActionsEnum.setRequired,
        },
        {
          sourceFieldKey: fieldNames.salesOrganization,
          conditionType: HeaderLevelDependencyConditionEnum.empty,
          action: HeaderLevelDependencyActionsEnum.setDisabled,
        },
        {
          sourceFieldKey: fieldNames.intendedPurpose,
          action: HeaderLevelDependencyActionsEnum.clearRequired,
        },
      ],
      class: 'intended-purpose-wrapper header-level__item_fullwidth',
      testId: 'soc-form-intended-purpose-field',
      width: '100%',
      label: translateSOC('form.intendedPurpose'),
      value: null,
      parentVisibilityValues: INTENDED_PURPOSE_VISIBILITY_VALUES,
      maxLength: 1024,
      disabled: false,
      hidden: false,
      required: false,
      block: 3,
    },
    {
      type: 'Textarea',
      key: fieldNames.nameOfTheRecipient,
      validationRules: [
        FormTemplateValidationRulesMap.MAXLENGTH,
        FormTemplateValidationRulesMap.REQUIRED,
      ],
      maxLength: 1024,
      dependencyRules: [
        {
          conditionType: HeaderLevelDependencyConditionEnum.or,
          conditions: [
            {
              sourceFieldKey: fieldNames.businessPurpose,
              conditionType: HeaderLevelDependencyConditionEnum.matchingValue,
            },
          ],
          action: HeaderLevelDependencyActionsEnum.setHidden,
        },
        {
          conditionType: HeaderLevelDependencyConditionEnum.or,
          conditions: [
            {
              sourceFieldKey: fieldNames.businessPurpose,
              conditionType: HeaderLevelDependencyConditionEnum.matchingValue,
            },
          ],
          action: HeaderLevelDependencyActionsEnum.setCleared,
        },
        {
          conditionType: HeaderLevelDependencyConditionEnum.or,
          conditions: [
            {
              sourceFieldKey: fieldNames.businessPurpose,
              conditionType: HeaderLevelDependencyConditionEnum.matchingValue,
            },
          ],
          action: HeaderLevelDependencyActionsEnum.clearRequired,
        },
        {
          sourceFieldKey: fieldNames.salesOrganization,
          conditionType: HeaderLevelDependencyConditionEnum.empty,
          action: HeaderLevelDependencyActionsEnum.setDisabled,
        },
      ],
      class: 'name-of-the-recipient-wrapper header-level__item_fullwidth',
      width: '100%',
      label: translateFields(FIELDS_ENUM.nameOfTheRecipient),
      value: null,
      parentVisibilityValues: NAME_OF_RECIPIENT_VISIBILITY_VALUES,
      disabled: false,
      hidden: false,
      required: true,
      block: 3,
    },
    {
      type: 'Datepicker',
      key: fieldNames.businessPurposeDate,
      validationRules: [FormTemplateValidationRulesMap.REQUIRED],
      dependencyRules: [
        {
          sourceFieldKey: fieldNames.businessPurpose,
          conditionType: HeaderLevelDependencyConditionEnum.matchingValue,
          action: HeaderLevelDependencyActionsEnum.setHidden,
        },
        {
          sourceFieldKey: fieldNames.businessPurpose,
          conditionType: HeaderLevelDependencyConditionEnum.matchingValue,
          action: HeaderLevelDependencyActionsEnum.setCleared,
        },
        {

          sourceFieldKey: fieldNames.businessPurpose,
          conditionType: HeaderLevelDependencyConditionEnum.certainValue,
          value: OTHER_VALUE,
          action: HeaderLevelDependencyActionsEnum.setRequired,
        },
      ],
      class: 'business-purpose-date-wrapper header-level__item_fullwidth',
      width: '375',
      label: translateFields(FIELDS_ENUM.businessPurposeDate),
      value: null,
      parentVisibilityValues: [OTHER_VALUE],
      disabled: false,
      hidden: false,
      required: false,
      block: 3,
    },
    {
      type: 'PurchasingPolicy',
      key: fieldNames.purchasingPolicy,
      validationRules: [FormTemplateValidationRulesMap.REQUIRED],
      dependencyRules: [
        {
          sourceFieldKey: fieldNames.salesOrganization,
          conditionType: HeaderLevelDependencyConditionEnum.empty,
          action: HeaderLevelDependencyActionsEnum.setDisabled,
        },
      ],
      class: 'purchasing-policy-wrapper header-level__item_fullwidth',
      testId: 'soc-form-purchasing-policy',
      width: '100%',
      label: translateSOC('form.purchasingPolicy'),
      default: false,
      value: false,
      parentVisibilityValues: [OTHER_VALUE],
      disabled: true,
      hidden: false,
      required: true,
      block: 4,
    },
  ],
  blockNames: ['Order Criteria', 'Pre-Approval', 'Business Purpose', 'Comp Product Purchasing Policy'],
  lineItemFields:
  {
    columnDefs: [
      {
        name: 'Line',
        field: fieldNames.line,
        required: false,
      },
      {
        name: translateFields(FIELDS_ENUM.materialNumber),
        field: fieldNames.materialNumber,
        required: true,
      },
      {
        name: translateFields(FIELDS_ENUM.orderQuantity),
        field: fieldNames.qty,
        required: true,
      },
      {
        name: translateFields(FIELDS_ENUM.contractNumber),
        field: fieldNames.contractNumber,
        required: false,
      },
      {
        name: translateFields(FIELDS_ENUM.contractOwnerName),
        field: fieldNames.contractOwnerName,
        required: true,
      },
      {
        name: translateFields(FIELDS_ENUM.crdLineLevel),
        field: fieldNames.crd,
        required: true,
      },
      {
        name: translateFields(FIELDS_ENUM.cancelDateLineLevel),
        field: fieldNames.cancelDate,
        required: true,
      },
      {
        name: translateFields(FIELDS_ENUM.usageCode),
        field: fieldNames.usageCode,
        required: false,
      },
      {
        name: translateFields(FIELDS_ENUM.plantCode),
        field: fieldNames.plantCode,
        required: false,
      },
      {
        name: translateFields(FIELDS_ENUM.unitAllotment),
        field: fieldNames.unitAllotment,
        required: false,
      },
      {
        name: translateFields(FIELDS_ENUM.requirementSegment),
        field: fieldNames.requirementSegment,
        required: false,
      },
      {
        name: translateFields(FIELDS_ENUM.vasCode),
        field: fieldNames.vasCodes,
        required: false,
      },
      {
        name: translateFields(FIELDS_ENUM.vasMaterial),
        field: fieldNames.vasMaterial,
        required: false,
      },
      {
        name: translateFields(FIELDS_ENUM.vasField1),
        field: fieldNames.vasField1,
        required: false,
      },
      {
        name: translateFields(FIELDS_ENUM.vasField2),
        field: fieldNames.vasField2,
        required: false,
      },
      {
        name: translateFields(FIELDS_ENUM.vasField3),
        field: fieldNames.vasField3,
        required: false,
      },
      {
        name: translateFields(FIELDS_ENUM.vasLongText),
        field: fieldNames.vasLongText,
        required: false,
      },
      {
        name: translateFields(FIELDS_ENUM.vasChargeCode),
        field: fieldNames.vasChargeCode,
        required: false,
      },
      {
        field: 'appSizes',
        name: '',
        required: false,
      },
      {
        field: 'availabilityData',
        name: '',
        required: false,
      },
    ],
    validationRules: {
      [fieldNames.contractNumber]: {
        [LineLevelValidationRulesEnum.required]: {
          required: 'columnDefined',
        },
        [LineLevelValidationRulesEnum.regExpValidation]: {
          pattern: '^[0-9]*$',
          errorMessage: 'Expected Format: 0123456789',
        },
        [LineLevelValidationRulesEnum.lengthValidation]: {
          maxLength: 10,
        },
      },
      [fieldNames.crd]: {
        [LineLevelValidationRulesEnum.required]: {
          required: 'columnDefined',
        },
      },
      [fieldNames.cancelDate]: {
        [LineLevelValidationRulesEnum.required]: {
          required: 'columnDefined',
        },
      },
      [fieldNames.materialNumber]: {
        [LineLevelValidationRulesEnum.required]: {
          required: 'columnDefined',
        },
        [LineLevelValidationRulesEnum.regExpValidation]: {
          pattern: '^([a-zA-Z0-9]{6})-([a-zA-Z0-9]{3})$',
          errorMessage: 'Expected Format: BQ3757-101',
        },
        [LineLevelValidationRulesEnum.lengthValidation]: {
          maxLength: 10,
        },
      },
      [fieldNames.qty]: {
        [LineLevelValidationRulesEnum.regExpValidation]: {
          pattern: '^[0-9]*$',
          errorMessage: 'Only digits are allowed',
        },
      },
      unitAllotment: {
        [LineLevelValidationRulesEnum.lengthValidation]: {
          maxLength: 70,
        },
      },
    },
    dependencies: [
      {
        fieldName: fieldNames.contractNumber,
        sourceFieldKey: fieldNames.sourcingMethod,
        sourceFieldType: 'HeaderLevel',
        conditionType: HeaderLevelDependencyConditionEnum.matchingValue,
        conditionArgs: {
          values: [sourcingMethodValues.CONTRACT],
        },
        action: LineLevelDependencyActionEnum.setRequired,
      },
      {
        fieldName: fieldNames.contractOwnerName,
        sourceFieldKey: fieldNames.sourcingMethod,
        sourceFieldType: 'HeaderLevel',
        conditionType: HeaderLevelDependencyConditionEnum.matchingValue,
        conditionArgs: {
          values: [sourcingMethodValues.CONTRACT],
        },
        action: LineLevelDependencyActionEnum.setRequired,
      },
      {
        fieldName: fieldNames.vasCodes,
        sourceFieldKey: fieldNames.shippingCondition,
        sourceFieldType: 'HeaderLevel',
        conditionType: HeaderLevelDependencyConditionEnum.filled,
        action: LineLevelDependencyActionEnum.setValue,
      },
      {
        fieldName: fieldNames.vasField3,
        sourceFieldKey: fieldNames.shippingCondition,
        sourceFieldType: 'HeaderLevel',
        conditionType: HeaderLevelDependencyConditionEnum.filled,
        action: LineLevelDependencyActionEnum.setValue,
      },
    ],
    rowData: [],
  },
  name: 'Free-of-Charge Order',
  orderType: {
    name: 'Free-of-Charge Order',
    id: 3,
    sapId: 'ZFRE',
  },
};
