import { cloneDeep } from 'lodash';

import { fieldNames } from '@/enums/singleOrderCreation/fieldNames';
import validationRules from '@/enums/validationRules/SOC';
import { RADIO_YES_VALUE, RADIO_NO_VALUE } from '@/enums/validationRules/dropdownValues';

import { orderConfigs } from '@/controllers/order';

import store from '@/store';

export class OrderRequest {
  constructor(formValues) {
    this._formValues = cloneDeep(formValues);

    const selectedOrderTypeSapId = store.state.SOC.orderType.sapId;
    this._orderConfig = orderConfigs[selectedOrderTypeSapId];
  }

  transfromOrder() {
    this._introduceInitialMapping();
    this._mapShippingCondition();
    this._mapVasCodes();

    const mappedOrder = {
      ...this._formValues.headerLevelFields,
      lineItems: [...this._formValues.lineItems],
      orderType: this._formValues.orderType,
    };

    if (this._formValues.id) {
      mappedOrder.id = this._formValues.id;
      mappedOrder.version = this._formValues.version;
    }

    return mappedOrder;
  }

  _introduceInitialMapping() {
    const emptyValuesRemoved = this._formValues.headerLevelFields.filter((field) => {
      return field.value || field.type === 'BaseRadioGroup' || field.type === 'Checkbox';
    });

    const headerLevelObject = {};

    emptyValuesRemoved.forEach(({
      key, value, type, hidden,
    }) => {
      if (!hidden) {
        headerLevelObject[key] = {
          value: this._mapHeaderValue(type, value),
          name: this._mapHeaderFieldName(key),
        };
      }
    });

    this._formValues.headerLevelFields = { ...headerLevelObject };

    this._formValues.lineItems = this._formValues.lineItems.map((row) => {
      const newRow = {};

      Object.keys(row).forEach(rowField => {
        if (rowField === 'appSizes') {
          newRow[rowField] = Object.keys(row[rowField])
            .filter((size) => parseInt(row[rowField][size]))
            .map((size) => {
              return { name: size, value: parseInt(row[rowField][size]) };
            });
        } else if (this._checkIfValue(row[rowField]?.value)) {
          newRow[rowField] = {
            value: row[rowField].value,
            name: this._mapLineFieldName(rowField),
          };
        }
      });

      return newRow;
    });
  }

  _mapHeaderValue(type, value) {
    switch (true) {
      case type === 'Checkbox' || type === 'PurchasingPolicy':
        return !value ? RADIO_NO_VALUE : RADIO_YES_VALUE;
      case type === 'SoldTo':
        return value.id;
      default:
    }

    return typeof value === 'string' ? value.trim() : value;
  }

  _checkIfValue(value) {
    return !(!value || !value?.length);
  }

  _mapHeaderFieldName(key) {
    const foundField = this._orderConfig.fields.find(field => field.key === key);

    return foundField?.label;
  }

  _mapLineFieldName(key) {
    const foundField = this._orderConfig.lineItemFields.columnDefs.find(({ field }) => field === key);

    return foundField?.name;
  }

  _mapShippingCondition() {
    const shippingConditionDefaultValue = validationRules.formatRules.shippingCondition.defaultValue;

    if (
      this._formValues.headerLevelFields.shippingCondition?.value === shippingConditionDefaultValue
    ) {
      delete this._formValues.headerLevelFields.shippingCondition;
    }
  }

  _mapVasCodes() {
    const lineLevelFields = [...this._formValues.lineItems];

    this._formValues.lineItems.forEach(({ vasCodes }, index) => {
      if (vasCodes) {
        lineLevelFields[index][fieldNames.vasCodes] = vasCodes.value.map((code) => ({
          name: this._mapLineFieldName(fieldNames.vasCodes),
          value: code,
        }));
      }
    });

    this._formValues.lineItems = lineLevelFields;
  }
}
