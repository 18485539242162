const myApprovalsData = {
  SET_MY_APPROVALS: 'SET_MY_APPROVALS',

  SET_IS_TABLE_DATA_LOADING: 'SET_IS_TABLE_DATA_LOADING',

  SET_TABLE_DATA_LOADING_ERROR: 'SET_TABLE_DATA_LOADING_ERROR',
};

const myApprovalsTable = {
  SET_PAGE_SIZE: 'SET_PAGE_SIZE',
  SET_PAGE_NUMBER: 'SET_PAGE_NUMBER',
  SET_TOTAL_AVAILABLE_ORDERS_NUMBER: 'SET_TOTAL_AVAILABLE_ORDERS_NUMBER',
  SET_TOTAL_AVAILABLE_PAGES_NUMBER: 'SET_TOTAL_AVAILABLE_PAGES_NUMBER',
};

export default {
  ...myApprovalsData,
  ...myApprovalsTable,
};
