import i18n from '@/plugins/i18n';
import { FormTemplateValidationRulesMap } from '@/enums/SOC/formTemplate.consts';
import ValidationRulesEnum from '@/enums/validationRules/SOC.js';
import { fieldNames } from '@/enums/singleOrderCreation/fieldNames';
import { LineLevelDependencyConditionEnum } from '@/controllers/order/dependencies/line';
import {
  HeaderLevelDependencyActionsEnum,
  HeaderLevelDependencyConditionEnum,
} from '@/enums/SOC/dependencyEnums';
import {
  dropdownValues,
  sourcingMethodValues,
  RADIO_NO_VALUE,
  RADIO_YES_VALUE,
  radioOptions,
} from '@/enums/validationRules/dropdownValues';
import {
  LineLevelDependencyActionEnum,
} from '@/controllers/order/dependencies/line/actions/actionsMap';
import { LineLevelValidationRulesEnum } from '@/controllers/order/validations/line';
import { setInitialMinDate } from '@/utils/singleOrderCreate';

import { fieldsMaxTextLength } from '@/controllers/order/enums/field';

import { translateModule } from '@/utils/string/translate';
import { FIELDS_ENUM } from '@/enums/language/fieldsEnum';

const translateFields = translateModule('fields');
const translateSOC = translateModule('pages.SOC');

const shippingConditionDefaultValue = ValidationRulesEnum.formatRules.shippingCondition.defaultValue;

export default {
  fields: [
    {
      type: 'SelectSingle',
      key: fieldNames.salesOrganization,
      dependencyRules: [
        {
          sourceFieldKey: fieldNames.materialNumber,
          sourceFieldType: 'LineLevel',
          conditionType: LineLevelDependencyConditionEnum.columnHasValue,
          action: HeaderLevelDependencyActionsEnum.setDisabled,
        },
        {
          sourceFieldKey: fieldNames.materialNumber,
          sourceFieldType: 'LineLevel',
          conditionType: LineLevelDependencyConditionEnum.columnHasValue,
          action: HeaderLevelDependencyActionsEnum.setTooltipMessage,
          tooltipMessage: i18n.global.t('pages.SOC.form.disabledDropdownTooltip'),
        },
      ],
      validationRules: [FormTemplateValidationRulesMap.REQUIRED],
      items: [],
      class: 'sales-organization-wrapper header-level__item_fullwidth header-level__item_title',
      testId: 'soc-form-sales-org-field',
      width: '375',
      label: translateFields(FIELDS_ENUM.salesOrg),
      value: '',
      tooltipMessage: '',
      disabled: false,
      hidden: false,
      required: true,
    },
    {
      type: 'SelectSingle',
      key: fieldNames.sourcingMethod,
      dependencyRules: [
        {
          conditionType: HeaderLevelDependencyConditionEnum.or,
          conditions: [
            {
              sourceFieldKey: fieldNames.salesOrganization,
              conditionType: HeaderLevelDependencyConditionEnum.empty,
            },
            {
              sourceFieldKey: fieldNames.materialNumber,
              sourceFieldType: 'LineLevel',
              conditionType: LineLevelDependencyConditionEnum.columnHasValue,
            },
          ],
          action: HeaderLevelDependencyActionsEnum.setDisabled,
        },
        {
          sourceFieldKey: fieldNames.materialNumber,
          sourceFieldType: 'LineLevel',
          conditionType: LineLevelDependencyConditionEnum.columnHasValue,
          action: HeaderLevelDependencyActionsEnum.setTooltipMessage,
          tooltipMessage: i18n.global.t('pages.SOC.form.disabledDropdownTooltip'),
        },
        {
          sourceFieldKey: fieldNames.salesOrganization,
          conditionType: HeaderLevelDependencyConditionEnum.empty,
          action: HeaderLevelDependencyActionsEnum.setCleared,
        },
      ],
      items: dropdownValues.sourcingMethod,
      class: 'sourcing-method-channel-wrapper',
      testId: 'soc-form-sourcing-method-channel-field',
      width: '375',
      label: translateFields(FIELDS_ENUM.sourcingMethod),
      isValueText: true,
      value: null,
      disabled: false,
      hidden: false,
      required: true,
    },
    {
      type: 'SelectSingle',
      key: fieldNames.salesChannel,
      dependencyRules: [
        {
          conditionType: HeaderLevelDependencyConditionEnum.or,
          conditions: [
            {
              sourceFieldKey: fieldNames.salesOrganization,
              conditionType: HeaderLevelDependencyConditionEnum.empty,
            },
            {
              sourceFieldKey: fieldNames.materialNumber,
              sourceFieldType: 'LineLevel',
              conditionType: LineLevelDependencyConditionEnum.columnHasValue,
            },
            {
              sourceFieldKey: fieldNames.sourcingMethod,
              conditionType: HeaderLevelDependencyConditionEnum.certainValue,
              value: sourcingMethodValues.AUTO,
            },
          ],
          action: HeaderLevelDependencyActionsEnum.setDisabled,
        },
        {
          sourceFieldKey: fieldNames.materialNumber,
          sourceFieldType: 'LineLevel',
          conditionType: LineLevelDependencyConditionEnum.columnHasValue,
          action: HeaderLevelDependencyActionsEnum.setTooltipMessage,
          tooltipMessage: i18n.global.t('pages.SOC.form.disabledDropdownTooltip'),
        },
        {
          sourceFieldKey: fieldNames.salesOrganization,
          conditionType: HeaderLevelDependencyConditionEnum.filled,
          action: HeaderLevelDependencyActionsEnum.setAsyncItems,
        },
        {
          sourceFieldKey: fieldNames.sourcingMethod,
          conditionType: HeaderLevelDependencyConditionEnum.filled,
          action: HeaderLevelDependencyActionsEnum.setAsyncItems,
        },
        {
          sourceFieldKey: fieldNames.sourcingMethod,
          conditionType: HeaderLevelDependencyConditionEnum.changedValue,
          action: LineLevelDependencyActionEnum.setCleared,
        },
        {
          sourceFieldKey: fieldNames.salesOrganization,
          conditionType: HeaderLevelDependencyConditionEnum.changedValue,
          action: HeaderLevelDependencyActionsEnum.setCleared,
        },
        {
          sourceFieldKey: fieldNames.salesOrganization,
          conditionType: HeaderLevelDependencyConditionEnum.hasOneSelectItem,
          action: HeaderLevelDependencyActionsEnum.setFirstItemAsValue,
        },
        {
          sourceFieldKey: fieldNames.sourcingMethod,
          conditionType: HeaderLevelDependencyConditionEnum.hasOneSelectItem,
          action: HeaderLevelDependencyActionsEnum.setFirstItemAsValue,
        },
        {
          sourceFieldKey: fieldNames.salesOrganization,
          conditionType: HeaderLevelDependencyConditionEnum.empty,
          action: HeaderLevelDependencyActionsEnum.setCleared,
        },
      ],
      items: [],
      class: 'sales-channel-wrapper',
      testId: 'soc-form-sales-channel-field',
      width: '375',
      label: translateFields(FIELDS_ENUM.channel),
      isValueText: true,
      value: null,
      tooltipMessage: '',
      disabled: true,
      hidden: false,
      required: true,
      async: true,
    },
    {
      type: 'SoldTo',
      key: fieldNames.soldToNumber,
      validationRules: [FormTemplateValidationRulesMap.REQUIRED],
      dependencyRules: [
        {
          conditionType: HeaderLevelDependencyConditionEnum.or,
          conditions: [
            {
              sourceFieldKey: fieldNames.salesOrganization,
              sourceFieldType: 'HeaderLevel',
              conditionType: HeaderLevelDependencyConditionEnum.empty,
            },
            {
              sourceFieldKey: fieldNames.materialNumber,
              sourceFieldType: 'LineLevel',
              conditionType: LineLevelDependencyConditionEnum.columnHasValue,
            },
          ],
          action: HeaderLevelDependencyActionsEnum.setDisabled,
        },
        {
          sourceFieldKey: fieldNames.materialNumber,
          sourceFieldType: 'LineLevel',
          conditionType: LineLevelDependencyConditionEnum.columnHasValue,
          action: HeaderLevelDependencyActionsEnum.setTooltipMessage,
          tooltipMessage: i18n.global.t('pages.SOC.form.disabledDropdownTooltip'),
        },
        {
          sourceFieldKey: fieldNames.salesOrganization,
          conditionType: HeaderLevelDependencyConditionEnum.filled,
          action: HeaderLevelDependencyActionsEnum.setParentValue,
        },
        {
          sourceFieldKey: fieldNames.salesOrganization,
          conditionType: HeaderLevelDependencyConditionEnum.changedValue,
          action: HeaderLevelDependencyActionsEnum.setParentValue,
        },
        {
          sourceFieldKey: fieldNames.salesOrganization,
          conditionType: HeaderLevelDependencyConditionEnum.empty,
          action: HeaderLevelDependencyActionsEnum.setCleared,
        },
      ],
      class: 'sold-to-wrapper',
      width: '375',
      label: translateFields(FIELDS_ENUM.soldTo),
      name: 'Sold To',
      value: null,
      tooltipMessage: '',
      parentValue: '',
      disabled: true,
      hidden: false,
      required: true,
    },
    {
      type: 'ShipTo',
      key: fieldNames.shipToNumber,
      validationRules: [FormTemplateValidationRulesMap.REQUIRED],
      dependencyRules: [
        {
          conditionType: HeaderLevelDependencyConditionEnum.or,
          conditions: [
            {
              sourceFieldKey: fieldNames.salesOrganization,
              sourceFieldType: 'HeaderLevel',
              conditionType: HeaderLevelDependencyConditionEnum.empty,
            },
            {
              sourceFieldKey: fieldNames.soldToNumber,
              sourceFieldType: 'HeaderLevel',
              conditionType: HeaderLevelDependencyConditionEnum.empty,
            },
            {
              sourceFieldKey: fieldNames.materialNumber,
              sourceFieldType: 'LineLevel',
              conditionType: LineLevelDependencyConditionEnum.columnHasValue,
            },
          ],
          action: HeaderLevelDependencyActionsEnum.setDisabled,
        },
        {
          sourceFieldKey: fieldNames.materialNumber,
          sourceFieldType: 'LineLevel',
          conditionType: LineLevelDependencyConditionEnum.columnHasValue,
          action: HeaderLevelDependencyActionsEnum.setTooltipMessage,
          tooltipMessage: i18n.global.t('pages.SOC.form.disabledDropdownTooltip'),
        },
        {
          conditionType: HeaderLevelDependencyConditionEnum.or,
          conditions: [
            {
              sourceFieldKey: fieldNames.salesOrganization,
              conditionType: HeaderLevelDependencyConditionEnum.empty,
            },
            {
              sourceFieldKey: fieldNames.soldToNumber,
              conditionType: HeaderLevelDependencyConditionEnum.empty,
            },
          ],
          action: HeaderLevelDependencyActionsEnum.setCleared,
        },
        {
          sourceFieldKey: fieldNames.soldToNumber,
          conditionType: HeaderLevelDependencyConditionEnum.changedValue,
          action: HeaderLevelDependencyActionsEnum.setItems,
        },
      ],
      class: 'ship-to-wrapper',
      width: '375',
      label: translateFields(FIELDS_ENUM.shipTo),
      name: 'Ship To',
      value: '',
      tooltipMessage: '',
      parentValue: {},
      touched: false,
      disabled: true,
      hidden: false,
      required: true,
    },
    {
      type: 'Input',
      key: fieldNames.customerPONumber,
      validationRules: [
        FormTemplateValidationRulesMap.REQUIRED,
        FormTemplateValidationRulesMap.MAXLENGTH,
        FormTemplateValidationRulesMap.CAPITALIZE,
      ],
      maxLength: 35,
      dependencyRules: [
        {
          sourceFieldKey: fieldNames.salesOrganization,
          conditionType: HeaderLevelDependencyConditionEnum.empty,
          action: HeaderLevelDependencyActionsEnum.setDisabled,
        },
      ],
      class: 'customer-po-number-wrapper',
      testId: 'soc-form-customer-po-number-field',
      width: '375',
      label: translateFields(FIELDS_ENUM.purchaseOrderNumber),
      value: null,
      disabled: false,
      hidden: false,
      required: true,
    },
    {
      type: 'Datepicker',
      key: fieldNames.crd,
      validationRules: [FormTemplateValidationRulesMap.REQUIRED],
      dependencyRules: [
        {
          sourceFieldKey: fieldNames.salesOrganization,
          conditionType: HeaderLevelDependencyConditionEnum.empty,
          action: HeaderLevelDependencyActionsEnum.setDisabled,
        },
      ],
      class: 'crd-wrapper',
      testId: 'soc-form-crd-field',
      width: '375',
      minDate: setInitialMinDate({
        refDate: new Date(),
      }),
      label: translateFields(FIELDS_ENUM.crd),
      value: null,
      disabled: false,
      hidden: false,
      required: true,
    },
    {
      type: 'Datepicker',
      key: fieldNames.cancelDate,
      validationRules: [FormTemplateValidationRulesMap.REQUIRED],
      dependencyRules: [
        {
          conditionType: HeaderLevelDependencyConditionEnum.or,
          conditions: [
            {
              sourceFieldKey: fieldNames.salesOrganization,
              conditionType: HeaderLevelDependencyConditionEnum.empty,
            },
            {
              sourceFieldKey: fieldNames.crd,
              conditionType: HeaderLevelDependencyConditionEnum.empty,
            },
          ],
          action: HeaderLevelDependencyActionsEnum.setDisabled,
        },
        {
          sourceFieldKey: fieldNames.crd,
          conditionType: HeaderLevelDependencyConditionEnum.changedValue,
          action: HeaderLevelDependencyActionsEnum.setMinDate,
          actionArgs: {
            add: 6,
            unit: 'calendarDay',
          },
        },
        {
          sourceFieldKey: fieldNames.crd,
          conditionType: HeaderLevelDependencyConditionEnum.changedValue,
          action: HeaderLevelDependencyActionsEnum.setDateValue,
          actionArgs: {
            add: 30,
            unit: 'calendarDay',
          },
        },
      ],
      class: 'cancel-date-wrapper',
      testId: 'soc-form-cancel-date-field',
      width: '375',
      label: translateFields(FIELDS_ENUM.cancelDate),
      minDate: null,
      value: null,
      disabled: false,
      hidden: false,
      required: true,
    },
    {
      type: 'SelectSingle',
      key: fieldNames.shippingCondition,
      dependencyRules: [
        {
          sourceFieldKey: fieldNames.salesOrganization,
          conditionType: HeaderLevelDependencyConditionEnum.empty,
          action: 'setDisabled',
        },
      ],
      items: [
        ...dropdownValues.shippingCondition,
      ],
      class: 'shipping-condition-method-channel-wrapper',
      testId: 'soc-form-shipping-condition',
      width: '375',
      label: translateFields(FIELDS_ENUM.shippingCondition),
      value: shippingConditionDefaultValue,
      disabled: false,
      hidden: false,
      required: false,
    },
    {
      type: 'Input',
      key: fieldNames.nameOfOrderer,
      validationRules: [FormTemplateValidationRulesMap.MAXLENGTH],
      maxLength: 35,
      dependencyRules: [
        {
          sourceFieldKey: fieldNames.salesOrganization,
          conditionType: HeaderLevelDependencyConditionEnum.empty,
          action: 'setDisabled',
        },
      ],
      class: 'name-of-orderer-wrapper',
      testId: 'soc-form-name-of-orderer-field',
      width: '375',
      label: translateFields(FIELDS_ENUM.nameOfOrderer),
      value: null,
      disabled: false,
      hidden: false,
      required: false,
    },
    {
      type: 'Input',
      key: fieldNames.markFor,
      validationRules: [
        FormTemplateValidationRulesMap.MAXLENGTH,
        FormTemplateValidationRulesMap.ONLY_DIGITS,
      ],
      maxLength: 10,
      dependencyRules: [
        {
          sourceFieldKey: fieldNames.salesOrganization,
          conditionType: HeaderLevelDependencyConditionEnum.empty,
          action: 'setDisabled',
        },
      ],
      class: 'mark-for-wrapper',
      testId: 'soc-form-mark-for-field',
      width: '375',
      label: translateFields(FIELDS_ENUM.markFor),
      value: null,
      disabled: false,
      hidden: false,
      required: false,
    },
    {
      type: 'SelectSingle',
      key: fieldNames.deliveryBlock,
      dependencyRules: [
        {
          sourceFieldKey: fieldNames.salesOrganization,
          conditionType: HeaderLevelDependencyConditionEnum.empty,
          action: 'setDisabled',
        },
      ],
      items: [
        {
          text: '01 - Cancel Expected',
          value: '01',
        },
        {
          text: '02 - Exception Hold-Alloc',
          value: '02',
        },
        {
          text: '03 - Exception Hold-NoAlo',
          value: '03',
        },
        {
          text: '04 - Waiting on PO',
          value: '04',
        },
        {
          text: '05 - Waiting on Product',
          value: '05',
        },
        {
          text: '06 - Contract Not Rfrncd',
          value: '06',
        },
      ],
      class: 'delivery-block-channel-wrapper',
      testId: 'soc-form-delivery-block-channel-field',
      width: '375',
      label: translateFields(FIELDS_ENUM.deliveryBlock),
      value: null,
      disabled: false,
      hidden: false,
      required: false,
    },
    {
      type: 'SelectSingle',
      key: fieldNames.departmentCode,
      validationRules: [
        FormTemplateValidationRulesMap.MAXLENGTH,
        FormTemplateValidationRulesMap.ONLY_DIGITS,
      ],
      maxLength: 10,
      dependencyRules: [
        {
          sourceFieldKey: fieldNames.soldToNumber,
          conditionType: HeaderLevelDependencyConditionEnum.changedValue,
          action: HeaderLevelDependencyActionsEnum.setItems,
        },
        {
          conditionType: HeaderLevelDependencyConditionEnum.or,
          conditions: [
            {
              sourceFieldKey: fieldNames.soldToNumber,
              conditionType: HeaderLevelDependencyConditionEnum.empty,
            },
            {
              sourceFieldKey: fieldNames.salesOrganization,
              conditionType: HeaderLevelDependencyConditionEnum.empty,
            },
          ],
          action: HeaderLevelDependencyActionsEnum.setCleared,
        },
        {
          conditionType: HeaderLevelDependencyConditionEnum.or,
          conditions: [
            {
              sourceFieldKey: fieldNames.soldToNumber,
              conditionType: HeaderLevelDependencyConditionEnum.empty,
            },
            {
              sourceFieldKey: fieldNames.salesOrganization,
              conditionType: HeaderLevelDependencyConditionEnum.empty,
            },
          ],
          action: HeaderLevelDependencyActionsEnum.setDisabled,
        },
      ],
      class: 'department-code-wrapper',
      testId: 'soc-form-department-code-field',
      width: '375',
      label: translateFields(FIELDS_ENUM.departmentCode),
      value: null,
      disabled: false,
      hidden: false,
      required: false,
    },
    {
      type: 'Checkbox',
      key: fieldNames.reasonCodeAuthorized,
      validationRules: [FormTemplateValidationRulesMap.REQUIRED],
      dependencyRules: [
        {
          sourceFieldKey: fieldNames.salesOrganization,
          conditionType: HeaderLevelDependencyConditionEnum.empty,
          action: 'setDisabled',
        },
      ],
      class: 'confirmation-checkbox-wrapper header-level__item_fullwidth',
      testId: 'soc-form-reason-code-checkbox-field',
      width: '100%',
      label: translateSOC('form.reasonCodeAuthorized'),
      value: false,
      disabled: true,
      hidden: false,
      required: false,
    },
    {
      type: 'SelectSingle',
      key: fieldNames.reasonCode,
      validationRules: [FormTemplateValidationRulesMap.REQUIRED],
      dependencyRules: [
        {
          conditionType: HeaderLevelDependencyConditionEnum.or,
          conditions: [
            {
              sourceFieldKey: fieldNames.salesOrganization,
              conditionType: HeaderLevelDependencyConditionEnum.empty,
            },
            {
              sourceFieldKey: fieldNames.reasonCodeAuthorized,
              conditionType: HeaderLevelDependencyConditionEnum.empty,
            },
          ],
          action: 'setDisabled',
        },
        {
          sourceFieldKey: fieldNames.reasonCodeAuthorized,
          conditionType: HeaderLevelDependencyConditionEnum.filled,
          action: 'setRequired',
        },
        {
          sourceFieldKey: fieldNames.reasonCodeAuthorized,
          conditionType: HeaderLevelDependencyConditionEnum.empty,
          action: 'setCleared',
        },
      ],
      items: [],
      class: 'reason-code-wrapper',
      testId: 'soc-form-reason-code-field',
      width: '375',
      label: translateFields(FIELDS_ENUM.reasonCode),
      value: null,
      disabled: false,
      hidden: false,
      required: true,
    },
    {
      type: 'Textarea',
      key: fieldNames.nikeInternalComments,
      validationRules: [
        FormTemplateValidationRulesMap.REQUIRED,
        FormTemplateValidationRulesMap.MAXLENGTH,
      ],
      dependencyRules: [
        {
          sourceFieldKey: fieldNames.salesOrganization,
          conditionType: HeaderLevelDependencyConditionEnum.empty,
          action: 'setDisabled',
        },
        {
          sourceFieldKey: fieldNames.reasonCode,
          conditionType: HeaderLevelDependencyConditionEnum.filled,
          action: 'setRequired',
        },
      ],
      class: 'nike-internal-comments-wrapper header-level__item_fullwidth',
      testId: 'soc-form-nike-internal-comments-field',
      width: '782',
      label: translateFields(FIELDS_ENUM.nikeInternalComments),
      maxLength: fieldsMaxTextLength.nikeInternalCommentsLength,
      value: null,
      disabled: false,
      hidden: false,
      required: false,
    },
    {
      type: 'BaseRadioGroup',
      key: fieldNames.addressOverride,
      validationRules: [],
      dependencyRules: [
        {
          sourceFieldKey: fieldNames.salesOrganization,
          conditionType: HeaderLevelDependencyConditionEnum.empty,
          action: 'setDisabled',
        },
      ],
      class: 'address-override-wrapper header-level__item_fullwidth',
      width: '100%',
      label: translateFields(FIELDS_ENUM.addressOverride),
      tooltip: 'For returns the address override is where the return needs to be picked up from if not the defaulted ship to address',
      value: RADIO_NO_VALUE,
      radioItems: radioOptions,
      disabled: false,
      hidden: false,
      required: false,
    },
    {
      type: 'Input',
      key: fieldNames.forwardingAgent,
      validationRules: [
        FormTemplateValidationRulesMap.MAXLENGTH,
        FormTemplateValidationRulesMap.ONLY_DIGITS_LETTERS,
      ],
      maxLength: 10,
      dependencyRules: [
        {
          sourceFieldKey: fieldNames.addressOverride,
          conditionType: HeaderLevelDependencyConditionEnum.matchingValue,
          conditionArgs: {
            values: [RADIO_NO_VALUE],
          },
          action: 'setHidden',
        },
      ],
      class: 'forwarding-agent-wrapper',
      width: '375',
      label: translateFields(FIELDS_ENUM.forwardingAgent),
      value: null,
      disabled: false,
      hidden: false,
      required: false,
    },
    {
      type: 'Input',
      key: fieldNames.transportationZone,
      validationRules: [
        FormTemplateValidationRulesMap.MAXLENGTH,
        FormTemplateValidationRulesMap.ONLY_DIGITS_LETTERS,
      ],
      maxLength: 10,
      dependencyRules: [
        {
          sourceFieldKey: fieldNames.addressOverride,
          conditionType: HeaderLevelDependencyConditionEnum.matchingValue,
          conditionArgs: {
            values: [RADIO_NO_VALUE],
          },
          action: 'setHidden',
        },
      ],
      class: 'transportation-zone-wrapper',
      width: '375',
      label: translateFields(FIELDS_ENUM.transportationZone),
      value: null,
      disabled: false,
      hidden: false,
      required: false,
    },
    {
      type: 'Input',
      key: fieldNames.name1,
      validationRules: [
        FormTemplateValidationRulesMap.MAXLENGTH,
        FormTemplateValidationRulesMap.ONLY_DIGITS_LETTERS_SPACES,
      ],
      maxLength: 40,
      dependencyRules: [
        {
          sourceFieldKey: fieldNames.addressOverride,
          conditionType: HeaderLevelDependencyConditionEnum.matchingValue,
          conditionArgs: {
            values: [RADIO_NO_VALUE],
          },
          action: 'setHidden',
        },
        {
          sourceFieldKey: fieldNames.addressOverride,
          conditionType: HeaderLevelDependencyConditionEnum.matchingValue,
          conditionArgs: {
            values: [RADIO_YES_VALUE],
          },
          action: 'setRequired',
        },
      ],
      class: 'name-wrapper',
      width: '375',
      label: translateFields(FIELDS_ENUM.name),
      value: null,
      disabled: false,
      hidden: false,
      required: false,
    },
    {
      type: 'Input',
      key: fieldNames.name2,
      validationRules: [
        FormTemplateValidationRulesMap.MAXLENGTH,
        FormTemplateValidationRulesMap.ONLY_DIGITS_LETTERS_SPACES,
      ],
      maxLength: 40,
      dependencyRules: [
        {
          sourceFieldKey: fieldNames.addressOverride,
          conditionType: HeaderLevelDependencyConditionEnum.matchingValue,
          conditionArgs: {
            values: [RADIO_NO_VALUE],
          },
          action: 'setHidden',
        },
      ],
      class: 'name-co-wrapper',
      width: '375',
      label: translateFields(FIELDS_ENUM.nameCO),
      value: null,
      disabled: false,
      hidden: false,
      required: false,
    },
    {
      type: 'Input',
      key: fieldNames.street1,
      validationRules: [FormTemplateValidationRulesMap.MAXLENGTH],
      maxLength: 60,
      dependencyRules: [
        {
          sourceFieldKey: fieldNames.addressOverride,
          conditionType: HeaderLevelDependencyConditionEnum.matchingValue,
          conditionArgs: {
            values: [RADIO_NO_VALUE],
          },
          action: 'setHidden',
        },
        {
          sourceFieldKey: fieldNames.addressOverride,
          conditionType: HeaderLevelDependencyConditionEnum.matchingValue,
          conditionArgs: {
            values: [RADIO_YES_VALUE],
          },
          action: 'setRequired',
        },
      ],
      class: 'street-number-wrapper',
      width: '375',
      label: translateFields(FIELDS_ENUM.streetNumberName),
      value: null,
      disabled: false,
      hidden: false,
      required: false,
    },
    {
      type: 'Input',
      key: fieldNames.street2,
      validationRules: [FormTemplateValidationRulesMap.MAXLENGTH],
      maxLength: 40,
      dependencyRules: [
        {
          sourceFieldKey: fieldNames.addressOverride,
          conditionType: HeaderLevelDependencyConditionEnum.matchingValue,
          conditionArgs: {
            values: [RADIO_NO_VALUE],
          },
          action: 'setHidden',
        },
      ],
      class: 'shop-wrapper',
      width: '375',
      label: translateFields(FIELDS_ENUM.shopFloorSuiteEtc),
      value: null,
      disabled: false,
      hidden: false,
      required: false,
    },
    {
      type: 'Input',
      key: fieldNames.postalCode,
      validationRules: [
        FormTemplateValidationRulesMap.MAXLENGTH,
        FormTemplateValidationRulesMap.ONLY_DIGITS_LETTERS_DASH_SPACES,
      ],
      maxLength: 10,
      dependencyRules: [
        {
          sourceFieldKey: fieldNames.addressOverride,
          conditionType: HeaderLevelDependencyConditionEnum.matchingValue,
          conditionArgs: {
            values: [RADIO_NO_VALUE],
          },
          action: 'setHidden',
        },
        {
          sourceFieldKey: fieldNames.addressOverride,
          conditionType: HeaderLevelDependencyConditionEnum.matchingValue,
          conditionArgs: {
            values: [RADIO_YES_VALUE],
          },
          action: 'setRequired',
        },
      ],
      class: 'postal-code-wrapper',
      width: '375',
      label: translateFields(FIELDS_ENUM.postalCode),
      value: null,
      disabled: false,
      hidden: false,
      required: false,
    },
    {
      type: 'Input',
      key: fieldNames.city,
      validationRules: [
        FormTemplateValidationRulesMap.MAXLENGTH,
        FormTemplateValidationRulesMap.ONLY_LETTERS_SPACES,
      ],
      maxLength: 40,
      dependencyRules: [
        {
          sourceFieldKey: fieldNames.addressOverride,
          conditionType: HeaderLevelDependencyConditionEnum.matchingValue,
          conditionArgs: {
            values: [RADIO_NO_VALUE],
          },
          action: 'setHidden',
        },
        {
          sourceFieldKey: fieldNames.addressOverride,
          conditionType: HeaderLevelDependencyConditionEnum.matchingValue,
          conditionArgs: {
            values: [RADIO_YES_VALUE],
          },
          action: 'setRequired',
        },
      ],
      class: 'city-wrapper',
      width: '375',
      label: translateFields(FIELDS_ENUM.city),
      value: null,
      disabled: false,
      hidden: false,
      required: false,
    },
    {
      type: 'SelectSingle',
      key: fieldNames.country,
      dependencyRules: [
        {
          sourceFieldKey: fieldNames.addressOverride,
          conditionType: HeaderLevelDependencyConditionEnum.matchingValue,
          conditionArgs: {
            values: [RADIO_NO_VALUE],
          },
          action: HeaderLevelDependencyActionsEnum.setHidden,
        },
        {
          sourceFieldKey: fieldNames.addressOverride,
          conditionType: HeaderLevelDependencyConditionEnum.matchingValue,
          conditionArgs: {
            values: [RADIO_YES_VALUE],
          },
          action: HeaderLevelDependencyActionsEnum.setRequired,
        },
      ],
      items: [],
      class: 'country-wrapper',
      width: '375',
      label: translateFields(FIELDS_ENUM.country),
      isValueText: true,
      value: null,
      disabled: false,
      hidden: false,
      required: true,
    },
    {
      type: 'SelectSingle',
      key: fieldNames.region,
      validationRules: [],
      maxLength: 3,
      dependencyRules: [
        {
          sourceFieldKey: fieldNames.addressOverride,
          conditionType: HeaderLevelDependencyConditionEnum.matchingValue,
          conditionArgs: {
            values: [RADIO_NO_VALUE],
          },
          action: HeaderLevelDependencyActionsEnum.setHidden,
        },
        {
          sourceFieldKey: fieldNames.addressOverride,
          conditionType: HeaderLevelDependencyConditionEnum.matchingValue,
          conditionArgs: {
            values: [RADIO_YES_VALUE],
          },
          action: HeaderLevelDependencyActionsEnum.setRequired,
        },
        {
          sourceFieldKey: fieldNames.country,
          conditionType: HeaderLevelDependencyConditionEnum.filled,
          action: HeaderLevelDependencyActionsEnum.setAsyncItems,
        },
        {
          sourceFieldKey: fieldNames.country,
          conditionType: HeaderLevelDependencyConditionEnum.empty,
          action: HeaderLevelDependencyActionsEnum.setDisabled,
        },
        {
          conditionType: HeaderLevelDependencyConditionEnum.or,
          conditions: [
            {
              sourceFieldKey: fieldNames.country,
              conditionType: HeaderLevelDependencyConditionEnum.empty,
            },
            {
              sourceFieldKey: fieldNames.country,
              conditionType: HeaderLevelDependencyConditionEnum.changedValue,
            },
          ],
          action: HeaderLevelDependencyActionsEnum.setCleared,
        },
      ],
      class: 'region-wrapper',
      width: '375',
      label: translateFields(FIELDS_ENUM.region),
      isValueText: true,
      value: null,
      items: [],
      async: true,
      disabled: false,
      hidden: true,
      required: false,
    },
    {
      type: 'Input',
      key: fieldNames.district,
      validationRules: [
        FormTemplateValidationRulesMap.MAXLENGTH,
        FormTemplateValidationRulesMap.ONLY_DIGITS_LETTERS_SPACES,
      ],
      maxLength: 40,
      dependencyRules: [
        {
          sourceFieldKey: fieldNames.addressOverride,
          conditionType: HeaderLevelDependencyConditionEnum.matchingValue,
          conditionArgs: {
            values: [RADIO_NO_VALUE],
          },
          action: 'setHidden',
        },
      ],
      class: 'district-wrapper',
      width: '375',
      label: translateFields(FIELDS_ENUM.district),
      value: null,
      disabled: false,
      hidden: false,
      required: false,
    },
    {
      type: 'Input',
      key: fieldNames.telephone,
      validationRules: [FormTemplateValidationRulesMap.MAXLENGTH],
      maxLength: 30,
      dependencyRules: [
        {
          sourceFieldKey: fieldNames.addressOverride,
          conditionType: HeaderLevelDependencyConditionEnum.matchingValue,
          conditionArgs: {
            values: [RADIO_NO_VALUE],
          },
          action: 'setHidden',
        },
      ],
      class: 'telephone-wrapper',
      width: '375',
      label: translateFields(FIELDS_ENUM.telephone),
      value: null,
      disabled: false,
      hidden: false,
      required: false,
    },
  ],
  lineItemFields:
  {
    columnDefs: [
      {
        name: 'Line',
        field: 'line',
        required: false,
      },
      {
        name: translateFields(FIELDS_ENUM.materialNumber),
        field: 'materialNumber',
        required: true,
      },
      {
        name: translateFields(FIELDS_ENUM.orderQuantity),
        field: 'qty',
        required: true,
      },
      {
        name: translateFields(FIELDS_ENUM.contractNumber),
        field: 'contractNumber',
        required: false,
      },
      {
        name: translateFields(FIELDS_ENUM.crdLineLevel),
        field: 'crd',
        required: true,
      },
      {
        name: translateFields(FIELDS_ENUM.cancelDateLineLevel),
        field: 'cancelDate',
        required: true,
      },
      {
        name: translateFields(FIELDS_ENUM.usageCode),
        field: 'usageCode',
        required: false,
      },
      {
        name: translateFields(FIELDS_ENUM.plantCode),
        field: 'plantCode',
        required: false,
      },
      {
        name: translateFields(FIELDS_ENUM.requirementSegment),
        field: 'requirementSegment',
        required: false,
      },
      {
        name: translateFields(FIELDS_ENUM.vasCode),
        field: 'vasCodes',
        required: false,
      },
      {
        name: translateFields(FIELDS_ENUM.vasMaterial),
        field: 'vasMaterial',
        required: false,
      },
      {
        name: translateFields(FIELDS_ENUM.vasField1),
        field: 'vasField1',
        required: false,
      },
      {
        name: translateFields(FIELDS_ENUM.vasField2),
        field: 'vasField2',
        required: false,
      },
      {
        name: translateFields(FIELDS_ENUM.vasField3),
        field: 'vasField3',
        required: false,
      },
      {
        name: translateFields(FIELDS_ENUM.vasLongText),
        field: 'vasLongText',
        required: false,
      },
      {
        name: translateFields(FIELDS_ENUM.vasChargeCode),
        field: 'vasChargeCode',
        required: false,
      },
      {
        field: 'appSizes',
        name: '',
        required: false,
      },
      {
        field: 'availabilityData',
        name: '',
        required: false,
      },
    ],
    validationRules: {
      [fieldNames.contractNumber]: {
        [LineLevelValidationRulesEnum.required]: {
          required: 'columnDefined',
        },
        [LineLevelValidationRulesEnum.regExpValidation]: {
          pattern: '^[0-9]*$',
          errorMessage: 'Expected Format: 0123456789',
        },
        [LineLevelValidationRulesEnum.lengthValidation]: {
          maxLength: 10,
        },
      },
      [fieldNames.crd]: {
        [LineLevelValidationRulesEnum.required]: {
          required: 'columnDefined',
        },
      },
      [fieldNames.cancelDate]: {
        [LineLevelValidationRulesEnum.required]: {
          required: 'columnDefined',
        },
      },
      [fieldNames.materialNumber]: {
        [LineLevelValidationRulesEnum.required]: {
          required: 'columnDefined',
        },
        [LineLevelValidationRulesEnum.regExpValidation]: {
          pattern: '^([a-zA-Z0-9]{6})-([a-zA-Z0-9]{3})$',
          errorMessage: 'Expected Format: BQ3757-101',
        },
        [LineLevelValidationRulesEnum.lengthValidation]: {
          maxLength: 10,
        },
      },
      [fieldNames.qty]: {
        [LineLevelValidationRulesEnum.regExpValidation]: {
          pattern: '^[0-9]*$',
          errorMessage: 'Only digits are allowed',
        },
      },
    },
    dependencies: [
      {
        fieldName: fieldNames.contractNumber,
        sourceFieldKey: fieldNames.sourcingMethod,
        sourceFieldType: 'HeaderLevel',
        conditionType: HeaderLevelDependencyConditionEnum.matchingValue,
        conditionArgs: {
          values: [sourcingMethodValues.CONTRACT],
        },
        action: LineLevelDependencyActionEnum.setRequired,
      },
      {
        fieldName: fieldNames.vasCodes,
        sourceFieldKey: fieldNames.shippingCondition,
        sourceFieldType: 'HeaderLevel',
        conditionType: HeaderLevelDependencyConditionEnum.filled,
        action: LineLevelDependencyActionEnum.setValue,
      },
      {
        fieldName: fieldNames.vasField3,
        sourceFieldKey: fieldNames.shippingCondition,
        sourceFieldType: 'HeaderLevel',
        conditionType: HeaderLevelDependencyConditionEnum.filled,
        action: LineLevelDependencyActionEnum.setValue,
      },
    ],
    rowData: [],
  },
  name: 'Standard Order',
  orderType: {
    name: 'Standard Order',
    id: 5,
    sapId: 'ZSTA',
  },
};
