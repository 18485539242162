import state from './availability.state';
import actions from './availability.actions';
import mutations from './availability.mutations';
import getters from './availability.getters';

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
