import cloneDeep from 'lodash/cloneDeep';
import mutationTypes from './constants/mutations';
import { AVAILABILITY_FILTERS_EMPTY_STATE } from './constants/state';

export default {
  [mutationTypes.SET_LOADING](state, isLoading) {
    state.isLoading = isLoading;
  },

  [mutationTypes.SET_ALL_FILTERS](state, filters) {
    state.filters = cloneDeep(filters);
  },

  [mutationTypes.SET_FILTER_BY_KEY](state, { key, value }) {
    state.filters[key] = value;
  },

  [mutationTypes.CLEAR_ALL_FILTERS](state) {
    state.filters = cloneDeep(AVAILABILITY_FILTERS_EMPTY_STATE);
  },

  [mutationTypes.SET_AVAILABILITY_DETAILS](state, details) {
    state.availabilityDetails = details;
  },

  [mutationTypes.SET_PAGE_NUMBER](state, pageNumber) {
    state.pageNumber = pageNumber;
  },

  [mutationTypes.SET_PAGE_SIZE](state, pageSize) {
    state.pageSize = pageSize;
  },
};
