export default {
  SET_FOUND_EMPLOYEES(state, employees) {
    state.foundEmployees = employees;
  },

  SET_EMPLOYEE_EMAIL(state, email) {
    state.employeeEmail = email;
  },

  SET_IS_FOUND_EMPLOYEES_LOADING(state, isLoading) {
    state.isFoundEmployeesLoading = isLoading;
  },
};
