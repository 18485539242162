import actions from './user.actions';
import state from './user.state';
import mutations from './user.mutations';
import getters from './user.getters';

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
