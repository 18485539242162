<template>
  <div :class="[baseClassName, statusColorClass]" />
</template>

<script setup>
import { computed } from 'vue';

import { statusColorVariants } from './constants';

const props = defineProps({
  params: {
    type: Object,
    required: true,
  },
});

const baseClassName = 'indicator-renderer';

const statusColorClass = computed(() => {
  const colorVariant = statusColorVariants[props.params.data.status];

  return colorVariant ? `${baseClassName}__${colorVariant}` : '';
});
</script>

<style lang="scss">
@import 'podium-tokens';

.indicator-renderer {
  width: 8px;
  height: 8px;
  background-color: $podium-color-blue-600;
  border-radius: 50%;

  &__success {
    background-color: $podium-color-green-600;
  }

  &__danger {
    background-color: $podium-color-red-600;
  }
}
</style>
