import { USER_CONTEXT_ROLE_VALUES, APPROVAL_STATUSES } from './constants';

export default {
  getApprovalStatusValue: ({ approvalStatusValue }, { getIsManagerApprover }) => (getIsManagerApprover ? approvalStatusValue : ''),

  getApprovalSubmitRequest: (state, { getIsManagerApprover }) => {
    const {
      approvalOrderId, approvalStatusValue, approvalCommentValue, approvalRowData,
    } = state;

    const lineItems = approvalRowData;

    const requestData = {
      orderId: Number(approvalOrderId),
      isDraft: false,
    };

    if (approvalCommentValue) {
      requestData.comment = approvalCommentValue;
    }

    return getIsManagerApprover
      ? {
        ...requestData,
        status: approvalStatusValue || APPROVAL_STATUSES.OPEN,
      }
      : {
        ...requestData,
        lineItems,
      };
  },

  getApprovalSaveDraftRequest: (_, { getApprovalSubmitRequest }) => {
    return { ...getApprovalSubmitRequest, isDraft: true };
  },

  getIsManagerApprover: ({ userContextRole }) => userContextRole === USER_CONTEXT_ROLE_VALUES.MANAGER,
  getIsContractOwnerApprover: ({ userContextRole }) => userContextRole === USER_CONTEXT_ROLE_VALUES.CONTRACT_OWNER,

  getApprovalValidationError: (
    {
      formData, approvalStatusValue, approvalCommentValue, approvalOrderId,
    },
    { getIsManagerApprover },
  ) => {
    let errorMessage = '';

    if (getIsManagerApprover) {
      errorMessage = approvalStatusValue ? '' : 'Select order approval response to submit.';
    } else {
      const isAllLinesUpdated = formData.lineItemFields.rowData.every(
        ({ approverStatus }) => approverStatus.value !== APPROVAL_STATUSES.OPEN,
      );

      errorMessage = isAllLinesUpdated
        ? ''
        : 'All the lines should be reviewed and updated with your decision before you can submit.';
    }

    if (approvalCommentValue?.length > 512) {
      errorMessage = 'Max length (512) exceeded';
    }

    if (!Number(approvalOrderId)) {
      errorMessage = 'System error has occurred. Please refresh the page';
    }

    return errorMessage;
  },
};
