import employeeRepository from '@/api/now/employee';
import { LineLevelValidationRulesEnum } from '@/controllers/order/validations/line/index';
import { fieldNames } from '@/enums/singleOrderCreation/fieldNames';

const asyncValidate = {
  [fieldNames.contractOwnerName]: contractOwnerNameRuleValidation,
};

export async function asyncRuleValidation({
  fieldName, formFieldData, errorMessage,
}) {
  return (await asyncValidate[fieldName]?.({ formFieldData, errorMessage })) ?? formFieldData;
}

export async function contractOwnerNameRuleValidation({
  formFieldData,
  errorMessage = 'Invalid Contract Owner Name',
}) {
  formFieldData.errors = formFieldData.errors.filter(
    (error) => !error[LineLevelValidationRulesEnum.asyncValidation],
  );
  if (!formFieldData.value) {
    return formFieldData;
  }

  try {
    let responseData;
    if (formFieldData.value.includes('@')) {
      responseData = await employeeRepository.getEmployeeByEmail(formFieldData.value);
    } else {
      responseData = await employeeRepository.getEmployeeById(formFieldData.value);
    }

    if (responseData?.data?.nikeId) {
      formFieldData.value = responseData.data.nikeId;
    } else {
      formFieldData.errors.push({ [LineLevelValidationRulesEnum.asyncValidation]: errorMessage });
    }
  } catch (err) {
    console.error(err);
    formFieldData.errors.push({ [LineLevelValidationRulesEnum.asyncValidation]: errorMessage });
  }

  return formFieldData;
}
