/* eslint-disable default-param-last */
import vasCodesOptions from '@/enums/singleOrderCreation/vasCodesOptions';
import ValidationRulesEnum from '@/enums/validationRules/SOC.js';
import { fieldNames } from '@/enums/singleOrderCreation/fieldNames';
import { addDaysToDate, addBusinessDaysToDate } from '@/utils/singleOrderCreate';

const setNewValue = {
  [fieldNames.vasCodes]: setNewVasCodes,
  [fieldNames.vasField3]: setNewVasField3,
  [fieldNames.crd]: setNewCRD,
  [fieldNames.cancelDate]: setNewCancelDate,
};

export function dependencyRuleActionSetValue({
  fieldName,
  result,
  lineLevelData,
  dependencyRule = {},
}) {
  if (result === undefined || result === null) {
    return lineLevelData;
  }

  const lineLevel = {
    ...lineLevelData,
    [fieldName]: {
      ...lineLevelData[fieldName],
      value:
        setNewValue[fieldName]?.(result, lineLevelData[fieldName], dependencyRule.actionArgs)
        ?? result,
    },
  };

  return lineLevel;
}

/**
 * @param {string|null} shippingConditionValue
 * @param {string[]} vasCodesValue
 * @returns {string[]}
 *
 * vasCodes should be array - initial value is empty string right now
 */
function setNewVasCodes(shippingConditionValue = null, { value: vasCodesValue }) {
  const { S01 } = vasCodesOptions;

  if (isDefaultShippingCondition(shippingConditionValue) || !shippingConditionValue) {
    return vasCodesValue.filter((vasCode) => vasCode !== S01.value);
  }

  return [...new Set([...vasCodesValue, S01.value])];
}

function setNewVasField3(shippingConditionValue = null, { value: vasField3Value }) {
  let newVasField3Value = vasField3Value;

  if (isDefaultShippingCondition(shippingConditionValue) || !shippingConditionValue) {
    newVasField3Value = vasField3Value
      .split(',')
      .filter((vasCodeWithComment) => !vasCodeWithComment.includes(vasCodesOptions.S01.value))
      .join(',');
  } else {
    const vasField3exist = vasField3Value
      .split(',')
      .filter((vasCodeWithComment) => vasCodeWithComment.includes(vasCodesOptions.S01.value),
      ).length;
    if (!vasField3exist) {
      newVasField3Value = `${vasField3Value}${vasField3Value ? ',' : ''}${
        vasCodesOptions.S01.value
      }=`;
    }
  }

  return newVasField3Value;
}

function setNewCRD(result, { value: currentValue }) {
  if (result) {
    return currentValue || result;
  }
}

function setNewCancelDate(result, { value: currentValue }, actionArgs = {}) {
  if (!currentValue || actionArgs.force) {
    if (actionArgs.unit === 'calendarDay') {
      return addDaysToDate(result, actionArgs.add);
    }
    if (actionArgs.unit === 'businessDay') {
      return addBusinessDaysToDate(result, actionArgs.add);
    }
  } else {
    return currentValue;
  }
}

function isDefaultShippingCondition(shippingConditionValue = '') {
  return shippingConditionValue === ValidationRulesEnum.formatRules.shippingCondition.defaultValue;
}
