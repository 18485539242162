import { validateHeader, validateLine } from '@/controllers/order/utils/validation';
import { formatConfig } from '@/controllers/order/utils/config';
import { disableHeaderFields, disableLineFields } from '@/controllers/order/utils/mutation';
import { completeHeaderFields } from '@/controllers/order/utils/field';
import { applyDependencies as _applyDependencies } from '@/controllers/order/utils/dependency';
import { updateOptions as _updateOptions } from '@/controllers/order/utils/options';
import { mapFields as _mapFields } from '@/controllers/order/utils/mappings';

import { CONFIG } from '@/controllers/order/enums/config';

import { AvailabilityBusinessTypesEnum } from '@/enums/order/availabilityBusinessTypes';

export class OrderController {
  columnRequiredStatusUpdates = new Map();

  config = {};

  constructor(orderConfig) {
    if (!orderConfig) return;

    this.config = formatConfig(orderConfig);

    const defaultProperties = structuredClone({
      ...CONFIG.DEFAULT_PROPERTIES,
      ...orderConfig,
    });

    this.formTemplate = {
      ...defaultProperties,
      fields: completeHeaderFields(orderConfig.fields),
    };
  }

  updateHeaderField(formData, updatedField) {
    formData.fields = formData.fields.map(field => (updatedField.key === field.key ? updatedField : field));

    return formData;
  }

  updateLineField(formData, rows) {
    formData.lineItemFields.rowData = rows;

    return formData;
  }

  updateSingleRow(formData, rowToUpdate) {
    const updatedRowData = formData.lineItemFields.rowData;
    const rowIndex = updatedRowData.findIndex(row => row.id === rowToUpdate.id);

    updatedRowData[rowIndex] = rowToUpdate;

    return formData;
  }

  disableAllFields(form) {
    return ({
      fields: disableHeaderFields(form),
      lineItemFields: disableLineFields(form),
    });
  }

  async validate(formData, updatedField = {}) {
    formData.fields = await validateHeader(this.config.header[updatedField.key], formData, updatedField);
    formData.lineItemFields.rowData = await validateLine(this.config.line[updatedField.name], formData, updatedField);

    return formData;
  }

  async applyDependencies(form, initialForm, updatedField, operation) {
    return await _applyDependencies({
      form,
      operation,
      initialForm,
      updatedField,
      config: this.config,
      columnRequiredStatusUpdates: this.columnRequiredStatusUpdates,
    });
  }

  // TODO: This method is temporary and its logic will need to be moved to SOC controller
  // dependencies. Corresponding conditions/actions should be added to ZFRE config.
  // Currently this method acts as an explicit dependency.
  validateBusinessTypes(form, lineData) {
    const sourcingMethodField = form.fields.find(({ key }) => key === 'sourcingMethod');
    const isContractMethod = sourcingMethodField?.value === 'CONTRACT';

    if (!isContractMethod) return;

    const soldToField = form.fields.find(({ key }) => key === 'soldToNumber');
    const orderBusinessType = soldToField?.value?.availabilityBusinessType;
    const lineFieldName = 'contractOwnerName';

    if (!orderBusinessType) return;

    let isEveryRowSportsMarketing = true;

    const setContractNumberRequired = row => {
      const { contractBusinessType } = row.availabilityData;

      const isSportsMarketing = contractBusinessType === orderBusinessType
        && contractBusinessType === AvailabilityBusinessTypesEnum.SPORTS_MARKETING;

      row[lineFieldName].required = !isSportsMarketing;

      if (!isSportsMarketing) {
        isEveryRowSportsMarketing = false;
      }
    };

    if (!lineData?.contractBusinessType) {
      form.lineItemFields.rowData.forEach(setContractNumberRequired);
    } else {
      const row = form.lineItemFields.rowData[lineData.rowIndex];
      setContractNumberRequired(row);
    }

    if (isEveryRowSportsMarketing) {
      this.columnRequiredStatusUpdates.set(lineFieldName, {
        field: lineFieldName,
        required: false,
      });
    }
  }

  updateOptions(form, orderType, userPermissions, geo) {
    return _updateOptions(form, orderType, userPermissions, geo);
  }

  mapFields(formData, formDataFromBE, draft) {
    return _mapFields(formData, formDataFromBE, draft);
  }
}
