import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';

import 'vuetify/styles';

import vuetifyParams from '../enums/vuetify/params';

export function registerVuetify(app) {
  const vuetify = createVuetify({
    components,
    directives,
    ...vuetifyParams,
  });

  app.use(vuetify);
}
