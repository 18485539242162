import radioOptions from "@/enums/myOrders/radioOptions";

const myOrdersData = {
  myOrders: [],
  orderTypes: [],
  myOrdersSummary: {},
  myOrdersErrors: {},

  isFetchingOrders: false,
  isMyOrdersErrorLoading: false,

  tableDataLoadingError: null,
  filtersDataLoadingError: null,
};

const filtersSelection = {
  selectedOrderTypes: [],
  selectedOrderOwnership: radioOptions[0].value,
};

const myOrdersTable = {
  tableParams: {},
  gridApi: {},

  datasource: {},
  columnDef: [],

  orderId: null,
  pageSize: 50,
  pageNumber: 0,
  pageTokens: [null],

  sortedColumnName: null,
  sortedColumnOrder: null,

  tableFilters: {},

  totalAvailablePagesNumber: null,
  totalAvailableOrdersNumber: null,

  userPreferences: [],
};

export default {
  ...myOrdersData,
  ...myOrdersTable,
  ...filtersSelection,
};
