import { APPROVAL_STATUSES_VALUES } from './constants';

export default {
  isApprovalFlow: false,
  approvalStatusValue: '',
  approvalCommentValue: '',
  approvalStatuses: APPROVAL_STATUSES_VALUES,
  totalApprovalCount: 0,
  isApprovalsLoading: false,

  approvalMajorFieldsData: {},
  isOrderApprovalSubmitLoading: false,
  isOrderApprovalDraftSaveLoading: false,
  approvalRowData: [],

  approvalOrderId: '',
  userContextRole: null,

  approvalSubmitError: '',
  selectedOrder: {},
};
