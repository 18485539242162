import testingContext from '../../plugins/jest-context';

let requireModule;

const isJest = process.env.JEST_WORKER_ID !== undefined;

if (isJest) {
  requireModule = testingContext('./', false, /.vue$/);
} else {
  requireModule = require.context('./', false, /.vue$/);
}

const icons = {};

requireModule.keys().forEach((filename) => {
  const iconName = filename.replace(/(\.\/|\.vue)/g, '');
  icons[iconName] = requireModule(filename).default || requireModule(filename);
});

export default icons;
