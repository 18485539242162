const myApprovalsData = {
  myApprovals: null,
  isTableDataLoading: null,
};

const myApprovalsTable = {
  pageSize: 25,
  pageNumber: 0,
  totalAvailablePagesNumber: null,
  totalAvailableOrdersNumber: null,
};

export default {
  ...myApprovalsData,
  ...myApprovalsTable,
};
