export const HeaderLevelDependencyConditionEnum = {
  or: 'or',
  and: 'and',
  empty: 'empty',
  filled: 'filled',
  visible: 'visible',
  oneItem: 'oneItem',
  certainValue: 'certainValue',
  changedValue: 'changedValue',
  matchingValue: 'matchingValue',
  notCertainValue: 'notCertainValue',
  hasOneSelectItem: 'hasOneSelectItem',
  setOfConditions: 'setOfConditions',
  isSportsMarketing: 'isSportsMarketing',
  isNotSportsMarketing: 'isNotSportsMarketing',
};

export const HeaderLevelDependencyActionsEnum = {
  setValue: 'setValue',
  setItems: 'setItems',
  setHidden: 'setHidden',
  setVisible: 'setVisible',
  setCleared: 'setCleared',
  setMinDate: 'setMinDate',
  setDisabled: 'setDisabled',
  setRequired: 'setRequired',
  setDateValue: 'setDateValue',
  clearTouched: 'clearTouched',
  clearRequired: 'clearRequired',
  setAsyncItems: 'setAsyncItems',
  setParentValue: 'setParentValue',
  setNewManagers: 'setNewManagers',
  setTooltipMessage: 'setTooltipMessage',
  setFirstItemAsValue: 'setFirstItemAsValue',
};
