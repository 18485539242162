export default {
  SET_RECENTLY_UPLOADED_FILENAMES(state, filenames) {
    state.recentlyUploadeFilenames = filenames;
  },

  SET_FILE_PROCESS_STATUS_LIST(state, payload) {
    state.fileProcessStatusList = payload;
  },

  RESET_FILE_PROCESS_STATUS_LIST(state) {
    state.fileProcessStatusList = [];
  },

  SET_FAILED_FILES(state, failedFiles = []) {
    state.failedFiles = failedFiles;
  },
};
