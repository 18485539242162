const getDefaultStructure = (field) => {
  return field || { dependencies: [], validations: [] };
};

const addHeaderFieldValidationsToMap = (map, field) => {
  map.header[field.key] ??= getDefaultStructure(map[field.key]);
  map.header[field.key].validations = field.validationRules;
};

const addLineFieldValidationsToMap = (map) => ([sourceField, validations]) => {
  map.line[sourceField] ??= getDefaultStructure(map[sourceField.fieldName]);
  map.line[sourceField].validations = validations;
};

const getTargetKey = (type, entity) => {
  return type === 'LineLevel' ? entity.fieldName : entity.key;
};

const addFieldDependenciesToMap = (map, dependency) => {
  const { sourceFieldType, sourceFieldKey, targetType } = dependency;
  const mapLevel = sourceFieldType === 'LineLevel' ? map.line : map.header;

  if (targetType === 'LineLevel') {
    map.lineConfigDeps.push(dependency);
  }

  mapLevel[sourceFieldKey] = getDefaultStructure(mapLevel[sourceFieldKey]);
  mapLevel[sourceFieldKey].dependencies.push(dependency);
};

const addDependencyToMap = (map, targetType, field) => dependencyRule => {
  const { ...ruleFields } = dependencyRule;
  const dependencyEntity = field || dependencyRule;
  const targetKey = getTargetKey(targetType, dependencyEntity);

  if (ruleFields.conditions) {
    ruleFields.conditions.forEach(condition => {
      const dependency = {
        targetKey,
        targetType,
        ...condition,
        ...dependencyRule,
      };

      addFieldDependenciesToMap(map, dependency);
    });
  } else {
    const dependency = {
      ...dependencyRule,
      targetKey,
      targetType,
    };

    addFieldDependenciesToMap(map, dependency);
  }
};

const getConfigHeaderPart = (headerFields, map) => {
  headerFields.forEach((field) => {
    addHeaderFieldValidationsToMap(map, field);
    field.dependencyRules.forEach(addDependencyToMap(map, 'HeaderLevel', field));
  });
};

const getConfigLinePart = (lineFields, map) => {
  Object.entries(lineFields.validationRules).forEach(addLineFieldValidationsToMap(map));
  lineFields.dependencies.forEach(addDependencyToMap(map, 'LineLevel'));
};

export const formatConfig = ({ fields: headerFields, lineItemFields: lineFields }) => {
  const map = {
    header: {},
    line: {},
    lineConfigDeps: [],
  };

  getConfigHeaderPart(headerFields, map);
  getConfigLinePart(lineFields, map);

  return map;
};
