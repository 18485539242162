export const FILTER_KEYS = {
  SOURCING_METHOD: 'sourcingMethod',
  PRODUCT_CODES: 'productCodes',
  GTINS: 'gtins',
  SALES_ORGANIZATION: 'salesOrganization',
  SALES_CHANNEL: 'salesChannel',
  CONTRACT_NUMBER: 'contractNumber',
  INVENTORY_QUALITY_CODE: 'inventoryQualityCode',
  INVENTORY_SEGMENTATION_CODE: 'inventorySegmentationCode',
  PLANT_CODE: 'plantCode',
};
