/**
 * @link: https://confluence.nike.com/pages/viewpage.action?spaceKey=EHQSD&title=Environment+Mapping
 */
import test from "@/config/test.json";
import local from "@/config/local.json";
import delta from "@/config/qa-delta.json";
import oscar from "@/config/qa-oscar.json";
import sierra from "@/config/qa-sierra.json";
import production from "@/config/production.json";
import development from "@/config/development.json";
import productionEcho from "@/config/production-echo.json";

const { VUE_APP_ENV } = process.env;

let cmBaseUrl;
let orderCancellationUrl;
let businessDashboardUrl;
let productionSupportUrl;

switch (VUE_APP_ENV) {
  case "local":
    cmBaseUrl = local.cmBaseUrl;
    orderCancellationUrl = local.orderCancellationUrl;
    businessDashboardUrl = local.businessDashboardUrl;
    productionSupportUrl = local.productionSupportUrl;
    break;
  case "dev":
    cmBaseUrl = development.cmBaseUrl;
    orderCancellationUrl = development.orderCancellationUrl;
    businessDashboardUrl = development.businessDashboardUrl;
    productionSupportUrl = development.productionSupportUrl;
    break;
  case "test":
    cmBaseUrl = test.cmBaseUrl;
    orderCancellationUrl = test.orderCancellationUrl;
    businessDashboardUrl = test.businessDashboardUrl;
    productionSupportUrl = test.productionSupportUrl;
    break;
  case "qa-delta":
    cmBaseUrl = delta.cmBaseUrl;
    orderCancellationUrl = delta.orderCancellationUrl;
    businessDashboardUrl = delta.businessDashboardUrl;
    productionSupportUrl = delta.productionSupportUrl;
    break;
  case "qa-sierra":
    cmBaseUrl = sierra.cmBaseUrl;
    orderCancellationUrl = sierra.orderCancellationUrl;
    businessDashboardUrl = sierra.businessDashboardUrl;
    productionSupportUrl = sierra.productionSupportUrl;
    break;
  case "qa-oscar":
    cmBaseUrl = oscar.cmBaseUrl;
    orderCancellationUrl = oscar.orderCancellationUrl;
    businessDashboardUrl = oscar.businessDashboardUrl;
    productionSupportUrl = oscar.productionSupportUrl;
    break;
  case "prod":
    cmBaseUrl = production.cmBaseUrl;
    orderCancellationUrl = production.orderCancellationUrl;
    businessDashboardUrl = production.businessDashboardUrl;
    productionSupportUrl = production.productionSupportUrl;
    break;
  case "prod-echo":
    cmBaseUrl = productionEcho.cmBaseUrl;
    orderCancellationUrl = productionEcho.orderCancellationUrl;
    businessDashboardUrl = productionEcho.businessDashboardUrl;
    productionSupportUrl = productionEcho.productionSupportUrl;
    break;
  default:
}

function getNowIamUrl() {
  // Prod Echo has a special link (not covered by Akamai)
  if (VUE_APP_ENV === "prod-echo") {
    return "https://now-iam-ui-prod-echo.mp-ordermgmt-prod.nikecloud.com";
  }

  const env = VUE_APP_ENV === "prod" ? "" : `-${VUE_APP_ENV}`;

  return `https://osd-now-iam${env}.nike.com/`;
}

export default {
  CM_L1: `${cmBaseUrl}/overview`,
  CM_L2: `${cmBaseUrl}/details`,

  BUSINESS_DASHBOARD: businessDashboardUrl,

  NOW_IAM: getNowIamUrl(),

  ORDER_CANCELLATION: orderCancellationUrl,
  PRODUCTION_SUPPORT: productionSupportUrl,
};
