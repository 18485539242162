export const CONFIG = {
  DEFAULT_PROPERTIES: {
    name: 'OrderController',
    orderType: undefined,
    fields: [],
    blockNames: [],
    lineItemFields: [],
  },
};

export const FIELD = {
  DEFAULT_PROPERTIES: {
    isValid: true,
    errors: [],
    touched: false,
    phase: 'beforeCreate',
  },
};
