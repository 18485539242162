import uploadedFilesRepository from '@/api/now/uploadedFiles';
import { createFormData } from './helpers/uploadToServer';

export default {
  async sendFiles({ rootState }, uploadedFiles) {
    const { orderTypes } = rootState.fileValidationRules.validationRules;

    const files = [...uploadedFiles.values()];
    const formData = createFormData(files, orderTypes);

    return uploadedFilesRepository.sendFiles(formData);
  },

  async getFileProcessStatusList(
    {
      commit, dispatch, state: { fileProcessStatusList = [] },
    },
    files = [],
  ) {
    try {
      const fileNames = files.map(({ fileName }) => fileName);
      const { data: processingFilesList = [] } = await uploadedFilesRepository.fetchFileProcessStatus(fileNames);

      if (processingFilesList) {
        commit('SET_FILE_PROCESS_STATUS_LIST', processingFilesList);

        if (processingFilesList.find((file) => file.status === 'FAIL')) {
          await dispatch('getNotSucceededFiles');
        }

        return processingFilesList;
      }

      return fileProcessStatusList;
    } catch (e) {
      console.log(e);
    }
  },

  async getNotSucceededFiles({ commit }) {
    try {
      const { data: notSucceededFilesList = [] } = await uploadedFilesRepository.getNotSucceededFiles();

      commit('SET_FAILED_FILES', notSucceededFilesList);
    } catch (e) {
      console.log(e);
    }
  },

  async deleteFile({ dispatch }, fileName = '') {
    try {
      await uploadedFilesRepository.deleteFile(fileName);
      await dispatch('getNotSucceededFiles');
    } catch (e) {
      console.log(e);
    }
  },

  resetFileProcessStatusList({ commit }) {
    return commit('RESET_FILE_PROCESS_STATUS_LIST');
  },

  setRecentlyUploadedFilenames({ commit }, filenames) {
    commit('SET_RECENTLY_UPLOADED_FILENAMES', filenames);
  },
};
