import { ReturnOrderTypeSapIdNameEnum, SamplesOrderTypeSapIdNameEnum } from '@/enums/order/orderType.js';

import { fieldNames } from '@/enums/singleOrderCreation/fieldNames';

import { mandatoryRadioFields } from '@/enums/SOC/mandatoryRadioFields';
import { sourcingMethodValues } from '@/enums/validationRules/dropdownValues.js';

export default {

  headerLevel: (state) => state.formData.fields,
  rowData: (state) => state.formData.lineItemFields.rowData,
  firstRowInAvailabilityQueue: (state) => {
    return state.rowsPendingForAvailability[0];
  },

  isReturnOrderType: (state) => Object.keys(ReturnOrderTypeSapIdNameEnum).includes(state.orderType.sapId),

  isSamplesOrderType: (state) => Object.keys(SamplesOrderTypeSapIdNameEnum).includes(state.orderType.sapId),

  hasFormErrors: (state) => {
    const headerLevelErrors = state.formData.fields.some((field) => (!field.disabled && !field.hidden ? field?.errors?.length : false),
    );

    const lineLevelErrors = state.formData.lineItemFields.rowData.some((row) => {
      return Object.values(row).some((rowField) => {
        if (!rowField) {
          return false;
        }

        return !rowField.disabled ? rowField?.errors?.length : false;
      });
    });

    return headerLevelErrors || lineLevelErrors;
  },

  isRequiredHeaderFieldsFilled: (state) => {
    return state.formData.fields.every(({
      value, required, hidden, disabled, type, key,
    }) => {
      if (!required || hidden || disabled) {
        return true;
      }

      if (type === 'BaseRadioGroup' && !mandatoryRadioFields.includes(key)) {
        return true;
      }

      return !!value;
    });
  },

  isLineLevelRequiredFieldsFilled: (state) => {
    return state.formData.lineItemFields.rowData?.every((row) => {
      return Object.values(row).every((field) => {
        return field?.required ? !!field?.value : true;
      });
    });
  },
  getTotalQuantity: state => (
    state.formData.lineItemFields.rowData.reduce((totalQuantity, row) => {
      const rowQuantity = row?.qty?.value || 0;
      totalQuantity += (parseInt(rowQuantity) || 0);

      return totalQuantity;
    }, 0)
  ),

  getFields: state => state.formData.fields,

  // MY APPROVALS
  getLineApproverStatuses: state => {
    return state.formData.lineItemFields.rowData.map(({ approverStatus }) => {
      return ({ status: approverStatus.value, id: approverStatus.FetchedRowId });
    });
  },

  getIsContractReference: (state) => {
    return (
      state.formData.fields.find(({ key }) => key === fieldNames.sourcingMethod)?.value
      === sourcingMethodValues.CONTRACT
    );
  },
};
