import approvalsRepository from '@/api/now/approvals';

import * as mutations from './mutation-types';

export default {
  setIsApprovalFlow({ commit }, isApprovalFlow) {
    commit(mutations.SET_IS_APPROVAL_FLOW, isApprovalFlow);
  },

  setApprovalStatusValue({ commit }, status) {
    commit(mutations.SET_APPROVAL_STATUS_VALUE, status);
  },

  setApprovalCommentValue({ commit }, comment) {
    commit(mutations.SET_APPROVAL_COMMENT_VALUE, comment);
  },

  resetSelectedOrder({ commit }) {
    commit(mutations.SET_SELECTED_ORDER, null);
  },

  async fetchApprovalsCount({ commit }) {
    commit(mutations.SET_IS_APPROVALS_LOADING, true);

    try {
      const request = {
        pageNumber: 0,
        pageSize: 1,
        sortBy: 'ID',
        sortOrder: 'ASC',
      };

      const { data } = await approvalsRepository.fetchMyApprovals(request);

      commit(mutations.SET_TOTAL_APPROVAL_COUNT, data.totalOrders);
    } catch (err) {
      console.log(err);
    } finally {
      commit(mutations.SET_IS_APPROVALS_LOADING, false);
    }
  },

  resetApprovalData({ commit }) {
    commit(mutations.SET_IS_APPROVAL_FLOW, false);
    commit(mutations.SET_APPROVAL_STATUS_VALUE, '');
    commit(mutations.SET_APPROVAL_COMMENT_VALUE, '');
    commit(mutations.SET_APPROVAL_ROW_DATA, []);
    commit(mutations.SET_APPROVAL_ORDER_ID, '');
    commit(mutations.SET_USER_CONTEXT_ROLE, null);
    commit(mutations.SET_APPROVAL_SUBMIT_ERROR, '');
  },

  setLinesApprovalStatus({ commit }, payload) {
    commit(mutations.SET_LINES_APPROVAL_STATUS, payload);
  },

  async submitOrderApproval({ commit, getters }) {
    commit(mutations.SET_APPROVAL_SUBMIT_ERROR, '');

    if (getters.getApprovalValidationError) return;

    commit(mutations.SET_ORDER_APPROVAL_SUBMIT_LOADING, true);

    try {
      const request = getters.getApprovalSubmitRequest;
      await approvalsRepository.updateApprovalStatus(request);
    } catch (e) {
      commit(
        mutations.SET_APPROVAL_SUBMIT_ERROR,
        'Error occured during order submit, please try again later.',
      );
      console.log(e);
    } finally {
      commit(mutations.SET_ORDER_APPROVAL_SUBMIT_LOADING, false);
    }
  },

  async saveOrderApprovalDraft({ commit, getters }) {
    commit(mutations.SET_APPROVAL_SUBMIT_ERROR, '');

    commit(mutations.SET_ORDER_APPROVAL_DRAFT_SAVE_LOADING, true);

    try {
      const request = getters.getApprovalSaveDraftRequest;

      await approvalsRepository.updateApprovalStatus(request);
    } catch (e) {
      commit(
        mutations.SET_APPROVAL_SUBMIT_ERROR,
        'Error occured during order submit, please try again later.',
      );
      console.log(e);
    } finally {
      commit(mutations.SET_ORDER_APPROVAL_DRAFT_SAVE_LOADING, false);
    }
  },
};
