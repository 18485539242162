import localStorageKey from '@/enums/browserStorage/localStorageKeyEnum';

export default {
  SET_AVAILABLE_EXCEPTION_GROUPS(state, payload) {
    state.availableExceptionGroups = payload;
  },

  SET_SOLD_TO(state, payload) {
    state.soldTo = payload;
  },

  SET_SHIP_TO(state, payload) {
    state.shipTo = payload;
  },

  SET_PRE_APPLIED_SOLD_TO(state, payload) {
    state.preAppliedSoldTo = payload;
  },

  SET_PRE_APPLIED_SHIP_TO(state, payload) {
    state.preAppliedShipTo = payload;
  },

  SET_ORDER_TYPES(state, payload) {
    state.orderTypes = payload;
  },

  SET_EXCEPTION_GROUPS(state, payload) {
    state.exceptionGroups = payload;
  },

  SET_DATE_FROM(state, dateFrom) {
    state.dateFrom = dateFrom;
  },

  SET_DATE_TO(state, dateTo) {
    state.dateTo = dateTo;
  },

  CLEAR_ALL_FILTERS(state) {
    const {
      orderTypes, soldTo, shipTo, exceptionGroups,
    } = state;

    localStorage.removeItem('globalFilter');

    const newFilters = {
      orderTypes,
      soldTo,
      shipTo,
      exceptionGroups,
    };

    const dateFilters = ['dateFrom', 'dateTo'];

    Object.keys(newFilters).forEach((filter) => {
      state[filter] = [];
      state.appliedFilters[filter] = [];
    });

    dateFilters.forEach((fieldName) => {
      state[fieldName] = '';
      state.appliedFilters[fieldName] = '';
    });
  },

  APPLY_ALL_FILTERS(state) {
    const {
      orderTypes, soldTo, shipTo, exceptionGroups, dateFrom, dateTo,
    } = state;

    const newFilters = {
      orderTypes,
      soldTo,
      shipTo,
      exceptionGroups,
      dateFrom,
      dateTo,
    };

    state.appliedFilters = { ...newFilters };

    localStorage.setItem(localStorageKey.globalFilter, JSON.stringify(newFilters));
  },
};
