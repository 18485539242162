import { cloneDeep } from 'lodash';

export const outageEntity = {
  startDate: { date: '', time: '' },
  expireDate: { date: '', time: '' },
  zone: '',
  enabled: false,
  initiator: '',
};

export default {
  plannedOutage: cloneDeep(outageEntity),
  isServiceDown: false,
  isLoading: false,
};
