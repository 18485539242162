import mutations from './mutationTypes';

const resources = {
  [mutations.SET_RESOURCES](state, resources) {
    state.resources = resources;
  },
  [mutations.SET_IS_RESOURCES_LOADING](state, isResourcesLoading) {
    state.isResourcesLoading = isResourcesLoading;
  },
};

export default {
  ...resources,
};
