import i18n from '@/plugins/i18n';

import {
  isDateField,
  isLogicalField,
  isSecondLevelDependencyField,
} from '@/controllers/order/utils/field';
import { fieldNames } from '@/enums/singleOrderCreation/fieldNames';
import { convertDateFormat, isPastDate } from '@/utils/dates';
import dateFormats from '@/enums/dates/dateFormats';
import { addDaysToDate } from '@/utils/singleOrderCreate';

const mapInitialField = (result, feField) => ({
  ...feField,
  touched: true,
});

const setValue = (result, feField, beFields, draft) => {
  let value;
  let errors;

  const beField = beFields[feField.key];

  if (isLogicalField(feField.type)) {
    value = beField?.value?.toLowerCase() === 'y';
  } else {
    value = beField?.value;
  }

  if (feField.key === fieldNames.soldToNumber) {
    value = { id: value, shipTos: [{ id: beFields.shipToNumber?.value }] };
  }

  if (isDateField(feField.key)) {
    value = convertDateFormat(beField?.value, dateFormats.CHINA);
    errors = isPastDate(beField?.value) ? [{ error: i18n.global.t('widgets.errorMessages.incorrectDate') }] : [];
  }

  if (draft && beField?.errorType === 'REQUIRED_FIELD') {
    value = null;
  }

  return {
    ...result,
    value: value || result.value,
    ...(errors && { errors }),
  };
};

const showIfHasBEValue = (result, feField, beFields) => {
  const beField = beFields[feField.key];

  if (beField) {
    return {
      ...result,
      hidden: false,
    };
  }

  return result;
};

const hideIfSecondLevelField = (result, feField, beFields, draft) => {
  const beField = beFields[feField.key];

  if (!draft && isSecondLevelDependencyField(feField.key) && !beField) {
    return {
      ...result,
      hidden: true,
    };
  }

  return result;
};

const setMinDates = (result, feField, beFields, draft) => {
  if (draft) {
    let { minDate } = feField;

    if (feField.key === fieldNames.cancelDate && beFields[fieldNames.crd]?.value) {
      minDate = addDaysToDate(beFields[fieldNames.crd]?.value, 6);
    }

    if (feField.key === fieldNames.validToDate && beFields[fieldNames.validFromDate]?.value) {
      minDate = addDaysToDate(beFields[fieldNames.validFromDate]?.value, 1);
    }

    if (minDate) {
      return {
        ...result,
        minDate,
      };
    }
  }

  return result;
};

const setNotDisabled = (result) => ({
  ...result,
  disabled: false,
});

const headerMiddlewares = [
  mapInitialField,
  setValue,
  showIfHasBEValue,
  hideIfSecondLevelField,
  setMinDates,
  setNotDisabled,
];

export const mapHeaderFields = (form, fieldsFromBackEnd, draft) => {
  const { fields } = form;

  return fields.map(field => headerMiddlewares.reduce((result, middleware) => {
    return middleware(result, field, fieldsFromBackEnd, draft);
  }, {}));
};
