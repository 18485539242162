// TODO: The name is misleading since it behaves as Not Matching Value for HL fields use case
export const dependencyRuleMatchingValue = ({
  field, dependency, updatedField,
}) => {
  // * HL and LL dependencies
  if (dependency.conditionArgs) {
    return !!dependency.conditionArgs.values.includes(updatedField.value);
  }

  // ! Not matching value case
  return !field?.parentVisibilityValues?.includes(updatedField.value);
};
