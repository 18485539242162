/**
 * @file Dictionary that contains all endpoints in NOW application
 *
 * @link https://developer.niketech.com/docs/projects/Nike%20Order%20Workspace?tab=api
 */

export const ORDER_REPO = {
  ORDERS_SUMMARY: "v1/repo/ordersSummary",
  ORDERS: "v2/repo/orders",
  FAILED_ORDER_DETAILS: "v1/repo/failedOrderDetails",
};

// Comp / Approval - Comp order specific APIs
export const COMP = {
  MY_APPROVALS: "v1/approval/myApprovals",
  APPROVAL: "v1/approval",
};

export const TEMPLATE = {
  DOWNLOAD_TEMPLATE: "v1/downloadTemplate",
  VALID_MASS_ORDER_TRAITS: "v1/validMassOrderTraits",
  VALID_SINGLE_ORDER_TRAITS: "v1/validSingleOrderTraits",
  FIELD_MAPPING: "v1/fieldMapping",
  ORDER_TYPE_MAPPING: "v1/orderTypeMapping",
};

export const ORDER = {
  ORDERS: "v1/orders",
  GET_BY_FILE_NAMES: "v1/orders/getByFileNames", // ! Deprecated
  SAVE_ALL: "v1/orders/saveAll",
  DELETE: "v1/orders/delete",
  SUBMISSION: "v1/submission",
  SAVE: "v1/orders/save",
  SAVE_AND_SUBMIT: "v1/orders/saveAndSubmit",
  RESUBMIT: "v1/orders/resubmit",
  USER_PREFERENCES: "v1/userPreferences",
};

export const PRODUCTION_SUPPORT = {
  SUMMARY: "ops-dashboard/summary/stats",
  LIST: "v1/orders/getByFileNames",
  ORDER_DETAIL: "v1/orders/saveAll",
};

export const AUDIT_EVENT = {
  ORDER_EVENTS: "order_mgmt/audit_event/v1",
};

export const FILE = {
  UPLOAD_FILES: "v1/uploadFiles",
  FILE_PROCESS_STATUS: "v1/getFileProcessStatusByFileIds",
  GET_NOT_SUCCEEDED_FILES: "v1/getNotSucceededFiles",
  DELETE_FILE: "v1/files",
};

export const PRODUCT = {
  AVAILABILITY_DETAILS: "v2/availability/getAvailabilityDetails",
  MATERIAL_AVAILABILITY: "v2/availability/getMaterialAvailability",
};

export const GET_ORDER_TYPE_MAPPING = "v1/orderTypeMapping";

export const MY_ORDERS = {
  GET_MY_ORDERS: "v1/repo/orders",
  GET_ORDER_TYPES: "v1/orderTypeMapping",
  GET_MY_ORDERS_SUMMARY: "v1/repo/ordersSummary",
};

export const EMPLOYEE = {
  MANAGERS: "v1/employee/getManagers",
  GET_BY_EMAIL: "v1/employee/getByEmail",
  GET_BY_ID: "v1/employee/getById",
  LOOKUP: "v1/employee/lookup",
  FIND_BY_EMAIL: "v1/employee/findByEmail",
  FIND_BY_ID: "v1/employee/findById",
  FIND_CURRENT_USER: "v1/employee/findCurrentUser",
};

export const CUSTOMER = {
  DETAILS: "v1/getCustomerDetails",
  GET_SOLD_TOS: "v1/getSoldTos",
  GET_SHIP_TOS: "v1/getShipTos",
  GET_DEPARTMENT_CODES: "v1/getDepartmentCodes",
};

export const LO_FILTERING = {
  EXCEPTION_CASES: "v1/l0Filtering/getExceptionCases",
  EXCEPTION_GROUPS: "v1/l0Filtering/getExceptionGroups",
  USER_ATTRIBUTES: "v1/l0Filtering/getUserAttributes",
  USER_ACTIVITY_OVERVIEW: "v1/l0Filtering/getUserActivityOverview",
  GLOBAL_FILTERS: "v1/l0Filtering",
  MATERIAL_CATEGORY: "/v1/common/category",
  MATERIAL_SUBCATEGORY: "/v1/common/subCategory",
};

export const FILTER_MANAGEMENT = {
  SAVED_FILTERS: "v1/userOrderFilters",
  DEFAULT_SAVED_FILTERS: "v1/userOrderFilters/default",
};

// OSD Common Value Store
export const OSD = {
  GET_GEO_BY_NAME: "v1/common/geo",
  GET_COUNTRIES: "v1/common/country",
};

export const ISSUE_OVERVIEW = {
  GET_ISSUES: "v1/issueOverview",
  GET_ORDER_ISSUE_TYPES: "v1/issueOverview/orderIssueTypes",
};

export const ISSUE_DETAILS = {
  SAVE_LINES: "v1/issueDetails/save",
  GET_ISSUES: "v1/issueDetails/issues",
  GET_LINES: "v1/issueDetails/getLines",
  GET_ISSUE_TYPES_FILTER_COUNTS: "v1/issueDetails/issueTypeFilterCounts",
};

export const MAINTENANCE = {
  GET_OUTAGE_STATUS: "v1/maintenance/outageStatus",
  ENABLE_OUTAGE: "v1/maintenance/outageEnable",
  DISABLE_OUTAGE: "v1/maintenance/outageDisable",
  GET_SERVICE_DOWN_STATUS: "v1/maintenance/serviceDownStatus",
  ENABLE_SERVICE_DOWN: "v1/maintenance/serviceDownEnable",
  DISABLE_SERVICE_DOWN: "v1/maintenance/serviceDownDisable",
};

export const LINK_LIBRARY = {
  GET_RESOURCES: "v1/linkLibrary/resources",
  UPDATE_FAVORITE_STATUS: "v1/linkLibrary/resources/favorites",
};

export const DUPLICATES = {
  DUPLICATES: "v1/duplicates",
  GET_DUPLICATES_FOR_MULTIPLE: "v1/duplicates/search",
};

export const EXCEPTIONS = {
  GET_EXCEPTIONS: "v2/repo/cases",
  GET_RELATED_ORDERS: "v2/repo/cases/relatedOrders",
  GET_SELECTED_POS: "v2/repo/cases/selectedPos",
};

export const CASES = {
  RESOLVE: "v1/cases/resolve",
};
