import cloneDeep from "lodash/cloneDeep";

import moment from "moment";
import {
  FIELD_KEYS,
  LINE_DC_BYPASS_ELIGIBILITY_VALUES,
  VAS_VALUES,
} from "@/pages/Home/GlobalFilters/config";

import { orderTypeMap } from "./constants.js";

export const filterTypes = {
  IN: "IN",
  OR: "OR",
  AFTER: "AFTER",
  BEFORE: "BEFORE",
  EQUALS: "EQUALS",
  IN_RANGE: "inRange",
  IS_PRESENT: "IS_PRESENT",
};

const searchFilters = [
  {
    column: "PO",
    operator: "CONTAINS",
  },
  {
    column: "OFOA_ID",
    operator: "CONTAINS",
  },
  {
    column: "SAP_ORDER_NUMBER",
    operator: "CONTAINS",
  },
];

const dateFields = [
  FIELD_KEYS.CANCEL_DATE,
  FIELD_KEYS.CREATED_AT,
  FIELD_KEYS.CRD,
  FIELD_KEYS.LINE_CANCEL_DATE,
  FIELD_KEYS.LINE_CRD,
];

function determineOperator({ key, value }) {
  switch (true) {
    case dateFields.includes(key):
      if (value?.length > 10) {
        return filterTypes.IN_RANGE;
      }

      return filterTypes.EQUALS;
    case [FIELD_KEYS.LINE_DC_BYPASS_ELIGIBILITY, FIELD_KEYS.LINE_VAS].includes(key):
      return filterTypes.IS_PRESENT;

    case value.includes(","):
      return filterTypes.IN;

    default:
      return filterTypes.EQUALS;
  }
}

function parseValue(field, operator) {
  if ([FIELD_KEYS.CREATED_BY, FIELD_KEYS.MODIFIED_BY].includes(field.key)) {
    field.value = field.value.toUpperCase();
  }

  switch (true) {
    case operator === filterTypes.IN:
      return field.value.split(",").map((arrayValue) => {
        if (field.key === FIELD_KEYS.ORDER_TYPE) {
          return orderTypeMap[arrayValue.trim()];
        }

        return arrayValue.trim();
      });

    case operator === filterTypes.IS_PRESENT:
      return mapBooleanValues(field);

    default:
      return field.key === FIELD_KEYS.ORDER_TYPE ? orderTypeMap[field.value] : field.value;
  }
}

export function parseGlobalFilters(filters) {
  const reversedFieldKeys = reverseValuesAndKeys(FIELD_KEYS);

  const mappedFilters = cloneDeep(filters).map((field) => {
    const operator = determineOperator(field);
    const isArrayValue = operator === filterTypes.IN;
    const filterValue = parseValue(field, operator);

    return {
      column: reversedFieldKeys[field.key],
      operator,
      exclude: field.exclude,
      ...(!isArrayValue && { value: filterValue }),
      ...(isArrayValue && { values: filterValue }),
    };
  });

  return splitRangeFields(mappedFilters);
}

function reverseValuesAndKeys(fieldKeys) {
  return Object.entries(fieldKeys).reduce((newFieldKeys, [key, value]) => {
    newFieldKeys[value] = key;

    return newFieldKeys;
  }, {});
}

function splitRangeFields(filters) {
  const splitFields = filters.filter(
    (filterField) => filterField.operator !== filterTypes.IN_RANGE
  );

  filters.forEach((filterField) => {
    if (filterField.operator === filterTypes.IN_RANGE) {
      const splitValues = filterField.value.split(" - ");

      if (filterField?.exclude) {
        const { dateBefore, dateAfter } = getExcludedDates(splitValues);

        splitFields.push({
          operator: filterTypes.OR,
          filters: [
            {
              column: filterField.column,
              value: dateBefore,
              operator: filterTypes.BEFORE,
            },
            {
              column: filterField.column,
              value: dateAfter,
              operator: filterTypes.AFTER,
            },
          ],
        });

        return;
      }

      splitFields.push({
        column: filterField.column,
        value: splitValues[0],
        operator: filterTypes.AFTER,
      });
      splitFields.push({
        column: filterField.column,
        value: splitValues[1],
        operator: filterTypes.BEFORE,
      });
    }
  });

  return splitFields;
}

function mapBooleanValues(field) {
  switch (field.key) {
    case FIELD_KEYS.LINE_DC_BYPASS_ELIGIBILITY:
      return String(LINE_DC_BYPASS_ELIGIBILITY_VALUES.ELIGIBLE === field.value);

    case FIELD_KEYS.LINE_VAS:
      return String(VAS_VALUES.WITH_WAS === field.value);

    default:
  }
}

export function splitSearchQuery(query) {
  if (!query) return [];

  let searchQueries = [query];

  if (query.includes(",")) {
    searchQueries = query.split(",");
  } else if (query.includes("\t")) {
    searchQueries = query.split("\t");
  }

  return searchQueries.map((q) => q.trim()).filter(Boolean);
}

export function getSearchFilters(searchQueries) {
  const filters = [];

  searchQueries.forEach((query) => {
    searchFilters.forEach((filter) => {
      filters.push({ ...filter, value: query.trim() });
    });
  });

  const searchFilter = {
    operator: filterTypes.OR,
    filters,
  };

  return [searchFilter];
}

function getExcludedDates(splitValues) {
  const dateBefore = moment(splitValues[0]).subtract(1, "days").format("YYYY-MM-DD");
  const dateAfter = moment(splitValues[1]).add(1, "days").format("YYYY-MM-DD");

  return { dateBefore, dateAfter };
}
