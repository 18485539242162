import moment from 'moment-timezone';

import timeFormats from '@/enums/dates/timeFormats';
import dateFormats from '@/enums/dates/dateFormats';

import { usTimezoneNames } from '@/enums/timezones.js';

const now = moment();

export const getTimeShift = (timezone) => {
  return now.tz(timezone).format('Z');
};

export const getTimezonesSelectList = () => {
  const chinaOption = {
    text: 'Asia/Beijing (GMT+08:00)',
    value: 'Asia/Shanghai',
    timeShift: '+08:00',
  };

  const usList = moment.tz.zonesForCountry('US').reduce((acc, zone) => {
    const timeShift = getTimeShift(zone);

    let formattedName = zone.replace(/_/g, ' ');

    if (usTimezoneNames.includes(formattedName)) {
      if (formattedName === 'America/Los Angeles') formattedName = 'America/Portland';

      acc.push({
        text: `${formattedName} (GMT${timeShift})`,
        value: zone,
        timeShift,
      });
    }

    return acc;
  }, []);

  const timezonesList = [chinaOption, ...usList];

  timezonesList.sort((a, b) => {
    // from +00:00 to +00
    const hourA = a.timeShift.split(':')[0];
    const hourB = b.timeShift.split(':')[0];

    return hourA - hourB;
  });

  return timezonesList;
};

export const getUTCDate = ({
  date, time, timezone,
}) => {
  return moment.tz(`${date}T${time}:00`, timezone).utc().format();
};

export const getDateFromUTC = (utcString, timezone) => {
  const momentDate = moment.utc(utcString).tz(timezone);
  const date = momentDate.format(dateFormats.ISO);
  const time = momentDate.format(timeFormats.hr24);

  return {
    date,
    time,
  };
};

export const getLocalTimezone = () => {
  return moment.tz.guess();
};
