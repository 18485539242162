import { isEmpty } from 'lodash';
import { fieldNames } from '@/enums/singleOrderCreation/fieldNames';

export function dependencyRulesActionSetItems({
  field, value, dependency,
}) {
  const { sourceFieldKey } = dependency;

  if (value) {
    switch (true) {
      case sourceFieldKey === fieldNames.soldToNumber && field?.key === fieldNames.shipToNumber:
        return setShipToNumber(field, value);
      case sourceFieldKey === fieldNames.soldToNumber && field?.key === fieldNames.departmentCode:
        return setDepartmentCodes(field, value);
      default:
    }
  }

  return field;
}

function setShipToNumber(field, value) {
  return { ...field, items: value?.shipTos || [] };
}

function setDepartmentCodes(field, value) {
  if (isEmpty(value?.departmentCodes)) {
    return { ...field, items: [] };
  }

  return {
    ...field,
    items: value?.departmentCodes?.map(({ id, alias }) => ({ text: alias, value: id })) || [],
  };
}
