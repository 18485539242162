import ActionsRenderer from '@/components/ui/AgGridTable/CellRenderers/ActionsRenderer';

const DATE_FILTER_TYPES = ['inRange', 'Before', 'After'];

const actionsRendererConfig = {
  maxWidth: 32,
  pinned: 'right',
  headerName: '',
  headerHeight: 63,
  suppressMenu: true,
  field: 'Actions',
  colId: 'actions',
  cellStyle: {
    padding: 0,
    'justify-content': 'center',
  },
  cellRenderer: ActionsRenderer,
};

const fieldsForTextColumnWithFilter = {
  menuTabs: ['filterMenuTab'],
  filter: 'agTextColumnFilter',
  filterParams: {
    suppressAndOrCondition: true, filterOptions: ['equals'], buttons: ['clear'],
  },
};

const fieldsForNumberColumnWithFilter = {
  menuTabs: ['filterMenuTab'],
  filter: 'agTextColumnFilter',
  filterParams: {
    suppressAndOrCondition: true, filterOptions: ['equals'], buttons: ['clear'],
  },
};

const fieldsForDataColumnWithFilter = {
  menuTabs: ['filterMenuTab'],
  filter: 'agDateColumnFilter',
  filterParams: {
    buttons: ['clear'],
    debounceMs: 1000,
    suppressAndOrCondition: true,
    filterOptions: [...DATE_FILTER_TYPES],
  },
};

const columnsDefinition = [
  {
    field: 'poNumber',
    headerName: 'PO #',
    width: 225,
    resizable: true,
    ...fieldsForTextColumnWithFilter,
  },
  {
    field: 'orderType',
    headerName: 'Order Type',
    width: 130,
    suppressMenu: true,
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 217,
    suppressMenu: true,
  },
  {
    field: 'createdAt',
    headerName: 'Date Created',
    width: 167,
    ...fieldsForDataColumnWithFilter,
  },
  {
    field: 'createdBy',
    headerName: 'Created By',
    width: 167,
    ...fieldsForTextColumnWithFilter,
  },
  {
    field: 'sourceSystem',
    headerName: 'Source system',
    width: 167,
    ...fieldsForTextColumnWithFilter,
  },
  {
    field: 'modifiedAt',
    headerName: 'Date Modified',
    width: 167,
    ...fieldsForDataColumnWithFilter,
  },
  {
    field: 'modifiedBy',
    headerName: 'Modified By',
    width: 167,
    ...fieldsForTextColumnWithFilter,
  },
  {
    field: 'soldToNumber',
    headerName: 'Sold To',
    width: 132,
    ...fieldsForNumberColumnWithFilter,
  },
  {
    field: 'shipToNumber',
    headerName: 'Ship To',
    width: 132,
    ...fieldsForNumberColumnWithFilter,
  },
  {
    field: 'crd',
    headerName: 'CRD',
    width: 167,
    ...fieldsForDataColumnWithFilter,
  },
  {
    field: 'cancelDate',
    headerName: 'Cancel Date',
    width: 167,
    ...fieldsForDataColumnWithFilter,
  },
  {
    field: 'validFromDate',
    headerName: 'Valid From',
    width: 150,
    ...fieldsForDataColumnWithFilter,
  },
  {
    field: 'validToDate',
    headerName: 'Valid To',
    width: 150,
    ...fieldsForDataColumnWithFilter,
  },
  {
    field: 'reasonCode',
    headerName: 'Reason Code',
    width: 174,
    ...fieldsForTextColumnWithFilter,
  },
  {
    field: 'sapOrderNumber',
    headerName: 'SAP Sales Order #',
    width: 174,
    ...fieldsForTextColumnWithFilter,
  },
  {
    field: 'ofoaId',
    headerName: 'OFOA Order #',
    width: 320,
    ...fieldsForTextColumnWithFilter,
  },
];

/**
 * Key - field/column name in AgGrid table
 * Value - expected value (column/filed name) for filters params for API endpoint: /v1/repo/orders
 * https://developer.niketech.com/docs/projects/Nike%20Order%20Workspace?tab=api
 */
const columnNames = {
  crd: 'CRD',
  poNumber: 'PO',
  orderType: 'ORDER_TYPE',
  status: 'STATUS',
  createdAt: 'CREATED_AT',
  createdBy: 'CREATED_BY',
  modifiedAt: 'MODIFIED_AT',
  modifiedBy: 'MODIFIED_BY',
  soldToNumber: 'SOLD_TO',
  shipToNumber: 'SHIP_TO',
  validFromDate: 'VALID_FROM',
  validToDate: 'VALID_TO',
  reasonCode: 'REASON_CODE',
  sapOrderNumber: 'SAP_ORDER_NUMBER',
  sourceSystem: 'SOURCE_SYSTEM',
  cancelDate: 'CANCEL_DATE',
  ofoaId: 'OFOA_ID',
  markFor: 'MARK_FOR',
  accountType: 'ACCOUNT_TYPE',
  departmentCode: 'DEPARTMENT_CODE',
  salesOrg: 'SALES_ORG',
  businessType: 'BUSINESS_TYPE',
  channel: 'CHANNEL',
  subChannel: 'SUB_CHANNEL',
  marketplaceUnit: 'MARKETPLACE_UNIT',

};

const sortedColumnOrders = {
  asc: 'ASC',
  desc: 'DESC',
};

const filterTypes = {
  equals: 'equals',
  inRange: 'inRange',
  before: 'before',
  after: 'after',
};

export default {
  columnNames,
  filterTypes,
  columnsDefinition,
  sortedColumnOrders,
  actionsRendererConfig,
};

export const DATE_FILTER_TYPE = 'date';

export const statusKeyToTableCellText = {
  DRAFT: 'Draft',
  REWORK: 'Rework',
  STASHED: 'Stashed',
  DELETED: 'Deleted',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
  RECEIVED: 'Received',
  ARCHIVED: 'Archived',
  EXCEPTION: 'Exception',
  SUBMITTED: 'Submitted',
  SENT: 'Sent',
  SUBMITTING: 'Submitting',
  SENT_FAILED: 'Sent Failed',
  NOT_ACCEPTED: 'Not Accepted',
  ACKNOWLEDGED: 'Acknowledged',
  FAILED_IN_S4: 'Failed in S/4',
  FAILED_IN_SCP: 'Failed in SCP',
  READY_TO_SUBMIT: 'Ready to Submit',
  CONTAINS_ERRORS: 'Contains Errors',
  PENDING_APPROVAL: 'Pending Approval',
  STO_IDOC_CREATED: 'STO IDOC Created',
  IN_PROGRESS_IN_OFOA: 'In Progress in OFOA',
  SUCCESS_IN_OFOA: 'Success in OFOA',
  FAILED_IN_OFOA: 'Failed in OFOA',
  SENT_FOR_CONTROLLED_ALLOCATION: 'Sent for Controlled Allocation',
  UNKNOWN: 'Unknown',
};
