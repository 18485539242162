import { FILTER_KEYS } from '@/enums/availability/filterKeys';

export const AVAILABILITY_FILTERS_EMPTY_STATE = {
  [FILTER_KEYS.GTINS]: null,
  [FILTER_KEYS.INVENTORY_SEGMENTATION_CODE]: null,
  [FILTER_KEYS.SALES_ORGANIZATION]: null,
  [FILTER_KEYS.PLANT_CODE]: null,
  [FILTER_KEYS.INVENTORY_QUALITY_CODE]: null,
  [FILTER_KEYS.SOURCING_METHOD]: null,
  [FILTER_KEYS.SALES_CHANNEL]: null,
  [FILTER_KEYS.CONTRACT_NUMBER]: null,
  [FILTER_KEYS.PRODUCT_CODES]: null,
};
