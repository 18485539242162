<template>
  <div :class="baseClassName">
    <router-link :to="route" :class="`${baseClassName}__title`">
      {{ rowData.poNumber }}
    </router-link>
    <p
      v-if="rowData.ofoaId"
      :class="`${baseClassName}__subtitle`"
    >
      {{ translateFields(FIELDS_ENUM.ofoaOrderId) }}: {{ rowData.ofoaId }}
    </p>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { translateModule } from '@/utils/string/translate';

import { FIELDS_ENUM } from '@/enums/language/fieldsEnum';

const translateFields = translateModule('fields');

const props = defineProps({
  params: {
    type: Object,
    required: true,
  },
});

const baseClassName = 'po-renderer';

const rowData = computed(() => props.params.data);

const route = computed(() => {
  if (!rowData.value.ofoaId) {
    return `/purchase-order/nowId=${rowData.value.nowId}`;
  }

  return `/purchase-order/ofoaId=${rowData.value.ofoaId}`;
});

</script>

<style lang="scss">
@import "podium-tokens";

.po-renderer {
  &__title {
    color: $podium-color-blue-400;
    cursor: pointer;
  }

  &__subtitle {
    font: $podium-text-legal-1;
    font-weight: 500;
  }
}
</style>
