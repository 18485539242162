import state from './template.state';
import actions from './templates.actions';
import mutations from './templates.mutations';
import getters from './templates.getters';

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
