<template>
  <div class="ag-grid-actions-renderer">
    <v-menu bottom left :disabled="tooltipMessage">
      <template #activator="{ props }">
        <Icon
          v-bind="props"
          size="18"
          name="EllipsisVerticalLargeDefaultOnLight"
          class="ag-grid-actions-renderer__icon"
          :class="{
            'ag-grid-actions-renderer__icon--disabled': tooltipMessage,
          }"
        />
      </template>

      <div
        v-if="menuOptions.length"
        class="ag-grid-actions-renderer__list-container"
      >
        <div
          v-for="{ name, handler } in menuOptions"
          :key="name"
          link
          class="now-body-3 ag-grid-actions-renderer__list-item"
          @click="handler(params)"
        >
          {{ name }}
        </div>
      </div>

      <div v-else class="ag-grid-actions-renderer__list-container">
        <div class="now-body-3 ag-grid-actions-renderer__list-item">
          No Actions Available
        </div>
      </div>
    </v-menu>
  </div>
</template>

<script>
import { Icon } from 'podium';

export default {
  components: {
    Icon,
  },

  computed: {
    menuOptions() {
      return this.params.menuOptions;
    },
    tooltipMessage() {
      return !!this.params.value?.tooltipMessage;
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/colors.scss';

.ag-grid-actions-renderer {
  &__icon {
    &.podium-icon {
      fill: $primary-black;
    }

    &--disabled {
      cursor: not-allowed;
    }
  }

  &__list-container {
    background: $primary-white;
    border-radius: 2px !important;
    border: 1px solid $grey-2;
    box-shadow: 0 9px 16px rgba(0, 0, 0, 0.2);
  }

  &__list-item {
    padding: 12px 14px;
    cursor: pointer;
  }

  .v-list {
    padding: 0;
  }

  .v-list-item__title {
    @extend .now-body-3;
  }
}
</style>
