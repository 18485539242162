import orderTypes from './orderTypes/mutations';
import businessTypes from './businessType/mutations';
import customerDetails from './customerDetails/mutations';
import filters from './filters/mutations';

export default {
  ...orderTypes,
  ...businessTypes,
  ...customerDetails,
  ...filters,
};
