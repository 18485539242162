import { createRouter, createWebHistory } from 'vue-router';
import { navigationGuard } from '@okta/okta-vue';

import routes from './paths';
import userPermissionGuard from './guards/userPermissionGuard';

import monitoring from '@/utils/monitoring';

export const routePaths = {
  home: '/',
  bulk: '/bulk',
};

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export function registerRouter(app) {
  router.beforeEach(navigationGuard);
  router.beforeEach(userPermissionGuard);

  router.afterEach(() => {
    monitoring.sendCustomEvent('NOW_PAGE_VISITED');
  });

  app.use(router);
}

export default router;
