import repository from '@/api/repositories/serverRepository';
import { OSD } from '@/api/endpoints';

export default {
  fetchGeoInfoByName(name) {
    return repository.get(`${OSD.GET_GEO_BY_NAME}/${name}`).then((response) => response.data);
  },
  fetchCountries(code) {
    const endpoint = code ? `${OSD.GET_COUNTRIES}/${code}/regions` : OSD.GET_COUNTRIES;

    return repository.get(endpoint).then((response) => response.data);
  },
};
