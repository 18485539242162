<template>
  <div :class="`${getBaseClassName}__container`">
    <span v-if="upperLabel" :class="`${getBaseClassName}__upper-label`">
      {{ upperLabel }}
    </span>
    <div
      :class="[
        `${getBaseClassName}`,
        `${getBaseClassName}--${getSize}`,
        { [`${getBaseClassName}--border`]: isBorder },
        { [`${getBaseClassName}--disabled`]: isDisabled },
        { [`${getBaseClassName}--error-border`]: !!errorMessage },
      ]"
      @click="$refs.input.focus()"
    >
      <input
        ref="input"
        v-maska
        :data-maska="inputMask"
        :placeholder="placeholder"
        :value="injectedValue"
        :class="[
          `${getBaseClassName}__value`,
          { [`${getBaseClassName}__value--center`]: isCentered },
          { [`${getBaseClassName}__value--read-only`]: isReadonly },
        ]"
        :tabindex="tabindex"
        :readonly="isReadonly"
        @blur="onBlur($event.target.value)"
        @change="onChange($event.target.value)"
        @input="onInput($event.target.value)"
        @focus="onFocus($event.target.value)"
        @click="onClick($event.target.value)"
        @keydown="onKeydown($event)"
      />
      <span
        v-if="label"
        :class="[
          `${getBaseClassName}__label`,
          `${getBaseClassName}__label--${size}`,
          {
            [`${getBaseClassName}__label--filled`]: injectedValue || placeholder,
          },
        ]"
      >
        <span v-if="isRequired" :class="`${getBaseClassName}__required-sign`">*</span
        >{{ label }}</span
      >
      <div :class="`${getBaseClassName}__icons`">
        <Icon
          v-if="isClearIcon && injectedValue"
          size="18"
          name="CloseLargeDefaultOnLight"
          :class="`${getBaseClassName}__icon-clear`"
          @click="onClearValue"
        />
        <Icon v-if="iconRight" :name="iconRight" :class="`${getBaseClassName}__icon-right`" />
        <div :class="`${getBaseClassName}__icon-slot`">
          <slot />
        </div>
      </div>
    </div>
    <span :class="`${getBaseClassName}__error`">{{ errorMessage }}</span>
  </div>
</template>

<script>
import { Icon } from "podium";

import { BASE_CLASS_NAME, BASE_INPUT_SIZES } from "./config";

export default {
  components: {
    Icon,
  },

  props: {
    isRequired: Boolean,
    isBorder: Boolean,
    isDisabled: Boolean,
    isCentered: Boolean,
    isClearIcon: {
      type: Boolean,
      default: true,
    },

    size: {
      type: String,
      required: true,
      validator(size) {
        return Object.values(BASE_INPUT_SIZES).includes(size);
      },
    },

    inputMask: {
      type: String,
      default: "",
    },

    label: {
      type: String,
      default: "",
    },

    upperLabel: {
      type: String,
      default: "",
    },

    placeholder: {
      type: String,
      default: "",
    },

    iconRight: {
      type: String,
      default: "",
    },

    injectedValue: {
      type: [String, Number, Array],
      default: "",
    },

    errorMessage: {
      type: String,
      default: "",
    },

    isReadonly: {
      type: Boolean,
      default: false,
    },
  },

  emits: ["change", "blur", "focus", "input", "click", "keydown"],

  computed: {
    getBaseClassName() {
      return BASE_CLASS_NAME;
    },

    getSize() {
      return this.size;
    },

    /**
     * Disable focus on the input when navigating with TAB
     */
    tabindex() {
      return this.isDisabled ? -1 : 0;
    },
  },

  methods: {
    onChange(changedValue) {
      this.$emit("change", changedValue);
    },

    onBlur(changedValue) {
      this.$emit("blur", changedValue);
    },

    onFocus(value) {
      this.$emit("focus", value);
    },

    onInput(value) {
      this.$emit("input", value);
    },

    onClick(value) {
      this.$emit("click", value);
    },

    onKeydown(event) {
      this.$emit("keydown", event);
    },

    onClearValue() {
      this.$emit("input", "");
      this.$emit("change", "");
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/colors.scss";

.base-input {
  @extend .now-body-3;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $primary-white;
  text-overflow: ellipsis;

  &__container {
    position: relative;
    height: 100%;
  }

  &--s {
    width: 100px;
    height: 40px;
  }

  &--xs {
    width: 50px;
    height: 24px;
  }

  &--m {
    width: 100%;
    height: 24px;
  }

  &--l {
    width: 335px;
    height: 40px;
  }

  &--xl {
    width: 375px;
    height: 40px;
  }

  &--universal {
    width: 100%;
    height: 100%;
  }

  &--border {
    border: 1px solid $grey-2;
  }

  &:hover,
  &:focus-within {
    border-color: $primary-black;
  }

  &__value {
    width: 100%;
    height: 100%;
    padding: 0 8px;
    text-overflow: ellipsis;

    &--center {
      text-align: center;
    }

    &--read-only {
      cursor: pointer;
    }
  }

  &__required-sign {
    padding-right: 3px;
    color: $brand-error-red;
  }

  &__label {
    position: absolute;
    pointer-events: none;
    padding: 0px 5px;
    top: 9px;
    left: 8px;
    color: $grey-1;
    transition: all 0.3s ease;
    &--m {
      @extend .now-label-1;

      top: 1px;
      left: 0px;
    }
  }

  &__value:focus ~ &__label {
    font-size: 10px;
    color: $primary-black;
    background-color: $primary-white;
    transform: translate(0px, -20px);
  }

  &__label--filled {
    font-size: 10px;
    background-color: $primary-white;
    transform: translate(0px, -20px);
  }

  &__upper-label {
    @extend .now-label-2;

    color: $grey-1;
    margin-left: 2px;
    pointer-events: none;
  }

  &--disabled {
    pointer-events: none;

    .base-input__value {
      color: $grey-2;
    }

    .base-input__label {
      color: $grey-2;
    }

    .base-input__icon-clear {
      stroke: $grey-2;
    }

    .base-input__icon-right {
      stroke: $grey-2;
    }

    &:hover {
      border-color: $grey-2;
    }
  }

  &__icons {
    display: flex;
    align-items: center;

    .podium-icon {
      cursor: pointer;
    }

    & > * {
      stroke: $grey-1;
    }

    .base-input__icon-right {
      pointer-events: none;
    }
  }

  &--error-border {
    border-color: $error-red;

    &:hover {
      border-color: $error-red;
    }
  }
}

.base-input__error {
  @extend .now-body-3;

  position: absolute;
  left: 14px;
  font-size: 12px;
  color: $brand-error-red;
  pointer-events: none;
}
</style>
