// TODO: We should leave this to CSS where needed (text-transform: capitalize)
export function capitalizeEveryWord(value) {
  if (!value) return '';

  return value
    .toString()
    .toLowerCase()
    .split(' ')
    .map((word) => `${word.charAt(0).toUpperCase()}${word.slice(1)}`)
    .join(' ');
}
