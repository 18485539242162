import { ValidationRules } from '@/controllers/order/validations/header';
import { LineLevelValidationRules } from '@/controllers/order/validations/line';

const validateHeaderField = async(formFieldPromise, validation) => {
  const formField = await formFieldPromise;

  return await validation.conditions
    ? ValidationRules[validation.conditions](
      formField,
      validation,
    )
    : formField;
};

export const validateHeader = async(fieldConfig, formData, updatedField) => {
  const validations = fieldConfig?.validations;

  if (!validations || !validations?.length) return formData.fields;

  // TODO: add key:index map
  const fieldsPromises = formData.fields.map(async(field) => {
    if (field.key !== updatedField.key) {
      return field;
    }

    return validations.reduce(await validateHeaderField, Promise.resolve({ ...field }));
  });

  return await Promise.all(fieldsPromises);
};

const validateLineField = async(fieldName) => {
  return async(fieldPromise, [validationMethod, validationData]) => {
    const formFieldData = await fieldPromise;

    return await LineLevelValidationRules[validationMethod]?.({
      fieldName,
      formFieldData,
      ...validationData,
    });
  };
};

export const validateLine = async(fieldConfig, formData, updatedField = {}) => {
  const validations = Object.entries(fieldConfig?.validations || {});
  const resolvedRows = formData.lineItemFields.rowData;

  if (!validations.length) return formData.lineItemFields.rowData;

  const rowPromises = resolvedRows.map(async(updatedRow) => {
    const fieldName = updatedField.name;
    const fieldData = updatedRow[fieldName];

    const validatedField = await validations.reduce(
      await validateLineField(fieldName),
      Promise.resolve({ ...fieldData }),
    );

    return { ...updatedRow, [fieldName]: { ...validatedField } };
  });

  return await Promise.all(rowPromises);
};
