import i18n from "@/plugins/i18n";

import ordersRepository from "@/api/productionSupport";

import * as notificationUtils from "@/utils/notifications";

export default {
  setDashboardLayoutSnapshot({ commit }, snapshot) {
    commit("SET_DASHBOARD_LAYOUT_SNAPSHOT", snapshot);
  },

  async fetchUserActivityOverview({ commit, rootGetters, rootState }) {
    if (!rootState.globalFilters.savedFilters) {
      return;
    }

    try {
      commit("SET_IS_ORDERS_INFO_LOADING", true);

      const getRequestFilterParams = rootGetters["myOrders/getRequestFilterParams"] || {};
      const { filters } = getRequestFilterParams;

      const { data, warnings } = await ordersRepository.fetchUserActivityOverviewByFilters(filters);
      const {
        totalExceptions,
        myOrdersQuantity,
        totalPOExceptions,
        totalUnitsImpacted,
        myApprovalsQuantity,
        massOrdersInProgress,
      } = data || {};

      const isFetchFailed =
        warnings?.length > 0 && Object.values(data).every((value) => value === "0");

      if (isFetchFailed) {
        const errorMessage = i18n.global.t(
          "notifications.errorMessages.http.unableToGetOrderDataSummarySupport"
        );
        notificationUtils.showErrorBanner({ title: errorMessage });
      }

      commit("SET_SUMMARY", {
        totalExceptions,
        totalPOExceptions,
        totalUnitsImpacted,
        myApprovalsQuantity: myApprovalsQuantity !== undefined ? myApprovalsQuantity : "--",
        massOrdersInProgress: massOrdersInProgress !== undefined ? massOrdersInProgress : "--",
      });

      commit("SET_MY_ORDERS_QUANTITY", myOrdersQuantity !== undefined ? myOrdersQuantity : "--");
    } catch (e) {
      commit("SET_SUMMARY", {
        totalExceptions: 0,
        totalPOExceptions: 0,
        totalUnitsImpacted: 0,
        myApprovalsQuantity: 0,
        massOrdersInProgress: 0,
      });

      console.error(e);
    } finally {
      commit("SET_IS_ORDERS_INFO_LOADING", false);
    }
  },
};
