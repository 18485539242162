import formBuilderPhases from '@/enums/SOC/formBuilderPhases';

export default {
  orderType: {},
  formData: {
    fields: [],
    lineItemFields: {
      columnDefs: [],
      dependencies: [],
      rowData: [],
      validationRules: {},
    },
  },
  blockNames: [],
  name: undefined,
  phase: formBuilderPhases.beforeCreate,
  isDependencyBypass: false,
  userContext: '',
  gridApi: {},
  draftOrderInfo: {},
  isBEDataPopulated: false,
  availabilityInProgressCount: 0,
  rowsPendingForAvailability: [],
  isRowDataPaste: false,
  geoData: {},
};
