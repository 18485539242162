import testingContext from '../../plugins/jest-context';

let requireModule;

const isJest = process.env.JEST_WORKER_ID !== undefined;

if (isJest) {
  requireModule = testingContext('./', true, /index.js$/);
} else {
  requireModule = require.context('./', true, /index.js$/);
}

const modules = {};

requireModule.keys().forEach((filename) => {
  const fileIndex = isJest ? 9 : 1;
  const moduleName = filename.split('/')[fileIndex] !== 'index.js' ? filename.split('/')[fileIndex] : undefined;

  if (moduleName) {
    modules[moduleName] = requireModule(filename).default || requireModule(filename);
  }
});

export default modules;
