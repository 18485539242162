import actions from './maintenance.actions';
import state from './maintenance.state';
import mutations from './maintenance.mutations';
import getters from './maintenance.getters';

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
