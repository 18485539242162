import { get } from 'lodash';
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';
import router from '@/router/index.js';

import employeeRepository from '@/api/now/employee';
import filterPanelRepository from '@/api/now/caseManagement/filterPanel';

import * as mutations from './mutation-types';

import localStorageKey from '@/enums/browserStorage/localStorageKeyEnum';
import monitoring from '@/utils/monitoring';

import { getPermissionSet } from './helpers';
import { CASE_PERMISSION_KEYS } from './constants';

import appWrapper from '@/appInstance';

export default {
  setUserHasPermissions({ commit }, payload) {
    commit(mutations.SET_USER_HAS_PERMISSIONS, payload);
  },

  async checkUserPermissions({ commit, dispatch }) {
    try {
      const { data: responseData } = await filterPanelRepository.fetchUserAttributes();

      const casePermissionSets = CASE_PERMISSION_KEYS.reduce((acc, key) => {
        acc[key] = getPermissionSet(responseData[key]);

        return acc;
      }, {});
      const casePermissionsObjects = CASE_PERMISSION_KEYS.reduce((acc, key) => {
        acc[key] = responseData[key];

        return acc;
      }, {});
      commit(mutations.SET_CASE_PERMISSIONS, casePermissionSets);
      commit(mutations.SET_CASE_PERMISSIONS_OBJECTS, casePermissionsObjects);

      if (responseData) {
        dispatch('setUserHasPermissions', true);
      }

      commit(mutations.SET_IS_MPO_USER, !get(responseData, 'nonMpo', true));
      commit(mutations.SET_USER_ADMIN_RIGHTS, !!responseData?.maintenanceAdmin);

      const fetchedGeos = responseData.geos.map(({ id }) => id);

      commit(mutations.SET_USER_GEO, fetchedGeos);

      return responseData;
    } catch (error) {
      console.log(error);
      dispatch('setUserHasPermissions', false);
      throw error;
    }
  },

  async getCurrentUser({ commit }) {
    const { data } = await employeeRepository.getCurrentUser();

    commit(mutations.SET_USER, data);
  },

  async login({ commit }) {
    const { $auth } = appWrapper.app.config.globalProperties;
    const { token, tokenManager } = $auth;

    try {
      const { tokens } = await token.parseFromUrl();
      const { accessToken } = tokens.accessToken;

      const originalUri = $auth.getOriginalUri() || '/';

      tokenManager.setTokens(tokens);

      localStorage.setItem(localStorageKey.isLoggedIn, true);

      if (accessToken) {
        localStorage.setItem(localStorageKey.userAppActiveState, true);

        const username = jwt_decode(accessToken).sub;
        monitoring.initUserAttributes(username);
      }

      const { name = 'Home', params = {} } = router.resolve({ path: originalUri });

      router.replace({ name, params });
      commit(mutations.SET_HAS_ACCESS_TOKEN, true);
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  async logout({ dispatch }, { postLogoutRedirectUri = '' } = {}) {
    const { $auth } = appWrapper.app.config.globalProperties;
    try {
      dispatch('setUserHasPermissions', undefined);

      await $auth.signOut({
        postLogoutRedirectUri: `${window.location.origin}${postLogoutRedirectUri}`,
      });

      localStorage.setItem(localStorageKey.isLoggedIn, false);
      localStorage.setItem(localStorageKey.userAppActiveState, false);
    } catch (error) {
      console.error(error);
    }
  },
};
