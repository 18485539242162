import { COLUMN_KEYS, columnDefs } from './constants';

export function getDefaultUserPreferences() {
  return columnDefs.slice(1).map(columnDef => ({
    id: columnDef.field,
    name: columnDef.headerName,
    visible: true,
  }));
}

export function getColumnDefsFromUserPreferences(userPreferences, previousColumnDefs) {
  const columnDefsByKey = new Map();

  previousColumnDefs.forEach(columnDef => {
    columnDefsByKey.set(columnDef.field, columnDef);
  });

  return userPreferences.reduce((acc, column) => {
    const columnDef = columnDefsByKey.get(column.id);

    columnDef.headerName = column.name;
    columnDef.hide = !column.visible;

    acc.push(columnDef);

    return acc;
  }, [columnDefsByKey.get(COLUMN_KEYS.INDICATOR)]);
}

export function mergeUserPreferences(clientSidePreferences = [], serverSidePreferences = []) {
  const serverSidePreferencesMap = new Map();

  serverSidePreferences.forEach(serverPreference => {
    serverSidePreferencesMap.set(serverPreference.id, serverPreference);
  });

  return clientSidePreferences.reduce((acc, clientPreference) => {
    const serverPreference = serverSidePreferencesMap.get(clientPreference.id);

    if (!serverPreference) acc.push(clientPreference);

    return acc;
  }, structuredClone(serverSidePreferences));
}
