import repository from '@/api/repositories/serverRepository';
import { LO_FILTERING, CUSTOMER } from '@/api/endpoints';

export default {
  /**
   * ! This method is duplicate src/api/now/customerDetails/index.js
   * TODO:
   * - Remove it from here
   * - Adjust users of this method to use one in src/api/now/customerDetails/index.js
   */
  fetchCustomerDetails(filterParams) {
    return repository.post(CUSTOMER.DETAILS, filterParams);
  },

  fetchUserAttributes() {
    repository.__addFilter(new RegExp(LO_FILTERING.USER_ATTRIBUTES));

    return repository.get(LO_FILTERING.USER_ATTRIBUTES);
  },

  fetchExceptionGroups(filters) {
    return repository.post(LO_FILTERING.EXCEPTION_GROUPS, filters);
  },

  fetchGlobalFilterParams() {
    return repository.get(LO_FILTERING.GLOBAL_FILTERS);
  },

  getSoldTosForGlobalFilters(filterParams) {
    return repository.post(CUSTOMER.GET_SOLD_TOS, filterParams);
  },

  getShipTosForGlobalFilters(filterParams) {
    return repository.post(CUSTOMER.GET_SHIP_TOS, filterParams);
  },

  getDepartmentCodesForGlobalFilters(filterParams) {
    return repository.post(CUSTOMER.GET_DEPARTMENT_CODES, filterParams);
  },

  fetchMaterialCategory(params) {
    return repository.post(LO_FILTERING.MATERIAL_CATEGORY, params);
  },

  fetchMaterialSubCategory(params) {
    return repository.post(LO_FILTERING.MATERIAL_SUBCATEGORY, params);
  },
};
