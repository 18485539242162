import { fetchAvailabilityDetails } from '@/api/now/availability';

import * as actionTypes from './constants/actions';
import mutationTypes from './constants/mutations';
import { AVAILABILITY_FILTERS_EMPTY_STATE } from './constants/state';

export default {
  async [actionTypes.APPLY_FILTERS]({ commit, dispatch }, filters) {
    commit(mutationTypes.SET_ALL_FILTERS, filters);

    await dispatch(actionTypes.FETCH_AVAILABILITY_DETAILS, filters);
  },

  async [actionTypes.FETCH_AVAILABILITY_DETAILS]({ commit }, filters) {
    commit(mutationTypes.SET_LOADING, true);

    try {
      const {
        data: { products },
      } = await fetchAvailabilityDetails(filters);

      commit(mutationTypes.SET_AVAILABILITY_DETAILS, products);
    } catch (err) {
      console.error(err);
    } finally {
      commit(mutationTypes.SET_LOADING, false);
    }
  },

  async [actionTypes.CLEAR_FILTER]({
    dispatch, commit, state,
  }, { key, value }) {
    const currentValue = state.filters[key];
    const isArray = Array.isArray(currentValue);

    let updatedValue = AVAILABILITY_FILTERS_EMPTY_STATE[key];

    if (isArray) {
      const filteredValues = currentValue.filter((v) => v !== value);

      if (filteredValues.length > 0) updatedValue = filteredValues;
    }

    commit(mutationTypes.SET_FILTER_BY_KEY, { key, value: updatedValue });

    await dispatch(actionTypes.FETCH_AVAILABILITY_DETAILS, state.filters);
  },

  [actionTypes.CLEAR_ALL_FILTERS]({ commit }) {
    commit(mutationTypes.CLEAR_ALL_FILTERS);

    commit(mutationTypes.SET_AVAILABILITY_DETAILS, []);
  },

  [actionTypes.SET_PAGE_NUMBER]({ commit }, pageNumber) {
    commit(mutationTypes.SET_PAGE_NUMBER, pageNumber);
  },

  [actionTypes.SET_PAGE_SIZE]({ commit }, pageSize) {
    commit(mutationTypes.SET_PAGE_SIZE, pageSize);
  },
};
