import orderTypes from './orderTypes/state';
import businessTypes from './businessType/state';
import customerDetails from './customerDetails/state';
import filters from './filters/state';

export default {
  ...orderTypes,
  ...businessTypes,
  ...customerDetails,
  ...filters,
};
