import constants from './constants';

export function parseFilters(filters) {
  const { filterTypes, columnNames } = constants;

  const parsedFilters = Object.keys(filters).map((filteredColumn) => {
    if (filters[filteredColumn].type === filterTypes.inRange) {
      return [
        {
          column: columnNames[filteredColumn],
          operator: filterTypes.after.toUpperCase(),
          value: filters[filteredColumn].filter || filters[filteredColumn].dateFrom.split(' ')[0],
        },
        {
          column: columnNames[filteredColumn],
          operator: filterTypes.before.toUpperCase(),
          value: filters[filteredColumn].filter || filters[filteredColumn].dateTo.split(' ')[0],
        },
      ];
    }

    return {
      column: columnNames[filteredColumn],
      operator: filters[filteredColumn].type.toUpperCase(),
      value: filters[filteredColumn].filter || filters[filteredColumn].dateFrom.split(' ')[0],
    };
  });

  return parsedFilters.flat();
}
