export default {
  SET_DASHBOARD_LAYOUT_SNAPSHOT(state, snapshot) {
    state.dashboardLayoutSnapshot = snapshot;
  },

  SET_MY_ORDERS_QUANTITY(state, myOrdersQuantity) {
    state.myOrdersQuantity = myOrdersQuantity;
  },

  SET_IS_ORDERS_INFO_LOADING(state, isOrdersInfoLoading) {
    state.isOrdersInfoLoading = isOrdersInfoLoading;
  },

  SET_SUMMARY(state, summary) {
    state.summary = summary;
  },
};
