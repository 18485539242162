import { isEqual } from 'lodash';

import { orderConfigs, OrderController } from '@/controllers/order';

import singleOrdersRepository from '@/api/now/orders';
import addressOverrideRepository from '@/api/now/singleOrderCreate/addressOverride';

import { pipeActions } from '@/utils/store.js';
import formBuilderPhases from '@/enums/SOC/formBuilderPhases';

import { APPROVAL_STATUSES } from '../approvals/constants';
import { OrderRequest } from '../helpers/orderTransformation';

import { OrderTypeSapIdsEnum } from '@/enums/order/orderType.js';

let controller;

export default {

  async initializeController({ commit, dispatch }, payload) {
    commit('setPhase', formBuilderPhases.creatingController);

    const { orderTypesToSalesOrgs } = await dispatch('user/checkUserPermissions', false, {
      root: true,
    });

    const selectedOrder = orderTypesToSalesOrgs.find(item => item.orderType.name === payload);
    const selectedOrderType = selectedOrder.orderType.sapId;
    const orderFormConfig = orderConfigs[selectedOrderType];

    if (!orderFormConfig) {
      throw new Error('Order config not found');
    }

    controller = new OrderController(orderFormConfig);

    commit('setPhase', formBuilderPhases.creatingForm);

    dispatch('initializeForm');
  },

  async initializeForm({ commit, dispatch }) {
    const userPermissions = await dispatch(
      'user/checkUserPermissions',
      false,
      { root: true },
    );

    const userGeos = userPermissions.geos.map(({ id }) => id);

    await dispatch('fetchGeoData', userGeos);

    commit('setRowData', []);

    commit('setFormData', controller.formTemplate);
    commit('setName', controller.formTemplate.name);
    commit('setOrderType', controller.formTemplate.orderType);
    commit('setBlockNames', controller.formTemplate.blockNames);
    commit('setLineItemColumnDefs', controller.formTemplate.lineItemFields.columnDefs);
    commit('setLineItemDependencies', controller.formTemplate.lineItemFields.dependencies);
    commit('setLineItemValidationRules', controller.formTemplate.lineItemFields.validationRules);
    controller.formTemplate.fields = await dispatch('fetchInitialFormInputValues', controller.formTemplate);

    commit('setPhase', formBuilderPhases.updatingValidations);

    await dispatch('updateForm', { form: controller.formTemplate, initialForm: controller.formTemplate });
  },

  setFormFields: ({ commit }, {
    form, updatedField, ...payload
  }) => {
    commit('setFormData', form);
    commit('setHeaderLevelData', form.fields);
    commit('setRowData', form.lineItemFields.rowData);

    return {
      form, updatedField, ...payload,
    };
  },

  async applyValidations({ commit }, {
    form, updatedField, ...payload
  }) {
    if (updatedField) {
      form = await controller.validate(form, updatedField);
    }

    commit('setPhase', formBuilderPhases.updatingDependencies);

    return {
      form, updatedField, ...payload,
    };
  },

  async applyDependencies(context, {
    form,
    updatedField,
    initialForm,
    operation,
  }) {
    form = await controller.applyDependencies(form, initialForm, updatedField, operation);

    return {
      form, updatedField, initialForm,
    };
  },

  async updateOptions(
    {
      commit, dispatch, getters, state,
    },
    {
      form, updatedField, ...payload
    },
  ) {
    let { geoData } = state;

    const { orderType } = controller.formTemplate;

    orderType.isReturnOrderType = getters.isReturnOrderType;

    const userPermissions = await dispatch('user/checkUserPermissions', false, { root: true });

    const userGeos = userPermissions.geos.map(({ id }) => id);

    if (!geoData?.names || !isEqual(userGeos, geoData.names)) {
      geoData = await dispatch('SOC/fetchGeoData', userGeos);
    }

    form = controller.updateOptions(form, orderType, userPermissions, geoData);
    form.lineItemFields.columnDefs = await dispatch('updateLineColumnDefs', controller.columnRequiredStatusUpdates);

    commit('setPhase', formBuilderPhases.ready);

    return {
      form, updatedField, ...payload,
    };
  },

  async updateForm({ dispatch }, payload) {
    const actions = [
      'applyDependencies',
      'applyValidations',
      'updateOptions',
      'setFormFields',
    ];

    return await pipeActions(actions, payload, dispatch);
  },

  updateHeaderField: async(
    {
      commit, dispatch, state,
    },
    updatedField,
  ) => {
    const initialForm = JSON.parse(JSON.stringify(state.formData));
    const form = controller.updateHeaderField(state.formData, updatedField);

    commit('setFormData', form);
    commit('setPhase', formBuilderPhases.updatingValidations);

    await dispatch('updateForm', {
      form, initialForm, updatedField,
    });

    if (updatedField.key === 'soldToNumber') {
      dispatch('validateBusinessTypes');
    }
  },

  updateLineField: async(
    {
      commit, dispatch, state,
    },
    {
      rows,
      operation,
      updatedField,
      shouldIgnoreChecks,
    },
  ) => {
    const initialForm = JSON.parse(JSON.stringify(state.formData));
    const form = controller.updateLineField(state.formData, rows);

    commit('setFormData', form);

    const actionName = shouldIgnoreChecks ? 'setFormFields' : 'updateForm';

    await dispatch(actionName, {
      form,
      initialForm,
      updatedField,
      operation,
    });

    if (operation === 'deleteSelectedRows') {
      dispatch('validateBusinessTypes');
    }
  },

  updateEditFormFields({ dispatch, state }) {
    const initialForm = JSON.parse(JSON.stringify(state.formData));
    const payload = {
      initialForm,
      form: state.formData,
    };

    dispatch('updateForm', payload);
  },

  async validateBusinessTypes({ state, dispatch }, lineData) {
    if (state.orderType.sapId === OrderTypeSapIdsEnum.ZFRE) {
      controller.validateBusinessTypes(state.formData, lineData);

      state.formData.lineItemFields.columnDefs = await dispatch(
        'updateLineColumnDefs',
        controller.columnRequiredStatusUpdates,
      );
    }
  },

  increaseAvailabilityInProgressCount({ state, commit }) {
    commit('setAvailabilityInProgressCount', state.availabilityInProgressCount + 1);
  },

  decreaseAvailabilityInProgressCount({ state, commit }) {
    commit('setAvailabilityInProgressCount', state.availabilityInProgressCount - 1);
  },

  pushRowToAvailabilityQueue({ commit }, rowIndex) {
    commit('pushRowToAvailabilityQueue', rowIndex);
  },

  removeRowFromAvailabilityQueue({ commit }, rowIndex) {
    commit('removeRowFromAvailabilityQueue', rowIndex);
  },

  clearAvailabilityQueue({ commit }) {
    commit('setAvailabilityInProgressCount', 0);
    commit('clearAvailabilityQueue');
  },

  updateLineColumnDefs: ({ state, commit }, columnRequiredUpdates) => {
    if (!columnRequiredUpdates.size) {
      return state.formData.lineItemFields.columnDefs;
    }

    let performUpdate = false;
    const columnDefs = state.formData.lineItemFields.columnDefs.map((column) => {
      const updateColumnDef = columnRequiredUpdates.get(column.field);
      if (updateColumnDef && updateColumnDef.required !== column.required) {
        performUpdate = true;

        return {
          ...column,
          required: updateColumnDef.required ?? column.required,
        };
      }

      return column;
    });

    if (performUpdate) {
      commit('setLineItemColumnDefs', columnDefs);
      controller.columnRequiredStatusUpdates = new Map();
    }

    return columnDefs;
  },

  setDependencyBypass({ commit }) {
    commit('setDependencyBypass', true);
  },

  removeDependencyBypass({ commit }) {
    commit('setDependencyBypass', false);
  },

  async saveAndSubmitOrder({ state }) {
    try {
      const mappedOrder = new OrderRequest({
        orderType: state.orderType,
        headerLevelFields: state.formData.fields,
        lineItems: state.formData.lineItemFields.rowData,
      }).transfromOrder();

      const { draftOrderInfo } = state;
      const isDraftData = Object.values(draftOrderInfo).every((value) => value);

      if (isDraftData) {
        Object.keys(draftOrderInfo).forEach((key) => {
          mappedOrder[key] = draftOrderInfo[key];
        });
      }

      return singleOrdersRepository.saveAndSubmitSocOrder(mappedOrder);
    } catch (err) {
      console.error(err);
    }
  },

  async saveAsDraftOrder({ state, commit }) {
    try {
      const draftOrder = new OrderRequest({
        id: state.draftOrderInfo.id,
        version: state.draftOrderInfo.version,
        orderType: state.orderType,
        headerLevelFields: state.formData.fields,
        lineItems: state.formData.lineItemFields.rowData,
      }).transfromOrder();

      const { data } = await singleOrdersRepository.saveDraftSocOrder(draftOrder);

      commit('setDraftInfo', { id: data.order.id, version: data.order.version });
    } catch (err) {
      console.error(err);
      throw new Error();
    }
  },

  resetDraftOrderInfo({ commit }) {
    commit('setDraftInfo', {});
  },

  /**
   * Fetch initial values from the external API/BE
   * Currently works only for: Header Level fields and NOW-API
   */
  async fetchInitialFormInputValues({ dispatch }, { fields }) {
    return await Promise.all(fields.map(async(field) => {
      if (field.fetchInitialValue) {
        const initialValue = await dispatch(
          field.fetchInitialValue.vuexAction,
          field.fetchInitialValue.vuexActionArgs,
          { root: true },
        );

        let initialOptions = field.options;

        if (field.key === 'nameOfOrderer') {
          initialOptions = await dispatch('SOC/findEmployee', initialValue, { root: true });
        }

        return {
          ...field,
          value: initialValue,
          options: initialOptions,
        };
      }

      return field;
    }),
    );
  },

  removeFormData({ commit }) {
    commit('setFormData', {
      fields: [],
      lineItemFields: {
        rowData: [],
        columnDefs: [],
        validationRules: {},
        dependencies: [],
      },
    });
    commit('setBlockNames', []);
    commit('setPhase', formBuilderPhases.creatingForm);
    commit('setName', undefined);
    commit('setOrderType', {});
    commit('SET_SELECTED_ORDER', {});
  },

  setGridApi({ commit }, gridApi) {
    commit('setGridApi', gridApi);
  },

  async getOrderById({ commit, dispatch }, orderId = '') {
    try {
      commit('SET_SELECTED_ORDER', {});

      const { data } = await singleOrdersRepository.getOrderById(orderId);

      commit('setOrderType', data.order.orderType.name);
      commit('SET_SELECTED_ORDER', data.order);

      if (data.order?.approval) {
        const approvalStatus = data.order.approval.status;

        dispatch('setApprovalCommentValue', data.order.approval.comment);
        dispatch(
          'setApprovalStatusValue',
          approvalStatus === APPROVAL_STATUSES.OPEN ? '' : approvalStatus,
        );
      }

      commit('setDraftInfo', { id: data.order.id, version: data.order.version });
      commit('SET_APPROVAL_ORDER_ID', orderId);
      commit('SET_USER_CONTEXT_ROLE', data.userContext.role);
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  async fetchGeoData({ commit }, geos) {
    try {
      const responses = geos.map(
        async(geo) => await addressOverrideRepository.fetchGeoInfoByName(geo),
      );
      const geoData = await Promise.all(responses);
      const countries = await addressOverrideRepository.fetchCountries();

      const mappedGeoData = {
        salesOrgs: [],
        names: [],
        countries: [],
        reasonCodes: [],
        vasChargeCodes: [],
      };

      geoData.forEach(({
        salesOrgs, name, reasonCodes, vasChargeCodes,
      }, index) => {
        mappedGeoData.salesOrgs.push(...salesOrgs);
        mappedGeoData.names.push(name);
        mappedGeoData.countries[index] = { countries, name };
        mappedGeoData.reasonCodes[index] = { reasonCodes, name };
        mappedGeoData.vasChargeCodes[index] = { vasChargeCodes, name };
      });
      commit('setGeoData', mappedGeoData);

      return mappedGeoData;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  // _______________________MY APPROVALS_______________________

  setFetchedFormData({ state, commit }, payload) {
    const convertedOrder = controller.mapFields(state.formData, payload);
    const formData = controller?.disableAllFields(convertedOrder);

    commit('setFormData', formData);

    state.gridApi.redrawRows?.();
  },

  // _______________________MY ORDERS_______________________
  setFetchedDraftFormData({ state, commit }, payload) {
    const formData = controller.mapFields(state.formData, payload, true);

    commit('setFormData', { ...formData });

    state.gridApi.redrawRows?.();
  },

  updateSingleRow({ state, commit }, rowToUpdate) {
    const formData = controller.updateSingleRow(state.formData, rowToUpdate);

    commit('setFormData', formData);
  },

  setIsRowDataPaste({ commit }, value) {
    commit('setIsRowDataPaste', value);
  },
};
