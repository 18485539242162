import { DependencyRules } from '@/controllers/order/dependencies/header';
import { LineLevelDependencyRules } from '@/controllers/order/dependencies/line';

const getHeaderPropsToChange = ({
  hidden, disabled, required, touched, value, parentValue, minDate,
}) => ({
  hidden, disabled, required, touched, value, parentValue, minDate: minDate ? minDate?.toString() : minDate,
});

const getHeaderConditionResult = ({
  dependency, updatedField, initialForm, form, field,
}) => {
  return DependencyRules[dependency.conditionType]?.({
    field,
    dependency,
    updatedField,
    initialForm,
    form,
  });
};

const getLineConditionResult = ({
  dependency, updatedField, form,
}) => {
  const rows = form.lineItemFields.rowData;
  const { rowIndex } = updatedField;

  return LineLevelDependencyRules[dependency.conditionType]?.({
    row: rows[rowIndex],
    rows,
    dependency,
    updatedField,
    rowIndex,
  });
};

const getHeaderFieldsWithDependencies = (header, form) => {
  return form.fields.reduce((acc, field) => {
    if (header[field.key]?.dependencies) {
      acc.push(field);
    }

    return acc;
  }, []);
};

const getHeaderFieldsWithLineDependencies = (lineConfigDeps, form) => {
  const lineFieldKeys = lineConfigDeps.reduce((acc, dep) => {
    if (dep.sourceFieldType === 'HeaderLevel') {
      acc.add(dep.sourceFieldKey);
    }

    return acc;
  }, new Set());

  return form.fields.filter(field => {
    return lineFieldKeys.has(field.key);
  });
};

const getLineFieldsWithDependencies = (line, form) => {
  const rows = form.lineItemFields.rowData || [];
  const uniqueDependencyKeys = new Set();

  Object.entries(line).forEach(([key, config]) => {
    if (config?.dependencies) uniqueDependencyKeys.add(key);
  });

  const keysOfFieldsWithDependencies = [...uniqueDependencyKeys.values()];

  return rows.reduce((acc, row, rowIndex) => {
    keysOfFieldsWithDependencies.forEach(fieldKey => acc.push({
      name: fieldKey,
      rowIndex,
      cellData: rows[rowIndex][fieldKey],
    }));

    return acc;
  }, []);
};

export const getFieldConfig = (config, { key, name } = {}) => {
  if (key) return config.header[key];
  if (name) return config.line[name];
};

export const getConditionResult = (payload) => {
  const { dependency } = payload;

  return dependency.sourceFieldType === 'LineLevel'
    ? getLineConditionResult(payload)
    : getHeaderConditionResult(payload);
};

export const memoHeaderFields = (prev) => {
  const prevProps = getHeaderPropsToChange(prev);

  const hasChanged = (curr) => {
    const currentProps = getHeaderPropsToChange(curr);

    return JSON.stringify(prevProps) !== JSON.stringify(currentProps);
  };

  return hasChanged;
};

export const getFieldsWithDependencies = ({
  form,
  config,
  updatedField,
  operation,
}) => {
  if (operation === 'addNewRow') {
    return getHeaderFieldsWithLineDependencies(config.lineConfigDeps, form, true);
  }

  if (updatedField) return [updatedField];

  return [
    ...getHeaderFieldsWithDependencies(config.header, form),
    ...getLineFieldsWithDependencies(config.line, form),
  ];
};
