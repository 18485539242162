import cloneDeep from 'lodash/cloneDeep';
import { AVAILABILITY_FILTERS_EMPTY_STATE } from './constants/state';

export default {
  filters: cloneDeep(AVAILABILITY_FILTERS_EMPTY_STATE),
  isLoading: false,
  availabilityDetails: [],
  pageNumber: 0,
  pageSize: 25,
};
