export default {
  onOpenModal: ({ commit, state }, nameModal) => {
    const result = [nameModal, ...state.openedModals];

    commit('SET_MODAL', result);
  },

  onCloseModal: ({ commit, state }, nameModal) => {
    const result = state.openedModals.filter((item) => item !== nameModal);

    commit('SET_MODAL', result);
  },
};
