import * as mutations from './constants/mutation-types';

export default {
  [mutations.SET_PLANNED_OUTAGE](state, data) {
    state.plannedOutage = {
      ...state.plannedOutage,
      ...data,
    };
  },

  [mutations.SET_SERVICE_DOWN](state, isServiceDown) {
    state.isServiceDown = isServiceDown;
  },

  [mutations.SET_MAINTENANCE_LOADING](state, isLoading) {
    state.isLoading = isLoading;
  },
};
