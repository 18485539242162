// * Solution to avoid action from within watcher in BaseSelectSingle.vue which creates unnecessary updateField SOC action when num of items are eq 1 - Such operation should be here in action rule.
// * This should be general rule for all SelectSingle input types.
// TODO: Try to create general config for each input type of each order type to avoid setting same rule for each input field config.
// TODO: Should be merged into src/controllers/dependencies/header/actions/setValue.js
export function dependencyRulesActionSetFirstItemAsValue({ field, value }) {
  if (value) {
    return { ...field, value: field.items[0].value };
  }

  return field;
}
