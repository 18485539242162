import exceptionsChartState from './exceptionsChart/state';
import exceptionsChartGetters from './exceptionsChart/getters';
import exceptionsChartMutations from './exceptionsChart/mutations';
import exceptionsChartActions from './exceptionsChart/actions';

import filterPanelState from './filterPanel/state';
import filterPanelGetters from './filterPanel/getters';
import filterPanelMutations from './filterPanel/mutations';
import filterPanelActions from './filterPanel/actions';

export default {
  namespaced: true,
  state: {
    ...exceptionsChartState,
    ...filterPanelState,
  },
  getters: {
    ...exceptionsChartGetters,
    ...filterPanelGetters,
  },
  mutations: {
    ...exceptionsChartMutations,
    ...filterPanelMutations,
  },
  actions: {
    ...exceptionsChartActions,
    ...filterPanelActions,
  },
};
