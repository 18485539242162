export const validationRuleIsOnlyLettersSpaces = (formField) => {
  if (!formField.touched) {
    return formField;
  }

  const pattern = '^[A-Za-z\u3000\u3400-\u4DBF\u4E00-\u9FFF\\s]+$';
  const regex = new RegExp(pattern);

  if (regex.test(formField.value) || !formField.value) {
    formField.errors = formField.errors.filter((error) => !error.lettersSpacesOnly);
  } else {
    formField.errors.push({ lettersSpacesOnly: 'Only letters and spaces are allowed' });
  }

  return formField;
};
