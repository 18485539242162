import { h } from 'vue';

import icons from '@/components/icons';

const aliases = {
  nowClose: 'NowCloseIcon',
  nowLoader: 'NowLoaderIcon',
};

export const nowAliases = Object.entries(aliases).reduce((acc, [alias, component]) => {
  acc[alias] = `now:${component}`;

  return acc;
}, {});

export const nowIcons = {
  component: (props) => h(icons[props.icon]),
};
