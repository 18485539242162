const BASE_LOADER_TYPES = {
  L: 'l',
  M: 'm',
  S: 's',
  XS: 'xs',
};

const BASE_LOADER_SIZES = {
  L: '80',
  M: '48',
  S: '24',
  XS: '16',
};

export { BASE_LOADER_TYPES, BASE_LOADER_SIZES };
