import repository from '@/api/repositories/serverRepository';
import { EXCEPTIONS, CASES } from '@/api/endpoints';

export default {
  fetchExceptions(filterParams) {
    return repository.post(EXCEPTIONS.GET_EXCEPTIONS, filterParams);
  },
  fetchRelatedOrders(filterParams) {
    return repository.post(EXCEPTIONS.GET_RELATED_ORDERS, filterParams);
  },
  fetchSelectedPos(filterParams) {
    return repository.post(EXCEPTIONS.GET_SELECTED_POS, filterParams);
  },
  resolveCases: (cases) => {
    return repository.post(CASES.RESOLVE, { cases });
  },
};
