<template>
  <div ref="calendarWrapper" :class="wrapperClass">
    <div v-if="isTopBarVisible" class="calendar__bar">
      <IconButton
        tabindex="-1"
        iconName="CaretLeftLargeDefaultOnLight"
        :aria-label="prevArrowAriaLabel"
        :size="Size.SMALL"
        :variant="IconButtonVariant.GHOST"
        :disabled="isPrevArrowDisabled"
        @click="handleArrowPrev"
      />
      <transition :name="transitionName">
        <Button
          :key="transitionKey"
          tabindex="-1"
          class="calendar__button--switch-panels"
          :size="Size.SMALL"
          :variant="ButtonVariant.GHOST"
          @click="handleSwitchPanels"
        >
          {{ switchPanelsButtonText }}
        </Button>
      </transition>
      <IconButton
        tabindex="-1"
        iconName="CaretRightLargeDefaultOnLight"
        :aria-label="nextArrowAriaLabel"
        :size="Size.SMALL"
        :variant="IconButtonVariant.GHOST"
        :disabled="isNextArrowDisabled"
        @click="handleArrowNext"
      />
    </div>
    <transition name="fade-1" mode="out-in">
      <div v-if="isCalendarGridVisible" key="calendar-grid">
        <transition :name="transitionName">
          <div :key="transitionKey" class="calendar__grid">
            <div v-for="day in daysOfWeek" :key="day" class="calendar__weekday">
              {{ day }}
            </div>
            <button
              v-for="day in displayedDays"
              :key="getKeyFromMoment(day)"
              :class="getDayClasses(day)"
              :disabled="checkDayDisabled(day)"
              tabindex="-1"
              @click="handleSelectDay(day)"
              @mouseenter="handleMouseOverDay(day)"
            >
              {{ getDayFromMoment(day) }}
            </button>
          </div>
        </transition>
      </div>
      <div v-else-if="isMonthGridVisible" key="month-grid">
        <transition :name="transitionName">
          <div :key="transitionKey" class="calendar__month-grid">
            <button
              v-for="month in displayedMonths"
              :key="getKeyFromMoment(month)"
              :class="getMonthClasses(month)"
              :disabled="isMonthDisabled(month)"
              tabindex="-1"
              @click="handleChangeMonth(month)"
              @mouseenter="handleMouseOverDay(month)"
            >
              {{ getMonthFromMoment(month) }}
            </button>
          </div>
        </transition>
      </div>

      <div v-else-if="isYearGridVisible" key="year-grid">
        <div class="calendar__year-grid">
          <button
            v-for="(year, index) in displayedYears"
            :key="getKeyFromMoment(year)"
            class="calendar__year-item"
            :class="getYearClasses(year, index)"
            tabindex="-1"
            @click="handleChangeYear(year, index)"
          >
            {{ getYearFromMoment(year) }}
          </button>
        </div>
      </div>
    </transition>
    <div v-if="isRangeDatepicker && !isYearGridVisible" class="calendar__range-buttons">
      <Button
        :size="Size.SMALL"
        :variant="ButtonVariant.SECONDARY"
        @click="handleCancel"
      >
        Cancel
      </Button>
      <Button
        :size="Size.SMALL"
        :variant="ButtonVariant.PRIMARY"
        @click="handleAcceptChanges"
      >
        Apply
      </Button>
    </div>
  </div>
</template>

<script>
import moment from 'moment-business-days';
import {
  Size,
  Button,
  IconButton,
  ButtonVariant,
  IconButtonVariant,
} from 'podium';

import { DAYS_OF_WEEK } from './config';

export default {
  components: {
    Button,
    IconButton,
  },

  props: {
    isDisplayedAboveInput: Boolean,
    isPrevAndNextMonthVisible: Boolean,
    isRangeDatepicker: Boolean,
    isHoverHiglightDisabled: Boolean,

    injectedSelectedDate: {
      type: Object,
      default: null,
    },

    injectedSelectedRange: {
      type: Array,
      default: () => [],
    },

    minDate: {
      type: String,
      default: '1900-01-01',
    },

    maxDate: {
      type: String,
      default: '2099-12-31',
    },
  },

  emits: ['daySelected', 'rangeSelected', 'changesAccepted', 'cancel'],

  data() {
    return {
      displayedDate: {},
      selectedDay: null,
      isCalendarGridVisible: true,
      isMonthGridVisible: false,
      isYearGridVisible: false,
      isTopBarVisible: true,
      transitionKey: 0,
      transitionName: '',
      transitionDuration: 250,
      highlightedYearIndex: null,
      selectedRange: [],
      hoveredDay: null,

      Size,
      ButtonVariant,
      IconButtonVariant,
    };
  },

  computed: {
    daysOfWeek() {
      return DAYS_OF_WEEK;
    },

    displayedMonth() {
      return moment(this.displayedDate).format('MMM');
    },

    displayedYear() {
      return moment(this.displayedDate).format('YYYY');
    },

    startOfMonth() {
      return moment(this.displayedDate).startOf('month');
    },

    endOfMonth() {
      return moment(this.displayedDate).endOf('month');
    },

    firstDisplayedDay() {
      return moment(this.startOfMonth).startOf('week');
    },

    lastDisplayedDay() {
      return moment(this.endOfMonth).endOf('week');
    },

    switchPanelsButtonText() {
      return `${this.displayedMonth} ${this.displayedYear}`;
    },

    displayedDays() {
      const displayedDays = [];

      const start = this.firstDisplayedDay;

      while (!start.isAfter(this.lastDisplayedDay)) {
        displayedDays.push(moment({ ...start }));
        start.add(1, 'day');
      }

      return displayedDays;
    },

    displayedMonths() {
      const start = moment(this.displayedDate).startOf('year');
      const end = moment(this.displayedDate).endOf('year');

      const displayedMonths = [];

      while (!start.isAfter(end)) {
        displayedMonths.push(moment({ ...start }));
        start.add(1, 'month');
      }

      return displayedMonths;
    },

    displayedYears() {
      const start = moment(this.minDate);
      const end = moment(this.maxDate);

      const displayedYears = [];

      while (!start.isAfter(end)) {
        displayedYears.push(moment({ ...start }));
        start.add(1, 'year');
      }

      return displayedYears.reverse();
    },

    wrapperClass() {
      return {
        calendar__wrapper: true,
        'calendar__wrapper--overflow-auto': this.isYearGridVisible,
        'calendar__wrapper--above-input': this.isDisplayedAboveInput,
        'calendar__wrapper--range-picker': this.isRangeDatepicker,
        'calendar__wrapper--above-input-range': this.isRangeDatepicker && this.isDisplayedAboveInput,
      };
    },

    isPrevArrowDisabled() {
      if (this.isCalendarGridVisible) {
        return moment(this.startOfMonth).isSameOrBefore(moment(this.minDate));
      }

      if (this.isMonthGridVisible) {
        return moment(this.displayedYear).isSameOrBefore(moment(this.minDate));
      }

      return false;
    },

    isNextArrowDisabled() {
      if (this.isCalendarGridVisible) {
        return moment(this.endOfMonth).isAfter(moment(this.maxDate));
      }

      if (this.isMonthGridVisible) {
        return moment(this.displayedYear).isSameOrAfter(moment(this.maxDate).startOf('year'));
      }

      return false;
    },

    nextArrowAriaLabel() {
      return this.isMonthGridVisible ? 'Next year' : 'Next month';
    },

    prevArrowAriaLabel() {
      return this.isMonthGridVisible ? 'Previous year' : 'Previous month';
    },

    sortedFirstFromRangeAndHover() {
      if (this.selectedRange.length === 1 && this.hoveredDay) {
        if (this.hoveredDay.isBefore(this.selectedRange[0])) {
          return [moment(this.hoveredDay), moment(this.selectedRange[0])];
        }

        return [moment(this.selectedRange[0]), moment(this.hoveredDay)];
      }

      return null;
    },
  },

  watch: {
    isYearGridVisible() {
      setTimeout(() => {
        const { calendarWrapper } = this.$refs;
        const highlightedYearIndex = this.displayedYears.findIndex(
          (year) => year.format('YYYY') === this.displayedYear,
        );

        calendarWrapper.scrollTop = highlightedYearIndex * 40 - 111;
      }, this.transitionDuration);
    },
  },

  mounted() {
    if (this.injectedSelectedDate === 'error') {
      this.displayedDate = null;

      return;
    }

    if (this.injectedSelectedDate) {
      this.selectedDay = this.injectedSelectedDate;
      this.displayedDate = null;
      this.displayedDate = this.injectedSelectedDate;
    }

    if (this.injectedSelectedRange.length) {
      const [firstCombination, _] = this.injectedSelectedRange;
      this.displayedDate = firstCombination;

      this.selectedRange = this.injectedSelectedRange.map((date) => moment(date));
    }

    if (
      !this.injectedSelectedDate
      && this.injectedSelectedRange.length === 0
      && moment(this.minDate).isAfter(moment())
    ) {
      this.displayedDate = this.minDate;
    }
  },

  methods: {
    getDayClasses(day) {
      return {
        calendar__day: true,
        'calendar__day--today': this.isDayToday(day),
        'calendar__day--disabled': this.isDayDisabled(day),
        'calendar__day--this-month': this.isDayThisMonth(day),
        'calendar__day--selected': this.isDaySelected(day),
        'calendar__day--hidden': this.isDayHidden(day),
      };
    },

    getMonthClasses(month) {
      return {
        'calendar__month-item': true,
        'calendar__month-item--disabled': this.isMonthDisabled(month),
        'calendar__month-item--selected': this.isMonthSelected(month),
      };
    },

    getYearClasses(year) {
      return {
        'calendar__year-item--selected': year.format('YYYY') === this.displayedYear,
      };
    },

    handleArrowPrev() {
      this.transitionName = 'slide-left';
      this.transitionKey++;

      if (this.isMonthGridVisible) {
        this.displayedDate = moment(this.displayedDate).subtract(1, 'year');

        return;
      }

      this.displayedDate = moment(this.displayedDate).subtract(1, 'month');
    },

    handleArrowNext() {
      this.transitionName = 'slide-right';
      this.transitionKey++;

      if (this.isMonthGridVisible) {
        this.displayedDate = moment(this.displayedDate).add(1, 'year');

        return;
      }

      this.displayedDate = moment(this.displayedDate).add(1, 'month');
    },

    handleSelectDay(day) {
      if (this.isRangeDatepicker) {
        this.handleSelectRange(day);

        return;
      }

      this.selectedDay = day;

      setTimeout(() => {
        this.$emit('daySelected', day);
      }, 50);
    },

    handleSelectRange(day) {
      if (this.selectedRange.length === 2) {
        this.selectedRange = [];
      }

      this.selectedRange.push(day);
      this.sortSelectedRange();
      this.$emit(
        'rangeSelected',
        this.selectedRange.map((date) => date.format('YYYY-MM-DD')),
      );
    },

    sortSelectedRange() {
      if (this.selectedRange.length <= 1) {
        return this.selectedRange;
      }

      if (this.selectedRange[0].isBefore(this.selectedRange[1])) {
        return this.selectedRange;
      }

      this.selectedRange = this.selectedRange.slice().reverse();
    },

    handleChangeMonth(month) {
      this.displayedDate = month;

      this.isCalendarGridVisible = true;
      this.isMonthGridVisible = false;
    },

    handleChangeYear(year) {
      this.displayedDate = year;
      this.isYearGridVisible = false;
      this.isMonthGridVisible = true;

      setTimeout(() => {
        this.isTopBarVisible = true;
      }, this.transitionDuration);
    },

    handleSwitchPanels() {
      if (this.isCalendarGridVisible) {
        this.isCalendarGridVisible = false;
        this.isMonthGridVisible = true;

        return;
      }

      if (this.isMonthGridVisible) {
        this.isMonthGridVisible = false;
        this.isYearGridVisible = true;

        setTimeout(() => {
          this.isTopBarVisible = false;
        }, this.transitionDuration);
      }
    },

    checkDayDisabled(day) {
      return day.isBefore(this.minDate) || day.isAfter(this.maxDate);
    },

    isMonthDisabled(month) {
      return (
        !moment(this.minDate).startOf('month').isSameOrBefore(month)
        || !moment(this.maxDate).isSameOrAfter(month)
      );
    },

    getKeyFromMoment(date) {
      return date.format('YYYY-MM-DD');
    },

    getDayFromMoment(date) {
      return date.date();
    },

    getMonthFromMoment(date) {
      return date.format('MMMM').slice(0, 3);
    },

    getYearFromMoment(date) {
      return date.format('YYYY');
    },

    isDayToday(day) {
      return day.isSame(moment().startOf('day'));
    },

    isDayDisabled(day) {
      return day.isBefore(moment(this.minDate)) || day.isAfter(this.maxDate);
    },

    isDayThisMonth(day) {
      return !day.isBefore(this.startOfMonth) && !day.isAfter(this.endOfMonth);
    },

    isDaySelected(day) {
      if (!this.isRangeDatepicker) {
        return this.selectedDay && day.isSame(this.selectedDay);
      }

      if (this.selectedRange.length === 2) {
        return (
          day.isSameOrAfter(this.selectedRange[0]) && day.isSameOrBefore(this.selectedRange[1])
        );
      }

      if (!this.isHoverHiglightDisabled && this.sortedFirstFromRangeAndHover) {
        return (
          day.isSameOrAfter(this.sortedFirstFromRangeAndHover[0])
          && day.isSameOrBefore(this.sortedFirstFromRangeAndHover[1])
        );
      }

      return false;
    },

    isDayHidden(day) {
      return (
        !this.isPrevAndNextMonthVisible
        && (day.isBefore(this.startOfMonth) || day.isAfter(this.endOfMonth))
      );
    },

    isMonthSelected(month) {
      if (!this.isRangeDatepicker) {
        return (
          this.selectedDay
          && this.selectedDay.isSameOrAfter(month.startOf('month'))
          && this.selectedDay.isSameOrBefore(month.endOf('month'))
        );
      }

      if (this.selectedRange.length === 2) {
        return (
          month.isSameOrAfter(this.selectedRange[0].startOf('month'))
          && month.isSameOrBefore(this.selectedRange[1].endOf('month'))
        );
      }

      if (!this.isHoverHiglightDisabled && this.sortedFirstFromRangeAndHover) {
        return (
          this.sortedFirstFromRangeAndHover[0].startOf('month').isSameOrBefore(month)
          && this.sortedFirstFromRangeAndHover[1].startOf('month').isSameOrAfter(month)
        );
      }

      return false;
    },

    handleMouseOverDay(day) {
      this.hoveredDay = day;
    },

    handleAcceptChanges() {
      this.$emit('changesAccepted');
    },

    handleCancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/components/ui/datePicker/datePicker.scss';
@import '@/styles/colors.scss';

$wrapper-height: 280px;
$wrapper-height-range: 332px;

.calendar {
  color: $primary-black;

  &__btn {
    cursor: pointer;
    color: $primary-black;
    border: none;
    background: none;
    transition: 0.25s all ease;

    &:hover {
      background: $grey-3;
    }
  }

  &__wrapper {
    position: absolute;
    overflow: hidden;
    width: 280px;
    height: $wrapper-height;
    top: calc(100% + 1px);
    left: 0;
    border: 1px solid $grey-2;
    background: $primary-white;
    border-radius: 2px;
    box-shadow: $date-popup-shadow;
    z-index: 3;

    &--overflow-auto {
      overflow: auto;
      overflow: overlay;
    }

    &--above-input {
      top: -1 * ($wrapper-height + 8px);
    }

    &--above-input-range {
      top: -1 * ($wrapper-height-range + 8px);
    }

    &--range-picker {
      height: $wrapper-height-range;
    }
  }

  &__grid {
    display: grid;
    place-items: center;
    padding: 0 12px;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: repeat(7, 1fr);
  }

  &__bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 4px;
    padding: 0 12px;
  }

  &__weekday {
    display: grid;
    place-items: center;
    width: 32px;
    height: 32px;
    font-weight: 600;
    font-size: 12px;
    color: $grey-1;
  }

  &__day {
    display: grid;
    place-items: center;
    width: 32px;
    height: 32px;
    font-size: 12px;
    color: $primary-black;
    border-radius: 50%;
    transition: 0.25s all ease;

    &:hover {
      background: $grey-3;
    }

    &--today {
      border: 1px solid $primary-black;
    }

    &--this-month {
      visibility: visible;
      font-weight: 500;
    }

    &--selected {
      background: $primary-black;
      border-radius: 50em;
      color: white;
      font-weight: 600;

      &:hover {
        background: $primary-black;
      }
    }

    &--disabled {
      color: $grey-5;
      cursor: default;
      font-weight: 500;

      &:hover {
        background: none;
      }
    }

    &--hidden {
      visibility: hidden;
    }
  }

  &__button--switch-panels.podium-button {
    padding: 8px;
    width: 150px;
    color: $primary-black;
    font-size: 12px;
    font-weight: 900;
    transition: all 0.25s ease;

    &:hover {
      color: $active-link;
    }
  }

  &__month-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-column-gap: 2px;
    grid-row-gap: 2px;
    padding: 0 12px;
  }

  &__month-item {
    @extend .calendar__btn;
    @extend .now-label-1;

    margin: 8px 0;
    padding: 10px;
    border-radius: 4px;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    cursor: pointer;

    &--disabled {
      color: $grey-5;
      cursor: default;

      &:hover {
        background: $primary-white;
      }
    }

    &--selected {
      color: $primary-white;
      background: $primary-black;

      &:hover {
        background: $primary-black;
      }
    }
  }

  &__year-grid {
    display: flex;
    flex-direction: column;
  }

  &__year-item {
    @extend .calendar__btn;
    @extend .now-body-2;

    padding: 8px 0;

    &:hover {
      background: $grey-3;
    }

    &--selected {
      padding: 16px 0;
      font-weight: 500;
      font-size: 26px;
    }
  }

  &__range-buttons {
    position: absolute;
    display: flex;
    bottom: 16px;
    right: 16px;
    align-items: center;

    & > button {
      margin-left: 16px;
      font-weight: normal;
    }
  }
}
</style>
