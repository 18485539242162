import repository from '@/api/repositories/serverRepository';
import { LINK_LIBRARY } from '@/api/endpoints';

export default {
  fetchResources() {
    return repository.get(LINK_LIBRARY.GET_RESOURCES);
  },

  updateFavoriteStatus(resourceData) {
    return repository.post(LINK_LIBRARY.UPDATE_FAVORITE_STATUS, resourceData);
  },
};
