/**
 * ! We have (2+) different OrterTypeNames Enum files
 * src/enums/order/orderType.js
 * src/enums/singleOrderCreation/orderTypeNames.js
 * TODO: Should be addressed in dedicated ticket: https://jira.nike.com/browse/GOLOM-8840
 */

export default [
  'Contract',
  'Free-of-Charge Order Type',
  'Free-of-Charge Order Type with Reference',
  'Standard Order Type',
  'Standard Order with Reference',
  'Return Sales Order',
  'Free-of-Charge Returns Sales Order',
  'Super User Order',
  'Samples Standard Order',
];

/**
 * @link: https://confluence.nike.com/pages/viewpage.action?spaceKey=OSD&title=Order+type+mapping%3A+NOW+vs+SAP
 */
export const OrderTypeSapIdsEnum = {
  ZCON: 'ZCON',
  ZSTA: 'ZSTA',
  ZFRE: 'ZFRE',
  ZRST: 'ZRST',
  ZRFR: 'ZRFR',
  NOW_SAMPLE_STD: 'NOW_SAMPLE_STD',
  NOW_SAMPLE_FREE: 'NOW_SAMPLE_FREE',
};

export const OrderTypeSapIdNameEnum = {
  [OrderTypeSapIdsEnum.ZCON]: 'Contract',
  [OrderTypeSapIdsEnum.ZSTA]: 'Standard Order',
  [OrderTypeSapIdsEnum.ZFRE]: 'Free-of-Charge Order',
  [OrderTypeSapIdsEnum.ZRST]: 'Return Sales Order',
  [OrderTypeSapIdsEnum.ZRFR]: 'Free-of-Charge Returns Sales Order',
  [OrderTypeSapIdsEnum.NOW_SAMPLE_STD]: 'Samples Standard Order',
};

export const ReturnOrderTypeSapIdNameEnum = {
  [OrderTypeSapIdsEnum.ZRST]: OrderTypeSapIdNameEnum[OrderTypeSapIdsEnum.ZRST],
  [OrderTypeSapIdsEnum.ZRFR]: OrderTypeSapIdNameEnum[OrderTypeSapIdsEnum.ZRFR],
};

export const SamplesOrderTypeSapIdNameEnum = {
  [OrderTypeSapIdsEnum.NOW_SAMPLE_STD]: OrderTypeSapIdNameEnum[OrderTypeSapIdsEnum.NOW_SAMPLE_STD],
  [OrderTypeSapIdsEnum.NOW_SAMPLE_FREE]: OrderTypeSapIdNameEnum[OrderTypeSapIdsEnum.NOW_SAMPLE_FREE],
};
