import { addressOverrideFieldNames } from './addressOverride';

// TODO: This should be extracted to root-level constants
export const fieldNames = {
  ...addressOverrideFieldNames,

  qty: 'qty', // ! Inconsistency with BE: returned field name is quantity
  crd: 'crd',
  size: 'size',
  line: 'line',
  name: 'name',
  nameCO: 'nameCO',
  vatRate: 'vatRate',
  quantity: 'quantity',
  vasCode: 'vasCode',
  vasCodes: 'vasCodes',
  vasField1: 'vasField1',
  vasField2: 'vasField2',
  vasField3: 'vasField3',
  vasChargeCode: 'vasChargeCode',
  vasMaterial: 'vasMaterial',
  plantCode: 'plantCode',
  unitAllotment: 'unitAllotment',
  cancelDate: 'cancelDate',
  reasonCode: 'reasonCode',
  validToDate: 'validToDate',
  vasLongText: 'vasLongText',
  managersName: 'managersName',
  soldToNumber: 'soldToNumber',
  shipToNumber: 'shipToNumber',
  availability: 'availability',
  salesChannel: 'salesChannel',
  usageCodeLifi: 'usageCodeLifi',
  totalQuantity: 'totalQuantity',
  validFromDate: 'validFromDate',
  nameOfOrderer: 'nameOfOrderer',
  contractNumber: 'contractNumber',
  materialNumber: 'materialNumber',
  sourcingMethod: 'sourcingMethod',
  approverStatus: 'approverStatus',
  numberOfCartons: 'numberOfCartons',
  streetNumberName: 'streetNumberName',
  shopFloorSuiteEtc: 'shopFloorSuiteEtc',
  shippingCondition: 'shippingCondition',
  salesOrganization: 'salesOrganization',
  contractOwnerName: 'contractOwnerName',
  requirementSegment: 'requirementSegment',
  availabilityStatus: 'availabilityStatus',
  nikeInternalComments: 'nikeInternalComments',
  reasonCodeAuthorized: 'reasonCodeAuthorized',
  purchasingPolicy: 'compPoliciesAccepted',
  purchaseOrderNumber: 'purchaseOrderNumber',
  returnAuthorizationsNumber: 'returnAuthorizationsNumber',
  businessPurpose: 'businessPurpose',
  businessPurposeDate: 'bpDate',
  nameOfTheRecipient: 'bpRecipientName',
  hasDiligenceBeenCompleted: 'bpCharityDueDiligence',
  customerPONumber: 'customerPONumber',
  contactDisclaimer: 'contactDisclaimer',
  internalOrderReferenceNumber: 'internalOrderReferenceNumber',
  markFor: 'markFor',
  deliveryBlock: 'deliveryBlock',
  onBehalfOf: 'onBehalfOf',
  departmentCode: 'departmentCode',
  confirmation: 'confirmation',
  governmentOfficial: 'governmentOfficial',
  goPreApproved: 'goPreApproved',
  goPreApprovalRefNumber: 'goPreApprovalRefNumber',
  preApprovalDisclaimer: 'preApprovalDisclaimer',
  eventPurpose: 'bpEventType',
  attendeesList: 'bpAttendeesList',
  preApprovalOnDisclosure: 'bpPreApproved',
  giftPreApprovalReferenceNumber: 'bpPreApprovalRefNumber',
  preApprovalNumberDisclaimer: 'preApprovalNumberDisclaimer',
  globalSourcing: 'bpGlobalSourcing',
  sourcingMethodGa: 'sourcingMethodGa',
  preApprovalNotAllowed: 'preApprovalNotAllowed',
  charityTaxID: 'bpCharityTaxId',
  charitableOrganization: 'bpCharitableOrganization',
  intendedPurpose: 'bpIntendedPurpose',
  charityGiftNote: 'bpCharityNote',
  seedingInfo: 'seedingInfo',
  usageCode: 'usageCode',
  id: 'id',
  invoiceNumber: 'invoiceNumber',

  // Some of these fields may be duplicated. Added for purposes of preApproval section
  paRefNumber: 'paRefNumber',
  paNotAllowed: 'paNotAllowed',
  paContactEco: 'paContactEco',
  paContactSCI: 'paContactSCI',
  paDepartment: 'paDepartment',
  paInternalGift: 'paInternalGift',
  paExternalGift: 'paExternalGift',
  paSciRefNumber: 'paSciRefNumber',
  paDisclosureTool: 'paDisclosureTool',
  paSciDisclosureTool: 'paSciDisclosureTool',
  paGovernmentOfficial: 'paGovernmentOfficial',
  paDonationToCharitableOrganization: 'paDonationToCharitableOrganization',

  soldTo: 'soldTo', // TODO: Remove duplicates
  shipTo: 'shipTo', // TODO: Remove duplicates
} as const;
