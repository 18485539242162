import ContractOrderConfig from './contractOrder';
import StandardOrderConfig from './standardOrder';
import ReturnSalesOrderConfig from './returnSalesOrder';
import FreeOfChargeOrderConfig from './freeOfChargeOrder';
import FreeOfChargeReturnsOrderConfig from './freeOfChargeReturnsOrder';
import SamplesStandardOrderConfig from './samplesStandardOrder';
import SamplesFreeOfChargeOrderConfig from './samplesFreeOfChargeOrder';

export default {
  ZCON: ContractOrderConfig,
  ZSTA: StandardOrderConfig,
  ZRST: ReturnSalesOrderConfig,
  ZFRE: FreeOfChargeOrderConfig,
  ZRFR: FreeOfChargeReturnsOrderConfig,
  NOW_SAMPLE_STD: SamplesStandardOrderConfig,
  NOW_SAMPLE_FREE: SamplesFreeOfChargeOrderConfig,
};
