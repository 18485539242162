import moment from 'moment-business-days';
import dateFormats from '@/enums/dates/dateFormats';

import { formatDate } from '@/utils/dates';

export function addDaysToDate(date, daysToAdd, dateFormat = dateFormats.CHINA) {
  return moment(date, dateFormat).add(daysToAdd, 'days').format(dateFormat);
}

export function addBusinessDaysToDate(date, daysToAdd, dateFormat = dateFormats.CHINA) {
  return moment(date, dateFormat).businessAdd(daysToAdd);
}

export function upperCaseNamesInLineLevel(lineLevelLines) {
  return lineLevelLines.map((line) => {
    const lineWithUppercaseNames = {};

    Object.keys(line).forEach((fieldName) => {
      const fieldValue = line[fieldName];

      if (Array.isArray(fieldValue)) {
        lineWithUppercaseNames[fieldName] = fieldValue.map((fieldItem) => {
          return { ...fieldItem, name: fieldItem.name.toUpperCase() };
        });
      } else {
        lineWithUppercaseNames[fieldName] = {
          ...fieldValue,
          name: fieldValue?.name?.toUpperCase(),
        };
      }
    });

    return lineWithUppercaseNames;
  });
}

export const setInitialMinDate = ({
  refDate,
  add,
  unit = 'calendarDay',
  dateFormat = dateFormats.ISO,
}) => {
  let initialMinDate;

  if (add) {
    if (unit === 'businessDay') {
      initialMinDate = addBusinessDaysToDate(refDate, add).format(dateFormat);
    } else if (unit === 'calendarDay') {
      initialMinDate = addDaysToDate(refDate, add);
    }
  } else {
    initialMinDate = formatDate(refDate, dateFormat);
  }

  return initialMinDate;
};
