import moment from 'moment';
import { addDaysToDate, addBusinessDaysToDate } from '@/utils/singleOrderCreate';

export function dependencyRulesActionSetMinDate({
  field, value, dependency,
}) {
  if (!value) return field;

  const momentFromValue = moment(value).startOf('day');

  let minDate = value;
  if (dependency.actionArgs?.add) {
    if (dependency.actionArgs.unit === 'calendarDay') {
      minDate = addDaysToDate(momentFromValue, dependency.actionArgs.add);
    } else if (dependency.actionArgs.unit === 'businessDay') {
      minDate = addBusinessDaysToDate(momentFromValue, dependency.actionArgs.add);
    }
  }

  return { ...field, minDate };
}
