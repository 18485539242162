import state from './fileValidationRules.state';
import actions from './fileValidationRules.actions';
import mutations from './fileValidationRules.mutations';
import getters from './fileValidationRules.getters';

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
