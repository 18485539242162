import { isEqual } from 'lodash';
import { fieldNames } from '@/enums/singleOrderCreation/fieldNames';
import { preApprovalConditionFields } from '@/controllers/order/enums/field';

export const dependencyRuleSetOfConditions = ({ dependency, form }) => {
  const { fields } = form || { fields: [] };

  const setOfCombinations = {
    [fieldNames.paDisclosureTool]: [
      ['n', 'y', 'y', 'y'],
      ['n', 'n', 'y', 'y'],
      ['n', 'y', 'n', 'y'],
      ['n', 'y', 'y', 'n'],
      ['n', 'n', 'n', 'y'],
      ['n', 'n', 'y', 'n'],
      ['n', 'y', 'n', 'n'],
      ['y', 'n', 'n', 'y'],
    ],
    [fieldNames.paNotAllowed]: [
      ['y', 'y', 'y', 'y'],
      ['y', 'n', 'y', 'y'],
      ['y', 'y', 'n', 'y'],
      ['y', 'y', 'y', 'n'],
      ['y', 'n', 'y', 'n'],
      ['y', 'y', 'n', 'n'],
    ],
    [fieldNames.paRefNumber]: [
      ['n', 'n', 'n', 'n'],
      ['y', 'n', 'n', 'n'],
    ],
  };

  const dependentFieldValues = fields
    .filter((field) => preApprovalConditionFields.includes(field.key))
    .map(({ value }) => value);

  return setOfCombinations[(dependency.targetKey)]?.some(set => isEqual(set, dependentFieldValues)) || false;
};
