<template>
  <div
    v-if="!isWarningBanner"
    :class="`${baseClassName}__background`"
  >
    <Icon
      name="CheckLargeDefaultOnDark"
      :dark="true"
      :class="`${baseClassName}__icon`"
    />
  </div>

  <div
    v-else
    :class="`${baseClassName}__background--error`"
  >
    <h3>!</h3>
  </div>
</template>

<script setup>

import { Icon } from 'podium';

const baseClassName = 'base-status-icon';

defineProps({
  isWarningBanner: {
    type: Boolean,
    default: false,
  },
});

</script>

<style lang="scss">
@import 'podium-tokens';
@import '@/styles/colors.scss';

.base-status-icon {

  &__background {
    background-color: $brand-success-green;
    width: 40px;
    height: 40px;
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;

    &--error {
      background-color: $podium-color-orange-400;
      width: 40px;
      height: 40px;
      border-radius: 50%;

      display: flex;
      align-items: center;
      justify-content: center;

      h3 {
        font-size: 18px;
        color: $primary-white;
      }
    }
  }
}
</style>
