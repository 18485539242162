// This component will be removed once v-select will be substituted with Podium component
<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.98019 7.23206L5.22909 4.48096L4.43359 5.27645L7.18469 8.02755L4.43362 10.7786L5.22911 11.5741L7.98019 8.82305L10.7313 11.5741L11.5268 10.7786L8.77568 8.02755L11.5268 5.27645L10.7313 4.48096L7.98019 7.23206Z"
    />
  </svg>
</template>
