export default {
  async getUserAttributes({
    commit, dispatch, state,
  }) {
    try {
      const userAttributes = await dispatch('user/checkUserPermissions', false, { root: true });
      const { businessTypes, orderTypes } = transformUserAttributes(userAttributes);

      await commit('SET_AVAILABLE_BUSINESS_TYPES', businessTypes);
      await commit('SET_AVAILABLE_ORDER_TYPES', orderTypes);

      await dispatch(
        'SOC/setAllowedOrderTypes',
        userAttributes.orderTypesToSalesOrgs.map(({ orderType }) => orderType),
        { root: true },
      );

      await dispatch('saveSelectedBusinessTypes', state.availableBusinessTypes);
    } catch (error) {
      throw new Error(error);
    }
  },
};

function transformUserAttributes(responseData) {
  const { businessTypesToOrderTypes } = responseData;
  const businessTypes = [];
  const orderTypes = {};

  businessTypesToOrderTypes.forEach(({ businessType, orderTypes: responseOrderTypes }) => {
    businessTypes.push(businessType);

    orderTypes[businessType.name] = responseOrderTypes;
  });

  return {
    businessTypes,
    orderTypes,
  };
}
