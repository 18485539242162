import i18n from '@/plugins/i18n';

import { fieldNames } from '@/enums/singleOrderCreation/fieldNames';

export const translateModule = (modulePath: string) => {
  return (fieldName: string) => i18n.global.t(`${modulePath}.${fieldName}`);
};

export const translateFields = (fieldName: keyof typeof fieldNames) => {
  return translateModule('fields')(fieldName);
};
