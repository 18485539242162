import { defineAsyncComponent } from 'vue';

const BaseDatepicker = defineAsyncComponent(() => import('@/components/ui/BaseDatepicker'));

/**
 * TODO:
 * This mixin should be removed since its only purpose is to import
 * BaseDatepicker component. It should be either imported directly
 * or registered globally without using mixins.
 */
export const importBaseDatepickerConfig = {
  components: {
    BaseDatepicker,
  },
};
