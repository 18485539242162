import mutations from './mutation-type';

export default {
  [mutations.SET_VALIDATION_RULES](state, rules) {
    state.validationRules = rules;
  },

  [mutations.SET_ORDER_TYPES](state, types) {
    state.orderTypes = types;
  },

  [mutations.SET_PRODUCT_ENGINES](state, engines) {
    state.productEngines = engines;
  },
};
