import router from '@/router';
import store from '@/store';
import { IOrderData, IFailedOrderData } from './types';

export const editHandler = ({ data: orderData }: {data: IOrderData}) => {
  if (orderData?.nowId) {
    router.push(`/myOrders/${orderData.nowId}`);
  }
};

export const deleteHandler = ({ data: orderData }: {data: IOrderData}) => {
  store.dispatch('myOrders/deleteOrderActionHandler', orderData);
};

export const errorDetailHandler = ({ data: orderData }: { data: IFailedOrderData }) => {
  store.dispatch('myOrders/errorDetailOrder', orderData);
};

export const resolveActionHandler = ({ data: orderData } : { data: IOrderData }) => {
  if (orderData?.ofoaId) {
    router.push(`/purchase-order/ofoaId=${orderData.ofoaId}`);
  }
};

export const resubmitActionHandler = ({ data: orderData } : { data: IOrderData }) => {
  if (orderData?.nowId) {
    store.dispatch('myOrders/resubmitOrder', orderData.nowId);
  }
};
