import employeeRepository from '@/api/now/employee';
import { fieldNames } from '@/enums/singleOrderCreation/fieldNames';

export async function dependencyRulesActionSetNewManagers({
  field, form, value,
}) {
  if (!value) return field;

  try {
    const { fields } = form || { fields: [] };
    const { email } = fields.find(item => item.key === fieldNames.onBehalfOf);
    const { data } = await employeeRepository.getManagers(email);
    const items = data.map((item) => {
      return { text: item.displayName, value: item.nikeId };
    });

    return {
      ...field, items: [...items], value: field.value || items[0].value,
    };
  } catch (err) {
    console.error(err);
  }
}
