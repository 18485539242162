export const ATHLETE_SERVICE_EQUIPMENT_TEXT = 'Athlete Service – Equipment (Contracted)';
export const ATHLETE_SERVICE_MERCH_TEXT = 'Athlete Service – Merch (Contracted)';
export const CATALYST_MERCH_TEXT = 'Catalyst-Merch (Contracted)';
export const CHARITABLE_DONATION_TEXT = 'Charitable Donation';
export const CONTENT_CREATION_TEXT = 'Content Creation';
export const EVENT_TEXT = 'Event';
export const EXTERNAL_GIFT_TEXT = 'External Gift';
export const INTERNAL_RECOGNITION_TEXT = 'Internal Recognition';
export const INTERNAL_USE_TEXT = 'Internal Use';
export const SEEDING_TEXT = 'Seeding';
export const OTHER_TEXT = 'Other';

export const ATHLETE_SERVICE_EQUIPMENT_VALUE = 'ATHLETE_SERVICE_EQP';
export const ATHLETE_SERVICE_MERCH_VALUE = 'ATHLETE_SERVICE_MERCH';
export const CATALYST_MERCH_VALUE = 'CATALYST_MERCH';
export const CHARITABLE_DONATION_VALUE = 'CHARITABLE_DONATION';
export const CONTENT_CREATION_VALUE = 'CONTENT_CREATION';
export const EVENT_VALUE = 'EVENT';
export const EXTERNAL_GIFT_VALUE = 'EXTERNAL_GIFT';
export const INTERNAL_RECOGNITION_VALUE = 'INTERNAL_RECOGNITION';
export const INTERNAL_USE_VALUE = 'INTERNAL_USE';
export const SEEDING_VALUE = 'SEEDING';
export const OTHER_VALUE = 'OTHER';

export const BUSINESS_PURPOSE_VALUES = [
  { value: ATHLETE_SERVICE_EQUIPMENT_VALUE, text: ATHLETE_SERVICE_EQUIPMENT_TEXT },
  { value: ATHLETE_SERVICE_MERCH_VALUE, text: ATHLETE_SERVICE_MERCH_TEXT },
  { value: CATALYST_MERCH_VALUE, text: CATALYST_MERCH_TEXT },
  { value: CHARITABLE_DONATION_VALUE, text: CHARITABLE_DONATION_TEXT },
  { value: CONTENT_CREATION_VALUE, text: CONTENT_CREATION_TEXT },
  { value: EVENT_VALUE, text: EVENT_TEXT },
  { value: EXTERNAL_GIFT_VALUE, text: EXTERNAL_GIFT_TEXT },
  { value: INTERNAL_RECOGNITION_VALUE, text: INTERNAL_RECOGNITION_TEXT },
  { value: INTERNAL_USE_VALUE, text: INTERNAL_USE_TEXT },
  { value: SEEDING_VALUE, text: SEEDING_TEXT },
  { value: OTHER_VALUE, text: OTHER_TEXT },
];

export const INTENDED_PURPOSE_VISIBILITY_VALUES = [
  EXTERNAL_GIFT_VALUE,
  ATHLETE_SERVICE_EQUIPMENT_VALUE,
  ATHLETE_SERVICE_MERCH_VALUE,
  CATALYST_MERCH_VALUE,
  CONTENT_CREATION_VALUE,
  INTERNAL_RECOGNITION_VALUE,
  INTERNAL_USE_VALUE,
  SEEDING_VALUE,
  OTHER_VALUE,
];

export const NAME_OF_RECIPIENT_VISIBILITY_VALUES = [
  ATHLETE_SERVICE_EQUIPMENT_VALUE,
  ATHLETE_SERVICE_MERCH_VALUE,
  EXTERNAL_GIFT_VALUE,
  CATALYST_MERCH_VALUE,
  INTERNAL_RECOGNITION_VALUE,
  SEEDING_VALUE,
  OTHER_VALUE,
];
