export const addressOverrideFieldNames = {
  city: 'city',
  name1: 'name1',
  name2: 'name2',
  region: 'region',
  street1: 'street1',
  street2: 'street2',
  shop: 'shop',
  country: 'country',
  district: 'district',
  telephone: 'telephone',
  postalCode: 'postalCode',
  addressOverride: 'addressOverride',
  forwardingAgent: 'forwardingAgent',
  transportationZone: 'transportationZone',
} as const;

export const conditionalyRequiredAddressOverrideFields = [
  addressOverrideFieldNames.city,
  addressOverrideFieldNames.name1,
  addressOverrideFieldNames.region,
  addressOverrideFieldNames.country,
  addressOverrideFieldNames.street1,
  addressOverrideFieldNames.postalCode,
] as const;
