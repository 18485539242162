import moment from 'moment';
import { addDaysToDate, addBusinessDaysToDate } from '@/utils/singleOrderCreate';

export function dependencyRulesActionSetMinDate({
  result,
  fieldName,
  dependency,
  lineLevelData,
}) {
  const lineLevelCells = structuredClone(lineLevelData);

  if (!result) {
    return;
  }
  let minDate = result;
  if (dependency.actionArgs?.add) {
    const momentFromResult = moment(result).startOf('day');

    if (dependency.actionArgs.unit === 'calendarDay') {
      minDate = addDaysToDate(momentFromResult, dependency.actionArgs.add);
    } else if (dependency.actionArgs.unit === 'businessDay') {
      minDate = addBusinessDaysToDate(momentFromResult, dependency.actionArgs.add);
    }
  }

  lineLevelCells[fieldName].minDate = minDate;

  return lineLevelCells;
}
