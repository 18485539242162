import { LineLevelValidationRulesEnum } from '@/controllers/order/validations/line/index';

export const requiredRuleValidation = ({
  formFieldData,
  required: configRequired,
  errorMessage = 'This field is required',
}) => {
  formFieldData.errors = formFieldData.errors?.filter(
    (error) => !error[LineLevelValidationRulesEnum.required],
  );

  if (configRequired === 'columnDefined') {
    if (!formFieldData.value && formFieldData.required && !formFieldData.disabled) {
      formFieldData.errors.push({ [LineLevelValidationRulesEnum.required]: errorMessage });
    }
  } else if (configRequired === true) {
    if (!formFieldData.value && !formFieldData.disabled) {
      formFieldData.errors.push({ [LineLevelValidationRulesEnum.required]: errorMessage });
    }
  }

  return formFieldData;
};
