import { mdi } from 'vuetify/iconsets/mdi';

import { nowAliases, nowIcons } from './icons';

import colors from '../colors';

export default {
  theme: {
    options: {
      customProperties: true,
      variations: false,
    },
    icons: {
      iconfont: 'mdiSvg',
    },
    themes: {
      light: {
        dark: false,
        colors,
      },
    },
  },
  icons: {
    defaultSet: 'mdi',
    aliases: nowAliases,
    sets: {
      now: nowIcons,
      mdi,
    },
  },
};
