export const SET_IS_APPROVAL_FLOW = 'SET_IS_APPROVAL_FLOW';

export const SET_APPROVAL_STATUS_VALUE = 'SET_APPROVAL_STATUS_VALUE';
export const SET_APPROVAL_COMMENT_VALUE = 'SET_APPROVAL_COMMENT_VALUE';

export const SET_TOTAL_APPROVAL_COUNT = 'SET_TOTAL_APPROVAL_COUNT';
export const SET_IS_APPROVALS_LOADING = 'SET_IS_APPROVALS_LOADING';

export const SET_HEADER_LEVEL_FIELD_VALUES = 'SET_HEADER_LEVEL_FIELD_VALUES';
export const SET_APPROVAL_MAJOR_FIELDS_DATA = 'SET_APPROVAL_MAJOR_FIELDS_DATA';
export const SET_APPROVAL_ROW_DATA = 'SET_APPROVAL_ROW_DATA';
export const SET_LINES_APPROVAL_STATUS = 'SET_LINES_APPROVAL_STATUS';
export const SET_APPROVAL_ORDER_ID = 'SET_APPROVAL_ORDER_ID';
export const SET_USER_CONTEXT_ROLE = 'SET_USER_CONTEXT_ROLE';

export const SET_ORDER_APPROVAL_SUBMIT_LOADING = 'SET_ORDER_APPROVAL_SUBMIT_LOADING';
export const SET_ORDER_APPROVAL_DRAFT_SAVE_LOADING = 'SET_ORDER_APPROVAL_DRAFT_SAVE_LOADING';

export const SET_APPROVAL_SUBMIT_ERROR = 'SET_APPROVAL_SUBMIT_ERROR';
export const SET_SELECTED_ORDER = 'SET_SELECTED_ORDER';
