/**
 * Returns TRUE if at least one cell of the column has value, otherwise returns FALSE.
 */
export const dependencyRuleColumnHasValue = ({
  rows = [],
  dependency = {},
}) => {
  return rows.some(row => {
    const cellFieldValue = row?.[dependency.sourceFieldKey]?.value;

    return cellFieldValue || cellFieldValue === false;
  });
};
