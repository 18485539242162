/**
 * Key: HTTP response status code
 * Value: Key from src/enums/language/locales
 */
export default {
  400: 'badRequest',
  401: 'unauthorized',
  403: 'forbidden',
  404: 'notFound',
  408: 'requestTimeout',
  500: 'internalServerError',
  503: 'externalServiceUnavailable',
  unknown: 'unknownNetworkError', // Unknown in this scope
};
