import shared from './shared/actions';
import orderTypes from './orderTypes/actions';
import businessTypes from './businessType/actions';
import customerDetails from './customerDetails/actions';
import filters from './filters/actions';

export default {
  ...shared,
  ...orderTypes,
  ...businessTypes,
  ...customerDetails,
  ...filters,
};
