import repository from '@/api/repositories/serverRepository';
import { MAINTENANCE } from '@/api/endpoints';

export default {
  getOutageStatus: () => repository.get(MAINTENANCE.GET_OUTAGE_STATUS),

  enableOutage: (data = {}) => repository.post(MAINTENANCE.ENABLE_OUTAGE, data),

  disableOutage: () => repository.post(MAINTENANCE.DISABLE_OUTAGE),

  getServiceDownStatus: () => repository.get(MAINTENANCE.GET_SERVICE_DOWN_STATUS),

  enableServiceDown: () => repository.post(MAINTENANCE.ENABLE_SERVICE_DOWN),

  disableServiceDown: () => repository.post(MAINTENANCE.DISABLE_SERVICE_DOWN),
};
