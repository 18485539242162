// TODO: Will return value of updatedField instead of behaving like dependencyRuleIsParentFilled
// TODO: More improving is needed here... In order to detect if value changed check TODOs in
// src/controllers/lineLevelDependencies/conditions/columnChangedValue.js

/**
 * @returns {(*|undefined)}
 *
 * Returns VALUE of updatedField if Master field has been changed by the User action
 * Otherwise returns undefined
 */
export const dependencyRuleChangeNodeValue = ({
  dependency, initialForm, updatedField,
}) => {
  const { fields } = initialForm || { fields: [] };
  const srcField = fields.find(fld => fld.key === dependency.sourceFieldKey);

  if (
    updatedField
    && updatedField.key === srcField.key
    && updatedField.value !== srcField.value
  ) {
    return updatedField.value;
  }
};
