/**
 * Sets required status of LL field (AgGrid cell) AND column
 *
 * There are 2 use cases:
 * 1. actionArgs if provided is used as explicit required setter. Condition result is used as conditional statement (flow control) whether this rule will execute or not
 * 2. actionArgs if NOT provided this rule will always execute and condition result will be used as required status setter value
 *
 * NOTE: General rule is: If at least one LL field/cell in Column is required - the column to which field/cell belongs to is also required and marked with asterisk
 *
 * ! (1) The fact that comment block is this big indicates that code is not clear enough. We might want to split logic - one method responsible for the column required status and another one for field/cell
 *
 * ? (2) Method name: SetRequired OR SetColumnRequired OR SetColumnAndFieldRequired?
 * TODO: (1) and (2)... Low priority.
 */
export function dependencyRuleActionSetRequired({
  fieldName,
  result,
  lineLevelData,
  dependencyRule = {},
  columnRequiredStatusUpdates,
}) {
  let newRequiredStatus;
  if (!dependencyRule?.actionArgs) {
    newRequiredStatus = result;
  } else if (result && dependencyRule?.actionArgs) {
    newRequiredStatus = dependencyRule.actionArgs?.required;
  }

  if (
    typeof newRequiredStatus !== 'boolean'
    || lineLevelData[fieldName]?.required === newRequiredStatus
  ) {
    return lineLevelData;
  }

  columnRequiredStatusUpdates.set(fieldName, { field: fieldName, required: newRequiredStatus });

  return {
    ...lineLevelData,
    [fieldName]: { ...lineLevelData[fieldName], required: newRequiredStatus },
  };
}
