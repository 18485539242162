import table from './table';

const errorMessages = {
  DELETE_SUCCESS_MESSAGE: 'Order Has Been Successfully Deleted',
};

export default {
  ...table,
  ...errorMessages,
};
